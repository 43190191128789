export const paginationStyles = {
  orangePagination: {
    "& .MuiPaginationItem-root": {
      "&:hover": {
        backgroundColor: "#FFA500",
        color: "#fff",
      },
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#ef6c00",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#FFA700",
      },
    },
  },
};
