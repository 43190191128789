import React, { useState } from "react";

import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  LinearProgress,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { RenderCustomAccordions } from "./render-custom-accordions";

const CustomTableRow = styled(TableRow)({
  "&:hover": {
    cursor: "pointer",
  },
});

export const CvPublicRating = ({ ratings }) => {
  const [expandedRows, setExpandedRows] = useState([]);

  const handleOpenElement = (objKey) => {
    setExpandedRows((prevExpandedRows) => [...prevExpandedRows, objKey]);
  };

  const handleCloseElement = (objKey) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.filter((key) => key !== objKey)
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        margin: "40px 0",
        width: "70%",
      }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "500",
          }}
        >
          {ratings.comment}
        </Typography>
      </Box>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow root={"backgroundColor: #BEBEBE"}>
              <TableCell align="center">Расшифровка критерия</TableCell>
              <TableCell align="center">Комментарий</TableCell>
              <TableCell align="center">Значение критерия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(ratings).map((objKey) => {
              if (ratings[objKey].hasOwnProperty("comment")) {
                if (objKey !== "rateAll") {
                  return (
                    <React.Fragment key={objKey}>
                      <CustomTableRow
                        onClick={() =>
                          expandedRows.length
                            ? expandedRows.find((key) => key === objKey)
                              ? handleCloseElement(objKey)
                              : handleOpenElement(objKey)
                            : handleOpenElement(objKey)
                        }
                      >
                        <TableCell align="center">
                          <Typography
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {ratings[objKey].name}
                          </Typography>
                        </TableCell>

                        <TableCell align="center">
                          <Typography
                            sx={{
                              fontSize: "18px",
                              marginRight: "8px",
                            }}
                          >
                            {ratings[objKey].comment}
                          </Typography>
                        </TableCell>

                        <TableCell align="center">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{ width: "100px" }}>
                              {ratings[objKey].value < 0 && (
                                <LinearProgress
                                  variant="determinate"
                                  color="error"
                                  sx={{
                                    transform: "scaleX(-1)",
                                    height: "20px",
                                    width: "100px",
                                    backgroundColor: "transparent",
                                  }}
                                  value={
                                    Math.abs(ratings[objKey].value) > 100
                                      ? 100
                                      : Math.abs(ratings[objKey].value)
                                  }
                                />
                              )}
                            </Box>
                            <Box sx={{ width: "50px" }}>
                              <Typography
                                sx={{
                                  fontSize: "18px",
                                }}
                              >
                                {ratings[objKey].value}
                              </Typography>
                            </Box>
                            <Box sx={{ width: "100px" }}>
                              {ratings[objKey].value > 0 && (
                                <LinearProgress
                                  variant="determinate"
                                  color="success"
                                  sx={{
                                    height: "20px",
                                    width: "100px",
                                    ml: "5px",
                                    backgroundColor: "transparent",
                                  }}
                                  value={
                                    ratings[objKey].value > 100
                                      ? 100
                                      : ratings[objKey].value
                                  }
                                />
                              )}
                            </Box>
                          </Box>
                        </TableCell>
                      </CustomTableRow>
                      {expandedRows.find((key) => key === objKey) && (
                        <RenderCustomAccordions
                          ratings={ratings}
                          objKey={objKey}
                          showRecommendation={true}
                        />
                      )}
                    </React.Fragment>
                  );
                }
              }
            })}
            <TableRow>
              <TableCell align="center">
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  {ratings["rateAll"].name}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  {ratings["rateAll"].comment}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  {ratings["rateAll"].value}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
