import React, { useState, useEffect } from 'react';
import { InputBase, Divider, Typography, Paper } from '@mui/material';

export const BirthdayFieldCard = ({ textName, textstaff, setData }) => {
	const [expenseDate, setExpenseDate] = useState('');

	useEffect(() => {
		if (textstaff !== null && textstaff !== undefined) {
			setExpenseDate(textstaff);
		}
	}, [textstaff]);

	const handleChange = event => {
		const temp = event.target.value;

		setData(temp);
		setExpenseDate(temp);
	};

	return (
		<Paper
			elevation={6}
			sx={{
				display: 'flex',
				alignItems: 'center',
				minWidth: '200px',
				minHeight: '32px'
			}}
		>
			<Typography
				id='keep-mounted-modal-title'
				variant='h6'
				component='h2'
				sx={{
					display: 'flex',
					height: '100%',
					fontSize: '15px',
					bgcolor: '#fb8c00',
					color: '#852508',
					border: '1px solid',
					minWidth: '110px',
					textAlign: 'center',
					borderRadius: '4px',
					borderColor: '#a2a2a2',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				{textName}
			</Typography>
			<Divider sx={{ height: 24, ml: 0.5 }} orientation='vertical' />
			<InputBase
				id='date'
				label='Choose your birthdate'
				type='date'
				value={expenseDate}
				onChange={handleChange}
				inputlabelprops={{
					shrink: true
				}}
				sx={{ p: '0px 4px', ml: 1, flex: 1, fontSize: '15px' }}
			/>
		</Paper>
	);
};
