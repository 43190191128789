import React, { useState, useEffect } from "react";

import { Box, Modal, Alert } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const DelayedAlert = ({ text, severity, delay, setShowAlert }) => {
  const [isHide, setIsHide] = useState(false);

  useEffect(() => {
    const timerClose = setTimeout(() => {
      handleClose();
    }, delay);

      return () => {
          clearTimeout(timerClose);
      }
  }, []);

  const handleClose = () => {
    setIsHide(true);
    setShowAlert(false);
  };

  const showAlert = () => {
    if (!isHide) {
      return (
        <Modal
          keepMounted
          id="message-modal"
          open={!isHide}
          hideBackdrop={true}
          onDoubleClick={(event) => event.stopPropagation()}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          sx={{
              width: 'fit-content',
              height: 'fit-content',
              margin: 'auto'
          }}
        >
          <Box
              sx={{
                  position: "absolute",
                  width: 'max-content',
                  top: '-410px',
                  left: 0,
                  transform: "translate(-40%, 0)",
                  border: "2px solid #fb8c00",
                  borderRadius: "6px",
                  zIndex: 10000,
              }}
          >
            <Alert
              sx={{ fontSize: "20px" }}
              onClick={handleClose}
              variant="filled"
              icon={
                severity === "success" ? (
                  <CheckCircleOutlineIcon sx={{ fontSize: 30 }} />
                ) : severity === "warning" ? (
                  <WarningAmberIcon sx={{ fontSize: 30 }} />
                ) : severity === "info" ? (
                  <InfoOutlinedIcon sx={{ fontSize: 30 }} />
                ) : severity === "error" ? (
                  <ErrorOutlineIcon sx={{ fontSize: 30 }} />
                ) : (
                  {}
                )
              }
              severity={severity}
            >
              {text}
            </Alert>
          </Box>
        </Modal>
      );
    }
  };

  return <>{showAlert()}</>;
};
