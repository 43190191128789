import React, { useState, Fragment, useEffect } from 'react';

import { Box, TableBody, TableRow, TableCell } from '@mui/material';
import { UserStatsTableRow } from '../user-stats-table-row';
import { DealTableFormatDate } from '../../../../project-page/project-details-page/project-deal-information/deals-utils/deal-format-date';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';

const CustomTableRow = styled(TableRow)({
	'&:hover': {
		cursor: 'pointer'
	}
});

const CustomTableCell = styled(TableCell)({
	minWidth: '150px',
	textAlign: 'center'
});

export const SubscribersTableBody = ({ bot }) => {
	const [expandedRows, setExpandedRows] = useState([]);
	const [visibleRows, setVisibleRows] = useState(bot.sources);

	const handleOpenElement = objKey => {
		setExpandedRows(prevExpandedRows => [...prevExpandedRows, objKey]);
	};

	const handleCloseElement = objKey => {
		setExpandedRows(prevExpandedRows =>
			prevExpandedRows.filter(key => key !== objKey)
		);
	};

	//
	useEffect(() => {
		// Создаем объект для временного хранения данных по id
		let tempData = {};

		// Обходим исходный массив данных
		bot.sources.forEach(item => {
			let id = item.id;

			// Если id уже есть во временных данных
			if (tempData[id]) {
				// Обновляем lastUseDate, если текущая дата больше сохраненной
				tempData[id].lastUseDate =
					new Date(item.lastUseDate) > new Date(tempData[id].lastUseDate)
						? item.lastUseDate
						: tempData[id].lastUseDate;

				// Объединяем массив users
				tempData[id].users = [...item.users, ...tempData[id].users];
				// суммируем количество вызовов
				tempData[id].count = item.count + tempData[id].count;
			} else {
				// Если id отсутствует во временных данных, добавляем его
				tempData[id] = {
					id: id,
					name: item.name,
					lastUseDate: item.lastUseDate,
					users: item.users,
					count: item.count
				};
			}
		});

		// Преобразуем объект временных данных обратно в массив
		let resultArray = Object.values(tempData);

		setVisibleRows(resultArray);
	}, [bot]);

	return (
		<TableBody>
			{visibleRows
				.sort((a, b) => b.count - a.count)
				.map((source, index) => (
					<Fragment key={index}>
						<CustomTableRow
							onClick={() =>
								expandedRows.length
									? expandedRows.find(key => key === source.name)
										? handleCloseElement(source.name)
										: handleOpenElement(source.name)
									: handleOpenElement(source.name)
							}
						>
							<TableCell
								sx={{
									minWidth: '150px',
									display: 'flex',
									alignItems: 'center'
								}}
							>
								<span style={{ marginRight: '10px' }}>{source.name}</span>
								{expandedRows.find(key => key === source.name) ? (
									<KeyboardArrowUpIcon sx={{ color: '#ef6c00' }} />
								) : (
									<KeyboardArrowDownIcon />
								)}
							</TableCell>
							<CustomTableCell>{source.count}</CustomTableCell>
							<CustomTableCell>
								{source.lastUseDate
									? DealTableFormatDate(source.lastUseDate)
									: '-'}
							</CustomTableCell>
						</CustomTableRow>

						{expandedRows.find(key => key === source.name) && (
							<UserStatsTableRow
								source={
									visibleRows.filter(item => item.name === source.name)[0]
								}
							/>
						)}
					</Fragment>
				))}
			<Box sx={{ mb: 2 }} />
		</TableBody>
	);
};
