import React, { Suspense, useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import { ThemeProvider } from '@mui/material/styles';
import { DefaultStyles } from '../../styles/default-styles/default-styles';
import { routes } from '../../routes/routes';
import { SideMenuUi } from '../lib/ui/side-menu-ui';
import { LoginForm } from '../lib/ui/login-form';
import { PublicConvertResumeTool } from '../pages/tool-page/public-convert-resume-tool';
import { LoaderLogo } from '../lib/ui/loader-logo';
import { AlertContextProvider } from '../contexts/AlertContext/AlertContext';
import { CurrentUserContextProvider } from '../contexts/CurrentUserContext/CurrentUserContext';
import { AllUsersContextProvider } from '../contexts/AllUsersContext/AllUsersContext';

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar
}));

export const App = () => {
	const [checkAbout, setCheckAbout] = useState(false);

	return (
		<ThemeProvider theme={DefaultStyles}>
			<div className='App'>
				{localStorage.getItem('refreshToken') && localStorage.getItem('accessToken') ? (
					<CurrentUserContextProvider>
						<AllUsersContextProvider>
							<AlertContextProvider>
								<SideMenuUi checkAbout={checkAbout} setCheckAbout={setCheckAbout} />
								<DrawerHeader />
								<Suspense fallback={<LoaderLogo />}>
									<Routes>
										{routes.map(route => (
											<Route key={route.path} path={route.path} element={route.element} />
										))}
									</Routes>
								</Suspense>
							</AlertContextProvider>
						</AllUsersContextProvider>
					</CurrentUserContextProvider>
				) : window.location.pathname === '/public-convert-resume-tool' ? (
					<PublicConvertResumeTool />
				) : (
					<LoginForm checkAbout={checkAbout} setCheckAbout={setCheckAbout} />
				)}
			</div>
		</ThemeProvider>
	);
};
