import React, {useEffect, useState} from "react";

import { TableBody, TableCell, Typography, TableRow } from "@mui/material";

import { styled } from "@mui/material/styles";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff3e0",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const UserBotsTableBody = ({ userBotsInfo, statuses }) => {

  return (
    <TableBody>
      {userBotsInfo.map((row, index) => (
        <StyledTableRow key={index}>
          <TableCell
            sx={{
              paddingRight: 0,
              maxWidth: "160px",
            }}
          >
            <Typography>{row.phone}</Typography>
          </TableCell>
          <TableCell
            sx={{
              paddingRight: 0,
              maxWidth: "160px",
            }}
          >
            <Typography>
              {statuses.filter((status) => status.id === row.status)[0].name ??
                "-"}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              paddingRight: 0,
              maxWidth: "160px",
            }}
          >
            <Typography>
              {row.bathUntilDateTime !== null
                ? new Date(Date.parse(row.bathUntilDateTime)).toLocaleDateString()
                : "-"}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              paddingRight: 0,
              maxWidth: "160px",
            }}
          >
            <Typography>
              {row.bathWithDateTime !== null
                ? new Date(Date.parse(row.bathWithDateTime)).toLocaleDateString()
                : "-"}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              paddingRight: 0,
              maxWidth: "160px",
            }}
          >
            <Typography>{row.isPrivate ? "Приватный" : "Публичный"}</Typography>
          </TableCell>
          <TableCell
            sx={{
              paddingRight: 0,
              maxWidth: "160px",
            }}
          >
            <Typography>{new Date(Date.parse(row.addDateTime)).toLocaleDateString()}</Typography>
          </TableCell>
          <TableCell
            sx={{
              paddingRight: 0,
              maxWidth: "160px",
            }}
          >
            <Typography>{row.lifetimeString}</Typography>
          </TableCell>
        </StyledTableRow>
      ))}
    </TableBody>
  );
};
