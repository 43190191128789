export const CANDIDATE_CARD_PAGE_CONFIG = {
	REQ_OPEN_BUTTON_LABEL: 'Проверить требования',
	COPY_STAFF_DATA: {
		FULLNAME: 'ФИО',
		BIRTHDAY: 'Дата рождения',
		LOCATION: 'Локация',
		EMAIL: 'Почта',
		SPLITTER: ' - '
	},
	CHECK_REQ_MODAL: {
		TITLE: 'Проверка списка требований',
		PLACEHOLDER: 'Введите список требований разделяя их пробелами и специальными символами',
		LABEL: 'Список требований',
		HELPER_TEXT: 'Введите список требований: с новой строки, через запятую, - или •',
		COPY_BUTTON_LABEL: 'Копировать',
		BUTTON_LABEL: 'Проверить',
		ALT_BUTTON_LABEL: 'Копировать',
		COPY_ALERT_LABEL: 'Требования скопированы в буфер обмена',
		FIND_INDICATOR: '✅',
		REPLACE_REGEX: /✅/g,
		SPLIT_REGEX: /\n|, ?/g,
		COPY_SIGN_ENDING: 'лет опыта.\n\n'
	},
	ADD_REQ_MODAL: {
		TITLE: 'Добавление требований',
		PLACEHOLDER: 'Введите требования, разделяя их пробелами или запятой',
		LABEL: 'Требования',
		BUTTON_LABEL: 'Добавить',
		SPLIT_REGEX: /\n|, ?/g,
		ROWS_COUNT: 7
	}
};
