import React, { useState, useEffect, useCallback, useRef } from 'react';
import { InputBase, Typography, Box } from '@mui/material';

export const TextFieldCV = ({ textData, setData, weight }) => {
	const [term, setTerm] = useState('');
	const [termTime, setTermTime] = useState();
	const [focused, setFocused] = useState(false);
	const [isChanged, setIsChanged] = useState(false);
	const inputRef = useRef(null);

	useEffect(() => {
		if (textData !== null && textData !== undefined) {
			setTerm(textData);
		}
	}, [textData]);

	const handleChange = useCallback(element => {
		const term = element.target.value;
		setTerm(term.replace(/^[\s.`":'$@~;]*$/g, ''));
		setTermTime(term.replace(/^[\s.`":'$@~;]*$/g, ''));
		setIsChanged(true);
	}, []);

	useEffect(() => {
		const handleBeforeUnload = event => {
			// Perform actions before the component unloads
			if (isChanged && textData.replace(/^[\s.`":'$@~;]*$/g, '') !== termTime) {
				event.preventDefault();
				event.returnValue = '';
			}
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [isChanged, termTime]);

	const handleBoxClick = () => {
		setFocused(true);
		// Асинхронная фокусировка, чтобы дать время на переключение состояния и рендеринг InputBase
		setTimeout(() => {
			if (inputRef.current) {
				inputRef.current.focus();
				const val = inputRef.current.value; // Получаем текущее значение
				inputRef.current.value = ''; // Сбрасываем значение
				inputRef.current.value = val; // Возвращаем обратно значение
			}
		}, 0);
	};

	return focused ? (
		<InputBase
			inputRef={inputRef}
			value={term}
			onChange={handleChange}
			onBlur={() => {
				const trimmedTerm = term.trimEnd(); // Удаление пробелов и пустых строк в конце строки
				setTerm(trimmedTerm); // Обновление состояния без лишних пробелов и пустых строк
				setFocused(false);
				setIsChanged(false);
				setData(trimmedTerm);
			}}
			multiline
			fullWidth
			sx={{
				p: 1,
				pl: 1,
				pr: 1,
				fontSize: '15px',
				fontWeight: weight ? 'bold' : '',
				backgroundColor: '#f6e9d6', // Изменение цвета фона при фокусе
				transition: 'background-color 0.3s'
			}}
		/>
	) : (
		<Box
			onClick={() => handleBoxClick()}
			onBlur={() => setFocused(false)}
			sx={{
				transition: 'background-color 0.1s',
				'&:hover': {
					backgroundColor: '#fcf0de',
					cursor: 'pointer'
				}
			}}
		>
			<Typography
				variant='h6'
				component='h2'
				style={{ whiteSpace: 'pre-wrap', wordBreak: 'normal' }}
				sx={{
					p: 1,
					pl: 1,
					pr: 1,
					fontSize: '15px',
					fontWeight: weight ? 'bold' : ''
				}}
			>
				{term}
			</Typography>
		</Box>
	);
};
