import React, { createContext, useState } from 'react';
import ALERT_CONTEXT_CONFIG from './config';
import { MessageAlert } from '../../lib/ui/alerts/message-alert';

const AlertContext = createContext();

/**
 * Компонент-обертка, предоставляющий контекст оповещения пользователя
 * @param {JSX.Element} children - Дочерние компоненты
 * @returns {JSX.Element} - Компонент-обертка с контекстом оповещения
 */
const AlertContextProvider = ({ children }) => {
	// Состояние для отображения оповещения
	const [alertIsShow, setAlertIsShow] = useState(false);
	// Сообщение, которое будет отображено в оповещении
	const [alertMessage, setAlertMessage] = useState(
		ALERT_CONTEXT_CONFIG.DEF_MESSAGE
	);
	// Тип оповещения (ошибка, предупреждение, информация)
	const [alertType, setAlertType] = useState(ALERT_CONTEXT_CONFIG.DEF_TYPE);

	const showAlert = ({ message, type }) => {
		setAlertIsShow(true);
		setAlertMessage(message);
		setAlertType(type);
	};

	return (
		<AlertContext.Provider value={{ showAlert }}>
			{children}
			{/* Компонент для отображения оповещения */}
			<MessageAlert
				open={alertIsShow}
				message={alertMessage}
				setIsOpen={setAlertIsShow}
				type={alertType}
			/>
		</AlertContext.Provider>
	);
};

export { AlertContext, AlertContextProvider };
