import React, { useState, useEffect } from 'react';

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Typography
} from '@mui/material';

import { TextFieldStackCV } from '../../ui/text-field-stack-CV';

import {
	CvEducationDeleteRequest,
	CVEducationUpdateRequest
} from '../../../../../../api/api';
import { boxStyles as styledBox } from '../../../../../../styles/default-styles/box-styles/box-styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { buttonStyles } from '../../../../../../styles/default-styles/button-styles/button-styles';

export const UpdatingCvEducationInfo = ({
	educationName,
	educationOrganization,
	educationResult,
	educationYear,
	id,
	check,
	setCheck
}) => {
	const [educationNameData, setEducationNameData] = useState(null);
	const [educationOrganizationData, setEducationOrganizationData] =
		useState(null);
	const [educationResultData, setEducationResultData] = useState(null);
	const [educationYearData, setEducationYearData] = useState(null);
	const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

	const deleteModalCloseHandler = () => {
		setDeleteModalIsOpen(false);
	};

	const deleteModalOpenHandler = () => {
		setDeleteModalIsOpen(true);
	};

	const convertToNumber = variable => {
		return variable === null ? null : Number(variable);
	};

	useEffect(() => {
		if (
			educationNameData ||
			educationNameData === '' ||
			educationOrganizationData ||
			educationOrganizationData === '' ||
			educationResultData ||
			educationResultData === '' ||
			educationYearData ||
			educationYearData === ''
		)
			CVEducationUpdateRequest(
				id,
				educationNameData,
				educationOrganizationData,
				educationResultData,
				convertToNumber(educationYearData)
			)
				.then(() => {
					setCheck(!check);
				})
				.catch(requestError => {
					console.error(requestError.message);
					if (requestError.response.status === 401)
						localStorage.setItem('tokenError', true);
				});
	}, [
		educationNameData,
		educationOrganizationData,
		educationResultData,
		educationYearData,
		id
	]);

	const deleteEducation = async educationId => {
		try {
			await CvEducationDeleteRequest(educationId);

			setCheck(!check);
			deleteModalCloseHandler();
		} catch (requestError) {
			console.error(requestError.message);
			if (requestError.response.status === 401)
				localStorage.setItem('tokenError', true);
		}
	};

	return (
		<>
			<Grid item xs={6}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start',
						pb: 2
					}}
				>
					<Typography
						variant='h6'
						component='h2'
						sx={{
							fontSize: '20px',
							fontWeight: 'bold',
							p: '5px'
						}}
					>
						Учебное заведение:
					</Typography>
					<Box sx={{ ...styledBox.flexBoxRow, gap: '.5rem' }}>
						<TextFieldStackCV
							textData={educationName}
							setData={setEducationNameData}
						/>
						<IconButton onClick={deleteModalOpenHandler}>
							<DeleteIcon />
						</IconButton>
					</Box>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start',
						pb: 2
					}}
				>
					<Typography
						variant='h6'
						component='h2'
						sx={{
							fontSize: '20px',
							fontWeight: 'bold',
							p: '5px'
						}}
					>
						Факультет:
					</Typography>

					<TextFieldStackCV
						textData={educationOrganization}
						setData={setEducationOrganizationData}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start',
						pb: 2
					}}
				>
					<Typography
						variant='h6'
						component='h2'
						sx={{
							fontSize: '20px',
							fontWeight: 'bold',
							p: '5px'
						}}
					>
						Специализация:
					</Typography>

					<TextFieldStackCV
						textData={educationResult}
						setData={setEducationResultData}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start',
						pb: 2
					}}
				>
					<Typography
						variant='h6'
						component='h2'
						sx={{
							fontSize: '20px',
							fontWeight: 'bold',
							p: '5px'
						}}
					>
						Год окончания:
					</Typography>

					<TextFieldStackCV
						textData={educationYear}
						setData={setEducationYearData}
						yearField={true}
					/>
				</Box>
			</Grid>
			<Dialog open={deleteModalIsOpen} onClose={deleteModalCloseHandler}>
				<DialogTitle>
					<Typography
						variant='body1'
						sx={{ fontSize: '27px', marginBottom: 2, marginTop: 2 }}
					>
						Вы точно хотите удалить?
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Typography
						variant='body1'
						color='text.secondary'
						sx={{ fontSize: '24px', marginBottom: 10 }}
					>
						Образование нельзя будет восстановить!
					</Typography>
				</DialogContent>
				<DialogActions>
					<Box sx={{ marginBottom: 4, marginRight: 3 }}>
						<Button
							onClick={() => deleteEducation(id)}
							color='error'
							size='large'
							sx={{ ...buttonStyles.orangeButton, fontSize: '18px', mr: 2 }}
						>
							Удалить образование
						</Button>
						<Button
							onClick={deleteModalCloseHandler}
							color='secondary'
							size='large'
							sx={{ ...buttonStyles.orangeButton, fontSize: '18px' }}
						>
							Отмена
						</Button>
					</Box>
				</DialogActions>
			</Dialog>
		</>
	);
};
