import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { IconButton } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DeleteIcon from '@mui/icons-material/Delete';
import { ItemContainer, TransparentTextField } from './requirement-grid-item-styles';
import { useCommentUpdate } from '../../../../../../../../hooks/candidates-card-update-comment/useCommentUpdate';

/**
 * Компонент элемента сетки требований
 *
 * @param {object} props - Свойства компонента
 * @param {string | null} props.id - Идентификатор элемента
 * @param {string} props.defaultValue - Начальное значение
 * @param {function} props.onDelete - Функция удаления элемента
 * @param {function} props.onChange - Функция изменения элемента
 * @returns {JSX.Element} - Возвращает JSX элемент
 */
export const RequirementGridItem = ({ id = null, defaultValue = '', onDelete = id => {}, onChange = p => {} }) => {
	const textAreaRef = useRef();

	/**
	 * Функция для автоматического изменения высоты текстового поля
	 */
	const autoHeightResize = useCallback(() => {
		if (textAreaRef.current) {
			textAreaRef.current.style.height = 'auto'; // Сбрасывает высоту перед вычислением новой
			textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // Устанавливает высоту по содержимому
		}
	}, []);

	const [value, handleChange] = useCommentUpdate(
		defaultValue,
		500,
		useCallback(newValue => {
			onChange({ id, newValue });
		}, [])
	);

	useLayoutEffect(() => {
		autoHeightResize();
	}, [value]);

	return (
		<ItemContainer>
			<FiberManualRecordIcon
				className='requirement-grid-item_list-dot'
				fontSize='5px'
				sx={{ transition: 'color 0.5s ease', color: `#C0C0C0` }}
			/>
			<TransparentTextField ref={textAreaRef} value={value} onChange={handleChange} rows={1} />
			<IconButton
				className='requirement-grid-item_button_delete'
				size='small'
				sx={{ transition: 'opacity 0.3s ease', opacity: 0, visibility: 'hidden' }}
				onClick={() => onDelete(id)}>
				<DeleteIcon fontSize='small' />
			</IconButton>
		</ItemContainer>
	);
};
