export const StyledTabs = {
	styledTabs: {
		//для стилизования <Tabs />
		'& .Mui-selected': { color: '#FFFFFF' },
		'& .MuiTabs-indicator': { bgcolor: '#852508' }
	},

	styledTab: {
		//для стилизования <Tab />
		bgcolor: '#ef6c00',
		color: '#FFFFFF',
		fontWeight: 'bold',
		opacity: 0.5, // set your preferred opacity for non-selected tabs
		'&.Mui-selected': {
			color: '#FFFFFF',
			opacity: 1 // This resets the opacity for the selected tab
		},
		'&:hover': {
			bgcolor: '#ff9800',
			color: '#FFFFFF',
			opacity: 1, // This resets the opacity for the hovered tab
			transform: 'scale(1.03)'
		}
	}
};
