import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { orange, red } from '@mui/material/colors';

export const HelperText = styled(Typography)(({ theme, valid }) => ({
	padding: '.4rem',
	width: '100%',
	backgroundColor: valid === 'false' ? red[600] : orange[600],
	color: '#FFF',
	boxSizing: 'border-box',
	fontWeight: 600
}));
