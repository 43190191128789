import React, { useState, useEffect } from "react";

import { getCandidateGradeRequest } from "../../../../../../api/api";

import { Box, Typography, MenuItem, Menu } from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export const CandidateGrade = ({ textstaff, setData }) => {
  const [term, setTerm] = useState("");

  const [grade, setGrade] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    getCandidateGradeRequest()
      .then((data) => {
        setGrade(data);
      })
      .catch((requestError) => console.error(requestError.message));
  }, []);

  useEffect(() => {
    if (textstaff !== null && textstaff !== undefined) {
      const filteredResult = [];
      for (let i = 0; i < grade.length; i++) {
        if (grade[i].id === textstaff) {
          filteredResult.push(grade[i]);
        }
      }
      setTerm(filteredResult[0]?.name);
    }
  }, [textstaff, grade]);

  const handleChange = (grade) => {
    setTerm(grade.name);
    setData(grade.id);
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
        }}
        onClick={handleClick}
      >
        <Box sx={{ flexGrow: 1, userSelect: "none", width: "100px" }}>
          <Typography id="keep-mounted-modal-title" sx={{ p: "2px 4px", ml: 1, fontSize: "15px" }}>
            {term?.replace(/^\d+\.\s/, "")}
          </Typography>
        </Box>
        <Box>
          <Box size="small" sx={{ color: "#696969", cursor: "pointer" }}>
            {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Box>
        </Box>
      </Box>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} sx={{ maxHeight: "500px" }}>
        {grade?.map((row) => (
          <MenuItem key={row.id} onClick={() => handleChange(row)}>
            {row.name.replace(/^\d+\.\s/, "")}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
