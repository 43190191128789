export const BENCH_HEADER_COLUMNS = [
	{
		id: 'candidates',
		label: '',
		align: 'center',
		styles: {
			fontSize: '16px',
			padding: '5px'
		}
	},
	{
		id: 'days',
		label: 'Дней на бенче',
		popoverText: 'Сколько дней кандидат находится на бенче на момент сегодня',
		align: 'center',
		styles: {
			fontSize: '16px',
			padding: '5px'
		}
	},
	{
		id: 'stack',
		label: 'Подали на запрос',
		popoverText: 'Количество запросов, на которые подали кандидата за все время',
		align: 'center',
		styles: {
			fontSize: '16px',
			padding: '5px'
		}
	},
	{
		id: 'interviewsCount',
		label: 'Собесов',
		popoverText: 'Количество собеседований, назначенных кандидату за все время',
		align: 'center',
		styles: {
			fontSize: '16px',
			padding: '5px'
		}
	},
	{
		id: 'interviewsPercents',
		label: 'Собесов %',
		popoverText: 'Показатель перехода с этапа Подали на запрос к этапу Собес',
		align: 'center',
		styles: {
			fontSize: '16px',
			padding: '5px'
		}
	},
	{
		id: 'requestsPerDay',
		label: 'Запросов в день',
		popoverText: 'Количество запросов в день, которое отправляем на кандидата',
		align: 'center',
		styles: {
			fontSize: '16px',
			padding: '5px'
		}
	},
	{
		id: 'requestsGraph',
		label: 'Конверсия в собес',
		popoverText:
			'Графичное отображение процента запросов на которые подали кандидата/процента запросов которые перешли на собес',
		align: 'center',
		styles: {
			fontSize: '16px',
			padding: '8px'
		}
	}
];

export const TABS_CONFIG = [
	{
		id: 0,
		label: 'На бенче'
	},
	{
		id: 1,
		label: 'Весь период'
	}
];

export const BENCH_MAP_CONFIG = {
	COPY_MESSAGE: 'Бенч карта скопирована в буфер обмена'
};
