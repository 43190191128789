import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';

import { styled } from '@mui/material/styles';

import {
	Box,
	List,
	IconButton,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Tooltip,
	AppBar,
	Toolbar
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home';
import PieChartIcon from '@mui/icons-material/PieChart';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SettingsIcon from '@mui/icons-material/Settings';
import ListAltIcon from '@mui/icons-material/ListAlt';
import HandymanIcon from '@mui/icons-material/Handyman';
import PeopleIcon from '@mui/icons-material/People';
import SourceIcon from '@mui/icons-material/Source';
import ViewListIcon from '@mui/icons-material/ViewList';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EventNoteIcon from '@mui/icons-material/EventNote';

import { ProfileButton } from '../buttons/profile-button';
import { NotificationsButton } from '../buttons/notifications-button';
import { BenchMap } from '../../../pages/candidates-page/bench-map';

const drawerWidth = 300;

const openedMixin = theme => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen
	}),
	overflowX: 'hidden'
});

const closedMixin = theme => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	overflowX: 'hidden',
	width: 0,
	[theme.breakpoints.up('md')]: {
		width: `calc(${theme.spacing(10)} + 1px)`
	}
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar
}));

const MuiAppBar = styled(AppBar, {
	shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	})
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme)
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme)
	})
}));

export const SideMenuUi = ({ checkAbout, setCheckAbout }) => {
	const location = useLocation();

	const [open, setOpen] = React.useState(false);
	const [selectedIndex, setSelectedIndex] = useState(0);

	const drawerRef = useRef(null);

	const navigate = useNavigate();

	const handleDrawerOpen = () => {
		setOpen(!open);
	};

	const handleListItemClick = index => {
		setSelectedIndex(index);
		// Дополнительный код для перехода на соответствующую страницу
	};

	useEffect(() => {
		const handleMouseDown = event => {
			if (drawerRef.current && !drawerRef.current.contains(event.target)) {
				setOpen(false);
			}
		};
		document.addEventListener('mousedown', handleMouseDown);
		return () => {
			document.removeEventListener('mousedown', handleMouseDown);
		};
	}, [drawerRef]);

	useEffect(() => {
		switch (location.pathname) {
			case '/':
				setSelectedIndex(0);
				break;
			case '/details':
				setSelectedIndex(1);
				break;
			case '/sources':
				setSelectedIndex(2);
				break;
			case '/stats':
				setSelectedIndex(3);
				break;
			case '/candidates':
				setSelectedIndex(4);
				break;
			case '/project':
				setSelectedIndex(5);
				break;
			case '/deals':
				setSelectedIndex(6);
				break;
			case '/analysis-page':
				setSelectedIndex(7);
				break;
			case '/interviews-page':
				setSelectedIndex(8);
				break;
			case '/tools':
				setSelectedIndex(9);
				break;
			case '/administration':
				setSelectedIndex(10);
				break;
			default:
				if (location.pathname.startsWith('/project/')) {
					setSelectedIndex(5);
				} else if (location.pathname.startsWith('/candidate')) {
					setSelectedIndex(4);
				} else if (
					location.pathname.startsWith('/test-parsed-messages-tool') ||
					location.pathname.startsWith('/convert-resume-tool') ||
					location.pathname.startsWith('/public-convert-resume-tool')
				) {
					setSelectedIndex(9);
				} else if (
					location.pathname.startsWith('/addSample') ||
					location.pathname.startsWith('/stats') ||
					location.pathname.startsWith('/cvIndividual')
				) {
					setSelectedIndex(3);
				} else if (location.pathname.startsWith('/details')) {
					setSelectedIndex(1);
				} else {
					setSelectedIndex(0);
				}
				break;
		}
	}, [location]);

	return (
		<Box sx={{ flexGrow: 1 }}>
			<MuiAppBar
				position='fixed'
				sx={{ bgcolor: 'white', color: '#696969' }}
				open={open}
			>
				<Toolbar>
					<IconButton
						color='inherit'
						aria-label='open drawer'
						onClick={handleDrawerOpen}
						edge='start'
						sx={{
							marginRight: 5,
							pl: 0.7,
							...(open && { display: 'none' })
						}}
					>
						<MenuIcon sx={{ fontSize: 40 }} />
					</IconButton>
					<Box sx={{ flexGrow: 1, alignItems: 'center', display: 'flex' }}>
						<img
							style={{ objectFit: 'cover', width: 'auto', height: '56px', marginRight: '20px' }}
							src={`/logo.png`}
							alt=''
						/>
						<BenchMap />
					</Box>
					<NotificationsButton />
					<ProfileButton
						checkAbout={checkAbout}
						setCheckAbout={setCheckAbout}
					/>
				</Toolbar>
			</MuiAppBar>
			<Drawer variant='permanent' open={open} ref={drawerRef}>
				<DrawerHeader>
					<IconButton onClick={handleDrawerOpen}>
						<ChevronLeftIcon sx={{ fontSize: 40 }} color={'warning'} />
					</IconButton>
				</DrawerHeader>
				<List>
					{[
						'Главная',
						'Детализация запросов',
						'Источники',
						'Выборки CV',
						'Кандидаты',
						'Проекты',
						'Сделки',
						'Аналитика',
						'Собеседования',
						'Инструменты',
						'Администрирование'
					].map((text, index) => (
						<ListItem key={text} disablePadding sx={{ display: 'block' }}>
							<Tooltip title={open ? '' : text} placement='right'>
								<ListItemButton
									selected={selectedIndex === index}
									onMouseDown={event => {
										if (event.button === 1) {
											event.preventDefault();
											window.open(
												index === 0
													? '/'
													: index === 1
														? '/details'
														: index === 2
															? '/sources'
															: index === 3
																? '/stats'
																: index === 4
																	? '/candidates'
																	: index === 5
																		? '/project'
																		: index === 6
																			? '/deals'
																			: index === 7
																				? '/analysis-page'
																				: index === 8
																					? '/interviews-page'
																					: index === 9
																						? '/tools'
																						: index === 10
																							? '/administration'
																							: {}
											);
										}
									}}
									onClick={() => {
										handleListItemClick(index);
										navigate(
											index === 0
												? '/'
												: index === 1
													? '/details'
													: index === 2
														? '/sources'
														: index === 3
															? '/stats'
															: index === 4
																? '/candidates'
																: index === 5
																	? '/project'
																	: index === 6
																		? '/deals'
																		: index === 7
																			? '/analysis-page'
																			: index === 8
																				? '/interviews-page'
																				: index === 9
																					? '/tools'
																					: index === 10
																						? '/administration'
																						: {}
										);
									}}
									sx={{
										minHeight: 48,
										justifyContent: open ? 'initial' : 'center',
										px: 2.5,
										mb: 3,
										'&.Mui-selected': {
											backgroundColor: '#fff3e0',
											'&:hover': {
												backgroundColor: '#ffe0b2'
											}
										},
										'&:hover': {
											backgroundColor: 'rgba(0, 0, 0, 0.1)'
										}
									}}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : 'auto',
											justifyContent: 'center'
										}}
									>
										{index === 0 ? (
											<HomeIcon
												sx={{ fontSize: 35 }}
												color={selectedIndex === 0 ? 'warning' : ''}
											/>
										) : index === 1 ? (
											<ContentCopyIcon
												sx={{ fontSize: 35 }}
												color={selectedIndex === 1 ? 'warning' : ''}
											/>
										) : index === 2 ? (
											<SourceIcon
												sx={{ fontSize: 35 }}
												color={selectedIndex === 2 ? 'warning' : ''}
											/>
										) : index === 3 ? (
											<PieChartIcon
												sx={{ fontSize: 35 }}
												color={selectedIndex === 3 ? 'warning' : ''}
											/>
										) : index === 4 ? (
											<PeopleIcon
												sx={{ fontSize: 35 }}
												color={selectedIndex === 4 ? 'warning' : ''}
											/>
										) : index === 5 ? (
											<ListAltIcon
												sx={{ fontSize: 35 }}
												color={selectedIndex === 5 ? 'warning' : ''}
											/>
										) : index === 6 ? (
											<ViewListIcon
												sx={{ fontSize: 35 }}
												color={selectedIndex === 6 ? 'warning' : ''}
											/>
										) : index === 7 ? (
											<AssessmentIcon
												sx={{ fontSize: 35 }}
												color={selectedIndex === 7 ? 'warning' : ''}
											/>
										) : index === 8 ? (
											<EventNoteIcon
												sx={{ fontSize: 35 }}
												color={selectedIndex === 8 ? 'warning' : ''}
											/>
										) : index === 9 ? (
											<HandymanIcon
												sx={{ fontSize: 35 }}
												color={selectedIndex === 9 ? 'warning' : ''}
											/>
										) : index === 10 ? (
											<SettingsIcon
												sx={{ fontSize: 35 }}
												color={selectedIndex === 10 ? 'warning' : ''}
											/>
										) : (
											{}
										)}
									</ListItemIcon>
									<ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
								</ListItemButton>
							</Tooltip>
						</ListItem>
					))}
				</List>
			</Drawer>
		</Box>
	);
};
