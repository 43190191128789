import React, { useState, useEffect } from "react";

import { Box, Button, TextField, Stack, Typography } from "@mui/material";

import { PasswordInput } from "../password-input";
import { ResetPasswordForm } from "../reset-password-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const AuthorizationForm = ({
  Auth,
  showRegistration,
  setShowRegistration,
  checkAbout,
  setCheckAbout,
}) => {
  const [checkButton, setCheckButton] = useState(false);

  const [emailData, setEmailData] = useState("");
  const [passwordData, setPasswordData] = useState("");

  const [resettingPassword, setResettingPassword] = useState(false);

  useEffect(() => {
    if (emailData !== "" && passwordData !== "") {
      setCheckButton(true);
    } else {
      setCheckButton(false);
    }
  }, [emailData, passwordData]);

  const handleChangeEmail = (event) => {
    const temp = event.target.value;
    setEmailData(temp.replaceAll(/^[\s.`":'$@~;]*$/g, ""));
  };

  const handleAuth = () => {
    Auth(emailData, passwordData);
  };

  const handleResettingPassword = () => {
    setResettingPassword(!resettingPassword);
  };

  return (
    <>
      {!resettingPassword && (
        <Stack spacing={3}>
          <Typography
            id="keep-mounted-modal-title"
            variant="h6"
            component="h2"
            align="center"
            sx={{
              fontSize: "30px",
            }}
          >
            Вход в профиль
          </Typography>
          <TextField
            id="outlined-basic"
            name="email"
            type="email"
            label="Электронная почта"
            value={emailData}
            onChange={handleChangeEmail}
          />

          <PasswordInput
            id="password"
            name="password"
            complete="password"
            label="Пароль"
            setData={setPasswordData}
          />
        </Stack>
      )}
      <Stack spacing={3}>
        <Box
          onClick={handleResettingPassword}
          sx={{
            mt: 1,
            color: "#696969",
            cursor: "pointer",
            "&:hover": {
              color: "#b9b9b9",
            },
          }}
        >
          {resettingPassword ? <ArrowBackIcon /> : "Забыли пароль?"}
        </Box>
        {resettingPassword && (
          <ResetPasswordForm
            emailData={emailData}
            passwordData={passwordData}
            checkAbout={checkAbout}
            setCheckAbout={setCheckAbout}
            Auth={Auth}
          />
        )}

        {!resettingPassword && (
          <Button
            variant="contained"
            disabled={!checkButton || resettingPassword}
            sx={{
              fontSize: 20,
              color: "#696969",
              backgroundColor: "white",
              "&:hover": {
                backgroundColor: "#EEEEED",
              },
            }}
            onClick={handleAuth}
          >
            Войти
          </Button>
        )}
      </Stack>

      <Box sx={{ display: "flex", mt: 1 }}>
        Ещё нет профиля?
        <Box
          sx={{
            ml: 1,
            color: "#696969",
            cursor: "pointer",
            "&:hover": {
              color: "#b9b9b9",
            },
          }}
          onClick={() => setShowRegistration(!showRegistration)}
        >
          Зарегистрируйтесь
        </Box>
      </Box>
    </>
  );
};
