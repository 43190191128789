import { createContext, useEffect, useState } from 'react';
import { getRecruitersRoles } from '../../../api/api';
import { requestErrorWrapper } from '../../../utils/request-error-wrapper/request-error-wrapper';

const AllUsersContext = createContext();

const AllUsersContextProvider = ({ children }) => {
	const [allUsers, setAllUsers] = useState([]);
	const [updateTrigger, setUpdateTrigger] = useState(false);

	const updateUsers = () => {
		setUpdateTrigger(!updateTrigger);
	};

	const getAllUsers = async () => {
		const {
			payload: { users }
		} = await requestErrorWrapper({ handleRequest: () => getRecruitersRoles() });

		setAllUsers(users);
	};

	useEffect(() => {
		getAllUsers();
	}, [updateTrigger]);

	return <AllUsersContext.Provider value={{ allUsers, updateUsers }}>{children}</AllUsersContext.Provider>;
};

export { AllUsersContext, AllUsersContextProvider };
