import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { orange } from '@mui/material/colors';

export const GridItemWrapper = styled(Box)(() => ({
	gridColumn: 'span 6',
	'@media screen and (max-width: 1000px)': {
		gridColumn: 'span 12'
	}
}));

export const GridContainer = styled(Box)(() => ({
	display: 'grid',
	gridTemplateColumns: 'repeat(12, 1fr)',
	gap: '1rem',
	padding: '1rem',
	boxSizing: 'border-box',
	border: `2px solid`,
	borderColor: orange[800],
	borderRadius: '.9rem'
}));

export const GridItemShort = styled(Box)(() => ({
	gridColumn: 'span 2',
	height: 'fit-content',
	'@media screen and (max-width: 1400px)': {
		gridColumn: 'span 12'
	}
}));

export const GridItemLong = styled(Box)(() => ({
	gridColumn: 'span 10',
	'@media screen and (max-width: 1400px)': {
		gridColumn: 'span 12'
	}
}));
