import React, { useState, useRef, useEffect } from 'react';

import {
	Autocomplete,
	MenuItem,
	TextField,
	FormControlLabel,
	Checkbox,
	Chip
} from '@mui/material';
import { checkBoxStyles } from '../../../../../../styles/default-styles/check-box-styles/check-box-styles';

export const FilterSources = ({
	filterOptions,
	setFilter,
	filterValues,
	setActiveFilters,
	filterGroup
}) => {
	const [selectedOption, setSelectedOption] = useState(() => {
		const excludedKeys = ['all', 'none'];
		const keysArray = Object.keys(filterOptions).filter(
			key => !excludedKeys.includes(key) && filterOptions[key] !== 0
		);
		return keysArray ? keysArray : [];
	});
	const autocompleteRef = useRef(null);

	const isEmptyFilter = filter =>
		Object.values(filter).every(value => value === 0);

	const handleFilterClick = filter => {
		if (filter === 'none') {
			setFilter({});
		} else {
			const newFilter = {
				...filterOptions,
				none: 0,
				all: 0
			};

			newFilter[filter['filter']] = newFilter[filter['filter']] === 1 ? 0 : 1;

			if (isEmptyFilter(newFilter)) {
				setFilter({});
			} else {
				setFilter(newFilter);
			}
		}
	};

	const handleOptionSelect = option => {
		if (!option.length) {
			setSelectedOption([]);
			setActiveFilters(prevFilters => {
				const updatedFilters = { ...prevFilters };
				delete updatedFilters[filterGroup];
				return updatedFilters;
			});
		} else {
			setActiveFilters(prevFilters => {
				return {
					...prevFilters,
					[filterGroup]: [...option]
				};
			});
			setSelectedOption(option);
		}
	};

	useEffect(() => {
		if (selectedOption && !selectedOption.length) {
			handleFilterClick('none');
		}
	}, [selectedOption]);

	const renderTags = (tagValue, getTagProps) =>
		tagValue.map((option, index) => (
			<Chip
				variant='outlined'
				label={option}
				key={option}
				{...getTagProps({ index })}
				style={{ marginRight: 4 }}
				onDelete={() => {
					const filter = option;
					handleFilterClick({ filter });
					const updatedOptions = selectedOption.filter(item => item !== option);
					setSelectedOption(updatedOptions);

					// Если нет выбранных опций в фильтре, то удаляем его из списка активных фильтров, иначе обновляем список
					if (updatedOptions.length === 0) {
						setActiveFilters(prevFilters => {
							const updatedFilters = { ...prevFilters };
							delete updatedFilters[filterGroup];
							return updatedFilters;
						});
					} else {
						setActiveFilters(prevFilters => ({
							...prevFilters,
							[filterGroup]: updatedOptions
						}));
					}
				}}
			/>
		));

	return (
		<>
			<MenuItem sx={{ margin: '0 10px', padding: 0, width: '300px' }}>
				<Autocomplete
					fullWidth
					options={filterValues}
					getOptionLabel={option => option}
					multiple
					value={selectedOption}
					noOptionsText='Нет вариантов'
					disableCloseOnSelect
					onChange={(event, value) => handleOptionSelect(value)}
					renderInput={params => (
						<TextField
							variant='outlined'
							size='small'
							{...params}
							autoFocus
							ref={autocompleteRef}
						/>
					)}
					renderTags={renderTags}
					isOptionEqualToValue={(option, value) => option === value}
					renderOption={(props, option, { selected }) => {
						const maxLength = 70; // Maximum length of the option's text
						const truncatedText =
							option.length > maxLength
								? `${option.slice(0, maxLength)}...`
								: option;
						return (
							<li {...props}>
								<FormControlLabel
									sx={{ color: 'black' }}
									control={
										<Checkbox
											sx={checkBoxStyles.orangeCheckBox}
											checked={selected}
										/>
									}
									label={truncatedText}
									onClick={() => {
										const filter = option;
										handleFilterClick({ filter });
									}}
								/>
							</li>
						);
					}}
				/>
			</MenuItem>
		</>
	);
};
