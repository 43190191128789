import { Menu } from '@mui/material';
import React from 'react';
import { FilterMenuList } from './filter-menu-list/';
import { FilterMenuCategories } from './filter-menu-categories';
import { FilterMenuDate } from './filter-menu-date/filter-menu-date';
import { FilterMenuRange } from './filter-menu-range';
import { FilterMenuSearchList } from './filter-menu-search-list/filter-menu-search-list';
import { processCheckListFilter } from '../../../../utils/process-check-list-filter/process-check-list-filter';

export const FilterMenu = ({
	isOpen = false,
	filterGroup = '',
	filterList = [],
	anchorEl = <></>,
	activeFilters = [],
	setActiveFilters = props => {},
	handleClose = () => {},
	options = { menuType: 'list' },
	savedFilterValues,
	setSavedFilterValues
}) => {
	const { menuType } = options;

	// В зависимости от типа фильтра определяем компонент
	const MenuComponents = {
		list: FilterMenuList,
		stacks: FilterMenuCategories,
		date: FilterMenuDate,
		range: FilterMenuRange,
		searchList: FilterMenuSearchList
	};
	const FilterMenuComponent = MenuComponents[menuType] || (() => null);

	return (
		<Menu
			id='expanded-menu'
			MenuListProps={{
				'aria-labelledby': 'long-button'
			}}
			anchorEl={anchorEl}
			open={isOpen}
			onClose={handleClose}
			PaperProps={{
				style: {
					height: 'fit-content',
					maxHeight: '70%',
					width: 'fit-content',
					maxWidth: '50%',
					padding: '10px'
				}
			}}>
			<FilterMenuComponent
				activeFilters={activeFilters}
				setActiveFilters={setActiveFilters}
				processCheckListFilter={processCheckListFilter}
				filterList={filterList}
				filterGroup={filterGroup}
				savedFilterValues={savedFilterValues}
				setSavedFilterValues={setSavedFilterValues}
				options={options}
			/>
		</Menu>
	);
};
