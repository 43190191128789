import React from "react";
import { Box, Typography } from "@mui/material";
import { UniversalTable } from '../universal-table';
import { getTelegramBotTablesConfig } from '../universal-table/config';

export const TelegramBotTables = ({
  selectedTab,
  filterDataBySevenDays,
  filterDataByMonth,
 filterDataByPeriod,
  sortDataByDate,
  garbagePercentageData,
  notAccessedData,
  userBotStatisticData,
}) => {
  const tables = getTelegramBotTablesConfig(garbagePercentageData, notAccessedData, userBotStatisticData);
  return (
    <Box
      sx={{
        ml: 13,
        mt: 5,
        mr: 3,
        boxSizing: "border-box",
        padding: "10px 20px 20px 20px",
        borderRadius: "15px",
        border: "2px solid #ebebeb",
        marginTop: "10px",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "orange",
              padding: "5px",
              width: "200px",
              borderRadius: "5px",
              mb: 1,
            }}
          >
            <Typography fontWeight="bold" variant="h6" sx={{ color: "white" }}>
              Telegram Bot
            </Typography>
          </Box>
        </Box>
        {tables.map(({ title, data, totalLabel, valueKey }, index) => (
          <UniversalTable
            key={index}
            selectedTab={selectedTab}
            data={data}
            filterDataBySevenDays={filterDataBySevenDays}
            filterDataByMonth={filterDataByMonth}
            filterDataByPeriod={filterDataByPeriod}
            sortDataByDate={sortDataByDate}
            title={title}
            valueKey={valueKey}
          />
        ))}
      </Box>
    </Box>
  );
};
