import React, { useState, useEffect } from 'react';

import { Box } from '@mui/material';
import { boxStyles } from '../../../../styles/default-styles/box-styles/box-styles';

import { RecruiterStatsTable } from './recruiter-stats-table';
import { DateComponent } from './date-component/date-component';
import moment from 'moment';
import { requestDateFormat } from './date-component/config';

export const RecruiterStatsTab = () => {
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [datePickersEmpty, setDatePickersEmpty] = useState(true);

	useEffect(() => {
		if (datePickersEmpty) {
			let endDate = new Date();
			let startDate = new Date();

			startDate.setDate(endDate.getDate() - 6);

			setStartDate(moment(startDate).format(requestDateFormat));
			setEndDate(moment(endDate).format(requestDateFormat));
		}
	}, [datePickersEmpty]);

	return (
		<Box sx={{ ml: 12 }}>
			<Box
				sx={{
					...boxStyles.flexBoxRow,
					gap: '10px',
					mb: 1,
					mt: 2,
					width: '93vw',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'left'
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center'
					}}
				>
					<DateComponent
						setStartDate={setStartDate}
						setEndDate={setEndDate}
						datePickersEmpty={datePickersEmpty}
						setDatePickersEmpty={setDatePickersEmpty}
					/>
				</Box>
				<RecruiterStatsTable startDate={startDate} endDate={endDate} />
			</Box>
		</Box>
	);
};
