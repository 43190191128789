import React, { useState } from 'react';
import {
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	Box
} from '@mui/material';
import { buttonStyles } from '../../../../../../../styles/default-styles/button-styles/button-styles';
import { deleteTechnicalTermsRequest } from '../../../../../../../api/api';
import CloseIcon from '@mui/icons-material/Close';

export const DeleteTechnicalTermButton = ({ termId, check, setCheck }) => {
	const [modalOpen, setModalOpen] = useState(false);

	const handleOpenModal = event => {
		event.stopPropagation();
		setModalOpen(true);
	};

	const handleCloseModal = event => {
		event.stopPropagation();
		setModalOpen(false);
	};

	const handleDeleteConfirm = event => {
		event.stopPropagation();
		deleteTechnicalTermsRequest(termId)
			.then(() => {
				setCheck(!check);
			})
			.catch(error => {
				console.error('Failed to delete term: ', error);
			});
	};

	return (
		<>
			<IconButton size='small' onClick={e => handleOpenModal(e)}>
				<CloseIcon size='small' sx={{ color: '#ffffff' }} />
			</IconButton>
			<Dialog open={modalOpen} onClose={e => handleCloseModal(e)}>
				<DialogTitle>
					<Typography
						variant='body1'
						sx={{ fontSize: '27px', marginBottom: 2, marginTop: 2 }}
					>
						Вы точно хотите удалить технический термин?
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Typography
						variant='body1'
						color='text.secondary'
						sx={{ fontSize: '24px', marginBottom: 10 }}
					>
						Технический термин нельзя будет восстановить!
					</Typography>
				</DialogContent>
				<DialogActions>
					<Box sx={{ marginBottom: 4, marginRight: 3 }}>
						<Button
							onClick={e => handleDeleteConfirm(e)}
							color='error'
							size='large'
							sx={{ ...buttonStyles.orangeButton, fontSize: '18px', mr: 2 }}
						>
							Удалить технический термин
						</Button>
						<Button
							onClick={e => handleCloseModal(e)}
							color='secondary'
							size='large'
							sx={{ ...buttonStyles.orangeButton, fontSize: '18px' }}
						>
							Отмена
						</Button>
					</Box>
				</DialogActions>
			</Dialog>
		</>
	);
};
