import React, { useState, useEffect } from 'react';
//import * as jose from "jose";

import { AuthRefreshRequest, getAboutMeRequest } from '../../../../../api/api';

import { Button, Avatar, Menu, MenuItem, ListItemIcon } from '@mui/material';

import Logout from '@mui/icons-material/Logout';

export const ProfileButton = ({ checkAbout, setCheckAbout }) => {
	const [authName, setAuthName] = useState();
	const [anchorEl, setAnchorEl] = useState(null);
	const [check, setCheck] = useState();

	const openMenu = Boolean(anchorEl);

	useEffect(() => {
		if (
			localStorage.getItem('refreshToken') &&
			localStorage.getItem('accessToken')
		) {
			getAboutMeRequest()
				.then(data => {
					setAuthName(data.name);
				})
				.catch(requestError => {
					console.error(requestError.message);
					if (requestError.response.status === 401)
						localStorage.setItem('tokenError', true);
				});
		}
	}, [checkAbout, setCheckAbout]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (localStorage.getItem('tokenError') === 'true') {
				AuthRefreshRequest(
					localStorage.getItem('refreshToken'),
					localStorage.getItem('accessToken').replace('bearer ', '')
				)
					.then(data => {
						localStorage.setItem('accessToken', data.accessToken);
						localStorage.setItem('refreshToken', data.refreshToken);
						localStorage.setItem('tokenError', false);
						setCheck(!check);
					})
					.catch(requestError => {
						console.error(requestError.message);
						if (requestError.response.status === 400) {
							localStorage.removeItem('refreshToken');
							localStorage.removeItem('accessToken');
							localStorage.setItem('tokenError', false);
							setCheck(!check);
						}
					});
			} else {
				setCheck(!check);
			}
		}, 1000);
		return () => clearTimeout(timeOutId);
	}, [check, checkAbout]);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleCloseLogout = () => {
		setAnchorEl(null);
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('accessToken');
		setCheckAbout(checkAbout + 1);
	};

	return (
		<>
			<Button
				sx={{
					fontSize: 17,
					borderRadius: '30px',
					color: '#696969',
					'&:hover': {
						backgroundColor: '#EEEEED'
					}
				}}
				onClick={handleClick}
			>
				<Avatar
					alt='Remy Sharp'
					sx={{ fontSize: 35, mr: 1 }}
					src='https://proprikol.ru/wp-content/uploads/2019/08/kartinki-nyashnye-kotiki-16.jpg'
				/>
				{authName}
			</Button>
			<Menu
				id='basic-menu'
				anchorEl={anchorEl}
				open={openMenu}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button'
				}}
			>
				<MenuItem onClick={handleCloseLogout}>
					<ListItemIcon>
						<Logout color='action' />
					</ListItemIcon>
					Выйти
				</MenuItem>
			</Menu>
		</>
	);
};
