import React from "react";

import { Box, Grid, Typography, Divider } from "@mui/material";

import { TextFieldCVWrapper } from "../ui/text-field-CV-wrapper";

export const UpdatingCvDetailsAboutInfo = ({ cvData, setAboutData }) => {
  return (
    <Box>
      <Divider sx={{ height: 20, m: "0px 0px 20px 0px" }} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          marginBottom: "20px",
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          sx={{
            fontSize: "20px",
          }}
        >
          {"Обо мне"}
        </Typography>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box
            sx={{
              borderRadius: "3px",
              border: "1px solid #ffb74d",
              minHeight: "50px",
            }}
          >
            <TextFieldCVWrapper
              textData={cvData?.about}
              setData={setAboutData}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
