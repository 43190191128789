import { styled } from '@mui/material/styles';
import { InputBase } from '@mui/material';

export const TextInput = styled(InputBase)(
	({ characterwidth = false, width }) => ({
		padding: '.4rem',
		fontSize: '1.1rem',
		multiline: true,
		borderRadius: '.3rem',
		border: '2px solid #ffb74d',
		alignItems: 'center',
		width: characterwidth && `${width}ch`,
		'@media screen and (max-width: 1000px)': {
			fontSize: '1rem',
			width: '100%'
		}
	})
);
