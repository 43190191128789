import React, { useEffect, useState } from 'react';
import { FilterDots } from '../../../filter-dots';
import { parseObjectFilterList } from './utils/utils';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

export const TermGridFilter = ({ config, setFilterSettings }) => {
	const { filterList, filterGroupName } = config;

	const [activeFilters, setActiveFilters] = useState({ [filterGroupName]: [] });
	const [categoriesWithStackNames, setCategoriesWithStackNames] = useState({});

	useEffect(() => {
		const parsedFilterList = parseObjectFilterList(filterList); // { categoryName: [{ id, stackName }] } => { categoryName: ['stackName'] }

		setCategoriesWithStackNames(parsedFilterList);
	}, [filterList]);

	useEffect(() => {
		setFilterSettings(prevFilterSettings => ({
			...prevFilterSettings,
			activeStackNames: activeFilters[filterGroupName]
		}));
	}, [activeFilters]);

	return (
		<FilterDots
			filterList={categoriesWithStackNames}
			filterGroup='stack'
			activeFilters={activeFilters[filterGroupName]}
			setActiveFilters={setActiveFilters}
			activeFiltersAll={activeFilters}
			menuType='stacks'
			buttonElement={<FilterAltIcon color={activeFilters[filterGroupName]?.length > 0 ? 'warning' : ''} />}
		/>
	);
};
