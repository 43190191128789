import React, { useState, useEffect } from "react";

import { Box, Divider, Typography, Paper, MenuItem, Menu } from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export const SelectorTimeZone = ({ textName, textstaff, setData }) => {
  const [term, setTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (textstaff !== null && textstaff !== undefined) {
      setTerm(textstaff);
    }
  }, [textstaff]);

  const handleChange = (value) => {
    setData(value);
    setTerm(value);
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper
      elevation={6}
      sx={{ display: "flex", alignItems: "center", minHeight: "32px" }}
    >
      <Typography
        id="keep-mounted-modal-title"
        variant="h6"
        component="h2"
        sx={{
          display: "flex",
          height: "100%",
          fontSize: "15px",
          bgcolor: "#fb8c00",
          color: "#852508",
          border: "1px solid",
          minWidth: "110px",
          textAlign: "center",
          borderRadius: "4px",
          borderColor: "#a2a2a2",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {textName}
      </Typography>
      <Divider sx={{ height: 24, ml: 0.5 }} orientation="vertical" />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
          }}
          onClick={handleClick}
        >
          <Box sx={{ flexGrow: 1, userSelect: "none" }}>
            <Typography
              id="keep-mounted-modal-title"
              sx={{ p: "2px 4px", ml: 1, fontSize: "15px" }}
            >
              {term}
            </Typography>
          </Box>
          <Box>
            <Box size="small" sx={{ color: "#696969", cursor: "pointer" }}>
              {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Box>
          </Box>
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{ maxHeight: "500px" }}
        >
          {[
            "-9",
            "-8",
            "-7",
            "-6",
            "-5",
            "-4",
            "-3",
            "-2",
            "-1",
            "0",
            "+1",
            "+2",
            "+3",
            "+4",
            "+5",
            "+6",
            "+7",
            "+8",
            "+9",
          ].map((row) => (
            <MenuItem
              key={row}
              onClick={() => handleChange(row)}
              sx={{ minWidth: "225px" }}
            >
              {row}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Paper>
  );
};
