import { ApiClient } from '../clients/client';

export const sendToFavRequest = async id => {
	const { data } = await ApiClient.post(`tg/api/Messages/PutOnTheFavorites?messageID=${id}`);
	return data;
};

export const sendToTrashRequest = async id => {
	const { data } = await ApiClient.post(`tg/api/Messages/PutOnTheBlackList?messageID=${id}`);
	return data;
};

export const sendToDetachStuffFromProject = async (projectID, staffID) => {
	const { data } = await ApiClient.post(`tg/api/Staff/DetachFromProject?staff_id=${staffID}&project_id=${projectID}`);
	return data;
};

export const sendToEditMessageStack = async (messageID, stackID) => {
	const { data } = await ApiClient.post(`tg/api/Messages/EditMessageStack?messageID=${messageID}&stackID=${stackID}`);
	return data;
};

export const sendToAddCandidatInProject = async (staff_id, project_id) => {
	const { data } = await ApiClient.post(`tg/api/Staff/AttachToProject?staff_id=${staff_id}&project_id=${project_id}`);

	return data;
};

export const getIdleProjects = async messageId => {
	const { data } = await ApiClient.get(`tg/api/Messages/IdleProjects/${messageId}`);
	return data;
};

export const getAllSubCategories = async () => {
	const { data } = await ApiClient.get(`tg/api/Categories/GetAllSubCategories`);
	return data;
};

export const getParsedMessagesBySubCategory = async subCategori => {
	const { data } = await ApiClient.get(`tg/api/Messages/GetParsedMessagesBySubCategory?subCategori=${subCategori}`);
	return data;
};

export const getExtractStacksFromRequest = async requestId => {
	const { data } = await ApiClient.get(`tg/api/Request/ExtractStacksFromRequest/${requestId}`);
	return data;
};

export const getUsers = async () => {
	const { data } = await ApiClient.get(`cv/api/Auth/GetUsers`);
	return data;
};

export const getCategoriesStructureRequest = async () => {
	const { data } = await ApiClient.get('tg/api/Categories/GetCategoriesStructure');
	return data;
};

export const getSourcesArrRequest = async () => {
	const { data } = await ApiClient.get('tg/api/Messages/GetSources');
	return data;
};

export const getListRequest = async () => {
	const { data } = await ApiClient.get('tg/api/Messages/GetParsedMessages');
	return data;
};

export const getParsedMessagesRequest = async daysPeriod => {
	const { data } = await ApiClient.get(`tg/api/Messages/GetParsedMessages?daysPeriod=${daysPeriod}`);
	return data;
};

export const getAllStaffRequest = async () => {
	const response = await ApiClient.get('tg/api/Staff/AllStaffs');
	return response.data;
};

export const get = async () => {
	const response = await ApiClient.get('tg/api/Staff/AllStaffs');
	return response.data;
};

export const deleteDetachFromProjectRequest = async () => {
	const { data } = await ApiClient.delete('tg/api/Staff/DetachFromProject');
	return data;
};

export const fetchDataRequest = () => {
	const data = [
		ApiClient.get('tg/api/Statistics/GetDetailedStatistic'),
		ApiClient.get('tg/api/Statistics/GetShortStatistic'),
		ApiClient.get('cv/api/CV/GetLastCVDateTime'),
		ApiClient.get('tg/api/ApiAccessHistory/GetLastTelegramRequestTime'),
		ApiClient.get('tg/api/Messages/GetSourcesCount?daysPeriod=1'),
		ApiClient.get('tg/api/Messages/GetSourcesCount?daysPeriod=7'),
		ApiClient.get('tg/api/Messages/GetSourcesCount?daysPeriod=14'),
		ApiClient.get('tg/api/Messages/GetSourcesCount?daysPeriod=30')
	];
	return data;
};

export const changeTitleRequest = async (id, changedTitle) => {
	const { data } = await ApiClient.post('cv/api/HHQuery/Update/Name', {
		id: id,
		name: changedTitle
	});
	return data;
};

export const sendStatusRequest = async (id, status) => {
	const { data } = await ApiClient.post(`cv/api/HHQuery/${id}/Update/Status`, null, {
		params: {
			status: status
		}
	});
	return data;
};

export const sendToParseMessage = async escapedMessage => {
	const { data } = await ApiClient.post('tg/api/Parsing/ParseMessage', {
		content: escapedMessage,
		state: 'Обычное'
	});
	return data;
};

export const updateListRequest = async () => {
	const { data } = await ApiClient.get('cv/api/HHQuery/Queries');
	return data;
};

export const getRequestById = async id => {
	const { data } = await ApiClient.get(`cv/api/HHQuery/Query?id=${id}`);
	return data;
};

export const UpdateResponsibleUserRequest = async (queryId, userId) => {
	const { data } = await ApiClient.post('cv/api/HHQuery/Update/ResponsibleUser', {
		queryId: queryId,
		userId: userId
	});
	return data;
};

export const docxConverter = async (selectedFile, accessToken) => {
	const formData = new FormData();
	formData.append('file', selectedFile);

	try {
		const { data } = await ApiClient.post('cv/api/CV/DocX', formData, {
			responseType: 'blob'
		});

		const fileBlob = data;
		const fileName = selectedFile.name.replace(/\.[^/.]+$/, '');
		const processedFileName = `TechCrew_${fileName}.docx`;
		return new File([fileBlob], processedFileName, {
			type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
		});
	} catch (error) {
		console.error(error.message);
		if (error.response && error.response.status === 401) {
			localStorage.setItem('tokenError', true);
		}
		return null;
	}
};

export const GeneratePublicCV = async selectedFile => {
	const formData = new FormData();
	formData.append('file', selectedFile);

	const { data } = await ApiClient.post(`cv/api/CV/Create/FromDocument/Public`, formData);
	return data;
};

export const getAllStaffsRequest = async () => {
	const { data } = await ApiClient.get('tg/api/Staff/AllStaffs');
	return data;
};

export const deleteStaffsRequest = async id => {
	const { data } = await ApiClient.post(`tg/api/Staff/DeleteStaff/${id}`);
	return data;
};

export const getStaffsRequest = async id => {
	const { data } = await ApiClient.get(`tg/api/Staff/GetStaff/${id}`);
	return data;
};

export const getStaffsBySubCategory = async ({
	pageNum = 1,
	pageSize = 20,
	subCategory = null,
	searchString = null,
	cancelToken = null
}) => {
	const { data } = await ApiClient.get(
		`tg/api/Staff/StaffsBySubCategory?${
			pageNum ? `&PageNum=` + pageNum : ''
		}${searchString ? `&SearchString=` + searchString : ''}${
			subCategory ? `&SubCategori=` + subCategory : ''
		}${pageSize ? '&PageSize=' + pageSize : ''}`,
		{ cancelToken: cancelToken?.token }
	);
	return data;
};

export const getStaffsCountBySubCategory = async ({ subCategory = null, searchString = null }) => {
	const { data } = await ApiClient.get(
		`tg/api/Staff/StaffsCountBySubCategory?${
			subCategory ? `&subCategori=` + subCategory : ''
		}${searchString ? `&searchString=` + searchString : ''}`
	);
	return data;
};

export const getResumeStaffsRequest = async id => {
	const { data } = await ApiClient.get(`cv/api/CV/resume/${id}`);
	return data;
};

export const getCommunicationStatusRequest = async () => {
	const { data } = await ApiClient.get(`tg/api/Staff/CommunicationStatus`);
	return data;
};

export const getCandidateGradeRequest = async () => {
	const { data } = await ApiClient.get(`tg/api/Staff/Grades`);
	return data;
};

export const updateStaffRequest = async (
	id,
	FullNameData,
	ageData,
	LocationData,
	gradeData,
	descriptionData,
	stackData,
	experienceData,
	relevantExperienceData,
	phoneData,
	emailData,
	salaryData,
	rateData,
	birthdayData,
	timeZoneData,
	statusData,
	userIdData,
	cancelToken
) => {
	const { data } = await ApiClient.post(`tg/api/Staff/UpdateStaff/${id}`, {
		FullName: FullNameData,
		birthday: birthdayData,
		age: ageData,
		experience: experienceData,
		relevantExperience: relevantExperienceData,
		stackID: stackData,
		Description: descriptionData,
		grade: gradeData,
		Location: LocationData,
		email: emailData,
		phone: phoneData,
		rate: rateData,
		timezone: timeZoneData,
		salary: salaryData,
		communicationStatus: statusData,
		userID: userIdData,
		cancelToken: cancelToken
	});

	return data;
};

export const getCommentRequest = async id => {
	const { data } = await ApiClient.get(`tg/api/Staff/Comments/${id}`);
	return data;
};

export const addCommentRequest = async (id, commentData) => {
	const { data } = await ApiClient.post(`tg/api/Staff/AddComment`, {
		staffId: id,
		comment: commentData
	});
	return data;
};

export const sendSampleCreate = async (url, name, amount, stackId, getDislikedResumes) => {
	const { data } = await ApiClient.post('cv/api/HHQuery/Create', {
		url: url,
		name: name,
		amount: amount,
		stackId: stackId,
		isViewed: getDislikedResumes
	});
	return data;
};

export const updateStackHHQueryRequest = async (id, stackId) => {
	const { data } = await ApiClient.post('cv/api/HHQuery/Update/Stack', {
		id: id,
		stackId: stackId
	});
	return data;
};

export const getCVListByIdRequest = async (id, sort, sortOrder) => {
	const { data } = await ApiClient.get(`cv/api/CV/${id}`, {
		params: { Sort: sort, SortOrder: sortOrder }
	});
	return data;
};

export const getCVListByIdStreamRequest = async (id, sort, sortOrder) => {
	const { data } = await ApiClient.get(`cv/api/CV/${id}/Streaming`, {
		params: { Sort: sort, SortOrder: sortOrder }
	});
	return data;
};

export const generateStaffByCvIdRequest = async cvId => {
	const { data } = await ApiClient.post(`cv/api/HHUser/GenerateStaffByCvId/${cvId}`);
	return data;
};

export const getUnratedCvIdsRequest = async (queryId, sort, sortOrder) => {
	const { data } = await ApiClient.get(`cv/api/CV/${queryId}/UnratedIds`, {
		params: { Sort: sort, SortOrder: sortOrder }
	});
	return data;
};

export const getLikedCvIdsRequest = async (queryId, sort, sortOrder) => {
	const { data } = await ApiClient.get(`cv/api/CV/${queryId}/Liked`, {
		params: { Sort: sort, SortOrder: sortOrder }
	});
	return data;
};

export const rateResumeByIdRequest = async (resumeId, queryId, isGood) => {
	const { data } = await ApiClient.post(`cv/api/CV/Rate`, null, {
		params: {
			resumeId,
			queryId,
			isGood
		}
	});
	return data;
};

export const getAllCriterionsRequest = async () => {
	const { data } = await ApiClient.get(`cv/api/Rating/AllCriterions`);
	return data;
};

export const getAllSourcesRequest = async () => {
	const { data } = await ApiClient.get(`tg/api/Source/GetSources`);
	return data;
};

export const getAllSourcesRequestByDate = async (from, before = '') => {
	const { data } = await ApiClient.get(`tg/api/Source/GetSources?From=${from}&Before=${before}`);
	return data;
};

export const askToDeleteSourceRequest = async id => {
	const { data } = await ApiClient.post(`tg/api/Source/AskToDelete`, {
		sourceId: id
	});
	return data;
};

export const updateSourceRequest = async (id, source, IsAliasNullable = false, IsDescriptionNullable = false) => {
	const { data } = await ApiClient.post(`tg/api/Source/UpdateSource/${id}`, {
		description: source.description,
		alias: source.alias,
		IsAliasNullable: IsAliasNullable,
		IsDescriptionNullable: IsDescriptionNullable
	});
	return data;
};

export const createSourceRequest = async source => {
	const { data } = await ApiClient.post(`tg/api/Source/CreateTelegramSource`, {
		name: source.name,
		alias: source.alias,
		description: source.description
	});
	return data;
};

export const deactivateSourceRequest = async id => {
	const { data } = await ApiClient.post(`tg/api/Source/DeactivateSource/${id}`);
	return data;
};

export const AuthRequest = async (email, password) => {
	const { data } = await ApiClient.post(`cv/api/Auth/Login`, {
		email: email,
		password: password
	});
	return data;
};

export const AuthRefreshRequest = async (refreshToken, accessToken) => {
	const { data } = await ApiClient.post(`cv/api/Auth/RefreshToken`, {
		refreshToken: refreshToken,
		accessToken: accessToken
	});
	return data;
};

export const RegisterRequest = async (email, name, password) => {
	const { data } = await ApiClient.post(`cv/api/Auth/Register`, {
		email: email,
		name: name,
		password: password
	});
	return data;
};

export const ResetPasswordByEmail = async email => {
	const { data } = await ApiClient.post(`cv/api/Auth/ResetPassword?email=${email}`);
	return data;
};

export const ChangePasswordByCode = async (code, newPassword) => {
	const { data } = await ApiClient.post(`cv/api/Auth/ChangePasswordByCode`, {
		code: code,
		newPassword: newPassword
	});
	return data;
};

export const splitRequestByStacksRequest = async (id, selectedStacks) => {
	const { data } = await ApiClient.post(`tg/api/Request/SplitRequestByStacks`, {
		requestId: id,
		stackIds: selectedStacks
	});
	return data;
};

export const dealCreateRequest = async (
	projectID,
	selectedStaff,
	description,
	selectedMessage,
	selectedStatus,
	startDateTime,
	rate = 0,
	selectedResult
) => {
	const { data } = await ApiClient.post(`tg/api/Project/Deal/Create/${projectID}`, {
		staffId: selectedStaff?.id || 0,
		description: description || null,
		messageId: selectedMessage?.id || null,
		status: selectedStatus?.id || 0,
		startDateTime: new Date(startDateTime),
		rate: rate || 0,
		result: selectedResult || 0
	});
	return data;
};

export const DealUpdateRequest = async (
	dealid,
	newStatus,
	newDesciption,
	newDate,
	newRate,
	newCandidat,
	newQuery,
	newResult
) => {
	const { data } = await ApiClient.post(`tg/api/Project/Deal/Update/${dealid}`, {
		description: newDesciption,
		status: newStatus,
		start: newDate,
		rate: newRate,
		staffID: newCandidat,
		messageID: newQuery,
		result: newResult
	});
	return data;
};

export const getAllDealsRequest = async () => {
	const { data } = await ApiClient.get(`tg/api/Project/AllDeals`);
	return data;
};

export const getStatisticsCvNumberByDay = async (fromMonth, fromYear, beforeMonth, beforeYear) => {
	let apiEndpoint = `cv/api/Statistics/Cv/NumberByDay?fromMonth=${fromMonth}&fromYear=${fromYear}`;

	if (beforeMonth && beforeYear) apiEndpoint = `${apiEndpoint}&beforeMonth=${beforeMonth}&beforeYear=${beforeYear}`;

	const { data } = await ApiClient.get(apiEndpoint);
	return data;
};

export const getStatisticsCvContactNumberByDay = async (fromMonth, fromYear, beforeMonth, beforeYear) => {
	let apiEndpoint = `cv/api/Statistics/Cv/Сontact/NumberByDay?fromMonth=${fromMonth}&fromYear=${fromYear}`;

	if (beforeMonth && beforeYear) apiEndpoint = `${apiEndpoint}&beforeMonth=${beforeMonth}&beforeYear=${beforeYear}`;

	const { data } = await ApiClient.get(apiEndpoint);
	return data;
};

export const getNotAccessedData = async (fromMonth, fromYear, beforeMonth, beforeYear) => {
	let apiEndpoint = `tg/api/Source/NotAccessed/NumberByDay?fromMonth=${fromMonth}&fromYear=${fromYear}`;

	if (beforeMonth && beforeYear) apiEndpoint = `${apiEndpoint}&beforeMonth=${beforeMonth}&beforeYear=${beforeYear}`;

	const { data } = await ApiClient.get(apiEndpoint);
	return data;
};

export const getGarbagePercentageData = async (fromMonth, fromYear, beforeMonth, beforeYear) => {
	let apiEndpoint = `tg/api/Request/Garbage/NumberByDay?fromMonth=${fromMonth}&fromYear=${fromYear}`;

	if (beforeMonth && beforeYear) apiEndpoint = `${apiEndpoint}&beforeMonth=${beforeMonth}&beforeYear=${beforeYear}`;

	const { data } = await ApiClient.get(apiEndpoint);
	return data;
};

export const getUserBotStatisticData = async (fromMonth, fromYear, beforeMonth, beforeYear) => {
	let apiEndpoint = `tg/api/UserBots/Statistics?fromMonth=${fromMonth}&fromYear=${fromYear}`;

	if (beforeMonth && beforeYear) apiEndpoint = `${apiEndpoint}&beforeMonth=${beforeMonth}&beforeYear=${beforeYear}`;

	const { data } = await ApiClient.get(apiEndpoint);
	return data;
};

export const getUserBotStaffStatisticData = async (start, end) => {
	let apiEndpoint = `tg/api/UserBots/CommandStatistic/Staffs?From=${start}&Before=${end}`;

	const { data } = await ApiClient.get(apiEndpoint);
	return data;
};

export const deleteDealRequest = async dealId => {
	const { data } = await ApiClient.post(`tg/api/Project/Deal/Delete/${dealId}`);
	return data;
};

export const getAboutMeRequest = async () => {
	const { data } = await ApiClient.get(`cv/api/User/AboutMe`);

	return data;
};

export const getJobSearchStatusRequest = async () => {
	const { data } = await ApiClient.get(`cv/api/CV/JobSearchStatus`);
	return data;
};

export const GenerateBenchMapRequest = async () => {
	const { data } = await ApiClient.get(`tg/api/Staff/GenerateBenchMap`);
	return data;
};

export const GenerateBenchMapWithStatistics = async () => {
	const { data } = await ApiClient.get(`tg/api/Staff/GenerateBenchMapWithStatistics`);
	return data;
};

export const GenerateRemovedBenchMapWithStatistics = async () => {
	const { data } = await ApiClient.get(`tg/api/Staff/GenerateRemovedBenchMapWithStatistics`);
	return data;
};

export const CVUpdateRequest = async (
	id,
	fullNameData,
	birthdayData,
	countryData,
	cityData,
	priceData,
	stackData,
	aboutData,
	educationData
) => {
	const { data } = await ApiClient.post(`cv/api/CV/${id}/Update`, {
		fullName: fullNameData,
		age: null,
		dateOfBirth: birthdayData,
		country: countryData,
		city: cityData,
		price: priceData,
		searchStatus: null, //не  нужен
		stack: stackData,
		employment: null, //не нужен
		schedule: null, //не нужен
		about: aboutData,
		education: educationData,
		graduateYear: null
	});
	return data;
};

export const CVEducationUpdateRequest = async (id, name, organization, result, year) => {
	const { data } = await ApiClient.post(`cv/api/CV/Education/${id}/Update`, {
		name,
		organization,
		result,
		year
	});
	return data;
};

export const CvEducationDeleteRequest = async id => {
	const { data } = await ApiClient.post(`cv/api/CV/Education/${id}/Delete`);
	return data;
};

export const CVWorkExperienceUpdateRequest = async (
	id,
	companyNameData,
	companyCityData,
	descriptionData,
	positionData,
	startData,
	endData,
	isRelevantData,
	isCurrently
) => {
	const { data } = await ApiClient.post(`cv/api/CV/WorkExperience/${id}/Update`, {
		companyName: companyNameData,
		companyCity: companyCityData,
		description: descriptionData,
		start: startData,
		end: endData,
		position: positionData,
		isRelevant: isRelevantData,
		isCurrently: isCurrently
	});
	return data;
};

export const CVWorkExperienceAddRequest = async (
	id,
	companyName,
	companyCity,
	description,
	position,
	start,
	end,
	isRelevant
) => {
	const { data } = await ApiClient.post(`cv/api/CV/${id}/WorkExperience/Add`, {
		companyName: companyName,
		companyCity: companyCity,
		description: description,
		start: start,
		end: end,
		position: position,
		isRelevant: isRelevant
	});

	return data;
};

export const CVWorkExperienceDeleteRequest = async id => {
	const { data } = await ApiClient.post(`cv/api/CV/WorkExperience/${id}/Delete`);
	return data;
};

export const ChangeExperienceRequest = async (id, percent) => {
	const { data } = await ApiClient.post(`cv/api/CV/WorkExperience/${id}/ChangeExperience/${percent}`);
	return data;
};

export const GenerateStaffRequest = async link => {
	const { data } = await ApiClient.post(`cv/api/HHUser/GenerateStaff`, {
		value: link
	});
	return data;
};

export const AddKeySkillRequest = async Id => {
	const { data } = await ApiClient.post(`cv/api/CV/${Id}/keySkill/AddOne`);
	return data;
};

export const DeleteKeySkillRequest = async Id => {
	const { data } = await ApiClient.post(`cv/api/CV/keySkill/${Id}/Delete`);
	return data;
};

export const UpdateKeySkillRequest = async (Id, keySkillsData, keySkillsOrder) => {
	const { data } = await ApiClient.post(`cv/api/CV/keySkill/${Id}/Update`, {
		value: keySkillsData,
		order: keySkillsOrder
	});
	return data;
};

export const CloneByStaffRequest = async staffId => {
	const { data } = await ApiClient.post(`cv/api/HHUser/Cv/CloneByStaff/${staffId}`);
	return data;
};

export const UpdateExtraContactRequest = async (extraContactId, NewValue) => {
	const { data } = await ApiClient.post(`tg/api/Staff/${extraContactId}/UpdateExtraContact`, {
		value: NewValue
	});
	return data;
};

export const AddExtraContactRequest = async staffId => {
	const { data } = await ApiClient.post(`tg/api/Staff/${staffId}/AddExtraContact`);
	return data;
};

export const DeleteExtraContactRequest = async extraContactId => {
	const { data } = await ApiClient.post(`tg/api/Staff/${extraContactId}/DeleteExtraContact`);
	return data;
};

export const DeleteCommentRequest = async commentId => {
	const { data } = await ApiClient.post(`tg/api/Staff/${commentId}/DeleteComment`);
	return data;
};

export const getDefaultUrl = async () => {
	const { data } = await ApiClient.get('cv/api/HHQuery/Queries/GetDefaultHHLink');
	return data;
};

export const getHHTokenStatus = async () => {
	const { data } = await ApiClient.get('cv/api/Auth/GetHHTokenStatus');
	return data;
};

export const getResponsibleUsers = async () => {
	const { data } = await ApiClient.get('cv/api/Auth/GetUsers');
	return data;
};

export const getCommandStatistic = async (From, Before) => {
	const { data } = await ApiClient.get(`tg/api/UserBots/CommandStatistic?From=${From}&Before=${Before}`);
	return data;
};

export const GetSurveyList = async candidateId => {
	const { data } = await ApiClient.get(`survey/api/Survey/CandidateSurvey/${candidateId}`);
	return data;
};

export const CreateSurveyList = async (candidateId, stackId) => {
	const { data } = await ApiClient.post(`survey/api/Survey/Create`, {
		candidateId: candidateId,
		stackId: stackId
	});
	return data;
};

export const GetSurveyAnswersDescription = async () => {
	const { data } = await ApiClient.get(`survey/api/Survey/AnswerDescriptions`);
	return data;
};

export const WrapTechinalTerms = async text => {
	return await ApiClient.post('tg/api/Core/WrapTechnicalTerms', {
		text: text
	});
};

export const getUserBotsInfo = async () => {
	const { data } = await ApiClient.get(`tg/api/UserBots`);
	return data;
};

export const getUserBotsStatuses = async () => {
	const { data } = await ApiClient.get(`tg/api/UserBots/Statuses`);
	return data;
};

export const CreateUserBot = async phone => {
	return await ApiClient.post('tg/api/UserBots/Create', {
		phone: phone,
		isPrivate: false
	});
};

/**
 *
 * @param {string} phone
 * @param {number} code
 * @returns 200 | 401 | 403
 */
export const SendCreateBotCode = async (phone, code) => {
	return await ApiClient.post('tg/api/UserBots/SetCode', {
		phone: phone,
		code: code
	});
};

export const getTechnicalTermsRequest = async () => {
	const { data } = await ApiClient.get('tg/api/TechnicalTerms');
	return data;
};

export const addTechnicalTermsRequest = async ({
	newTermName = null,
	options = null,
	shortened = null,
	listStackId = null,
	listCategoryId = null
}) => {
	const { data } = await ApiClient.post(`tg/api/TechnicalTerms/Add`, {
		term: newTermName,
		options,
		shortened,
		listStackID: listStackId,
		listCategoryID: listCategoryId
	});
	return data;
};

export const updateTechnicalTermsRequest = async (
	id,
	newTermName = null,
	options = null,
	shortened = null,
	stacksIds = null
) => {
	const { data } = await ApiClient.post(`tg/api/TechnicalTerms/${id}/Update`, {
		term: newTermName,
		options,
		shortened,
		stacksIds
	});
	return data;
};

export const deleteTechnicalTermsRequest = async id => {
	const { data } = await ApiClient.post(`tg/api/TechnicalTerms/${id}/Delete`);
	return data;
};

export const updateUsersInStatistics = async () => {
	const { data } = await ApiClient.post('tg/api/UserBots/UpdateUsersInStatistics');
	return data;
};

export const getRecruitersRoles = async () => {
	const { data } = await ApiClient.get(`cv/api/User/Recruiters/GetUsers`);
	return data;
};

export const updateRecruitersRoles = async recruiters => {
	const { data } = await ApiClient.post('cv/api/User/Recruiters/ChangeStatus', {
		recruiters
	});
	return data;
};
export const getAllRequirementsRequest = async () => {
	const { data } = await ApiClient.get('tg/api/Requirement/All');
	return data;
};

export const getAllStaffsByRecruiters = async () => {
	const { data } = await ApiClient.get('tg/api/Staff/AllStaffsByRecruiters');
	return data;
};

export const getAllDealsByRecruiters = async () => {
	const { data } = await ApiClient.get('tg/api/Project/AllDealsByRecruiters');
	return data;
};

export const getRequirementsCriteriaById = async ({ staffId }) => {
	const { data } = await ApiClient.get(`tg/api/Requirement/${staffId}/All`);
	return data;
};

export const postUpdateCriteriaById = async ({ staffId, requirements }) => {
	const { data } = await ApiClient.post(`tg/api/Requirement/${staffId}/Add`, {
		staffId,
		requirements
	});
	return data;
};
