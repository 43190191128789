import { formatNamesToIds } from '../../../../lib/ui/sections/term-grid/utils';
import { UNRECOGNIZED_CATEGORY_ID } from '../../../../../constants/constants';

export const parseItCategories = itCategoriesData => {
	return itCategoriesData.reduce((acc, { id, name }, index) => {
		if (index === 0) acc['Категории'] = [];
		acc['Категории'].push({ id, name });
		return acc;
	}, {});
};

export const reformatToReqsWithCategories = (categoryNames, filterList, reqs) => {
	let categoryIds;

	if (categoryNames.length) {
		categoryIds = formatNamesToIds(categoryNames, filterList);
	} else {
		categoryIds = [UNRECOGNIZED_CATEGORY_ID];
	}

	return reqs.map(item => ({
		value: item.value,
		options: item.variants,
		categoriesId: categoryIds
	}));
};
