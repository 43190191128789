import { useState, useEffect, useContext } from 'react';

import { sortedUsers } from './utils';
import { CurrentUserContext } from '../../components/contexts/CurrentUserContext/CurrentUserContext';
import { AllUsersContext } from '../../components/contexts/AllUsersContext/AllUsersContext';

export const useSortedResponsible = (filterUsers = []) => {
	const [sortedArray, setSortedArray] = useState([]);
	const [thisUserId, setThisUserId] = useState(null);

	const {
		currentUser: { id: currentUserId = null, name }
	} = useContext(CurrentUserContext);

	const { allUsers = [] } = useContext(AllUsersContext);

	useEffect(() => {
		setThisUserId(currentUserId);
	}, [currentUserId]);

	useEffect(() => {
		const activeUserIds = allUsers.flatMap(({ id, isRecruiter }) => (isRecruiter ? [id] : []));

		setSortedArray(sortedUsers(filterUsers, thisUserId, activeUserIds));
	}, [allUsers, filterUsers, thisUserId]);

	return sortedArray.length > 1 ? sortedArray : [];
};
