import React, { useState, useCallback, useEffect } from 'react';
import { Paper, InputBase, IconButton, Divider } from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';
export const SearchFieldUi = ({ onUpdateSearchProp, clearSearchField = () => {}, styles = {} }) => {
	const [term, setTerm] = useState('');

	const onUpdateSearch = useCallback(element => {
		const term = element.target.value;
		setTerm(term.replace(/^[\s.`":'$@~;]*$/g, ''));
	}, []);

	useEffect(() => {
		const timeOutId = setTimeout(() => onUpdateSearchProp(term), 400);
		return () => clearTimeout(timeOutId);
	}, [term]);

	const clear = useCallback(() => {
		setTerm('');
		clearSearchField();
	}, [clearSearchField]);

	return (
		<Paper
			elevation={3}
			sx={{
				p: '2px 4px',
				display: 'flex',
				alignItems: 'center',
				height: 'fit-content',
				...styles
			}}>
			<InputBase
				id='search-item'
				sx={{ ml: 1, flex: 1 }}
				placeholder='Поиск'
				type={'text'}
				inputProps={{
					autoComplete: null
				}}
				value={term}
				onChange={onUpdateSearch}
			/>
			<Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
			<IconButton onClick={clear} sx={{ p: '10px' }} aria-label='directions'>
				<BackspaceIcon color={'warning'} />
			</IconButton>
		</Paper>
	);
};
