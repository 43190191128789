export const REQUIREMENTS_CHECK_LIST_CONFIG = {
	TITLE: 'Критерии для требований',
	ADD_BUTTON_LABEL: 'Добавить критерии',
	ADD_CRITERIA_MODAL: {
		BUTTON_LABEL: 'Добавить',
		SPLIT_REGEX: /\n|, ?/g,
		LABEL: 'Список новых критериев',
		HELPER_TEXT: 'Введите критерии через запятую или новую строку',
		TITLE: 'Добавление критериев'
	}
};
