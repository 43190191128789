import React, { useState, useEffect } from 'react';

import { CVUpdateRequest } from '../../../../api/api';

import { Box, Paper } from '@mui/material';

import { CvDetailsAboutInfo } from './cv-details-about-info';
import { CvDetailsBasicInfo } from './cv-details-basic-info';
import { CvDetailsEducationInfo } from './cv-details-education-info';
import { CvDetailsSkillsInfo } from './cv-details-skills-info';
import { CvDetailsWorkInfo } from './cv-details-work-info';

import { UpdatingCvDetailsAboutInfo } from './updating-cv-details-about-info';
import { UpdatingCvDetailsBasicInfo } from './updating-cv-details-basic-info';
import { UpdatingCvDetailsEducationInfo } from './updating-cv-details-education-info';
import { UpdatingCvDetailsSkillsInfo } from './updating-cv-details-skills-info';
import { UpdatingCvDetailsWorkInfo } from './updating-cv-details-work-info';

export const CvResumeCard = ({
	staffid,
	cvData,
	cvId,
	check,
	setCheck,
	checkPublicCV,
	setCheckPublicCV,
	modifi,
	setAlertIsOpen
}) => {
	const [fullNameData, setFullNameData] = useState(null);
	const [birthdayData, setBirthdayData] = useState(null);

	const [countryData, setCountryData] = useState(null);
	const [cityData, setCityData] = useState(null);

	const [priceData, setPriceData] = useState(null);
	const [stackData, setStackData] = useState(null);

	const [aboutData, setAboutData] = useState(null);

	const [educationData, setEducationData] = useState(null);

	const updateCvRequest = async params => {
		try {
			const {
				fullName = fullNameData,
				birthday = birthdayData,
				country = countryData,
				city = cityData,
				price = priceData,
				stack = stackData,
				about = aboutData,
				education = educationData
			} = params;

			return await CVUpdateRequest(cvId, fullName, birthday, country, city, price, stack, about, education);
		} catch (requestError) {
			console.error(requestError.message);
			if (requestError.response.status === 401) localStorage.setItem('tokenError', true);
		}
	};

	useEffect(() => {
		if (
			fullNameData ||
			fullNameData === '' ||
			birthdayData ||
			birthdayData === '' ||
			cityData ||
			cityData === '' ||
			countryData ||
			countryData === '' ||
			priceData ||
			priceData === 0 ||
			stackData ||
			stackData === '' ||
			aboutData ||
			aboutData === '' ||
			educationData ||
			educationData === ''
		)
			updateCvRequest({}).then(() => setCheck(!check));
	}, [aboutData, birthdayData, cityData, countryData, cvId, fullNameData, priceData, stackData, educationData]);

	return (
		<Paper component='form' elevation={6} sx={{ textAlign: 'left', p: '15px 15px 3rem', m: '0px 0px 30px 0px' }}>
			{modifi ? (
				<Box>
					<UpdatingCvDetailsBasicInfo
						staffid={staffid}
						cvData={cvData}
						setFullNameData={setFullNameData}
						setBirthdayData={setBirthdayData}
						setCountryData={setCountryData}
						setCityData={setCityData}
						setPriceData={setPriceData}
						setStackData={setStackData}
						checkPublicCV={checkPublicCV}
						setCheckPublicCV={setCheckPublicCV}
						updateCvRequest={updateCvRequest}
					/>
					<UpdatingCvDetailsWorkInfo
						cvData={cvData}
						check={check}
						setCheck={setCheck}
						setAlertIsOpen={setAlertIsOpen}
					/>
					<UpdatingCvDetailsSkillsInfo cvData={cvData} check={check} setCheck={setCheck} />
					<UpdatingCvDetailsAboutInfo cvData={cvData} setAboutData={setAboutData} />
					<UpdatingCvDetailsEducationInfo
						cvData={cvData}
						setEducationData={setEducationData}
						check={check}
						setCheck={setCheck}
					/>
				</Box>
			) : (
				<Box>
					<CvDetailsBasicInfo cvData={cvData} />
					<CvDetailsWorkInfo cvData={cvData} />
					<CvDetailsSkillsInfo cvData={cvData} />
					<CvDetailsAboutInfo cvData={cvData} />
					<CvDetailsEducationInfo cvData={cvData} />
				</Box>
			)}
		</Paper>
	);
};
