import React, { useEffect } from "react";
import { Box, Alert, Modal } from "@mui/material";

export const LoginMessage = ({ text, severity, showAlert, setShowAlert }) => {
  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  }, [setShowAlert, showAlert]);

  return (
    <>
      {showAlert ? (
        <Modal
          keepMounted
          id="message-modal"
          open={showAlert}
          hideBackdrop={true}
          onDoubleClick={(event) => event.stopPropagation()}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "20%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 10000,
            }}
          >
            <Alert severity={severity}>{text}</Alert>
          </Box>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};
