import React, { useCallback, useEffect, useState } from 'react';
import { CustomModal } from '../custom-modal';
import { boxStyles } from '../../../../../styles/default-styles/box-styles/box-styles';
import { Box, Button, TextField, Typography } from '@mui/material';
import { MODAL_SPLIT_INPUT_CONFIG, rowsCount } from './config';
import { HelperText } from './split-input-modal-styles';
import { EditableHtmlField } from './editable-html-field';
import { renderToString } from 'react-dom/server';
import { textFieldStyles } from '../../../../../styles/default-styles/text-field-styles/text-field-styles';

/**
 * Компонент модального окна для разбиения строки на значения
 * @param {Object} - пропсы компонента
 * @param {boolean} isOpen - флаг открытия модального окна
 * @param {Function} onClose - обработчик закрытия модального окна
 * @param {Object} styles - стили модального окна
 * @param {Object} config - конфигурация модального окна
 * @param {Object} options - опции модального окна
 * @param {Function} onProcess - обработчик обработки значений
 * @returns {JSX.Element} Компонент модального окна
 */
export const SplitInputModal = ({
	isOpen = false,
	onClose = () => {},
	styles = {},
	config = {},
	options = {},
	onProcess = valuesArr => {},
	size = 'md',
	type = 'def'
}) => {
	const [value, setValue] = useState('');
	const [values, setValues] = useState([]);
	const [originValue, setOriginValue] = useState('');
	const [valuesIsProcessed, setValuesIsProcessed] = useState(false);
	const [dataIsLoaded, setDataIsLoaded] = useState(true);
	const [isValid, setIsValid] = useState(true);
	const editableFieldRef = React.useRef();

	const {
		returnValueList = false,
		altProcessHandler,
		onValidation,
		innerHtmlProps,
		ExtendedElement,
		defaultValues = [],
		multiple = true
	} = options;

	const currentStyles = { height: 'fit-content', ...styles };

	const {
		SPLIT_REGEX_ERROR,
		BUTTON_LABEL,
		INVALID_MESSAGE,
		REPLACE_REGEX,
		HANDLE_VALUES_ERROR,
		LABEL,
		PLACEHOLDER,
		HELPER_TEXT,
		ALT_BUTTON_LABEL,
		TITLE,
		ROWS_COUNT,
		SPLIT_REGEX,
		REPLACE_STRING
	} = { ...MODAL_SPLIT_INPUT_CONFIG, ...config };

	// метод разбивает входную строку на значения и возвращает массив этих значений
	// пример: 'значение\nзначение\nзначение' => [{value: 'значение'}, {value: 'значение'}, {value: 'значение'}]
	const parseValues = useCallback(str => {
		try {
			let result;
			const arrFromStr = str.split(SPLIT_REGEX);

			result = arrFromStr.reduce((acc, string) => {
				if (string.trim().length > 0) {
					let result = string;

					if (REPLACE_REGEX) {
						result = result.replace(REPLACE_REGEX, REPLACE_STRING);
					}

					acc.push({ value: result.trim() });
				}
				return acc;
			}, []);

			return result;
		} catch (error) {
			console.error(error, SPLIT_REGEX_ERROR);
		}
	}, []);

	// метод объединяет два массива в один, путем обновления первого массива значениями из второго, ключевым свойством является value
	// пример:
	// array1: [
	//     {
	//         value: '1',
	//         variants: ['a', 'b']
	//     },
	//     {
	//         value: '2'
	//     },
	//     {
	//         value: '3'
	//     },
	//     {
	//         value: '5'
	//     }
	// ]
	// array2: [
	//     {
	//         value: '10'
	//     },
	//     {
	//         value: '2',
	//         variants: ['d']
	//     },
	//     {
	//         value: '4'
	//     }
	// ]
	// result: [
	// 		({
	// 			value: '10',
	// 			variants: ['a', 'b']
	// 		},
	// 		{
	// 			value: '2',
	// 			variants: ['d']
	// 		},
	// 		{
	// 			value: '4'
	// 		})
	// 	]
	function updateArrByArr(array1, array2) {
		const mergedArray = [];

		const maxLength = Math.max(array1.length, array2.length);

		for (let i = 0; i < maxLength; i++) {
			const item1 = array1[i] || {};
			const item2 = array2[i] || {};

			// Если value во втором массиве отсутствует или пустое, пропускаем этот элемент
			if (!item2.value) {
				continue;
			}

			const mergedItem = {
				value: item2.value
			};

			// Если variants во втором массиве отсутствует и variants есть в первом массиве, добавляем variants из первого массива
			if (item1.variants && !item2.variants) {
				mergedItem.variants = item1.variants;
			}

			// Если variants во втором массиве есть, добавляем его в результирующий объект
			if (item2.variants) {
				mergedItem.variants = item2.variants;
			}

			// Удаляем свойство variants, если массив variants пустой
			if (mergedItem.variants && mergedItem.variants.length === 0) {
				delete mergedItem.variants;
			}

			mergedArray.push(mergedItem);
		}

		return mergedArray;
	}

	// обработчик изменения значения
	const handleChange = useCallback(
		updatedValue => {
			// Сбрасываем флаг обработки требований
			if (valuesIsProcessed) setValuesIsProcessed(false);

			const parsedValues = parseValues(updatedValue);
			let formattedValue = updatedValue;

			// Обновляем состояния массива значений и поля ввода
			setValues(prevValues => updateArrByArr(prevValues, parsedValues));

			if (type === 'inner-html') {
				formattedValue = updatedValue.replace(/\n\n/g, '\n');
			}
			setValue(formattedValue);
		},
		[valuesIsProcessed]
	);

	// обработчик валидации значений
	const handleValidation = ({ valuesArr }) => {
		if (!onValidation || !valuesArr) return;

		let isValid = onValidation(valuesArr);

		setIsValid(!!isValid);
	};

	useEffect(() => {
		if (onValidation) {
			handleValidation({ valuesArr: values });
		}
	}, [values]);

	useEffect(() => {
		if (defaultValues.length) {
			setValues(parseValues(defaultValues.join('\n')));
			setValue(defaultValues.join('\n'));
		}
	}, []);

	// Обработчик закрытия модального окна
	const handleClose = () => {
		onClose();
		setValue('');
		setValues([]);
		setOriginValue('');
		setValuesIsProcessed(false);
	};

	// Обработчик обработки значений
	const handleProcess = async () => {
		try {
			if (dataIsLoaded) setDataIsLoaded(false);

			const response = await onProcess(values, value);

			setOriginValue(value);
			setValuesIsProcessed(true);
			setDataIsLoaded(true);

			if (response) {
				const { valuesString, valuesHtml } = response;

				if (returnValueList) {
					if (type === 'inner-html' && valuesHtml) {
						editableFieldRef.current.innerHTML = renderToString(valuesHtml);
					}

					setValue(valuesString);
				}

				return;
			}

			handleClose();
		} catch (error) {
			console.error(error, HANDLE_VALUES_ERROR);
		}
	};

	// обработчик ввода при невалидном значении
	const handleInvalidKeyPress = e => {
		if (e.key.match(SPLIT_REGEX) || e.key === 'Enter') {
			e.preventDefault();
		}
	};

	// обработчик ввода при невалидном значении
	const handleInvalidKeyDown = e => {
		if (e.key === 'Backspace') {
			setValue(prevValue => prevValue);
		}
	};

	return (
		<CustomModal isOpen={isOpen} onClose={handleClose} styles={currentStyles} size={size}>
			<Box sx={{ ...boxStyles.flexBoxColumn, gap: 4 }}>
				{/* Блок с заголовком */}
				<Typography variant='h5' sx={{ pt: 1 }}>
					{TITLE}
				</Typography>

				{/* Блок с основным контентом */}
				<Box sx={{ ...boxStyles.flexBoxColumn, gap: 3 }}>
					{/* Блок с редактируемым текстовым полем */}
					<Box>
						{type === 'inner-html' ? (
							<EditableHtmlField onChange={handleChange} editableFieldRef={editableFieldRef} {...innerHtmlProps} />
						) : (
							<TextField
								label={LABEL}
								placeholder={PLACEHOLDER}
								value={value}
								onInput={e => handleChange(e.target.value)}
								sx={{
									width: '100%',
									height: '100%',
									'.MuiInputBase-multiline': { borderRadius: 0 },
									...textFieldStyles.orangeTextField
								}}
								rows={ROWS_COUNT || rowsCount(size)}
								multiline={multiple}
								color={!isValid ? 'error' : 'warning'}
								disabled={!dataIsLoaded}
								onKeyPress={!isValid ? handleInvalidKeyPress : undefined}
								onKeyDown={!isValid ? handleInvalidKeyDown : undefined}
							/>
						)}
						{!!HELPER_TEXT && (
							<HelperText valid={isValid.toString()}>{!isValid ? INVALID_MESSAGE : HELPER_TEXT}</HelperText>
						)}
					</Box>
					{/* Блок с вариациями */}
					{ExtendedElement && <ExtendedElement values={values} setValues={setValues} />}
					{/* Блок с кнопками */}
					<Button
						variant='contained'
						color='warning'
						sx={{
							alignSelf: 'flex-end',
							width: 'fit-content',
							fontSize: '20px',
							fontWeight: '600'
						}}
						onClick={
							valuesIsProcessed && altProcessHandler ? () => altProcessHandler(value, originValue) : handleProcess
						}
						disabled={!value || !dataIsLoaded || !isValid}>
						{(valuesIsProcessed && ALT_BUTTON_LABEL) || BUTTON_LABEL}
					</Button>
				</Box>
			</Box>
		</CustomModal>
	);
};
