import React from "react";

import { Box, LinearProgress } from "@mui/material";

export const LinearProgressBar = ({ progressSurvey }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress
        color="warning"
        variant="determinate"
        value={progressSurvey}
      />
    </Box>
  );
};
