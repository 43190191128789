import React, { useState, useMemo } from "react";

import {
  FormControl,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormHelperText,
  useFormControl,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export const PasswordInput = ({
  id,
  name,
  complete,
  label,
  setData,
  errorText,
  helperText,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [term, setTerm] = useState("");

  const handleChangePassword = (event) => {
    const temp = event.target.value;
    setTerm(temp.replaceAll(/^[\s.`":'$@~;]*$/g, ""));
    setData(temp.replaceAll(/^[\s.`":'$@~;]*$/g, ""));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function MyFormHelperText() {
    const { focused } = useFormControl() || {};

    const myHelperText = useMemo(() => {
      if (helperText === "") {
        if (focused) {
          return "Пароль должен быть не меньше 8 символов";
        }
      } else {
        return helperText;
      }
    }, [focused]);

    return <FormHelperText>{myHelperText}</FormHelperText>;
  }

  return (
    <FormControl error={errorText} variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
      <OutlinedInput
        id={id}
        type={showPassword ? "text" : "password"}
        name={name}
        inputProps={{
          autoComplete: complete,
        }}
        value={term}
        onChange={handleChangePassword}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
      <MyFormHelperText />
    </FormControl>
  );
};
