import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { boxStyles } from '../../../../../styles/default-styles/box-styles/box-styles';

export const GridWrapperContainer = styled(Box)(({ theme }) => ({
	...boxStyles.flexBoxColumn,
	gap: '15px',
	justifyContent: 'space-between',
	width: '100%',
	height: '100%',
	minHeight: '79vh',
	border: '1px solid',
	borderColor: grey[300],
	padding: '20px',
	boxSizing: 'border-box',
	borderRadius: '15px'
}));
