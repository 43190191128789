import React, { useCallback } from 'react';
import { InputBase, Paper } from '@mui/material';
import { useCommentUpdate } from '../../../../../hooks/candidates-card-update-comment/useCommentUpdate';

export const TextComment = ({ comment, setData, onChange }) => {
	const [value, handleChange] = useCommentUpdate(comment, 500, useCallback((newValue) => {
		if (newValue !== comment) {
			setData(newValue);
			onChange({ description: newValue });
		}
	}, [comment, setData]));

	return (
		<Paper
			elevation={6}
			sx={{
				minHeight: '100px'
			}}
		>
			<InputBase
				value={value === undefined ? comment : value}
				onChange={handleChange}
				rows={4}
				multiline
				sx={{
					minWidth: '98%',
					p: '2px 4px',
					ml: 1,
					flex: 1,
					fontSize: '15px'
				}}
			/>
		</Paper>
	);
};
