import React, { useContext, useEffect, useState } from 'react';
import { Box, Checkbox, List, ListItem, Typography } from '@mui/material';
import { requestErrorWrapper } from '../../../../utils/request-error-wrapper/request-error-wrapper';
import { getRecruitersRoles, updateRecruitersRoles } from '../../../../api/api';
import { checkBoxStyles } from '../../../../styles/default-styles/check-box-styles/check-box-styles';
import { grey } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import { AllUsersContext } from '../../../contexts/AllUsersContext/AllUsersContext';

export const UsersStatsTab = () => {
	const [users, setUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const { updateUsers } = useContext(AllUsersContext);

	// метод получения данных с сервера
	const fetchUsersData = async () => {
		return await requestErrorWrapper({
			handleRequest: getRecruitersRoles
		});
	};

	// метод обновления ролей рекрутеров
	const updateRoles = async data => {
		return await requestErrorWrapper({
			handleRequest: () => updateRecruitersRoles(data)
		});
	};

	// форматирование и сортировка пользователей по алфавиту
	const sortAndFormatRecruitersData = async () => {
		setIsLoading(true);
		try {
			const usersData = await fetchUsersData();

			// Создание нового массива с измененными данными
			const formattedUsersData = usersData.payload.users.sort((a, b) => (a.name > b.name ? 1 : -1));
			setUsers(formattedUsersData);
			setIsLoading(false);
		} catch (error) {
			console.log('Ошибка получения данных о пользователях');
			console.error(error.message);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		sortAndFormatRecruitersData();
	}, []);

	const handleUpdateRecruitersRole = async (userId, isRecruiter) => {
		try {
			setUsers([...users.map(user => (user.id === userId ? { ...user, isRecruiter } : user))]);
			const updateRecruiters = [{ userId, isRecruiter }];
			await updateRoles(updateRecruiters).then(() => updateUsers());
		} catch (error) {
			console.log('Ошибка обновления ролей рекрутеров');
			console.error(error.message);
		}
	};

	return (
		<Box sx={{ mt: 2, ml: 13, mr: 5, height: '100%' }}>
			<Typography variant='h4'>Статистика по пользователям:</Typography>
			<Typography variant='h7' sx={{ color: grey[600], marginTop: '10px' }}>
				Выберите пользователей, которые будут отображены в Мониторинге, Активности, Аналитике, Сделках
			</Typography>
			<List
				sx={{
					display: 'grid',
					gridTemplateRows: 'repeat(9, 1fr)',
					gridAutoFlow: 'column',
					mt: 2,
					overflowY: 'auto'
				}}>
				{isLoading ? (
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<CircularProgress color='warning' />
					</Box>
				) : (
					users.map(user => (
						<ListItem key={user.id}>
							<Checkbox
								sx={checkBoxStyles.orangeCheckBox}
								size='large'
								checked={user.isRecruiter}
								onChange={e => {
									handleUpdateRecruitersRole(user.id, e.target.checked);
								}}
							/>
							<Typography sx={{ fontSize: '18px' }}>{user.name}</Typography>
						</ListItem>
					))
				)}
			</List>
		</Box>
	);
};
