import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { RequirementGridItem } from './requirement-grid-item';
import { buttonStyles } from '../../../../../../../styles/default-styles/button-styles/button-styles';
import { SplitInputModal } from '../../../../../../lib/ui/modals/split-input-modal';
import { REQUIREMENTS_CHECK_LIST_CONFIG } from './config';
import { requestErrorWrapper } from '../../../../../../../utils/request-error-wrapper/request-error-wrapper';
import { getRequirementsCriteriaById, postUpdateCriteriaById } from '../../../../../../../api/api';
import { postDeleteCandidateRequirement, postUpdateCandidateRequirement } from '../../../../../../../api/apiCandidate';

/**
 * Компонент для отображения и управления списком требований
 * @param {number} staffId - Идентификатор сотрудника
 * @returns {JSX.Element} - Возвращает JSX элемент
 */
export const RequirementsCheckList = ({ staffId }) => {
	const [values, setValues] = useState([]);
	const [addCriteriaModalIsOpen, setAddCriteriaModalIsOpen] = useState(false);
	const { TITLE, ADD_BUTTON_LABEL, ADD_CRITERIA_MODAL } = REQUIREMENTS_CHECK_LIST_CONFIG;

	/**
	 * Открыть модальное окно для добавления критериев
	 */
	const openAddCriteriaModal = () => {
		if (!addCriteriaModalIsOpen) setAddCriteriaModalIsOpen(true);
	};

	/**
	 * Закрыть модальное окно для добавления критериев
	 */
	const closeAddCriteriaModal = () => {
		if (addCriteriaModalIsOpen) setAddCriteriaModalIsOpen(false);
	};

	/**
	 * Получить критерии требований по идентификатору сотрудника
	 * @param {number} staffId - Идентификатор сотрудника
	 */
	const handleGetCriteria = async ({ staffId }) => {
		const criteria = await requestErrorWrapper({ handleRequest: () => getRequirementsCriteriaById({ staffId }) });

		setValues([...criteria]);
	};

	/**
	 * Добавить критерии требований
	 * @param {Array} criteria - Массив критериев
	 */
	const handleAddCriteria = async criteria => {
		const requirements = criteria.map(item => item.value);

		const response = await requestErrorWrapper({
			handleRequest: () => postUpdateCriteriaById({ staffId: parseInt(staffId), requirements })
		});

		setValues(prevValues => [...prevValues, ...response]);
	};

	/**
	 * Удалить критерий требования по его идентификатору
	 * @param {number} requirementId - Идентификатор критерия
	 */
	const handleDeleteCandidateCriterion = async requirementId => {
		const response = await requestErrorWrapper({
			handleRequest: () => postDeleteCandidateRequirement({ staffId: parseInt(staffId), requirementId })
		});

		if (response) setValues(prevValues => prevValues.filter(item => item.id !== requirementId));
	};

	/**
	 * Обновить критерий требования по его идентификатору
	 * @param {Object} params - Параметры для обновления критерия
	 */
	const handleUpdateCandidateCriterion = async ({ id, newValue, options = null, categoriesId = null }) => {
		const response = await requestErrorWrapper({
			handleRequest: () =>
				postUpdateCandidateRequirement({ staffId: parseInt(staffId), reqId: id, newValue, options, categoriesId })
		});

		if (response) setValues(prevValues => prevValues.map(item => (item.id === id ? response : item)));
	};

	useEffect(() => {
		handleGetCriteria({ staffId });
	}, []);

	return (
		<>
			<Box display='flex' flexDirection='column' gap='10px'>
				<Box pl={1} pr={3} display='flex' justifyContent='space-between' alignItems='center' gap='10px'>
					<Typography color='disabled' fontWeight='600' fontSize='1.3rem'>
						{TITLE}
					</Typography>
					<Button sx={{ ...buttonStyles.orangeButton, fontSize: '1rem' }} onClick={openAddCriteriaModal}>
						{ADD_BUTTON_LABEL}
					</Button>
				</Box>
				<Box
					sx={{
						border: '1px solid',
						borderColor: 'grey.300',
						borderRadius: '15px',
						height: '30vh',
						overflow: 'hidden'
					}}>
					<Grid
						container
						spacing={2}
						sx={{
							padding: '0 0 20px',
							boxSizing: 'border-box',
							width: '100%',
							maxHeight: '100%',
							margin: 0,
							overflow: 'auto',
							alignItems: 'center'
						}}>
						{values.map(({ id, value, ...props }) => (
							<Grid key={id} xs={6} item sx={{ justifyContent: 'center' }}>
								<RequirementGridItem
									id={id}
									defaultValue={value}
									onDelete={handleDeleteCandidateCriterion}
									onChange={handleUpdateCandidateCriterion}
								/>
							</Grid>
						))}
					</Grid>
				</Box>
			</Box>
			<SplitInputModal
				isOpen={addCriteriaModalIsOpen}
				onClose={closeAddCriteriaModal}
				config={ADD_CRITERIA_MODAL}
				onProcess={handleAddCriteria}
				size='fsy'
			/>
		</>
	);
};
