import React, { useState, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { getCategoriesStructureRequest, getTechnicalTermsRequest } from '../../../../api/api';
import { SearchTechnicalTerminologyBar } from './search-technical-terminology-bar';
import { TermsCardContainer } from './terms-сard-сontainer';
import { AddTermButton } from './add-term-button';
import { boxStyles } from '../../../../styles/default-styles/box-styles/box-styles';
import { FilterDots } from '../../../lib/ui/filter-dots';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

export const UnifiedTechnicalTerminologyTab = () => {
	const [technicalTermsData, setTechnicalTermsData] = useState([]);
	const [filteredTerms, setFilteredTerms] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [searchedTerms, setSearchedTerms] = useState([]);
	const [check, setCheck] = useState(false);
	const [termsIsLoaded, setTermsIsLoaded] = useState(false);
	const [filtersByCategory, setFiltersByCategory] = useState({});
	const [formattedFiltersByCategory, setFormattedFiltersByCategory] = useState({});
	const [activeFilters, setActiveFilters] = useState({});

	// получаем данные с сервера
	useEffect(() => {
		const getTermsAndFiltersData = async () => {
			setTermsIsLoaded(false);
			try {
				const techTerms = await getTechnicalTermsRequest();

				const filterListByCategories = await getCategoriesStructureRequest();

				const formattedFilterListByCategories = filterListByCategories.reduce((prevCategory, currentCategory) => {
					prevCategory[currentCategory.name] = currentCategory.subCategories.map(subCategory => subCategory.name);
					return prevCategory;
				}, {});

				setTechnicalTermsData(techTerms);
				setFiltersByCategory(filterListByCategories);
				setFormattedFiltersByCategory(formattedFilterListByCategories);
			} catch (requestError) {
				console.error(requestError.message);
				if (requestError.response.status === 401) localStorage.setItem('tokenError', true);
			}
		};

		getTermsAndFiltersData().then(() => setTermsIsLoaded(true));
	}, [check]);

	// реализация фильтрации
	useMemo(() => {
		let filteredTerms = [...technicalTermsData];

		const stackIds = Object.values(activeFilters)
			.flat()
			.map(subcategoryName => {
				const matchingSubcategory = filtersByCategory
					.flatMap(category => category.subCategories)
					.find(subcategory => subcategory.name === subcategoryName);
				return matchingSubcategory ? matchingSubcategory.id : null;
			});

		const filteredStackIds = stackIds.filter(id => id !== null);

		const activeFiltersGroups = Object.keys(activeFilters);

		if (activeFiltersGroups.length > 0) {
			filteredTerms = filteredTerms.filter(term => {
				return activeFiltersGroups.reduce((accumValue, currentGroup) => {
					return accumValue && term.listStackID.some(stackId => filteredStackIds.includes(stackId));
				}, true);
			});
		}

		setFilteredTerms(filteredTerms);
	}, [technicalTermsData, activeFilters]);

	// реализация поиска
	useEffect(() => {
		let searchedTerms = [...filteredTerms];

		if (searchValue) {
			searchedTerms = searchedTerms.filter(
				term =>
					term.term.toLowerCase().includes(searchValue.toLowerCase()) ||
					term.options.some(option => option.toLowerCase().includes(searchValue.toLowerCase()))
			);
		}

		setSearchedTerms(searchedTerms);
	}, [searchValue, filteredTerms]);

	return (
		<Box sx={{ mt: 2, ml: 13 }}>
			<Box
				sx={{
					...boxStyles.flexBoxRow,
					alignItems: 'center',
					gap: '10px',
					mb: 2,
					mt: 2,
					width: '92vw'
				}}>
				<SearchTechnicalTerminologyBar searchTerm={searchValue} setSearchTerm={setSearchValue} />
				<AddTermButton
					techTerms={technicalTermsData}
					filterList={formattedFiltersByCategory}
					filtersByCategory={filtersByCategory}
					check={check}
					setCheck={setCheck}
				/>
				<FilterDots
					filterGroup={'stack'}
					filterList={formattedFiltersByCategory}
					activeFilters={activeFilters['stack']}
					setActiveFilters={setActiveFilters}
					menuType='stacks'
					buttonElement={<FilterAltIcon color={activeFilters['stack']?.length > 0 ? 'warning' : ''} />}
				/>
			</Box>
			<TermsCardContainer
				filteredTerms={searchValue.length ? searchedTerms : filteredTerms}
				check={check}
				setCheck={setCheck}
				termsIsLoaded={termsIsLoaded}
				filterList={formattedFiltersByCategory}
				filtersByCategory={filtersByCategory}
			/>
		</Box>
	);
};
