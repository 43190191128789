import React, { useState } from "react";
import { buttonStyles } from "../../../../../styles/default-styles/button-styles/button-styles";
import { checkBoxStyles } from "../../../../../styles/default-styles/check-box-styles/check-box-styles";
import { AddFieldDate } from "./add-field-date";
import { CVWorkExperienceAddRequest } from "../../../../../api/api";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  Alert,
  AlertTitle,
  Snackbar,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const AddingCvWorkExperience = ({ id, check, setCheck }) => {
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [formData, setFormData] = useState({
    companyName: "",
    companyCity: "",
    position: "",
    isRelevant: false,
    isPresentDay: false,
    start: "",
    end: "",
    description: "",
  });

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleFormChange = (fieldName) => (event) => {
    let value = event.target.value;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    setFormData({ ...formData, [fieldName]: value });
  };

  const handleSave = () => {
    let {
      companyName,
      companyCity,
      position,
      isRelevant,
      isPresentDay,
      start,
      end,
      description,
    } = formData;

    if (
      companyName === "" ||
      position === "" ||
      start === ""
    ) {
      setOpenAlert(true);
      return;
    }

    //прибавляем к дате начала опыта работы 3 часа,
    //т.к. на бек должна отправляться дата по Гринвичу(у нас по мск)
    start.setHours(start.getHours() + 3);

    end = isPresentDay || end === "" ? null : end;

    if (
      companyName ||
      companyCity ||
      description ||
      position ||
      start ||
      end ||
      isRelevant !== null ||
      end === null
    ) {
      CVWorkExperienceAddRequest(
        id,
        companyName,
        companyCity,
        description,
        position,
        start,
        end,
        isRelevant
      )
        .then(() => {
          setCheck(!check);
        })
        .catch((requestError) => console.error(requestError.message));
    }

    handleCloseModal();
    setFormData({
      companyName: "",
      companyCity: "",
      position: "",
      isRelevant: false,
      isPresentDay: false,
      start: "",
      end: "",
      description: "",
    });
  };

  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: "80px",
          },
        },
      },
    },
  });

  return (
    <Box sx={{ mb: "5px" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert severity="warning">
          <AlertTitle>Внимание!</AlertTitle>
          Пожалуйста, заполните обязательные поля -{" "}
          <strong>они отмечены звездочкой!</strong>
        </Alert>
      </Snackbar>

      <Button
        onClick={handleOpenModal}
        sx={{
          bgcolor: "#ef6c00",
          borderRadius: "3%",
          color: "#FFFFFF",
          fontWeight: "bold",
          mr: 1,
          "&:hover": {
            bgcolor: "#ff9800",
            color: "#FFFFFF",
            transform: "scale(1.03)",
          },
        }}
      >
        <AddIcon /> Добавить опыт
      </Button>
      <Dialog maxWidth="lg" open={open} onClose={handleCloseModal}>
        <DialogTitle>Добавить опыт</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                sx={{
                  marginTop: "5px",
                }}
                required
                label="Организация"
                fullWidth
                color="warning"
                value={formData.companyName}
                onChange={handleFormChange("companyName")}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                sx={{ marginTop: "5px" }}
                label="Город"
                fullWidth
                color="warning"
                value={formData.companyCity}
                onChange={handleFormChange("companyCity")}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                color="warning"
                label="Должность"
                value={formData.position}
                onChange={handleFormChange("position")}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isRelevant"
                    sx={checkBoxStyles.orangeCheckBox}
                    checked={formData.isRelevant}
                    onChange={handleFormChange("isRelevant")}
                  />
                }
                label="Релевантный опыт"
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container alignItems="flex-end">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isPresentDay"
                      sx={checkBoxStyles.orangeCheckBox}
                      checked={formData.isPresentDay}
                      onChange={handleFormChange("isPresentDay")}
                    />
                  }
                  label="По настоящее время"
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <AddFieldDate
                formData={formData}
                setFormData={setFormData}
                isPresentDay={formData.isPresentDay}
              />
            </Grid>

            <Grid item xs={12}>
              <ThemeProvider theme={theme}>
                <TextField
                  variant="outlined"
                  multiline
                  maxRows={3}
                  color="warning"
                  label="Описание"
                  fullWidth
                  value={formData.description}
                  onChange={handleFormChange("description")}
                />
              </ThemeProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} sx={buttonStyles.orangeButton}>
            Сохранить
          </Button>
          <Button onClick={handleCloseModal} sx={buttonStyles.orangeButton}>
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
