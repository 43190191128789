import { styled } from '@mui/material/styles';
import { boxStyles } from '../../../../../styles/default-styles/box-styles/box-styles';
import { Stack } from '@mui/material';

export const StackContainer = styled(Stack)(() => ({
	minWidth: '300px',
	'@media screen and (max-width: 800px)': {
		minWidth: '150px'
	}
}));
