import React from "react";

import { Box, Pagination } from "@mui/material";

import { paginationStyles } from "../../../../../../styles/default-styles/pagination-styles/pagination-styles";

export const TermsCardPagination = ({ filteredTerms, itemsPerPage, currentPage, handlePageChange }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        mt: 4,
      }}
    >
      <Box
        sx={{
          padding: "10px",
          borderRadius: "15px",
          border: "2px solid #ebebeb",
        }}
      >
        <Pagination
          count={Math.ceil(filteredTerms.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          size="large"
          showFirstButton
          showLastButton
          sx={paginationStyles.orangePagination}
        />
      </Box>
    </Box>
  );
};
