/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "moment/locale/ru";

import { ruRU } from "@mui/x-date-pickers/locales";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { Box, Typography, Button } from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { pickersLayoutClasses } from "@mui/x-date-pickers/PickersLayout";

export const AddFieldDate = ({ isPresentDay, formData, setFormData }) => {
  const [term1, setTerm1] = useState(null);
  const [termTime1, setTermTime1] = useState("");
  const [term2, setTerm2] = useState(null);
  const [termTime2, setTermTime2] = useState("");
  const [openCalendar1, setOpenCalendar1] = useState(false);
  const [openCalendar2, setOpenCalendar2] = useState(false);

  const handleChange1 = (filter1) => {
    setTermTime1(filter1._d);
    setTerm1(filter1);
  };

  const handleChange2 = (filter2) => {
    setTerm2(filter2);
    setTermTime2(filter2._d);
  };

  useEffect(() => {
    setFormData({ ...formData, start: termTime1, end: termTime2 });
  }, [term1, term2]);

  const handleClearClick = () => {
    setTerm1(null);
    setTermTime1(null);
    setTerm2(null);
    setTermTime2(null);
  };
  return (
    <>
      <Button onClick={handleClearClick}>
        <Typography variant="body2" color="textSecondary">
          Сбросить дату
        </Typography>
      </Button>

      <Box sx={{ display: "flex", width: "100%" }}>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale="ru"
          localeText={
            ruRU.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <Box
            width="100%"
            sx={{ display: "flex" }}
            components={["DatePicker"]}
          >
            <DatePicker
              label="От *"
              maxDate={term2}
              closeOnSelect={false}
              open={openCalendar1}
              onOpen={() => setOpenCalendar1(true)}
              onClose={() => setOpenCalendar1(false)}
              value={term1}
              onChange={(newValue) => handleChange1(newValue)}
              slotProps={{
                toolbar: { hidden: false },
                textField: {
                  color: "warning",
                },
                layout: {
                  sx: {
                    [`.${pickersLayoutClasses.toolbar}`]: {
                      color: "white",
                      backgroundColor: "#fb8c00",
                      "& .MuiTypography-root ": {
                        color: "white",
                      },
                    },
                    [`.${pickersLayoutClasses.contentWrapper}`]: {
                      "& .Mui-selected": {
                        backgroundColor: "#fb8c00",
                        borderColor: "#fb8c00",
                        color: "white",
                      },
                      "& .MuiTabs-indicator": { bgcolor: "#f57c00" },
                      "& .MuiClock-pin": { bgcolor: "#f57c00" },
                      "& .MuiClockPointer-root": { bgcolor: "#f57c00" },
                      "& .MuiClockPointer-thumb": {
                        borderColor: "#f57c00",
                      },
                      "& .Mui-selected:hover": {
                        backgroundColor: "#fb8c00",
                        borderColor: "#fb8c00",
                        color: "white",
                      },
                      "& .Mui-selected:focus": {
                        backgroundColor: "#fb8c00",
                        borderColor: "#fb8c00",
                        color: "white",
                      },
                    },
                  },
                },
              }}
              sx={{
                width: "100%",
                svg: { color: "#fb8c00" },
                "& .MuiInputLabel-root.Mui-focused": { color: "#fb8c00" }, //styles the label
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#fb8c00",
                },
              }}
            />
          </Box>
        </LocalizationProvider>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 1,
            pt: 2,
          }}
        >
          <Typography
            id="keep-mounted-modal-title"
            sx={{
              fontSize: "20px",
            }}
          >
            -
          </Typography>
        </Box>

        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale="ru"
          localeText={
            ruRU.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <Box
            width="100%"
            sx={{ display: "flex" }}
            components={["DatePicker"]}
          >
            <DatePicker
              label="До"
              disabled={isPresentDay}
              minDate={term1}
              closeOnSelect={false}
              open={openCalendar2}
              onOpen={() => setOpenCalendar2(true)}
              onClose={() => setOpenCalendar2(false)}
              value={term2}
              onChange={(newValue) => handleChange2(newValue)}
              slotProps={{
                toolbar: { hidden: false },
                textField: {
                  color: "warning",
                },
                layout: {
                  sx: {
                    [`.${pickersLayoutClasses.toolbar}`]: {
                      color: "white",
                      backgroundColor: "#fb8c00",
                      "& .MuiTypography-root ": {
                        color: "white",
                      },
                    },
                    [`.${pickersLayoutClasses.contentWrapper}`]: {
                      "& .Mui-selected": {
                        backgroundColor: "#fb8c00",
                        borderColor: "#fb8c00",
                        color: "white",
                      },
                      "& .MuiTabs-indicator": { bgcolor: "#f57c00" },
                      "& .MuiClock-pin": { bgcolor: "#f57c00" },
                      "& .MuiClockPointer-root": { bgcolor: "#f57c00" },
                      "& .MuiClockPointer-thumb": {
                        borderColor: "#f57c00",
                      },
                      "& .Mui-selected:hover": {
                        backgroundColor: "#fb8c00",
                        borderColor: "#fb8c00",
                        color: "white",
                      },
                      "& .Mui-selected:focus": {
                        backgroundColor: "#fb8c00",
                        borderColor: "#fb8c00",
                        color: "white",
                      },
                    },
                  },
                },
              }}
              sx={{
                width: "100%",
                svg: { color: !isPresentDay ? "#fb8c00" : "#bdbdbd" },
                "& .MuiInputLabel-root.Mui-focused": { color: "#fb8c00" }, //styles the label
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#fb8c00",
                },
                MuiPickersPopper: {
                  paper: {
                    display: "none",
                  },
                },
              }}
            />
          </Box>
        </LocalizationProvider>
      </Box>
    </>
  );
};
