import React from 'react';
import { Box } from '@mui/material';
import { RequirementsCheckList } from './requirements-check-list';

export const CheckListsContainer = ({ staffId }) => {
	return (
		<>
			<Box pt={1} pb={1}>
				<RequirementsCheckList staffId={staffId} />
			</Box>
		</>
	);
};
