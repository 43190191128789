import React, { useState } from "react";

import bcrypt from "bcryptjs-react";

import { AuthRequest, RegisterRequest } from "../../../../api/api";

import { Box, Paper } from "@mui/material";

import { AuthorizationForm } from "./authorization-form";
import { RegistrationForm } from "./registration-form";
import { LoginMessage } from "./login-message/login-message";
import { VerificationTab } from './verification-tab';

export const LoginForm = ({ checkAbout, setCheckAbout }) => {
  const [showAlert, setShowAlert] = useState(false);

  const [text, setText] = useState();
  const [severity, setSeverity] = useState();
  const [showRegistration, setShowRegistration] = useState(false);
  const [showVerificationTab, setShowVerificationTab] = useState(false);

  const Auth = (emailData, passwordData) => {
    const hashedPassword = bcrypt.hashSync(
      passwordData,
      "$2a$10$CwTycUXWue0Thq9StjUM0u"
    );
    AuthRequest(emailData, hashedPassword)
      .then((data) => {
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
        setCheckAbout(checkAbout + 1);
      })
      .catch((requestError) => {
        if (requestError.message === "Request failed with status code 401") {
          setText("Неверный логин или пароль!");
          setSeverity("error");

          setShowAlert(true);
        }
        console.error(requestError.message);
      });
  };

  const Register = (emailData, nameData, passwordData) => {
    const hashedPassword = bcrypt.hashSync(
      passwordData,
      "$2a$10$CwTycUXWue0Thq9StjUM0u"
    );
    RegisterRequest(emailData, nameData, hashedPassword)
      .then(() => {
        setSeverity("success");
        setShowRegistration(false);
        setShowVerificationTab(true);
      })
      .catch((requestError) => {
        if (requestError.message === "Request failed with status code 400") {
          setText("Пользователь с такой почтой уже есть!");
          setSeverity("error");
          setShowAlert(true);
        }
        console.error(requestError.message);
      });
  };

  return (
    <Box>
      <LoginMessage
        text={text}
        severity={severity}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
      />

      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: showVerificationTab ? 800 : 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          border: showVerificationTab ? "6px solid #ef6c00" : "none",
        }}
      >
        {showVerificationTab ? (
          <VerificationTab />
        ) : !showRegistration ? (
          <AuthorizationForm
            Auth={Auth}
            showRegistration={showRegistration}
            setShowRegistration={setShowRegistration}
            checkAbout={checkAbout}
            setCheckAbout={setCheckAbout}
          />
        ) : (
          <RegistrationForm
            Register={Register}
            showRegistration={showRegistration}
            setShowRegistration={setShowRegistration}
          />
        )}
      </Paper>
    </Box>
  );
};
