import React, { useContext, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router';
import axios from 'axios';
import copy from 'copy-to-clipboard';
import { getCategoriesStructureRequest, getStaffsRequest, updateStaffRequest } from '../../../../api/api';
import { postAddSeveralRequirements, postCheckRequirements } from '../../../../api/apiCandidate';

import { Box, Button, CardMedia, Divider, Paper, Stack } from '@mui/material';

import { TextFieldCard } from './text-field-card';
import { NumberFieldCard } from './number-field-card';
import { ExperienceFieldCard } from './experience-field-card';
import { CandidateCardComments } from './candidate-card-comments';
import { TextComment } from './text-comment';
import { CandidateSelectCard } from './candidate-select-card';
import { BirthdayFieldCard } from './birthday-field-card';
import { CandidateContactsMenu } from './candidate-contacts-menu';
import { CandidatesClipsModal } from './candidates-clips-modal';
import { ReturnButton } from '../../../lib/ui/buttons/return-button';
import { SelectorTimeZone } from './selector-time-zone';
import { CommunicationStatusField } from './communication-status-field';
import { CandidateGradeField } from './candidate-grade-field';
import { TabResume } from './tabs-resume';
import { DateAddedCard } from './date-added-card';
import { CreateSurveyButton } from '../../../lib/ui/cv-resume-survey/create-survey-button';
import { MessageAlert } from '../../../lib/ui/alerts/message-alert';
import { CandidateInfo, CandidateInfoStacks, StyledStack } from './candidate-card-page-styles';
import { boxStyles as styledBox } from '../../../../styles/default-styles/box-styles/box-styles';
import { CANDIDATE_CARD_PAGE_CONFIG } from './config';
import { buttonStyles } from '../../../../styles/default-styles/button-styles/button-styles';
import { SplitInputModal } from '../../../lib/ui/modals/split-input-modal';
import { AlertContext } from '../../../contexts/AlertContext/AlertContext';
import { CANDIDATE_GRADE_NAMES, UNRECOGNIZED_CATEGORY_ID } from '../../../../constants/constants';
import { abbreviationFullName } from '../../../../utils/string-process';
import { TermWithVariantsModal } from '../../../lib/ui/modals/term-with-variants-modal';
import { CopyIconButton } from '../../../lib/ui/buttons/copy-icon-button';
import { addMarkerToRequirements, parseRequirements } from './utils';

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
	flexGrow: 1,
	transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	marginLeft: '1rem',
	marginRight: '1rem',
	'@media screen and (max-width: 1200px)': {
		marginRight: '6rem'
	},
	[theme.breakpoints.up('md')]: {
		paddingLeft: `calc(${theme.spacing(10)} + 15px)`
	}
}));

const setDocumentTitle = title => {
	document.title = title;
};

export const CandidateCardPage = () => {
	// инициализация состояний и переменных
	const [oneStaff, setOneStaff] = useState([]);
	const [fullNameData, setFullNameData] = useState(null);
	const [locationData, setLocationData] = useState(null);
	const [gradeData, setGradeData] = useState(null);
	const [descriptionData, setDescriptionData] = useState(null);
	const [stackData, setStackData] = useState(null);
	const [ageData, setAgeData] = useState(null);
	const [phoneData, setPhoneData] = useState(null);
	const [emailData, setEmailData] = useState(null);
	const [salaryData, setSalaryData] = useState(null);
	const [experienceData, setExperienceData] = useState(null);
	const [relevantExperienceData, setRelevantExperienceData] = useState(null);
	const [statusData, setStatusData] = useState(null);
	const [timeZoneData, setTimeZoneData] = useState(null);
	const [rateData, setRateData] = useState(null);
	const [birthdayData, setBirthdayData] = useState(null);
	const [responsibleData, setResponsibleData] = useState(null);
	const [check, setCheck] = useState(false);
	const [checkTokenError, setCheckTokenError] = useState();
	const [checkPublicCV, setCheckPublicCV] = useState();
	const [open, setOpen] = useState(true);
	const [checkReqModalIsOpen, setCheckReqModalIsOpen] = useState(false);
	const [alertIsOpen, setAlertIsOpen] = useState(false);
	const [cancelToken, setCancelToken] = useState(null);
	const [addReqModalIsOpen, setAddReqModalIsOpen] = useState(false);
	const [itCategories, setItCategories] = useState([]);
	const [defaultReqs, setDefaultReqs] = useState([]);
	const [publicCVData, setPublicCVData] = useState({});
	const [checkedRequirements, setCheckedRequirements] = useState([]);

	const convertToNumber = variable => {
		return variable === null ? null : Number(variable);
	};

	const errorAlertData = {
		type: 'error',
		message: 'Данные не сохранены',
		open: alertIsOpen,
		setIsOpen: setAlertIsOpen
	};

	let { id } = useParams();

	// контексты
	const { showAlert } = useContext(AlertContext);

	// методы
	useEffect(() => {
		setDocumentTitle('Карточка кандидата');

		getItCategories();
	}, []);

	useEffect(() => {
		getStaffsRequest(id)
			.then(data => {
				setOneStaff(data);
			})
			.catch(requestError => {
				console.error(requestError.message);
				if (requestError.response.status === 401) {
					localStorage.setItem('tokenError', true);
					setCheckTokenError(!checkTokenError);
				}
			});
	}, [id, checkPublicCV, checkTokenError, check]);

	const getItCategories = async () => {
		try {
			const stackFilterValues = await getCategoriesStructureRequest();

			if (stackFilterValues) setItCategories(stackFilterValues);
		} catch (requestError) {
			console.error(requestError.message);
			if (requestError.response.status === 401) {
				localStorage.setItem('tokenError', true);
				setCheckTokenError(!checkTokenError);
			}
		}
	};
	const updateData = async params => {
		const newCancelToken = axios.CancelToken.source();
		setCancelToken(newCancelToken);

		const {
			fullName = fullNameData,
			age = convertToNumber(ageData),
			location = locationData,
			grade = gradeData,
			description = descriptionData,
			stack = stackData,
			experience = experienceData,
			relevantExperience = relevantExperienceData,
			phone = phoneData,
			email = emailData,
			salary = salaryData,
			rate = rateData,
			birthday = birthdayData,
			timeZone = timeZoneData,
			status = statusData,
			userId = responsibleData
		} = params;

		try {
			return await updateStaffRequest(
				id,
				fullName,
				age,
				location,
				grade,
				description,
				stack,
				experience,
				relevantExperience,
				phone,
				email,
				salary,
				rate,
				birthday,
				timeZone,
				status,
				userId,
				newCancelToken
			);
		} catch (requestError) {
			console.error(requestError.message);
			if (requestError.response.status === 401) localStorage.setItem('tokenError', true);
		}
	};

	useEffect(() => {
		if (
			fullNameData ||
			fullNameData === '' ||
			ageData ||
			ageData === '' ||
			locationData ||
			locationData === '' ||
			gradeData >= 0 ||
			gradeData === '' ||
			descriptionData ||
			descriptionData === '' ||
			stackData ||
			stackData === '' ||
			experienceData ||
			experienceData === '' ||
			relevantExperienceData ||
			relevantExperienceData === '' ||
			phoneData ||
			phoneData === '' ||
			emailData ||
			emailData === '' ||
			salaryData ||
			salaryData === '' ||
			rateData ||
			rateData === '' ||
			birthdayData ||
			birthdayData === '' ||
			timeZoneData ||
			timeZoneData === '' ||
			statusData ||
			statusData === '' ||
			statusData === 0 ||
			responsibleData ||
			responsibleData === ''
		) {
			updateData({}).then(() => setCheck(!check));
		}

		return () => {
			if (cancelToken) {
				cancelToken.cancel();
			}
		};
	}, [
		ageData,
		birthdayData,
		emailData,
		experienceData,
		fullNameData,
		gradeData,
		id,
		locationData,
		phoneData,
		rateData,
		relevantExperienceData,
		salaryData,
		stackData,
		statusData,
		timeZoneData,
		responsibleData
	]);

	// методы для модальных окон
	const handleCheckReqModalClose = () => {
		setCheckReqModalIsOpen(false);
	};

	const handleCheckReqModalOpen = () => {
		setCheckReqModalIsOpen(true);
	};

	const handleAddReqModalClose = () => {
		setAddReqModalIsOpen(false);
		setDefaultReqs([]);
	};

	const handleAddReqModalOpen = reqName => {
		setDefaultReqs(prevReqs => [...prevReqs, reqName]);
		setAddReqModalIsOpen(true);
	};

	// обработчик запроса на проверку требований
	const checkRequirementsRequest = async ({ candidateId, publicCvId, requirements }) => {
		try {
			// Отправляем запрос на проверку требований
			return await postCheckRequirements({
				// на беке candidateId это staffId
				staffId: candidateId,
				publicCvId: publicCvId,
				requirements
			});
		} catch (requestError) {
			console.error(requestError.message);
			if (requestError.response.status === 401) {
				// Устанавливаем флаг ошибки в localStorage
				localStorage.setItem('tokenError', true);
			}
		}
	};

	// обработчик копирования в буфер обмена
	const handleCopy = ({ originValue }) => {
		const candidateSignString = `${oneStaff.stack} ${abbreviationFullName(oneStaff.fullName)} ${CANDIDATE_GRADE_NAMES[oneStaff.grade]} ${publicCVData.relevantExperience} ${CANDIDATE_CARD_PAGE_CONFIG.CHECK_REQ_MODAL.COPY_SIGN_ENDING}`;
		const textForCopy = addMarkerToRequirements(originValue, checkedRequirements);

		const isCopied = copy(candidateSignString + textForCopy);
		if (isCopied) {
			showAlert({
				message: CANDIDATE_CARD_PAGE_CONFIG.CHECK_REQ_MODAL.COPY_ALERT_LABEL,
				type: 'info'
			});
		}
	};

	const generateRequirementsHtml = (fullString, checkedRequirementsData) => {
		const fullStringByLines = fullString.split('\n').map(line => line.split(','));

		return (
			<>
				{fullStringByLines.map((requirements, index) => (
					<div key={index}>
						{requirements.map((req, index) => {
							let isFind = false;
							let isEmptyString = !req.trim().length;

							if (!isEmptyString) {
								const foundRequirement = checkedRequirementsData.find(({ requirement }) => requirement === req.trim());

								if (foundRequirement) {
									isFind = foundRequirement.isFind;
								}
							}

							return (
								<React.Fragment key={index}>
									{!isEmptyString ? (
										<>
											{' '}
											{!isFind && (
												<input
													// id инпута соответствует id требования
													id={id}
													alt={req}
													type='button'
													style={{
														margin: 0,
														padding: 0,
														boxSizing: 'border-box',
														backgroundColor: 'transparent',
														backgroundImage: 'url(https://www.svgrepo.com/show/502464/add.svg)',
														backgroundPosition: 'center',
														backgroundRepeat: 'no-repeat',
														backgroundSize: 'cover',
														border: 'none',
														cursor: 'pointer',
														width: '20px',
														height: '20px',
														transform: 'translateX(-2px)'
													}}
												/>
											)}
											{!!isFind ? ` ${CANDIDATE_CARD_PAGE_CONFIG.CHECK_REQ_MODAL.FIND_INDICATOR} ${req.trim()}` : req}
										</>
									) : (
										<br />
									)}
								</React.Fragment>
							);
						})}
					</div>
				))}
			</>
		);
	};

	// Обработчик проверки требований
	const handleCheckRequirements = async (requirements, fullString) => {
		try {
			const parsedRequirements = requirements.map(item => item.value);

			if (parsedRequirements.length > 0) {
				const checkedRequirementsData = await checkRequirementsRequest({
					candidateId: id,
					publicCvId: oneStaff?.publicCvId,
					requirements: parsedRequirements
				});

				if (checkedRequirementsData) {
					const parsedHtml = generateRequirementsHtml(fullString, checkedRequirementsData);

					const parsedRequirements = parseRequirements(checkedRequirementsData);

					setCheckedRequirements(checkedRequirementsData);

					return {
						valuesString: parsedRequirements.map(({ value }) => value).join('\n'),
						valuesHtml: parsedHtml
					};
				}
			}
		} catch (error) {
			console.error(error, 'Ошибка обработки запроса проверки требований');
		}
	};

	// обработчик добавления требований в дефолтные
	const handleAddRequirements = async (requirements, categoryNames) => {
		try {
			let categoryIds;

			if (categoryNames.length) {
				categoryIds = categoryNames.map(categoryName => itCategories.find(({ name }) => name === categoryName).id);
			} else {
				categoryIds = [UNRECOGNIZED_CATEGORY_ID];
			}

			const parsedRequirements = requirements.map(item => ({
				value: item.value,
				options: item.variants,
				categoriesId: categoryIds
			}));

			await postAddSeveralRequirements({
				newReqs: parsedRequirements
			});
		} catch (error) {
			console.error(error, 'Ошибка обработки запроса добавления требований');
		}
	};

	const copyStaffDataString = useMemo(() => {
		const { city, dateOfBirth, fullName } = publicCVData;
		const cvEmail = oneStaff.email === '-' ? null : oneStaff.email;
		const { FULLNAME, BIRTHDAY, SPLITTER, EMAIL, LOCATION } = CANDIDATE_CARD_PAGE_CONFIG.COPY_STAFF_DATA;

		if (!cvEmail && !fullName && !dateOfBirth && !city) {
			return 'Нет данных';
		}

		let formattedBirthday;

		if (dateOfBirth) {
			formattedBirthday = dateOfBirth.split('-');
			formattedBirthday = `${formattedBirthday[2]}.${formattedBirthday[1]}.${formattedBirthday[0]}`;
		}

		return [
			fullName ? FULLNAME + SPLITTER + fullName : null,
			dateOfBirth ? BIRTHDAY + SPLITTER + formattedBirthday : null,
			city ? LOCATION + SPLITTER + city : null,
			cvEmail ? EMAIL + SPLITTER + cvEmail : null
		]
			.filter(Boolean)
			.join('\n');
	}, [publicCVData, oneStaff.email]);

	return (
		<Box sx={{ display: 'flex' }}>
			<Main open={open}>
				<Box
					sx={{
						mt: '10px',
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'row',
						gap: '15px'
					}}>
					<Box sx={{ ...styledBox.flexBoxRow, gap: '1rem' }}>
						<ReturnButton way={'/candidates'} />
						<CandidatesClipsModal id={id} stackID={oneStaff?.stackID} />
					</Box>
					<Box sx={{ ...styledBox.flexBoxRow, gap: '1rem' }}>
						<CopyIconButton copyString={copyStaffDataString} itemName={'Данные кандидата'} />
						<Button sx={buttonStyles.orangeButton} variant='contained' onClick={handleCheckReqModalOpen}>
							{CANDIDATE_CARD_PAGE_CONFIG.REQ_OPEN_BUTTON_LABEL}
						</Button>
						<CreateSurveyButton stackID={oneStaff?.stackID} staffID={id} />
					</Box>
				</Box>
				<CandidateInfo>
					<Stack spacing={2} sx={{ mt: 2, flexGrow: 1 }}>
						<Paper elevation={6}>
							<CardMedia
								component='img'
								sx={{ borderRadius: '4px' }}
								width='10'
								height='190'
								image='https://cdn.fishki.net/upload/post/201405/28/1273062/mottonetua-41810.jpg'
								alt='Paella dish'
							/>
						</Paper>
						<DateAddedCard oneStaff={oneStaff} setData={setResponsibleData} />
					</Stack>
					<Box
						sx={{
							...styledBox.flexBoxColumn,
							justifyContent: 'space-between',
							flexWrap: 'wrap',
							width: '100%'
						}}>
						<CandidateInfoStacks>
							<StyledStack spacing={2}>
								<TextFieldCard textName={'ФИО'} textstaff={oneStaff?.fullName} setData={setFullNameData} />

								<TextFieldCard
									textName={'Телефон'}
									textstaff={oneStaff?.phone}
									setData={setPhoneData}
									options={{ copied: true, isPhone: true }}
								/>

								<NumberFieldCard textName={'Зарплата'} textstaff={oneStaff?.salary} setData={setSalaryData} />
								<NumberFieldCard textName={'Рейт'} textstaff={oneStaff?.rate} setData={setRateData} />

								<CandidateSelectCard textName={'Стек'} textstaff={oneStaff?.stack} setData={setStackData} />
							</StyledStack>

							<StyledStack spacing={2}>
								<ExperienceFieldCard
									textName={'Релев. опыт'}
									textstaff={oneStaff?.relevantWorkExperience}
									setExperienceData={setRelevantExperienceData}
								/>
								<ExperienceFieldCard
									textName={'Опыт'}
									textstaff={oneStaff?.experience}
									setExperienceData={setExperienceData}
								/>
								<CommunicationStatusField
									textName={'Статус общения'}
									textstaff={oneStaff?.communicationStatus}
									setData={setStatusData}
									rateData={oneStaff.rate}
								/>
								<TextFieldCard textName={'Локация'} textstaff={oneStaff?.location} setData={setLocationData} />
								<SelectorTimeZone textName={'Часовой пояс'} textstaff={oneStaff?.timezone} setData={setTimeZoneData} />
							</StyledStack>

							<StyledStack spacing={2}>
								<CandidateGradeField textName={'Грейд'} textstaff={oneStaff?.grade} setData={setGradeData} />
								<BirthdayFieldCard
									textName={'Дата рождения'}
									textstaff={oneStaff?.birthday}
									setData={setBirthdayData}
								/>

								<TextFieldCard
									textName={'Возраст'}
									textstaff={oneStaff?.age}
									setData={setAgeData}
									ageCard={true}
									birthDay={oneStaff?.birthday}
								/>

								<TextFieldCard textName={'Email'} textstaff={oneStaff?.email} setData={setEmailData} />
								<CandidateContactsMenu staffId={id} contacts={oneStaff?.contacts} check={check} setCheck={setCheck} />
							</StyledStack>
						</CandidateInfoStacks>

						<Box sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
							<TextComment comment={oneStaff?.description} setData={setDescriptionData} onChange={updateData} />
						</Box>
					</Box>
				</CandidateInfo>

				{oneStaff.cvid && (
					<Box>
						<Divider sx={{ height: 10 }} />
						<TabResume
							staffid={id}
							cvId={oneStaff?.cvid}
							publicCvId={oneStaff?.publicCvId}
							checkPublicCV={checkPublicCV}
							setCheckPublicCV={setCheckPublicCV}
							cvEmailData={oneStaff.email}
							setAlertIsOpen={setAlertIsOpen}
							alertIsOpen={alertIsOpen}
							publicCVData={publicCVData}
							setPublicCVData={setPublicCVData}
						/>
					</Box>
				)}
			</Main>
			<CandidateCardComments stateID={id} setOpen={setOpen} open={open} />
			<MessageAlert {...errorAlertData} />
			{checkReqModalIsOpen && (
				<SplitInputModal
					onClose={handleCheckReqModalClose}
					isOpen={checkReqModalIsOpen}
					config={CANDIDATE_CARD_PAGE_CONFIG.CHECK_REQ_MODAL}
					options={{
						returnValueList: true,
						altProcessHandler: (value, originValue) => handleCopy({ originValue }),
						innerHtmlProps: {
							handleAddReqModalOpen: reqName => handleAddReqModalOpen(reqName)
						}
					}}
					onProcess={handleCheckRequirements}
					size='fsy'
					type='inner-html'
				/>
			)}
			{addReqModalIsOpen && (
				<TermWithVariantsModal
					isOpen={addReqModalIsOpen}
					onClose={handleAddReqModalClose}
					config={CANDIDATE_CARD_PAGE_CONFIG.ADD_REQ_MODAL}
					onProcess={handleAddRequirements}
					size='lg'
					options={{
						categories: itCategories.reduce(
							(acc, item) => {
								acc['Категории'].push(item.name);
								return acc;
							},
							{
								Категории: []
							}
						),
						defaultValues: defaultReqs,
						defaultCategories: [
							itCategories.find(category =>
								category.subCategories.find(subCategory => subCategory.id === oneStaff?.stackID)
							).name
						]
					}}
				/>
			)}
		</Box>
	);
};
