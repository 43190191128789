import React from 'react';
import { CustomModal } from '../custom-modal';
import { TableCustom } from '../../tables/table-custom';
import { LAYOUT_TYPES } from './config';

/**
 * Компонент модального окна со встроенной таблицей
 * @param {Object} config - Объект конфигурации для модального окна.
 * @param {Object} modalCustomStyles - Пользовательские стили для модального окна.
 * @param {JSX.Element} content - Содержимое, которое должно быть отображено внутри модального окна.
 * @return {ReactNode} Компонент модального окна с указанным содержимым и конфигурацией.
 */

export const ModalWithTables = ({ config = {}, modalCustomStyles = {}, content = <></> }) => {
	const { isOpen, dataIsLoaded, onClose, styles, size } = modalCustomStyles;

	const layoutStyles = {
		[LAYOUT_TYPES.TABLE_FULL]: { flexDirection: 'column', tableFirst: true },
		[LAYOUT_TYPES.CONTENT_LEFT_TABLE_RIGHT]: {
			flexDirection: 'row',
			tableFirst: false
		},
		[LAYOUT_TYPES.TABLE_LEFT_CONTENT_RIGHT]: {
			flexDirection: 'row',
			tableFirst: true
		},
		[LAYOUT_TYPES.CONTENT_TOP_TABLE_BOTTOM]: {
			flexDirection: 'column',
			tableFirst: false
		},
		[LAYOUT_TYPES.TABLE_TOP_CONTENT_BOTTOM]: {
			flexDirection: 'column',
			tableFirst: true
		}
	};

	const renderSection = (section, index) => {
		const { layoutType, table } = section;
		const layout = layoutStyles[layoutType] || layoutStyles[LAYOUT_TYPES.TABLE_FULL];

		const TableComponent = (
			<TableCustom
				columns={table.columns}
				data={table.data}
				onRowClick={table.onRowClick}
				CustomTableRow={table.CustomTableRow}
				options={table.options}
				dataIsLoaded={table.dataIsLoaded}
				itemsCount={table.data.length}
			/>
		);

		return (
			<div
				key={index}
				style={{ display: 'flex', flexDirection: layout.flexDirection, ...table.options.tableContainerStyles }}>
				{layout.tableFirst ? TableComponent : <div style={{ flex: 1 }}>{section.children}</div>}
				{layout.tableFirst ? <div style={{ flex: 1 }}>{section.children}</div> : TableComponent}
			</div>
		);
	};

	return (
		<CustomModal dataIsLoaded={dataIsLoaded} isOpen={isOpen} onClose={onClose} styles={styles} size={size}>
			{content}
			{config.sections.map((section, index) => renderSection(section, index))}
		</CustomModal>
	);
};
