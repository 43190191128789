import React, { useState } from 'react';
import { addCommentRequest } from '../../../../../../api/api';

import {
	Box,
	Modal,
	Typography,
	ListItemButton,
	Paper,
	InputBase,
	Button,
	Tooltip
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import { ModalContainer } from './candidates-comment-modal-styles';

export const CandidatesCommentModal = ({
	stateID,
	checkRender,
	setCheckRender,
	openDrawer
}) => {
	const [open, setOpen] = useState(false);
	const [term, setTerm] = useState('');

	const handleOpen = () => setOpen(!open);

	const addComment = () => {
		addCommentRequest(stateID, term)
			.then(data => {
				setTerm(data);
				setCheckRender(!checkRender);
			})
			.catch(requestError => console.error(requestError.message));
	};

	const handleChange = event => {
		const temp = event.target.value;
		if (temp === '') {
			setTerm(undefined);
		} else {
			setTerm(temp.replace(/^[\s.`":'$@~;]*$/g, ''));
		}
	};

	return (
		<>
			{openDrawer ? (
				<Button
					variant='contained'
					color='primary'
					size='large'
					startIcon={<DataSaverOnIcon sx={{ fontSize: 35 }} />}
					sx={{
						bgcolor: '#ef6c00',
						borderRadius: '3%',
						mt: '10px',
						color: '#FFFFFF',
						fontWeight: 'bold',
						mr: 1,
						'&:hover': {
							bgcolor: '#ff9800',
							color: '#FFFFFF',
							transform: 'scale(1.03)'
						}
					}}
					onClick={handleOpen}
				>
					Добавить комментарий
				</Button>
			) : (
				<Tooltip title='Добавить комментарий' placement='left'>
					<Button
						variant='contained'
						color='primary'
						size='large'
						sx={{
							bgcolor: '#ef6c00',
							borderRadius: '3%',
							mt: '10px',
							color: '#FFFFFF',
							fontWeight: 'bold',
							mr: 1,
							'&:hover': {
								bgcolor: '#ff9800',
								color: '#FFFFFF',
								transform: 'scale(1.03)'
							}
						}}
						onClick={handleOpen}
					>
						<DataSaverOnIcon sx={{ fontSize: 25 }} />
					</Button>
				</Tooltip>
			)}

			<Modal
				keepMounted
				open={open}
				onClose={handleOpen}
				aria-labelledby='keep-mounted-modal-title'
				aria-describedby='keep-mounted-modal-description'
			>
				<ModalContainer>
					<Box sx={{ display: 'flex' }}>
						<Box sx={{ flexGrow: 1 }}>
							<Typography
								id='keep-mounted-modal-title'
								variant='h6'
								component='h2'
								sx={{
									fontSize: '30px'
								}}
							>
								Добавить комментарий
							</Typography>
						</Box>
						<Box>
							<ListItemButton onClick={handleOpen}>
								<CloseIcon color={'warning'} sx={{ fontSize: 35 }} />
							</ListItemButton>
						</Box>
					</Box>
					<Paper
						component='form'
						elevation={6}
						sx={{
							minHeight: '200px',
							m: '30px 0px 0px 0px'
						}}
					>
						<InputBase
							value={term}
							rows={8}
							multiline
							onChange={handleChange}
							sx={{ minWidth: '98%', p: '2px 4px', ml: 1, flex: 1 }}
						/>
					</Paper>
					<Button
						variant='contained'
						color='primary'
						size='large'
						startIcon={<DataSaverOnIcon sx={{ fontSize: 35 }} />}
						sx={{
							bgcolor: '#ef6c00',
							borderRadius: '3%',
							float: 'right',
							mt: '20px',
							color: '#FFFFFF',
							fontWeight: 'bold',
							mr: 1,
							'&:hover': {
								bgcolor: '#ff9800',
								color: '#FFFFFF',
								transform: 'scale(1.03)'
							}
						}}
						onClick={() => {
							handleOpen();
							addComment();
						}}
					>
						Добавить комментарий
					</Button>
				</ModalContainer>
			</Modal>
		</>
	);
};
