export const parseAddDateTime = addDateTime => {
	let [date, time] = addDateTime.split('T');
	let [year, month, day] = date.split('-');
	time = time.slice(0, 5);
	return `${time} ${day}.${month}.${year.substring(2)}`;
};

export const dateComparator = (a, b, order) => {
	let aDate = a === 'Invalid date' ? new Date(0) : new Date(a);
	let bDate = b === 'Invalid date' ? new Date(0) : new Date(b);

	if (isNaN(aDate.getTime())) {
		aDate = new Date(Infinity);
	}
	if (isNaN(bDate.getTime())) {
		bDate = new Date(Infinity);
	}

	if (aDate.getTime() === bDate.getTime()) {
		return 0;
	} else if (aDate > bDate) {
		return order === 'asc' ? 1 : -1;
	} else {
		return order === 'asc' ? -1 : 1;
	}
};

export const textComparator = (a, b, sortDirection = 'asc') => {
	const textA = a ? a.toString().toLowerCase() : '';
	const textB = b ? b.toString().toLowerCase() : '';

	if (textA === '' && textB === '') {
		return 0;
	}

	if (textA === '') {
		return 1;
	}

	if (textB === '') {
		return -1;
	}

	if (sortDirection === 'asc') {
		return textA.localeCompare(textB);
	} else if (sortDirection === 'desc') {
		return textB.localeCompare(textA);
	}

	return 0;
};

export const numberComparator = (a, b, sortDirection = 'asc') => {
	const numA = Number(a);
	const numB = Number(b);

	if (isNaN(numA) || isNaN(numB)) {
		return 0;
	}

	if (sortDirection === 'asc') {
		return numA - numB;
	} else if (sortDirection === 'desc') {
		return numB - numA;
	}

	return 0;
};

export const statusComparator = (a, b, order) => {
	if (a === b) {
		return 0;
	}
	let invert;
	order === 'asc' ? (invert = 1) : (invert = -1);
	if (a === 'Важно') {
		return -1 * invert;
	} else if (a === 'Обычный') {
		if (b === 'Важно') {
			return 1 * invert;
		} else {
			return -1 * invert;
		}
	} else if (a === 'Мусор') {
		if (b === 'Важно' || b === 'Обычный') {
			return 1 * invert;
		} else {
			return -1 * invert;
		}
	} else if (a === 'Не активно- Важно') {
		if (b === 'Не активно- Обычный' || b === 'Не активно- Мусор') {
			return -1 * invert;
		} else {
			return 1 * invert;
		}
	} else if (a === 'Не активно- Обычный') {
		if (b === 'Не активно- Мусор') {
			return -1 * invert;
		} else {
			return 1 * invert;
		}
	} else if (a === 'Не активно- Мусор') {
		return 1 * invert;
	}
};
