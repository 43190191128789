import { RootModal } from '../modal';
import { Box, Button, Typography } from '@mui/material';
import { buttonStyles } from '../../../../../styles/default-styles/button-styles/button-styles';
import { boxStyles } from '../../../../../styles/default-styles/box-styles/box-styles';
import { capitalizeFirstLetter } from '../../../../../utils/string-process';
import { DELETE_MODAL_CONFIG } from './config';

/**
 * Компонент модального окна для удаления.
 * @param {Object} - Входные параметры.
 * @param {boolean} isOpen - Флаг открытости модального окна.
 * @param {function} onClose - Функция закрытия модального окна.
 * @param {Object} styles - Стили для компонента.
 * @param {function} handleDelete - Функция для обработки удаления.
 * @param {Object} labels - Метки для компонента.
 */
export const DeleteModal = ({
	isOpen = false,
	onClose = () => {},
	styles = {},
	handleDelete = () => {},
	labels = {}
}) => {
	// Объединение входных стилей с дефолтными стилями
	const currentStyles = {
		width: 'fit-content',
		height: 300,
		maxWidth: 700,
		...styles
	};
	const {
		actionButtonStyles = {},
		cancelButtonStyles = {}
	} = styles.buttonStyles || {};

	// Извлечение значений по умолчанию из объекта меток, если они отсутствуют
	const {
		itemName = DELETE_MODAL_CONFIG.DEF_ITEM_NAME,
		actionName = DELETE_MODAL_CONFIG.DEF_ACTION_NAME,
		subtitle = DELETE_MODAL_CONFIG.DEF_SUBTITLE,
		title = '',
	} = labels;

	return (
		<RootModal isOpen={isOpen} onClose={onClose} styles={currentStyles}>
			<Box
				sx={{
					...boxStyles.flexBoxColumn,
					height: '100%',
					justifyContent: 'space-between',
					...styles.containerStyles
				}}
			>
				{/*Пустой div нужен для центрирования текста в модальном окне если нет подзаголовка*/}
				{!subtitle.length && <div />}
				<Box sx={{ ...boxStyles.flexBoxColumn, gap: 3, alignItems: 'center', ...styles.textStyles }}>
					<Typography variant='h4'>
						{!title ? DELETE_MODAL_CONFIG.TITLE_LABEL({ actionName, itemName }) : title}
					</Typography>
					{/* Если подзаголовок не пустой, отображаем его */}
					{!!subtitle.length && <Typography variant='h5'>{subtitle}</Typography>}
				</Box>

				<Box
					sx={{
						...boxStyles.flexBoxRow,
						gap: 1,
						justifyContent: 'flex-end',
						alignSelf: 'flex-end',
						...styles.buttonStyles
					}}
				>
					<Button
						sx={{ ...buttonStyles.orangeButton, fontSize: '1.3rem', ...actionButtonStyles }}
						size='large'
						onClick={handleDelete}
					>
						{capitalizeFirstLetter(actionName)} {itemName}
					</Button>
					<Button
						sx={{ ...buttonStyles.orangeButton, fontSize: '1.3rem', ...cancelButtonStyles }}
						size='large'
						onClick={onClose}
					>
						{DELETE_MODAL_CONFIG.CANCEL_BUTTON_LABEL}
					</Button>
				</Box>
			</Box>
		</RootModal>
	);
};
