import React, { useState, useEffect } from "react";
import { Box, Divider, Typography, Paper } from "@mui/material";

import { ExperienceMenu } from "./experience-menu";

export const ExperienceFieldCard = ({
  textName,
  textstaff,
  setExperienceData,
}) => {
  const [term, setTerm] = useState("");

  useEffect(() => {
    if (textstaff !== null && textstaff !== undefined) {
      if (textstaff === -1) {
        setTerm("-");
      } else setTerm(textstaff);
    }
  }, [textstaff]);

  return (
    <Paper
      elevation={6}
      sx={{
        display: "flex",
        alignItems: "center",
        minWidth: "200px",
        minHeight: "32px",
      }}
    >
      <Typography
        id="keep-mounted-modal-title"
        variant="h6"
        component="h2"
        sx={{
          display: "flex",
          height: "100%",
          fontSize: "15px",
          bgcolor: "#fb8c00",
          color: "#852508",
          border: "1px solid",
          minWidth: "110px",
          textAlign: "center",
          borderRadius: "4px",
          borderColor: "#a2a2a2",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {textName}
      </Typography>
      <Divider sx={{ height: 24, ml: 0.5 }} orientation="vertical" />
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          sx={{ fontSize: "15px", ml: 1.3 }}
          id="keep-mounted-modal-title"
        >
          {term}
        </Typography>
      </Box>
      <ExperienceMenu setExperienceData={setExperienceData} />
    </Paper>
  );
};
