import React, { useState, useEffect, useRef } from 'react';

import { Box, Menu, MenuItem, TextField, IconButton, Checkbox, FormControlLabel, Typography } from '@mui/material';
import isEqual from 'lodash/isEqual';


export const FilterMenuRange = ({
	setActiveFilters,
	filterList,
	filterGroup,
  savedFilterValues,
	setSavedFilterValues
}) => {

	const [num1, setNum1] = useState(savedFilterValues.filter1 || "");
	const [num2, setNum2] = useState(savedFilterValues.filter2 || "") ;

	// Фильтруем данные и обновляем их в активных фильтрах
	const applyFilters = () => {
		const filteredValues = filterList.filter(value => {
			const num1Valid = num1 === "" || parseInt(value) >= parseInt(num1);
			const num2Valid = num2 === "" || parseInt(value) <= parseInt(num2);
			return num1Valid && num2Valid;
		});
		setActiveFilters(prevFilters => ({
			...prevFilters,
			[filterGroup]: filteredValues
		}));
	};

	useEffect(() => {
		// Если значений нет, удаляем их из активных фильтров
		if (num1 === "" && num2 === "") {
			setSavedFilterValues({});
			setActiveFilters(prevFilters => {
				const updatedFilters = { ...prevFilters };
				delete updatedFilters[filterGroup];
				return updatedFilters;
			});
		}
		// Обновление сохраненных значений фильтра при изменении num1 или num2
		const newFilterValues = { ...savedFilterValues, filter1: num1, filter2: num2 };
		setSavedFilterValues(newFilterValues);
		// Если значение введено, применяем фильтр
		if (num1 !== "" || num2 !== "") {
			const timeout = setTimeout(() => applyFilters(), 500);
			return () => clearTimeout(timeout);
		}
	}, [num1, num2]);

	const handleChange = (event, setter) => {
		const newValue = event.target.value.replace(/[^\d]/g, '');
		setter(newValue);
	};

	useEffect(() => {
		if (savedFilterValues) {
			setNum1(savedFilterValues.filter1 || "");
			setNum2(savedFilterValues.filter2 || "");
		}
	}, [savedFilterValues]);

	const deleteAllFilters = () => {
		setNum1("");
		setNum2("");
		setSavedFilterValues({})
		setActiveFilters(prevFilters => {
			const updatedFilters = { ...prevFilters };
			delete updatedFilters[filterGroup];
			return updatedFilters;
		});
	};

	return (
		<Box>
			<MenuItem onClick={deleteAllFilters}>Сбросить фильтры</MenuItem>
			<Box sx={{ pr: 2, pl: 2, width: '150px' }}>
				<TextField
					id="standard-basic"
					label="От"
					variant="standard"
					color="warning"
					value={num1 ? num1 : ""}
					onChange={e => handleChange(e, setNum1)}
				/>
			</Box>
			<Box sx={{ pr: 2, pl: 2, width: '150px' }}>
				<TextField
					id="standard-basic"
					label="До"
					variant="standard"
					color="warning"
					value={num2 ? num2 : ""}
					onChange={e => handleChange(e, setNum2)}
				/>
			</Box>
		</Box>
	);
};
