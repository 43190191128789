import { ERRORS_CONFIG } from './config';
const logServerError = (statusCode, error = '') =>
	console.error(` ${statusCode} - ${ERRORS_CONFIG.SERVER[statusCode]}`, error);

export const requestErrorWrapper = async ({ handleRequest }) => {
	try {
		const response = await handleRequest();

		if (response) return response;
	} catch (error) {
		logServerError('DEFAULT', error);

		const statusCode = error.response?.status;
		if (statusCode) {
			const allowErrorStatusCodes = Object.keys(ERRORS_CONFIG.SERVER);

			logServerError(allowErrorStatusCodes.includes(statusCode.toString()) ? statusCode : 'UNKNOWN');

			// обработка ошибок, дополнять по необходимости
			switch (statusCode) {
				case 401:
					localStorage.setItem('tokenError', 'true');
					break;
				default:
			}
		}
	}
};
