import React, { useState } from 'react';

import { Box, Typography, Button, Divider } from '@mui/material';

import { CvRatingModal } from '../cv-rating-modal';
import { DelayedAlert } from '../../alerts/delayed-alert';
import { GridContainer, GridItem } from './cv-details-basic-info-styles';

export const CvDetailsBasicInfo = ({ cvData }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	const makeAlert = () => {
		if (showAlert) {
			return (
				<DelayedAlert
					text={
						'Обычно вы могли бы перейти на сайт Headhunter и увидеть это резюме. Но сейчас используется HH-аккаунт компании TechCrew, а ссылки на резюме, найденное другой компанией, запрещено передавать правилами. Позже доступ у вас появится.'
					}
					severity={'warning'}
					delay={4000}
					setShowAlert={setShowAlert}
				/>
			);
		}
	};
	return (
		<>
			<Box>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						padding: '10px 0px'
					}}
				>
					<Box>{makeAlert()}</Box>
					<Box
						sx={{
							width: '50%'
						}}
					>
						<Typography
							variant='h6'
							component='h2'
							sx={{
								fontSize: '20px'
							}}
						>
							{cvData.stack ? cvData.stack : '-'}
						</Typography>
					</Box>
					<Box
						sx={{
							width: '50%',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-around'
						}}
					>
						<Button
							variant='contained'
							disabled={cvData?.rating ? false : true}
							color='inherit'
							onClick={() => {
								setIsOpen(true);
							}}
						>
							Рейтинг {cvData?.rating?.rateAll.value}
						</Button>
						{process.env.REACT_APP_HEAD_HANTER_OPEN === 'true' ? (
							<Button
								variant='contained'
								color='inherit'
								href={cvData.url}
								target='_blank'
							>
								Открыть на HH.Ru
							</Button>
						) : (
							<Button
								variant='contained'
								color='inherit'
								onClick={() => {
									setShowAlert(true);
								}}
							>
								Открыть на HH.Ru
							</Button>
						)}
					</Box>
				</Box>
				<Divider />

				<GridContainer spacing={2}>
					<GridItem>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>
								Зарплата:
							</Typography>
							<Typography sx={{ fontSize: '15px' }}>
								{cvData.price?.toLocaleString('ru')}
							</Typography>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>
								Возраст:
							</Typography>
							<Typography sx={{ fontSize: '15px' }}>
								{cvData?.age ? cvData.age : '-'}
							</Typography>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>
								Релев. опыт:
							</Typography>
							<Typography sx={{ fontSize: '15px' }}>
								{cvData?.relevantExperience}
							</Typography>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>
								Опыт:
							</Typography>
							<Typography sx={{ fontSize: '15px' }}>
								{cvData?.allExperience ? cvData.allExperience : '-'}
							</Typography>
						</Box>
					</GridItem>

					<GridItem>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>
								Локация:
							</Typography>
							<Typography sx={{ fontSize: '15px' }}>
								{cvData?.city ? cvData.city : '-'}
							</Typography>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>
								ФИО:
							</Typography>
							<Typography sx={{ fontSize: '15px' }}>
								{cvData?.fullName ? cvData.fullName : '-'}
							</Typography>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>
								Статус поиска:
							</Typography>
							<Typography sx={{ fontSize: '15px' }}>
								{cvData?.searchStatus ? cvData.searchStatus : '-'}
							</Typography>
						</Box>
					</GridItem>

					<GridItem>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>
								Локация поиска:
							</Typography>
							<Typography sx={{ fontSize: '15px' }}>
								{cvData?.country ? cvData.country : '-'}
							</Typography>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>
								Занятость:
							</Typography>
							<Typography sx={{ fontSize: '15px' }}>
								{cvData?.employment ? cvData.employment : '-'}
							</Typography>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Typography
								sx={{
									marginRight: '5px',
									minWidth: '130px',
									fontWeight: 'bold'
								}}
							>
								График работы:
							</Typography>
							<Typography sx={{ fontSize: '15px' }}>
								{cvData?.schedule ? cvData.schedule : '-'}
							</Typography>
						</Box>
					</GridItem>

					<GridItem>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>
								Часовой пояс:
							</Typography>
							<Typography sx={{ fontSize: '15px' }}>
								{cvData.timeZone > 0 ? '+' + cvData.timeZone : cvData.timeZone}
							</Typography>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>
								Пол:
							</Typography>
							<Typography sx={{ fontSize: '15px' }}>
								{cvData.gender
									? cvData.gender === 'Неопределился'
										? '-'
										: cvData.gender
									: '-'}
							</Typography>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>
								Дата рождения:
							</Typography>
							<Typography sx={{ fontSize: '15px' }}>
								{cvData?.dateOfBirth
									? `${cvData.dateOfBirth.split('-')[2]}.${
											cvData.dateOfBirth.split('-')[1]
										}.${cvData.dateOfBirth.split('-')[0]}`
									: '-'}
							</Typography>
						</Box>
					</GridItem>
				</GridContainer>
			</Box>
			{cvData?.rating ? (
				<CvRatingModal
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					ratings={cvData.rating}
				/>
			) : (
				<></>
			)}
		</>
	);
};
