import React from "react";
import { Box, Button, Typography } from "@mui/material";

export const DownloadPublicCVButton = ({ cvId, processedFileName }) => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="subtitle1">
        Конвертированный файл: {processedFileName}
      </Typography>
      <Button
        href={`${process.env.REACT_APP_API_HOST}cv/api/CV/${cvId}/DocX/Public`}
        variant="contained"
        sx={{ mt: 2 }}
      >
        Скачать
      </Button>
    </Box>
  );
};
