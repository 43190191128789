import React, { useContext, useMemo } from 'react';

import { Paper } from '@mui/material';

import { ResponsibleSelected } from '../responsible-selected';

import { formatDate } from '../../../../../utils/format-date';
import { NamedField } from './named-field';
import { StackContainer } from './date-added-card-styles';
import { useSortedResponsible } from '../../../../../hooks/get-sorted-responsibles/useSortedResponsible';
import { AllUsersContext } from '../../../../contexts/AllUsersContext/AllUsersContext';

export const DateAddedCard = ({ oneStaff, setData }) => {
	const { allUsers = [] } = useContext(AllUsersContext);

	const filteredUsers = useMemo(() => allUsers.filter(({ id, name }) => !name.match(/[стр]?\.$/)), [allUsers]);

	const sortedResponsible = useSortedResponsible(filteredUsers);

	return (
		<Paper elevation={6} sx={{ padding: 1 }}>
			<StackContainer spacing={1}>
				<NamedField name='Дата добавления:' value={formatDate(oneStaff?.createDateTime)} />
				<NamedField name='Обновлено:' value={formatDate(oneStaff?.lastDownloadResume)} />
				<NamedField
					name='Ответственный:'
					element={ResponsibleSelected}
					text={oneStaff.user}
					responsible={sortedResponsible}
					setData={setData}
				/>
			</StackContainer>
		</Paper>
	);
};
