import React from 'react';

import {
	Box,
	Modal,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	ListItemButton,
	TableRow,
	TableCell,
	Typography,
	LinearProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { RenderCustomAccordions } from '../../../../pages/tool-page/public-convert-resume-tool/cv-public-rating/render-custom-accordions/render-custom-accordions';
import { useState } from 'react';
import { CvRatingModalTableRow } from './cv-rating-modal-table-row';

export const CvRatingModal = ({ isOpen, setIsOpen, ratings }) => {
	const handleOpen = () => setIsOpen(!isOpen);
	const [expandedRows, setExpandedRows] = useState([]);

	const handleOpenElement = objKey => {
		setExpandedRows(prevExpandedRows => [...prevExpandedRows, objKey]);
	};

	const handleCloseElement = objKey => {
		setExpandedRows(prevExpandedRows =>
			prevExpandedRows.filter(key => key !== objKey)
		);
	};

	return (
		<Modal
			keepMounted
			id='message-modal'
			open={isOpen}
			onClose={handleOpen}
			onDoubleClick={event => event.stopPropagation()}
			aria-labelledby='keep-mounted-modal-title'
			aria-describedby='keep-mounted-modal-description'
		>
			<Box
				sx={{
					position: 'fixed',
					width: '100%',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<Box
					sx={{
						width: '75%',
						height: '90%',
						display: 'flex',
						flexDirection: 'column',
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 3
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between'
						}}
					>
						<Box>
							<Typography
								sx={{
									fontSize: '24px',
									fontWeight: '500'
								}}
							>
								{ratings.comment}
							</Typography>
						</Box>
						<ListItemButton
							sx={{
								maxWidth: 'fit-content'
							}}
							onClick={handleOpen}
						>
							<CloseIcon sx={{ fontSize: 35 }} />
						</ListItemButton>
					</Box>
					<TableContainer>
						<Table size='small'>
							<TableHead>
								<TableRow root={'backgroundColor: #BEBEBE'}>
									<TableCell align='center'>Расшифровка критерия</TableCell>
									<TableCell align='center'>Комментарий</TableCell>
									<TableCell align='center'></TableCell>
									<TableCell align='center'>Значение критерия</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Object.keys(ratings).map(objKey => {
									if (ratings[objKey].hasOwnProperty('comment')) {
										if (objKey !== 'rateAll') {
											return (
												<React.Fragment key={objKey}>
													<CvRatingModalTableRow
														objKey={objKey}
														rating={ratings[objKey]}
														handleOpenElement={handleOpenElement}
														handleCloseElement={handleCloseElement}
														expandedRows={expandedRows}
													/>
													{expandedRows.find(key => key === objKey) && (
														<RenderCustomAccordions
															ratings={ratings}
															objKey={objKey}
															showRecommendation={false}
														/>
													)}
												</React.Fragment>
											);
										}
									}
								})}
								<TableRow>
									<TableCell align='center'>
										<Typography
											sx={{
												fontWeight: 'bold',
												fontSize: '18px'
											}}
										>
											{ratings['rateAll'].name}
										</Typography>
									</TableCell>
									<TableCell align='center'>
										<Typography
											sx={{
												fontWeight: 'bold',
												fontSize: '18px'
											}}
										>
											{ratings['rateAll'].comment}
										</Typography>
									</TableCell>
									<TableCell align='center' />
									<TableCell align='center'>
										<Typography
											sx={{
												fontWeight: 'bold',
												fontSize: '18px'
											}}
										>
											{ratings['rateAll'].value}
										</Typography>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
		</Modal>
	);
};
