import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import isEqual from 'lodash/isEqual';
import { limitedString } from '../../../../../utils/string-process';
import { checkBoxStyles } from '../../../../../styles/default-styles/check-box-styles/check-box-styles';

export const FilterMenuList = ({
	activeFilters,
	setActiveFilters,
	filterList,
	filterGroup,
	processCheckListFilter,
	options
}) => {
	const [updatedFilterList, setUpdatedFilterList] = useState(filterList);
	const { unsortable = false } = options;

	useEffect(() => {
		// Генерация нового списка фильтров из списка источников
		const generateFilterValues = () => {
			// Создаем специальное значение, которое объединяет "" и null
			const specialNullValue = '';
			const valuesSet = new Set(
				updatedFilterList.map(filter =>
					filter === null ? specialNullValue : filter === '' ? specialNullValue : filter
				)
			);
			return Array.from(valuesSet);
		};

		const newFilterList = generateFilterValues();
		setUpdatedFilterList(newFilterList);
	}, [filterList]);

	const activateAllFilters = () => {
		// Проверяем, содержит ли обновленный список фильтров специальное значение для пустой строки
		const containsSpecialNullValue = updatedFilterList.includes('');
		// Формируем список фильтров для активации, добавляя null, если среди фильтров есть специальное значение
		const filtersToActivate = containsSpecialNullValue ? [...updatedFilterList, null] : [...updatedFilterList];

		// Проверяем, активированы ли уже все фильтры
		if (!isEqual(activeFilters.sort(), filtersToActivate.sort())) {
			// Если нет, активируем все фильтры
			setActiveFilters(prevFilters => ({
				...prevFilters,
				[filterGroup]: filtersToActivate
			}));
		} else {
			// Если все фильтры уже активированы, удаляем их
			setActiveFilters(prevFilters => {
				const updatedFilters = { ...prevFilters };
				delete updatedFilters[filterGroup];
				return updatedFilters;
			});
		}
	};

	return (
		<FormGroup p={0}>
			<FormControlLabel
				control={
					<Checkbox
						sx={checkBoxStyles.orangeCheckBox}
						color='warning'
						onChange={activateAllFilters}
						checked={isEqual(!unsortable ? filterList?.sort() : filterList, activeFilters?.sort())}
					/>
				}
				label='Все фильтры'
				sx={{ paddingBottom: '5px', width: 'fit-content' }}
			/>
			{updatedFilterList.map((currentFilter, index) => (
				<FormControlLabel
					key={index}
					control={
						<Checkbox
							sx={checkBoxStyles.orangeCheckBox}
							color='warning'
							checked={activeFilters?.includes(currentFilter)}
							onChange={event => processCheckListFilter(filterGroup, currentFilter, event, setActiveFilters)}
						/>
					}
					label={currentFilter ? limitedString(currentFilter, 200, currentFilter.length > 200 && true) : ''}
					sx={{ paddingBottom: '5px', width: 'fit-content' }}
				/>
			))}
		</FormGroup>
	);
};
