import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';

import {
	getCommentRequest,
	DeleteCommentRequest
} from '../../../../../api/api';

import {
	Box,
	Divider,
	Typography,
	IconButton,
	Stack,
	Avatar
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { CandidatesCommentModal } from './candidates-comment-modal';

import { formatDate } from '../../../../../utils/format-date';
import { dateComparator } from '../../../../../utils/sort-comparators';

const drawerWidth = 400;

const openedMixin = theme => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen
	}),
	overflowX: 'hidden'
});

const closedMixin = theme => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(9.5)} + 1px)`
	}
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme)
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme)
	}),
	'@media screen and (max-width: 1200px)': {
		position: 'absolute'
	}
}));

export const CandidateCardComments = ({ stateID, setOpen, open }) => {
	const [comments, setComments] = useState(null);
	const [checkRender, setCheckRender] = useState('');

	useEffect(() => {
		getCommentRequest(stateID)
			.then(data => {
				if (Array.isArray(data)) {
					data.sort((a, b) => dateComparator(a.createdAt, b.createdAt, 'desc'));
					setComments(data);
				} else {
					console.error('Data is not an array:', data);
				}
			})
			.catch(requestError => console.error(requestError.message));
	}, [stateID, checkRender]);

	const DeleteComment = id => {
		DeleteCommentRequest(id)
			.then(() => {
				setCheckRender(!checkRender);
			})
			.catch(requestError => {
				console.error(requestError.message);
				if (requestError.response.status === 401)
					localStorage.setItem('tokenError', true);
			});
	};

	if (comments === null) {
		return <div>Loading...</div>;
	}

	const handleDrawerOpen = () => {
		setOpen(!open);
	};

	return (
		<Drawer variant='permanent' anchor='right' open={open}>
			<Box sx={{ display: 'flex', justifyContent: !open ? 'center' : '' }}>
				<IconButton
					onClick={handleDrawerOpen}
					sx={{
						mt: '70px'
					}}
				>
					{!open ? (
						<ChevronLeftIcon sx={{ fontSize: 35 }} color={'warning'} />
					) : (
						<ChevronRightIcon sx={{ fontSize: 35 }} color={'warning'} />
					)}
				</IconButton>
			</Box>

			<Typography
				id='keep-mounted-modal-title'
				variant='h6'
				component='h2'
				sx={{
					display: open ? 'block' : 'none',
					fontSize: '20px',
					justifyContent: 'center',
					alignItems: 'center',
					p: '10px 20px',
					pt: 0
				}}
			>
				Комментарии
			</Typography>

			<Box sx={{ display: 'flex', ml: open ? 9 : 1, mb: 1 }}>
				<CandidatesCommentModal
					stateID={stateID}
					checkRender={checkRender}
					setCheckRender={setCheckRender}
					openDrawer={open}
				/>
			</Box>

			<Divider />
			<Box sx={{ display: open ? 'block' : 'none' }}>
				<Stack sx={{ mt: 1 }}>
					{comments.map(comment => (
						<Box key={comment.id} sx={{ pr: 1 }}>
							<Box sx={{ display: 'flex', p: '10px 20px', pb: 0 }}>
								<Avatar
									alt='Remy Sharp'
									sx={{ fontSize: 25, mr: 1 }}
									src='https://proprikol.ru/wp-content/uploads/2019/08/kartinki-nyashnye-kotiki-16.jpg'
								/>
								<Typography
									id='keep-mounted-modal-title'
									sx={{
										fontSize: '16px',
										color: '#f57c00',
										fontWeight: 600
									}}
								>
									{`${comment.responsibleUser.name} `}
								</Typography>
								<Typography
									id='keep-mounted-modal-title'
									sx={{
										fontSize: '14px',
										//color: "#9e9e9e",
										pl: 1
									}}
								>
									{` ${formatDate(comment.createdAt)}`}
								</Typography>
							</Box>
							<Box sx={{ display: 'flex', pt: 0 }}>
								<Box sx={{ flexGrow: 1 }}>
									<Typography
										id='keep-mounted-modal-title'
										sx={{
											fontSize: '18px',
											p: '10px 20px',
											pt: 0
										}}
									>
										{comment.text}
									</Typography>
								</Box>
								<Box>
									<IconButton
										sx={{ p: '5px' }}
										aria-label='directions'
										onClick={() => DeleteComment(comment.id)}
									>
										<DeleteIcon color={'warning'} />
									</IconButton>
								</Box>
							</Box>
						</Box>
					))}
				</Stack>
			</Box>
		</Drawer>
	);
};
