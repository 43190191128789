import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import { formatAmninistratorTabDate } from '../administrator-tab-utils/administrator-tab-utils';
import { AccordionStyles } from '../../../../../styles/default-styles/accordion-styles/accordion-styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const UniversalTable = ({
	selectedTab,
	data,
	filterDataBySevenDays,
	filterDataByMonth,
	filterDataByPeriod,
	sortDataByDate,
	title,
	valueKey = 'count',
	totalLabel = false,
	totalTitle = 'Всего'
	}) => {
	// Функция для суммирования значений всех отображаемых данных
	const sumItemCount = (data) => {
		return data.reduce((total, item) => total + item[valueKey], 0);
	};

	let filteredData;
	switch (selectedTab) {
		case 0:
			filteredData = filterDataBySevenDays(data);
			break;
		case 1:
			filteredData = filterDataByMonth(data);
			break;
		default:
			filteredData = filterDataByPeriod(data);
	}

	const totalItemCount = sumItemCount(filteredData);

	const renderItem = (item, index) => (
		<Grid item xs={6} sm={3} md={2} lg={1} xl={0.8} key={index}
					sx={{ border: '1px solid #ddd', padding: 0,  paddingLeft: '0 !important'}}>
				<Grid container direction="column" sx={{ height: '100%' }}>
					<Grid item>
						<Typography variant="h6" fontWeight="bold" align="center">
							{formatAmninistratorTabDate(item.dateTime, selectedTab)}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h6" align="center">
							{valueKey === 'garbagePercentage' ? `${(item[valueKey] * 100).toFixed(2)}%` : item[valueKey]}
						</Typography>
					</Grid>
				</Grid>
		</Grid>
);

return (
	<Accordion defaultExpanded>
		<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={AccordionStyles.styledAccordion}>
				{totalLabel ? (
					<Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
						<Typography variant="h6" fontWeight="bold">
							{title}
						</Typography>
						<Typography variant="h6" fontWeight="bold" pr={2}>
							{totalTitle}: {totalItemCount}
						</Typography>
					</Box>
				) : (
					<Typography variant="h6" fontWeight="bold">
						{title}
					</Typography>
				)}
			</AccordionSummary>
			<AccordionDetails>
				<Grid container spacing={2}>
					{sortDataByDate(filteredData).map(renderItem)}
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
};