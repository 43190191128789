import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useState } from 'react';
import { FilterMenu } from '../filter-menu';

export const FilterDots = ({
	filterGroup = '',
	filterList,
	activeFilters = [],
	activeFiltersAll,
	setActiveFilters = props => {},
	menuType = 'list',
	options = {},
	buttonElement = (
		<MoreVertIcon color={activeFiltersAll?.hasOwnProperty(filterGroup) ? 'warning' : ''} /> // фильтр активен если в activeFiltersAll есть ключ filterGroup
	)
}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const handleClose = () => setIsOpen(false);
	const handleOpen = event => {
		setAnchorEl(event.currentTarget);
		setIsOpen(true);
	};
	// Объект для хранения состояний фильтров типа "date", "range" и т.п.
	const [savedFilterValues, setSavedFilterValues] = useState({});

	return (
		<>
			{/* кнопка меню */}
			<IconButton
				aria-label='more'
				id='long-button'
				aria-controls={isOpen ? 'expanded-menu' : undefined}
				aria-expanded={isOpen ? 'true' : undefined}
				aria-haspopup='true'
				onClick={event => handleOpen(event)}>
				{buttonElement}
			</IconButton>
			{/* динамическое меню */}
			<FilterMenu
				isOpen={isOpen}
				filterGroup={filterGroup}
				filterList={filterList}
				anchorEl={anchorEl}
				activeFilters={activeFilters}
				setActiveFilters={setActiveFilters}
				handleClose={handleClose}
				options={{ menuType, ...options }}
				savedFilterValues={savedFilterValues}
				setSavedFilterValues={setSavedFilterValues}
			/>
		</>
	);
};
