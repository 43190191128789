const UPDATING_CV_SKILLS_CONFIG = {
	ADD_SKILLS_BUTTON_LABEL: 'Добавить список навыков',
	ALERT_MESSAGE: {
		SUCCESS_ADD_SKILLS: 'Навыки успешно добавлены',
		ERROR_ADD_SKILLS: 'Ошибка добавления навыков'
	},
	ADD_SKILLS_MODAL: {
		TITLE: 'Добавление ключевых навыков',
		LABEL: 'Список навыков',
		HELPER_TEXT: 'Введите список навыков через запятую или с новой строки',
		BUTTON_LABEL: 'Добавить',
		SPLIT_REGEX: /[\n,]/g
	}
};

export default UPDATING_CV_SKILLS_CONFIG;
