import React from "react";
import { Box } from "@mui/material";
import { DeleteTechnicalTermButton } from "./delete-technical-term-button";

export const TermActionsButtons = ({ term, check, setCheck }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <DeleteTechnicalTermButton
        termId={term.id}
        check={check}
        setCheck={setCheck}
      />
    </Box>
  );
};
