import React from 'react';

import { Box, Divider } from '@mui/material';

import { UpdatingCvWorkInfo } from './updating-cv-work-info';
import { AddingCvWorkExperience } from '../adding-cv-work-experience';
import {
	GridContainer,
	GridItem
} from './updating-cv-details-work-info-styles';

export const UpdatingCvDetailsWorkInfo = ({
	cvData,
	check,
	setCheck,
	setAlertIsOpen
}) => {
	return (
		<Box>
			<Divider sx={{ m: '0px 0px 20px 0px' }} />
			<AddingCvWorkExperience
				id={cvData.id}
				check={check}
				setCheck={setCheck}
			/>

			<GridContainer>
				{cvData.workExperience?.map(item => (
					<GridItem key={item.id}>
						<UpdatingCvWorkInfo
							id={item.id}
							companyCity={item.companyCity}
							companyName={item.companyName}
							description={item.description}
							duration={item.duration}
							end={item.end}
							isRelevant={item.isRelevant}
							position={item.position}
							start={item.start}
							check={check}
							setCheck={setCheck}
							setAlertIsOpen={setAlertIsOpen}
						/>
					</GridItem>
				))}
			</GridContainer>
		</Box>
	);
};
