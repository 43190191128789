import React, { useState, useEffect } from 'react';

import { Typography, Box, MenuItem, Menu } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
export const ResponsibleSelected = ({ text, responsible, setData }) => {
	const [term, setTerm] = useState('');
	const [anchorEl, setAnchorEl] = useState(null);

	useEffect(() => {
		if (text !== null && text !== undefined) {
			setTerm(text.name);
		}
	}, [text]);

	const handleChange = responsible => {
		setTerm(responsible.name);
		setData(responsible.id);
		handleClose();
	};

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				flexGrow: 1,
				cursor: 'pointer',
				maxWidth: '200px'
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					flexGrow: 1
				}}
				onClick={handleClick}
			>
				<Box sx={{ flexGrow: 1, userSelect: 'none' }}>
					<Typography id='keep-mounted-modal-title' sx={{ p: 0 }}>
						{term?.slice(0, 20)}
						{term?.length > 20 ? '...' : ''}
					</Typography>
				</Box>
				<Box>
					<Box size='small' sx={{ color: '#696969', cursor: 'pointer' }}>
						{anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
					</Box>
				</Box>
			</Box>

			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				sx={{ maxHeight: '500px' }}
			>
				{responsible?.map(row => (
					<MenuItem key={row.id} onClick={() => handleChange(row)}>
						{row.name}
					</MenuItem>
				))}
			</Menu>
		</Box>
	);
};
