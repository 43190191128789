import React from 'react';
import { Box, Typography } from '@mui/material';

export const NamedField = ({
	name = 'name',
	value = 'value',
	element: Component,
	...props
}) => {
	return (
		<>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: 'repeat(2, 1fr)',
					columnGap: '10px'
				}}
			>
				<Typography
					id='keep-mounted-modal-title'
					variant='h6'
					component='h2'
					sx={{
						fontSize: '15px',
						width: 'fit-content'
					}}
				>
					{name}
				</Typography>
				{!Component && (
					<Typography
						sx={{
							fontSize: '15px',
							fontWeight: '600',
							width: 'fit-content'
						}}
					>
						{value}
					</Typography>
				)}
				{!!Component && <Component {...props} />}
			</Box>
		</>
	);
};
