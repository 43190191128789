import React, { useState } from 'react';

import { CloneByStaffRequest } from '../../../../../api/api';

import { Box, Typography, Button, Divider, Snackbar } from '@mui/material';

import { CvRatingModal } from '../cv-rating-modal';
import { TextFieldStackCV } from '../ui/text-field-stack-CV';
import { DateFieldCV } from '../ui/date-field-CV';
import { DelayedAlert } from '../../alerts/delayed-alert';
import { SlideTransition } from '../../slide-transition';

import { formatDateField } from '../../../../../utils/format-date-field';

import { ButtonPanel, GridContainer, HeaderContainer } from './updating-cv-details-basic-info-styles';
import { AutoSaveInput } from './auto-save-input/auto-save-input';

export const UpdatingCvDetailsBasicInfo = ({
	staffid,
	cvData,
	setFullNameData,
	setBirthdayData,
	setCountryData,
	setCityData,
	setPriceData,
	setStackData,
	checkPublicCV,
	setCheckPublicCV,
	updateCvRequest
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [open, setOpen] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	const CloneByStaff = () => {
		CloneByStaffRequest(staffid)
			.then(() => {
				setCheckPublicCV(!checkPublicCV);
			})
			.catch(requestError => console.error(requestError.message));
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const makeAlert = () => {
		if (showAlert) {
			return (
				<DelayedAlert
					text={
						'Обычно вы могли бы перейти на сайт Headhunter и увидеть это резюме. Но сейчас используется HH-аккаунт компании TechCrew, а ссылки на резюме, найденное другой компанией, запрещено передавать правилами. Позже доступ у вас появится.'
					}
					severity={'warning'}
					delay={4000}
					setShowAlert={setShowAlert}
				/>
			);
		}
	};

	return (
		<>
			<Box>
				<HeaderContainer>
					<TextFieldStackCV textData={cvData.stack} setData={setStackData} characterWidth={true} />
					<ButtonPanel>
						<Button variant='contained' color='inherit' onClick={CloneByStaff}>
							Подгрузить данные
						</Button>
						<Button
							variant='contained'
							href={`${process.env.REACT_APP_API_HOST}cv/api/CV/${cvData.id}/DocX`}
							color='inherit'>
							Скачать CV
						</Button>
						<Button
							variant='contained'
							color='inherit'
							disabled={!cvData?.rating}
							onClick={() => {
								setIsOpen(true);
							}}>
							Рейтинг {cvData?.rating?.rateAll?.value}
						</Button>
						{process.env.REACT_APP_HEAD_HANTER_OPEN === 'true' ? (
							<Button variant='contained' color='inherit' href={cvData.url} target='_blank'>
								Открыть на HH.ru
							</Button>
						) : (
							<Button
								variant='contained'
								color='inherit'
								onClick={() => {
									setShowAlert(true);
								}}>
								Открыть на HH.ru
							</Button>
						)}
						<Snackbar
							open={open}
							autoHideDuration={3000}
							onClose={handleClose}
							message='Скопированно!'
							key='copy-notification'
							TransitionComponent={SlideTransition}
							ContentProps={{ style: { backgroundColor: 'orange' } }}
						/>
					</ButtonPanel>
				</HeaderContainer>
				<Divider />
				<GridContainer>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ mr: '5px', fontWeight: 'bold' }}>Зарплата:</Typography>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<AutoSaveInput
							defaultValue={cvData.price}
							setData={setPriceData}
							dataKey={'salary'}
							onChange={updateCvRequest}
							type='number'
						/>
					</Box>

					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>Локация:</Typography>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<AutoSaveInput
							defaultValue={cvData?.city}
							setData={setCityData}
							dataKey={'city'}
							onChange={updateCvRequest}
						/>
					</Box>

					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>Локация поиска:</Typography>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<AutoSaveInput
							defaultValue={cvData?.country}
							setData={setCountryData}
							dataKey={'country'}
							onChange={updateCvRequest}
						/>
					</Box>

					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>Часовой пояс:</Typography>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ fontSize: '15px' }}>
							{cvData.timeZone > 0 ? '+' + cvData.timeZone : cvData.timeZone}
						</Typography>
					</Box>

					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ mr: '5px', fontWeight: 'bold' }}>Возраст:</Typography>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ fontSize: '15px' }}>{cvData?.age}</Typography>
					</Box>

					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>ФИО:</Typography>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<AutoSaveInput
							defaultValue={cvData?.fullName}
							setData={setFullNameData}
							dataKey={'fullName'}
							onChange={updateCvRequest}
						/>
					</Box>

					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>Занятость:</Typography>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ fontSize: '15px' }}>{cvData?.employment}</Typography>
					</Box>

					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>Пол:</Typography>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ fontSize: '15px' }}>
							{cvData?.gender ? (cvData?.gender === 'Неопределился' ? '-' : cvData?.gender) : '-'}
						</Typography>
					</Box>

					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>Релев. опыт:</Typography>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ fontSize: '15px' }}>{cvData?.relevantExperience}</Typography>
					</Box>

					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>Статус поиска:</Typography>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ fontSize: '15px' }}>{cvData?.searchStatus}</Typography>
					</Box>

					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography
							sx={{
								marginRight: '5px',
								fontWeight: 'bold'
							}}>
							График работы:
						</Typography>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ fontSize: '15px' }}>{cvData?.schedule}</Typography>
					</Box>

					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography
							sx={{
								marginRight: '5px',
								fontWeight: 'bold'
							}}>
							Дата рождения:
						</Typography>
					</Box>
					<DateFieldCV textData={formatDateField(cvData?.dateOfBirth)} setData={setBirthdayData} />

					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>Опыт:</Typography>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ fontSize: '15px' }}>{cvData?.allExperience}</Typography>
					</Box>
				</GridContainer>
			</Box>
			{cvData?.rating ? <CvRatingModal isOpen={isOpen} setIsOpen={setIsOpen} ratings={cvData.rating} /> : <></>}
			<Box>{makeAlert()}</Box>
		</>
	);
};
