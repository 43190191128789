import React, {useState} from "react";

import {AddExtraContactRequest} from "../../../../../api/api";

import {Box, Button, Typography, Stack, ListItemButton, Popover} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {ContactTextFieldCard} from "./contact-text-field-card";
import {requestErrorWrapper} from "../../../../../utils/request-error-wrapper/request-error-wrapper";

export const CandidateContactsMenu = ({
                                          staffId,
                                          contacts,
                                          check,
                                          setCheck,
                                      }) => {
    const [showWindow, setShowWindow] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const AddExtraContact = async () => {
        await requestErrorWrapper({
            handleRequest: () => AddExtraContactRequest(staffId)
        });
        setCheck(!check);
    };

    const handleClick = (event) => {
        setShowWindow(true);
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setShowWindow(false);
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{
                    minHeight: "32px",
                    borderRadius: "3%",
                    marginBottom: "2%",
                    fontSize: "15px",
                    bgcolor: "#fb8c00",
                    color: "#852508",
                    p: 0,
                    "&:hover": {
                        bgcolor: "#ff9800",
                        color: "#FFFFFF",
                        transform: "scale(1.03)",
                    },
                }}
                onClick={handleClick}
            >
                Доп. контакты
            </Button>

            <Popover
                id={showWindow ? 'simple-popover' : undefined}
                open={showWindow}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >

                <Box sx={{p: 2}}>
                    <Box className="handle" sx={{display: "flex"}}>
                        <Box sx={{flexGrow: 1}}>
                            <Typography
                                id="keep-mounted-modal-title"
                                variant="h6"
                                component="h3"
                                sx={{
                                    fontSize: "25px",
                                }}
                            >
                                Дополнительные контакты
                            </Typography>
                        </Box>
                        <Box>
                            <ListItemButton onClick={handleClose}>
                                <CloseIcon color={"warning"} sx={{fontSize: 25}}/>
                            </ListItemButton>
                        </Box>
                    </Box>
                    <Box sx={{mb: 2}}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            sx={{
                                bgcolor: "#ef6c00",
                                borderRadius: "3%",
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                mr: 1,
                                "&:hover": {
                                    bgcolor: "#ff9800",
                                    color: "#FFFFFF",
                                    transform: "scale(1.03)",
                                },
                            }}
                            onClick={AddExtraContact}
                        >
                            Добавить
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            width: 400,
                        }}
                    >
                        <Stack spacing={2}>
                            {contacts?.map((row, index) => (
                                <ContactTextFieldCard
                                    key={index}
                                    textName={"Доп. контакты"}
                                    textstaff={row}
                                    check={check}
                                    setCheck={setCheck}
                                />
                            ))}
                        </Stack>
                    </Box>
                </Box>
            </Popover>
        </>
    );
};
