import { createTheme } from '@mui/material/styles';

export const DefaultStyles = createTheme({
	typography: {
		fontFamily: 'Montserrat, sans-serif',
		button: {
			textTransform: 'none'
		}
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
			xxl: 1800
		}
	},
	components: {
		MuiScrollbar: {
			styleOverrides: {
				root: {
					backgroundColor: '#222'
				},
				thumb: {
					backgroundColor: '#fff'
				}
			}
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					fontSize: 20,
					fontFamily: [
						'-apple-system',
						'BlinkMacSystemFont',
						'"Segoe UI"',
						'Roboto',
						'Ubuntu',
						'Cantarell',
						'sans-serif',
						'"Fira Sans"',
						'"Droid Sans"',
						'"Helvetica Neue"'
					].join(',')
				},
				head: {
					fontWeight: 'fontWeightBold'
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: '#ef6c00'
				}
			}
		}
	}
});
