export function calculatePercentage(part, max) {
	if (max === 0) {
		return max;
	}
	return (part / max) * 100;
}

export function getMaxStatistics(arr) {
	const statsFields = [
		'cvStatistics',
		'messageStatistics',
		'callsToCandidatesStatistics',
		'addingCandidatesStatistics',
		'dealStatusStatistics'
	];
	const allowedKeys = {
		cvStatistics: ['allCount', 'likesCount', 'dislikesCount'],
		messageStatistics: ['allCount', 'favoritesCount', 'trashCount'],
		callsToCandidatesStatistics: [
			'allCount',
			'successfulCallsCount',
			'unsuccessfulCallsCount',
			'pendingCallsCount'
		],
		addingCandidatesStatistics: [
			'allCount',
			'addedViaQueryCount',
			'addedByLinkCount'
		],
		dealStatusStatistics: ['allCount', 'atWork', 'waiting', 'done']
	};

	const maxValues = {};

	statsFields.forEach(field => {
		maxValues[field] = {};

		allowedKeys[field].forEach(key => {
			let maxKeySum = 0;

			// Sum up the values for the specific key within the field for each element
			arr.forEach(person => {
				let totalValue = person.statistics.reduce((acc, stats) => {
					if (stats[field][key] !== undefined) {
						return acc + stats[field][key];
					} else {
						return acc;
					}
				}, 0);
				maxKeySum = Math.max(maxKeySum, totalValue);
			});

			maxValues[field][key] = maxKeySum;
		});
	});

	return maxValues;
}
