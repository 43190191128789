import React, { useEffect, useState } from 'react';
import { Box, MenuItem, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ruRU } from '@mui/x-date-pickers/locales';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { pickersLayoutClasses } from '@mui/x-date-pickers/PickersLayout';
import moment from 'moment/moment';

export const FilterMenuDate = ({
  setActiveFilters,
  filterList,
  filterGroup,
	savedFilterValues,
	setSavedFilterValues
}) => {

	const [num1, setNum1] = useState(savedFilterValues.filter1 || null);
	const [num2, setNum2] = useState(savedFilterValues.filter2 || null);
	const [openCalendar1, setOpenCalendar1] = useState(false);
	const [openCalendar2, setOpenCalendar2] = useState(false);

	let newFilter = Object.assign({}, savedFilterValues);

	// Фильтруем данные и обновляем их в активных фильтрах
	const applyFilters = () => {
		const filteredValues = filterList.filter(value => {
			const num1Valid = !num1 || moment(value).isSameOrAfter(moment(num1)); // Проверяем, что значение value >= num1 или num1 не задано
			const num2Valid = !num2 || moment(value).isSameOrBefore(moment(num2)); // Проверяем, что значение value <= num2 или num2 не задано
			return num1Valid && num2Valid;
		});
		setActiveFilters(prevFilters => ({
			...prevFilters,
			[filterGroup]: filteredValues.map(value => moment(value).format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ')) // преобразуем дату в универсальный формат для фильтрации и сортировки
		}));
	};

	useEffect(() => {
		// Если значений нет, удаляем их из активных фильтров
		if (num1 === null && num2 === null) {
			setSavedFilterValues({});
			setActiveFilters(prevFilters => {
				const updatedFilters = { ...prevFilters };
				delete updatedFilters[filterGroup];
				return updatedFilters;
			});
		}
    // Если значение введено, применяем фильтр
		if (num1 !== null || num2 !== null) {
			const timeout = setTimeout(() => applyFilters(), 500);
			return () => clearTimeout(timeout);
		}
	}, [num1, num2]);

	const handleChange = (value, setter) => {
		setter(value._d);
		const newFilterCopy = { ...newFilter };
		if (value._d === null) {
			delete newFilterCopy[setter === setNum1 ? 'filter1' : 'filter2'];
		} else {
			newFilterCopy[setter === setNum1 ? 'filter1' : 'filter2'] = moment(value._d);
		}

		setSavedFilterValues(newFilterCopy);
	};

	const deleteAllFilters = () => {
		setNum1(null);
		setNum2(null);
		setSavedFilterValues({})
		setActiveFilters(prevFilters => {
			const updatedFilters = { ...prevFilters };
			delete updatedFilters[filterGroup];
			return updatedFilters;
		});
	}

	return (
		<>
			<MenuItem onClick={deleteAllFilters}>Сбросить фильтры</MenuItem>
			<Box sx={{ pr: 2, pl: 2, width: '300px', display: 'flex' }}>
				<LocalizationProvider
					dateAdapter={AdapterMoment}
					adapterLocale="ru"
					localeText={
						ruRU.components.MuiLocalizationProvider.defaultProps.localeText
					}
				>
					<Box components={['DatePicker']}>
						<DatePicker
							label="От"
							maxDate={num2 ? moment(num2) : moment()}
							closeOnSelect={false}
							open={openCalendar1}
							onOpen={() => setOpenCalendar1(true)}
							onClose={() => setOpenCalendar1(false)}
							value={num1 ? moment(num1) : null}
							onChange={(newValue) => handleChange(newValue, setNum1)}
							slotProps={{
								textField: {
									variant: 'standard'
								},
								toolbar: { hidden: false },
								layout: {
									sx: {
										[`.${pickersLayoutClasses.toolbar}`]: {
											color: 'white',
											backgroundColor: '#fb8c00',
											'& .MuiTypography-root ': {
												color: 'white'
											}
										},
										[`.${pickersLayoutClasses.contentWrapper}`]: {
											'& .Mui-selected': {
												backgroundColor: '#fb8c00',
												borderColor: '#fb8c00',
												color: 'white'
											},
											'& .MuiTabs-indicator': { bgcolor: '#f57c00' },
											'& .MuiClock-pin': { bgcolor: '#f57c00' },
											'& .MuiClockPointer-root': { bgcolor: '#f57c00' },
											'& .MuiClockPointer-thumb': {
												borderColor: '#f57c00'
											},
											'& .Mui-selected:hover': {
												backgroundColor: '#fb8c00',
												borderColor: '#fb8c00',
												color: 'white'
											},
											'& .Mui-selected:focus': {
												backgroundColor: '#fb8c00',
												borderColor: '#fb8c00',
												color: 'white'
											}
										}
									}
								}
							}}
							sx={{
								svg: { color: '#fb8c00' },
								'& .MuiInputLabel-root.Mui-focused': { color: '#fb8c00' }, //styles the label
								'& .MuiInput-underline:after': {
									borderBottomColor: '#fb8c00'
								}
							}}
						/>
					</Box>
				</LocalizationProvider>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						p: 1,
						pt: 2
					}}
				>
					<Typography
						id="keep-mounted-modal-title"
						sx={{
							fontSize: '20px'
						}}
					>
						-
					</Typography>
				</Box>

				<LocalizationProvider
					dateAdapter={AdapterMoment}
					adapterLocale="ru"
					localeText={
						ruRU.components.MuiLocalizationProvider.defaultProps.localeText
					}
				>
					<Box components={['DatePicker']}>
						<DatePicker
							label="До"
							minDate={num1 ? moment(num1) : null}
							maxDate={moment()}
							closeOnSelect={false}
							open={openCalendar2}
							onOpen={() => setOpenCalendar2(true)}
							onClose={() => setOpenCalendar2(false)}
							value={num2 ? moment(num2) : null}
							onChange={(newValue) => handleChange(newValue, setNum2)}
							slotProps={{
								textField: {
									variant: 'standard'
								},
								toolbar: { hidden: false },
								layout: {
									sx: {
										[`.${pickersLayoutClasses.toolbar}`]: {
											color: 'white',
											backgroundColor: '#fb8c00',
											'& .MuiTypography-root ': {
												color: 'white'
											}
										},
										[`.${pickersLayoutClasses.contentWrapper}`]: {
											'& .Mui-selected': {
												backgroundColor: '#fb8c00',
												borderColor: '#fb8c00',
												color: 'white'
											},
											'& .MuiTabs-indicator': { bgcolor: '#f57c00' },
											'& .MuiClock-pin': { bgcolor: '#f57c00' },
											'& .MuiClockPointer-root': { bgcolor: '#f57c00' },
											'& .MuiClockPointer-thumb': {
												borderColor: '#f57c00'
											},
											'& .Mui-selected:hover': {
												backgroundColor: '#fb8c00',
												borderColor: '#fb8c00',
												color: 'white'
											},
											'& .Mui-selected:focus': {
												backgroundColor: '#fb8c00',
												borderColor: '#fb8c00',
												color: 'white'
											}
										}
									}
								}
							}}
							sx={{
								svg: { color: '#fb8c00' },
								'& .MuiInputLabel-root.Mui-focused': { color: '#fb8c00' }, //styles the label
								'& .MuiInput-underline:after': {
									borderBottomColor: '#fb8c00'
								},
								MuiPickersPopper: {
									paper: {
										display: 'none'
									}
								}
							}}
						/>
					</Box>
				</LocalizationProvider>
			</Box>
		</>
	);
};
