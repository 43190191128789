import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { TermGridCard } from './term-grid-card';
import { GridWrapperContainer } from './term-grid-styles';
import { PagePagination } from '../../page-pagination';
import { TermGridFilter } from './term-grid-filter';
import { SearchFieldUi } from '../../search-field-ui';
import { boxStyles } from '../../../../../styles/default-styles/box-styles/box-styles';
import { filterBySearch, filterByStack, formatNamesToIds, formatIdsToNames } from './utils';
import { DeleteModal } from '../../modals/delete-modal';
import { TermWithVariantsModal } from '../../modals/term-with-variants-modal';
import { buttonStyles } from '../../../../../styles/default-styles/button-styles/button-styles';
import { parseObjectFilterList } from './term-grid-filter/utils/utils';
import { foundValueById } from './utils';

/**
 * Компонент TermGrid
 *
 * @param {Array} values - Массив значений
 * @param {Object} options - Настройки
 * @param {Object} config - Конфигурация
 * @returns {JSX.Element} - Возвращает JSX элемент
 */
export const TermGrid = memo(({ values = [], options = {}, config = {} }) => {
	const {
		paginationConfig = {},
		categoriesConfig = {},
		search = false,
		onDelete = () => {},
		onEdit = () => {},
		onAdd = () => {}
	} = options;
	const { ADD_MODAL, EDIT_MODAL, KEY_VALUE_NAME, ADD_BUTTON_LABEL } = config;

	const [currentValueId, setCurrentValueId] = useState('');
	const [modalsIsOpenConfig, setModalsIsOpenConfig] = useState({
		delete: onDelete ? false : undefined,
		edit: onEdit ? false : undefined,
		add: onAdd ? false : undefined
	});
	const [visibleValues, setVisibleValues] = useState([]);
	const [filterSettings, setFilterSettings] = useState({
		activeStackNames: categoriesConfig ? [] : undefined,
		searchValue: search ? '' : undefined
	});

	const openDeleteModal = id => {
		if (!modalsIsOpenConfig.delete) {
			setCurrentValueId(id);
			setModalsIsOpenConfig(prev => ({ ...prev, delete: true }));
		}
	};

	const closeDeleteModal = () => {
		if (modalsIsOpenConfig.delete) {
			setModalsIsOpenConfig(prev => ({ ...prev, delete: false }));
			setCurrentValueId('');
		}
	};

	const handleDeleteValue = () => {
		onDelete(currentValueId);
		closeDeleteModal();
	};

	const openEditModal = id => {
		if (!modalsIsOpenConfig.edit) {
			setCurrentValueId(id);
			setModalsIsOpenConfig(prev => ({ ...prev, edit: true }));
		}
	};

	const closeEditModal = () => {
		if (modalsIsOpenConfig.edit) {
			setModalsIsOpenConfig(prev => ({ ...prev, edit: false }));
			setCurrentValueId('');
		}
	};

	const openAddModal = () => !modalsIsOpenConfig.add && setModalsIsOpenConfig(prev => ({ ...prev, add: true }));

	const closeAddModal = () => modalsIsOpenConfig.add && setModalsIsOpenConfig(prev => ({ ...prev, add: false }));

	const setSearchValue = newValue => setFilterSettings(prev => ({ ...prev, searchValue: newValue }));

	const filteredValues = useMemo(() => {
		const { activeStackNames, searchValue } = filterSettings;

		let filteredValues = filterByStack(activeStackNames, categoriesConfig, values);

		if (search) {
			filteredValues = filterBySearch(filteredValues, searchValue);
		}

		return filteredValues;
	}, [filterSettings, categoriesConfig, values, search]);

	const modalAddAndEditProps = useMemo(
		() => ({
			isOpen: modalsIsOpenConfig.edit || modalsIsOpenConfig.add,
			onClose: modalsIsOpenConfig.edit ? closeEditModal : closeAddModal,
			size: 'lg',
			config: modalsIsOpenConfig.edit ? EDIT_MODAL : ADD_MODAL,
			onProcess: modalsIsOpenConfig.edit
				? (editedValues, activeStacks) => onEdit(currentValueId, editedValues, activeStacks)
				: onAdd,
			options: modalsIsOpenConfig.edit
				? {
						categories: parseObjectFilterList(categoriesConfig.filterList),
						defaultValues: [foundValueById(currentValueId, values).value],
						defaultCategories: formatIdsToNames(
							foundValueById(currentValueId, values).categoriesId,
							categoriesConfig.filterList
						),
						defaultVariations: foundValueById(currentValueId, values).options
					}
				: { categories: parseObjectFilterList(categoriesConfig.filterList) }
		}),
		[
			modalsIsOpenConfig,
			currentValueId,
			values,
			categoriesConfig,
			EDIT_MODAL,
			ADD_MODAL,
			onEdit,
			onAdd,
			closeEditModal,
			closeAddModal
		]
	);

	return (
		<>
			<Box sx={{ ...boxStyles.flexBoxColumn, gap: '1rem', p: '10px' }}>
				<Box sx={{ ...boxStyles.flexBoxRow, gap: '1rem' }}>
					{search && <SearchFieldUi styles={{ width: '40vw' }} onUpdateSearchProp={setSearchValue} />}
					{onAdd && (
						<Button sx={{ ...buttonStyles.orangeButton, fontSize: '1rem', p: '10px' }} onClick={openAddModal}>
							{ADD_BUTTON_LABEL}
						</Button>
					)}
					{categoriesConfig && (
						<TermGridFilter config={categoriesConfig} values={values} setFilterSettings={setFilterSettings} />
					)}
				</Box>
				<GridWrapperContainer>
					<Grid container spacing={2}>
						{visibleValues.map(({ id, value }) => (
							<Grid key={id} xs={2} item>
								<TermGridCard
									label={value}
									onDeleteButtonClick={() => openDeleteModal(id)}
									onCardClick={() => openEditModal(id)}
								/>
							</Grid>
						))}
					</Grid>
					<Box sx={{ width: 'fit-content', margin: '0 auto' }}>
						<PagePagination
							setVisibleValues={setVisibleValues}
							values={filteredValues}
							config={paginationConfig}
							resetDependence={filterSettings}
						/>
					</Box>
				</GridWrapperContainer>
			</Box>
			{modalsIsOpenConfig.delete && (
				<DeleteModal
					isOpen={modalsIsOpenConfig.delete}
					onClose={closeDeleteModal}
					handleDelete={handleDeleteValue}
					labels={{ itemName: KEY_VALUE_NAME }}
				/>
			)}
			{(modalsIsOpenConfig.edit || modalsIsOpenConfig.add) && <TermWithVariantsModal {...modalAddAndEditProps} />}
		</>
	);
});
