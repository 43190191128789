import { lazy } from 'react';
import CandidateCardPage from '../components/pages/candidates-page/candidate-card-page';
import AdministrationPage from '../components/pages/settings-page';

const HomePage = lazy(() => import('../components/pages/home-page'));
const StatsPage = lazy(() => import('../components/pages/stats-page'));
const DetailsPage = lazy(() => import('../components/pages/details-page'));
const ProjectPage = lazy(() => import('../components/pages/project-page'));

// const AdministrationPage = lazy(() => import('../components/pages/settings-page'));

const CandidatesPage = lazy(() => import('../components/pages/candidates-page'));
// const CandidateCardPage = lazy(() => import('../components/pages/candidates-page/candidate-card-page'));
const ProjectDetailPage = lazy(() => import('../components/pages/project-page/project-details-page'));
const ToolPage = lazy(() => import('../components/pages/tool-page'));
const TestParsedMessagesTool = lazy(() =>
	import('../components/pages/tool-page/test-parsed-messages-tool').then(module => ({
		default: module.TestParsedMessagesTool
	}))
);
const ConvertResumeTool = lazy(() => import('../components/pages/tool-page/convert-resume-tool'));
const AddSamplePage = lazy(() => import('../components/pages/stats-page/add-sample-page'));
const CvListPage = lazy(() => import('../components/pages/stats-page/cv-list-page'));
const CVDetailPage = lazy(() => import('../components/pages/stats-page/cv-details-page'));
const ErrorPage = lazy(() => import('./../components/pages/error-page'));
const SourcesPage = lazy(() => import('../components/pages/sources-page'));
const DealsPage = lazy(() => import('../components/pages/deals-page'));
const AnalysisPage = lazy(() => import('../components/pages/analysis-page'));
const InterviewsPage = lazy(() => import('../components/pages/interviews-page'));
const PublicConvertResumeTool = lazy(() =>
	import('../components/pages/tool-page/public-convert-resume-tool').then(module => ({
		default: module.PublicConvertResumeTool
	}))
);

export const routes = [
	{ path: '/', element: <HomePage /> },
	{ path: '/stats', element: <StatsPage /> },
	{ path: '/details', element: <DetailsPage /> },
	{ path: '/candidates', element: <CandidatesPage /> },
	{ path: '/candidates/:id', element: <CandidateCardPage /> },
	{ path: '/project', element: <ProjectPage /> },
	{ path: '/deals', element: <DealsPage /> },
	{ path: '/tools', element: <ToolPage /> },
	{ path: '/administration', element: <AdministrationPage /> },
	{ path: '/addSample', element: <AddSamplePage /> },
	{ path: '/stats/:id', element: <CvListPage /> },
	{ path: '/cvIndividual', element: <CVDetailPage /> },
	{ path: '/project/:id', element: <ProjectDetailPage /> },
	{ path: '/test-parsed-messages-tool', element: <TestParsedMessagesTool /> },
	{ path: '/convert-resume-tool', element: <ConvertResumeTool /> },
	{ path: '/public-convert-resume-tool', element: <PublicConvertResumeTool /> },
	{ path: '/sources', element: <SourcesPage /> },
	{ path: '/analysis-page', element: <AnalysisPage /> },
	{ path: '/interviews-page', element: <InterviewsPage /> },
	{ path: '*', element: <ErrorPage /> }
];
