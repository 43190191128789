export const getHeadHunterTablesConfig = (cvCountData, contactCvCountData) => {
	return [
		{
			title: 'Получено резюме',
			data: cvCountData,
			totalTitle: 'Всего получено'
		},
		{
			title: 'Открыто контактов',
			data: contactCvCountData,
			totalTitle: 'Всего открыто'
		}
	];
};

export const getTelegramBotTablesConfig = (garbagePercentageData, notAccessedData, userBotStatisticData) => {
	return [
		{
			title: 'Парсинг, % мусора',
			data: garbagePercentageData,
			valueKey: 'garbagePercentage'
		},
		{
			title: 'Заблокированные источники',
			data: notAccessedData,
			valueKey: 'countNotAccessed'
		},
		{
			title: 'Забанено',
			data: userBotStatisticData,
			valueKey: 'countError'
		},
		{
			title: 'Активно',
			data: userBotStatisticData,
			valueKey: 'countAlive'
		},
		{
			title: 'Добавлено',
			data: userBotStatisticData,
			valueKey: 'countAdd'
		}
	];
};