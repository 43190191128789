export const formatDate = dateString => {
	const date = new Date(dateString);
	date.setHours(date.getHours());
	const day = date.getDate().toString().padStart(2, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const year = date.getFullYear();
	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');

	return `${hours}:${minutes} ${day}.${month}.${year.toString().substring(2)}`;
};
