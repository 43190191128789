import React from "react";

import { Box } from "@mui/material";
import { AddUserBotButton } from "./add-user-bot-button";
import { UserBotsTable } from "./user-bots-table";

export const UserBotsTab = () => {
  return (
    <Box
      sx={{
        marginLeft: "100px",
        marginRight: "10px",
        height: "91vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          mt: 2,
          mb: 2,
          display: "flex",
          alignItems: "center",
        }}
      >
        <AddUserBotButton />
      </Box>
      <UserBotsTable />
    </Box>
  );
};
