import React from 'react';

import { Box, Grid, Typography, Divider } from '@mui/material';
import { GridContainer, GridItem } from './cv-details-education-info-styles';

export const CvDetailsEducationInfo = ({ cvData }) => {
	const educationsMap = {
		name: 'Учебное заведение:',
		organization: 'Факультет:',
		result: 'Специализация:',
		year: 'Год окончания:'
	};

	let educationsArr = cvData?.educations?.map(item => {
		delete item.id;
		return item;
	});

	return (
		<Box sx={{ display: cvData.education !== null ? '' : 'none' }}>
			<Divider sx={{ height: 20, m: '0px 0px 20px 0px' }} />

			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					marginBottom: '20px'
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-start'
					}}
				>
					<Typography
						variant='h6'
						component='h2'
						sx={{
							fontSize: '20px'
						}}
					>
						Образование
					</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-start',
						pb: 2
					}}
				>
					<Typography
						variant='h6'
						component='h2'
						sx={{
							fontSize: '20px',
							fontWeight: 'bold'
						}}
					>
						Уровень:
					</Typography>
					<Typography
						variant='h6'
						component='h2'
						sx={{
							fontSize: '20px'
						}}
					>
						{cvData.education ? cvData.education : '-'}
					</Typography>
				</Box>
			</Box>
			<GridContainer>
				{educationsArr?.map((education, educationIndex) => (
					<GridItem key={educationIndex}>
						{Object.keys(education).map((educationKey, keyIndex) => (
							<Grid container key={keyIndex}>
								<Grid item xs={6}>
									<Typography
										variant='h6'
										component='h2'
										sx={{
											fontSize: '15px',
											fontWeight:
												educationKey === 'name' && educationsMap[educationKey]
													? 'bold'
													: ''
										}}
									>
										{educationsMap[educationKey]
											? educationsMap[educationKey]
											: '-'}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography
										variant='h6'
										component='h2'
										sx={{
											fontSize: '15px',
											fontWeight:
												educationKey === 'name' && education[educationKey]
													? 'bold'
													: ''
										}}
									>
										{education[educationKey] ? education[educationKey] : '-'}
									</Typography>
								</Grid>
							</Grid>
						))}
					</GridItem>
				))}
			</GridContainer>
		</Box>
	);
};
