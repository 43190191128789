import { styled } from '@mui/material/styles';
import { TableCell, TableRow } from '@mui/material';

export const BenchStyleTableRow = styled(TableRow)(() => ({}));

export const BenchTableCell = styled(TableCell)(() => ({
	fontSize: '16px',
	padding: '3px',
	width: '5%',
	borderBottom: 0,
	userSelect: 'none',
	boxSizing: 'border-box'
}));
