import React, { useState } from "react";
import { IconButton, Box } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

export const ResumeFileInput = ({
  handleFileChange,
  setSelectedFile,
  selectedFile,
  fileName,
  setFileName,
}) => {
  const [inputKey, setInputKey] = useState(Date.now());

  const resetInput = () => {
    setInputKey(Date.now());
    setSelectedFile(null);
    setFileName("Файл не выбран");
  };

  const handleDrop = (event) => {
    event.preventDefault();

    if (event.dataTransfer.files.length > 0) {
      setFileName(event.dataTransfer.files[0].name);
      setSelectedFile(event.dataTransfer.files[0]);
    } else {
      setFileName("Файл не выбран");
    }
  };

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <label
        htmlFor="fileInput"
        id="fileInputLabel"
        style={{ padding: "10px" }}
      >
        <input
          style={{
            width: "117px",
            overflow: "hidden",
          }}
          key={inputKey}
          id="fileInput"
          accept=".docx"
          type="file"
          onChange={(event) => {
            handleFileChange(event);
            setSelectedFile(event.target.files[0]);
          }}
          onDrop={handleDrop}
          onDragOver={(event) => {
            event.preventDefault();
          }}
        />
        {fileName}
      </label>
      {selectedFile && (
        <IconButton onClick={resetInput}>
          <CancelIcon />
        </IconButton>
      )}
    </Box>
  );
};
