import { ApiClient } from '../clients/client';

export const GetStaffsByProjectId = async projectId => {
	const { data } = await ApiClient.get(`tg/api/Staff/GetStaffsByProjectId?projectId=${projectId}`);
	return data;
};

export const postCheckRequirements = async ({ staffId, publicCvId, requirements }) => {
	const { data } = await ApiClient.post(`tg/api/Requirement/Check`, {
		staffId,
		publicCvId,
		requirements
	});
	return data;
};

export const postAddSeveralRequirements = async ({ newReqs }) => {
	const { data } = await ApiClient.post(`tg/api/Requirement/AddSeveral`, newReqs);
	return data;
};

export const postUpdateRequirementById = async ({ reqId, newReqData }) => {
	const { data } = await ApiClient.post(`tg/api/Requirement/Update?id=${reqId}`, newReqData);
	return data;
};

export const postDeleteRequirementById = async ({ reqId }) => {
	const { data } = await ApiClient.post(`tg/api/Requirement/Delete?id=${reqId}`);
	return data;
};

export const postAddSeveralSkills = async ({ resumeId, newSkills }) => {
	const { data } = await ApiClient.post(`cv/api/CV/${resumeId}/keySkill/AddMany`, newSkills);

	return data;
};

export const postUpdateCandidateRequirement = async ({ staffId, reqId, newValue, options, categoriesId }) => {
	const newReqData = {
		staffId,
		updateRequirementRequest: {
			id: reqId,
			value: newValue,
			options,
			categoriesId
		}
	};

	const { data } = await ApiClient.post(`tg/api/Requirement/${staffId}/Update`, newReqData);

	return data;
};

export const postDeleteCandidateRequirement = async ({ staffId, requirementId }) => {
	const deleteData = { stafferId: staffId, requirementId: requirementId };

	const { data } = await ApiClient.post(`tg/api/Requirement/${staffId}/Delete`, deleteData);

	return data;
};
