import React, { useState, useEffect } from "react";

import {
  DeleteExtraContactRequest,
  UpdateExtraContactRequest,
} from "../../../../../../api/api";

import {
  InputBase,
  Divider,
  Typography,
  Paper,
  IconButton,
} from "@mui/material";

import copy from "copy-to-clipboard";

import DeleteIcon from "@mui/icons-material/Delete";
import {CopyIconButton} from "../../../../../lib/ui/buttons/copy-icon-button";
import {requestErrorWrapper} from "../../../../../../utils/request-error-wrapper/request-error-wrapper";

export const ContactTextFieldCard = ({
  textName,
  textstaff,
  check,
  setCheck,
}) => {
  const [term, setTerm] = useState("");
  const [termTime, setTermTime] = useState();

  // обработчик кнопки копирования
  const handleCopyClick = () => copy(term);

  useEffect(() => {
    if (textstaff !== null && textstaff !== undefined) {
      setTerm(textstaff.value);
    }
  }, [textstaff]);

    const DeleteExtraContact = async () => {
        await requestErrorWrapper({
            handleRequest: () => DeleteExtraContactRequest(textstaff.id)
        });
        setCheck(!check);
    };

  const handleChange = (element) => {
    const temp = element.target.value;
    setTerm(temp.replace(/^[\s.`":'$@~;]*$/g, ""));
    setTermTime(temp.replace(/^[\s.`":'$@~;]*$/g, ""));
  };

    const UpdateExtraContact = async () => {
        await requestErrorWrapper({
            handleRequest: () =>  UpdateExtraContactRequest(textstaff.id, termTime)
        });
        setCheck(!check);
    };

    useEffect(() => {
    const timeOutId = setTimeout(() => {
        if (termTime === undefined) return;
        UpdateExtraContact();
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [termTime]);

  return (
    <Paper
      elevation={6}
      sx={{
        display: "flex",
        alignItems: "center",
        minHeight: "28px",
        width: 400,
      }}
    >
      <Typography
        id="keep-mounted-modal-title"
        variant="h6"
        component="h2"
        sx={{
          display: "flex",
          height: "100%",
          fontSize: "14px",
          bgcolor: "#fb8c00",
          color: "#852508",
          border: "1px solid",
          minWidth: "110px",
          textAlign: "center",
          borderRadius: "4px",
          borderColor: "#a2a2a2",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {textName}
      </Typography>
      <Divider sx={{ height: 24, ml: 0.5 }} orientation="vertical" />
      <InputBase
        type="text"
        value={term}
        multiline
        onChange={handleChange}
        sx={{p: "2px 4px", ml: 1, flex: 1, fontSize: "15px"}}
      />
        <Divider sx={{height: 24, m: 0.5}} orientation="vertical"/>
        <CopyIconButton copyString={term} itemName={textName} config={{size: 'medium', title: 'Копировать'}}/>
        <IconButton
            sx={{p: "10px"}}
            aria-label="directions"
            onClick={DeleteExtraContact}
        >
            <DeleteIcon color={"warning"}/>
        </IconButton>
    </Paper>
  );
};
