import React, { useContext } from 'react';
import {IconButton, Tooltip} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from 'copy-to-clipboard';
import { AlertContext } from '../../../../contexts/AlertContext/AlertContext';
import { capitalizeFirstLetter } from '../../../../../utils/string-process';
import COPY_ICON_BUTTON_CONFIG from './config';

/**
 * Кнопка для копирования текста.
 * @param {string} copyString - Текст для копирования.
 * @param {string} itemName - Название элемента.
 * @param {Object} config - Конфиг элемента.
 * @returns {JSX.Element} - Компонент кнопки копирования.
 */
export const CopyIconButton = ({
	copyString,
	itemName = COPY_ICON_BUTTON_CONFIG.ITEM_NAME,
	config = {}
}) => {
	const { showAlert } = useContext(AlertContext);

	// Обработчик нажатия на кнопку копирования
	const handleCopy = () => {
		try {
			const isCopied = copy(copyString.trim());

			if (isCopied) {
				showAlert({
					message: capitalizeFirstLetter(itemName).concat(
						' ',
						COPY_ICON_BUTTON_CONFIG.INFO_ALERT_MESSAGE
					),
					type: 'info'
				});
				return;
			}

			throw new Error('Не удалось скопировать');
		} catch (error) {
			console.error(error);
			showAlert({
				message: capitalizeFirstLetter(itemName).concat(
					' ',
					COPY_ICON_BUTTON_CONFIG.ERROR_ALERT_MESSAGE
				),
				type: 'error'
			});
		}
	};

	return (
		<Tooltip title={config.title ?? ''}>
			<IconButton onClick={handleCopy}>
				<ContentCopyIcon color='warning' fontSize={config.size ? config.size : 'small'}/>
			</IconButton>
		</Tooltip>
	);
};
