import { createContext, useEffect, useState } from 'react';
import { requestErrorWrapper } from '../../../utils/request-error-wrapper/request-error-wrapper';
import { getAboutMeRequest } from '../../../api/api';

const CurrentUserContext = createContext();

const CurrentUserContextProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState({});

	useEffect(() => {
		const getThisUserData = async () => {
			const userData = await requestErrorWrapper({ handleRequest: () => getAboutMeRequest() });

			setCurrentUser(userData);
		};

		getThisUserData();
	}, []);

	return <CurrentUserContext.Provider value={{ currentUser }}>{children}</CurrentUserContext.Provider>;
};

export { CurrentUserContext, CurrentUserContextProvider };
