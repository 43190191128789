import { WrapTechinalTerms } from "../api/api";
import DOMPurify from "dompurify";

export const wrapTechnicalTerms = (text, textSetter) => {
  textSetter(DOMPurify.sanitize(text));
  WrapTechinalTerms(text)
    .then((response) => response.data.technicalTermIndices)
    .then((indices) => {
      let offset = 0;
      const openTag =
        '<span style="font-weight: bold; background: orange; color: whitesmoke; border-radius: 4px; padding: 0 3px 0 3px">';
      const closeTag = "</span>";

      for (const index of indices) {
        const prefix = text.slice(0, index.start + offset);
        const wrappedSubstring = text.slice(
          index.start + offset,
          index.start + offset + index.length
        );
        const postfix = text.slice(index.start + offset + index.length);

        text = `${prefix}${openTag}${wrappedSubstring}${closeTag}${postfix}`;
        offset += openTag.length + closeTag.length;
      }

      textSetter(DOMPurify.sanitize(text));
    });
};
