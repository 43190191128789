import React from 'react';

import { Box, Divider } from '@mui/material';

import { CvWorkInfo } from './cv-work-info';
import { GridContainer } from './cv-details-work-info-styles.js';

export const CvDetailsWorkInfo = ({ cvData }) => {
	return (
		<Box
			sx={{
				display: cvData.workExperience?.length ? '' : 'none'
			}}
		>
			<Divider sx={{ height: 20, m: '0px 0px 20px 0px' }} />
			<GridContainer>
				{cvData.workExperience?.map(item => (
					<CvWorkInfo
						key={item.id}
						id={item.id}
						companyCity={item.companyCity}
						companyName={item.companyName}
						description={item.description}
						duration={item.duration}
						end={item.end}
						isRelevant={item.isRelevant}
						position={item.position}
						start={item.start}
					/>
				))}
			</GridContainer>
		</Box>
	);
};
