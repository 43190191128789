import React, { Fragment } from 'react';

import { TableCell, TableRow, TextField } from '@mui/material';

import { styled } from '@mui/material/styles';

const CustomTableCell = styled(TableCell)({
	minWidth: '150px',
	textAlign: 'center'
});
const boldLabelStyles = {
	fontWeight: 'bold',
	color: 'black'
};

export const UserStatsTableRow = ({ source }) => {
	return (
		<Fragment key={source.id}>
			{source.users ? (
				source.users
					.sort((a, b) => b.count - a.count)
					.map((user, index) => (
						<Fragment key={index}>
							<TableRow>
								<TableCell
									style={{ paddingLeft: '50px' }}
									sx={{
										display: 'flex',
										alignItems: 'center'
									}}
								>
									<span style={{ fontStyle: 'italic' }}>{user.name}</span>
								</TableCell>
								<CustomTableCell>{user.count}</CustomTableCell>
								<CustomTableCell>-</CustomTableCell>
							</TableRow>
							{user.commands
								.sort((a, b) => b.count - a.count)
								.map((command, index) => (
									<TableRow key={index}>
										<TableCell style={{ paddingLeft: '100px' }}>
											{command.name}
										</TableCell>
										<CustomTableCell>{command.count}</CustomTableCell>
										<CustomTableCell>-</CustomTableCell>
									</TableRow>
								))}
						</Fragment>
					))
			) : (
				<TableRow>
					<TableCell colSpan={3}>
						<TextField
							disabled
							fullWidth
							label='Нет данных'
							variant='outlined'
							InputLabelProps={{
								style: boldLabelStyles
							}}
						/>
					</TableCell>
				</TableRow>
			)}
		</Fragment>
	);
};
