import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { ReturnButton } from '../../../lib/ui/buttons/return-button';
import { ResumeFileInput } from '../convert-resume-tool/resume-file-input';
import { ConvertResumeButton } from '../convert-resume-tool/conver-resume-button';
import { CvPublicRating } from './cv-public-rating';
import { DownloadPublicCVButton } from './download-public-cv-button';

import { GeneratePublicCV } from '../../../../api/api';

export const PublicConvertResumeTool = () => {
	const [selectedFile, setSelectedFile] = useState(null);
	const [loading, setLoading] = useState(false);
	const [publicCV, setPublicCV] = useState(null);
	const [checkTokenError, setCheckTokenError] = useState();
	const [processedFileName, setProcessedFileName] = useState(null);
	const [fileName, setFileName] = useState('Файл не выбран');

	const handleFileChange = event => {
		setSelectedFile(event.target.files[0]);
		setFileName(event.target.files[0].name);
	};

	const uploadFile = async () => {
		setLoading(true);

		GeneratePublicCV(selectedFile)
			.then(data => {
				setPublicCV(data);
			})
			.catch(requestError => {
				console.error(requestError.message);
				if (requestError.response.status === 401) {
					localStorage.setItem('tokenError', true);
					setCheckTokenError(!checkTokenError);
				}
			});

		const fileName = selectedFile.name.replace(/\.[^/.]+$/, '');
		const processedFileName = `TechCrew_${fileName}.docx`;
		setProcessedFileName(processedFileName);

		setLoading(false);
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
			<Box sx={{ ml: { xs: 0, sm: 12 }, mt: { xs: 2, sm: 0 } }}>
				{localStorage.getItem('accessToken') && <ReturnButton way={'/tools'} />}
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						mt: 4
					}}
				>
					<Typography variant='h5' sx={{ mb: 2 }}>
						Инструмент конвертирования резюме
					</Typography>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center'
						}}
					>
						<ResumeFileInput
							handleFileChange={handleFileChange}
							setSelectedFile={setSelectedFile}
							selectedFile={selectedFile}
							fileName={fileName}
							setFileName={setFileName}
						/>
						<ConvertResumeButton
							loading={loading}
							uploadFile={uploadFile}
							selectedFile={selectedFile}
						/>
						{publicCV && (
							<DownloadPublicCVButton
								cvId={publicCV.id}
								processedFileName={processedFileName}
							/>
						)}
					</Box>
					{publicCV && <CvPublicRating ratings={publicCV.rating} />}
				</Box>
			</Box>
		</Box>
	);
};
