export const countPercentage = (part, main) => {
	if (main !== 0) {
		const number = (part / main) * 100;
		return number > 100 ? 100 : number;
	}
	return 0;
};

export const calculateWidth = percentage => {
	const maxWidth = 400; // Максимальная ширина в пикселях
	return (maxWidth * Math.log(1 + percentage)) / Math.log(101); // Логарифмическая функция
};
