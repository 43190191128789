import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	FormHelperText,
	Stack,
	TextField,
	Typography
} from '@mui/material';
import { textFieldStyles } from '../../../../../../styles/default-styles/text-field-styles/text-field-styles';
import { boxStyles } from '../../../../../../styles/default-styles/box-styles/box-styles';
import { orange } from '@mui/material/colors';
import { FilterDots } from '../../../../../lib/ui/filter-dots';
import { buttonStyles } from '../../../../../../styles/default-styles/button-styles/button-styles';
import React, { useEffect, useState } from 'react';
import { updateTechnicalTermsRequest } from '../../../../../../api/api';
import { UNRECOGNIZED_STACK_ID } from '../../../../../../constants/constants';

export const TermsModal = ({
	isOpen,
	term,
	filtersByCategory,
	onClose,
	filterList,
	setCheck,
	check
}) => {
	const [value, setValue] = useState(term);
	const [variations, setVariations] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [helperText, setHelperText] = useState(false);
	const [activeFilters, setActiveFilters] = useState({ stack: [] });

	const handleUpdateButtonClick = () => {
		if (!value.term || !!value.term.match(/^\s*$/)) {
			setHelperText(true);
			return;
		}

		setIsLoading(true);

		const stackIds = Object.values(activeFilters)
			.flat()
			.map(subcategoryName => {
				const matchingSubcategory = filtersByCategory
					.flatMap(category => category?.subCategories)
					.find(subcategory => subcategory?.name === subcategoryName);
				return matchingSubcategory ? matchingSubcategory?.id : null;
			});

		const filteredStackIds = stackIds.filter(id => id !== null);

		const filterEmptyStringsAndSpaces = element => {
			return element.trim() !== '';
		};

		const filteredVariations = variations
			.trim()
			.split(/\s*,\s*|\s+(?=,)|(?<=,)\s+/)
			.filter(filterEmptyStringsAndSpaces);

		return updateTechnicalTermsRequest(
			value.id,
			value.term,
			!variations.length ? [] : filteredVariations,
			null,
			!filteredStackIds.length
				? [UNRECOGNIZED_STACK_ID]
				: filteredStackIds.filter(id => id !== UNRECOGNIZED_STACK_ID)
		).then(
			() => {
				setCheck(!check);
				handleClose();
				setIsLoading(false);
			},
			error => {
				console.error('Error updating technical terms:', error);
				setIsLoading(false);
			}
		);
	};

	useEffect(() => {
		setValue(term);
		setVariations(term?.options.join(', '));

		const termStacks = term?.listStackID.map(stackId => {
			const matchingSubcategory = filtersByCategory
				.flatMap(category => category?.subCategories)
				.find(subcategory => subcategory?.id === stackId);
			return matchingSubcategory ? matchingSubcategory?.name : null;
		});

		setActiveFilters({ stack: [...termStacks] });
	}, [term]);

	const handleInputChange = event => {
		setHelperText(false);
		const inputTerm = event.target.value;
		setValue({ ...value, term: inputTerm });
	};

	const handleVariationChange = () => event => {
		setVariations(event.target.value);
	};

	const handleDialogClose = () => {
		handleClose();
		setHelperText(false);
	};

	const handleClose = () => {
		onClose();
		setValue(term);
		setVariations(term?.options.join(', '));
	};

	return (
		<Dialog maxWidth='lg' open={isOpen} onClose={handleDialogClose}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					mt: '20px'
				}}
			>
				<Typography variant='h5'>Редактирование термина</Typography>
				<Typography variant='subtitle1'>
					Отредактируйте термин или укажите варианты через запятую
				</Typography>
			</Box>
			<DialogContent>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '800px',
						pt: '10px'
					}}
				>
					<TextField
						sx={{ ...textFieldStyles.orangeTextField, mb: 2 }}
						fullWidth
						label='Отредактировать термин'
						variant='outlined'
						value={value.term}
						onChange={handleInputChange}
						multiline
						rows={4}
						helperText={
							helperText && (
								<FormHelperText sx={{ color: 'red' }}>
									Нельзя добавлять пустые значения
								</FormHelperText>
							)
						}
					/>
				</Box>
				<Box sx={{ ...boxStyles.flexBoxRow, gap: '10px', mb: 1 }}>
					<Typography
						sx={{
							width: '100%',
							color: activeFilters['stack'] ? 'black' : orange[800],
							display: 'flex',
							alignItems: 'center'
						}}
					>
						{activeFilters['stack']?.join(', ') ||
							'Выберите стек, иначе термин попадет в неизвестные'}
					</Typography>
					<FilterDots
						filterGroup='stack'
						filterList={filterList}
						activeFilters={activeFilters['stack']}
						setActiveFilters={setActiveFilters}
						menuType='stacks'
					/>
				</Box>
				<Divider />
				<Stack spacing={2} direction='column'>
					<TextField
						sx={{ ...textFieldStyles.orangeTextField, mt: '15px' }}
						label={`Варианты: ${value.term}`}
						variant='outlined'
						value={variations && variations.length ? variations : ''}
						onChange={handleVariationChange()}
						fullWidth
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleDialogClose}
					sx={{
						...buttonStyles.orangeButton,
						padding: '8px 16px',
						fontSize: '16px'
					}}
				>
					Отмена
				</Button>
				<Button
					onClick={handleUpdateButtonClick}
					disabled={isLoading}
					sx={{
						...buttonStyles.orangeButton,
						padding: '8px 16px',
						fontSize: '16px'
					}}
				>
					{isLoading ? <CircularProgress size={24} /> : 'Добавить'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
