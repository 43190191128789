import React, { useEffect, useMemo, useState } from 'react';
import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
	Typography
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { TableRowDef } from './table-row-def/table-row-def';

export const TableCustom = ({
	columns = [],
	data = [],
	onRowClick = () => {},
	CustomTableRow = TableRow,
	options = {},
	dataIsLoaded = false,
	itemsCount = 0
}) => {
	// распаковываем объект настроек, задаем значения по умолчанию
	const {
		size = 'medium',
		tableHeight = '50rem',
		tableWidth = '70rem',
		pagination = false,
		rowsPerPage = 20,
		onChangeRowsPerPage = newRowsPerPage => {},
		page = 0,
		onChangePage = newPage => {},
		height = '85%',
		onButtonClick = elementId => {},
		tableStyles = {}
	} = options;

	const [loading, setLoading] = useState(false);
	const [popoverAnchor, setPopoverAnchor] = useState({});

	const handleChangeRowsPerPage = event => {
		onChangeRowsPerPage(parseInt(event.target.value, 10));
	};
	const handleChangePage = (event, newPage) => {
		onChangePage(newPage);
		setLoading(true);
	};

	useEffect(() => {
		dataIsLoaded ? setLoading(false) : setLoading(true);
	}, [dataIsLoaded]);

	// Определяем, является ли текущая страница последней
	const isLastPage = page >= Math.ceil(itemsCount / rowsPerPage) - 1;
	// Определяем, является ли текущая страница первой
	const isFirstPage = page === 0;

	const tableHeaders = useMemo(
		() => (
			<TableHead>
				<TableRow>
					{columns.map(({ id, label, popoverText, align, styles }) => (
						<TableCell key={id} align={align} sx={styles}>
							{popoverText ? (
								<div>
									<Tooltip
										title={popoverText}
										componentsProps={{
											tooltip: {
												sx: {
													color: 'black',
													backgroundColor: 'white',
													maxWidth: '250px',
													fontSize: '1rem',
													boxShadow: '0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
													transition: 'box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
												}
											}
										}}>
										<Typography>{label}</Typography>
									</Tooltip>
								</div>
							) : (
								label
							)}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		),
		[columns, popoverAnchor]
	);

	return (
		<Box sx={{ height: tableHeight }}>
			<TableContainer sx={{ width: tableWidth, height: height, borderRadius: '0px' }} component={Paper}>
				<Table stickyHeader size={size} sx={{ ...tableStyles }}>
					{tableHeaders}
					<TableBody>
						{!dataIsLoaded ? (
							<TableRowDef Element={CircularProgress} colLength={columns.length} color='warning' />
						) : data.length ? (
							data.map(row => (
								<CustomTableRow key={row.id} data={row} onClick={onRowClick} onButtonClick={onButtonClick} />
							))
						) : (
							<TableRowDef Element={Typography} colLength={columns.length} children='Список пуст :(' />
						)}
					</TableBody>
				</Table>
			</TableContainer>
			{pagination && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 20]}
					component='div'
					count={itemsCount}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					nextIconButtonProps={{ disabled: loading || isLastPage }}
					backIconButtonProps={{ disabled: loading || isFirstPage }}
					labelRowsPerPage='Строк на странице'
					labelDisplayedRows={({ from, to, count }) => {
						const pageNumber = Math.ceil(to / rowsPerPage);
						const totalPageNumber = Math.ceil(count / rowsPerPage);
						return `Страница ${pageNumber} из ${totalPageNumber}`;
					}}
				/>
			)}
		</Box>
	);
};
