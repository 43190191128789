import React, { useState } from 'react';

import { ChangeExperienceRequest } from '../../../../../../api/api';

import { Button, Snackbar, Alert } from '@mui/material';

export const ChangeExperienceCV = ({ id, check, setCheck, percent }) => {
	const [openAlert, setOpenAlert] = useState(false);

	const handleDownloadCV = () => {
		ChangeExperienceRequest(id, percent)
			.then(data => {
				if (data.isError) {
					setOpenAlert(true);
				}
				setCheck(!check);
			})
			.catch(requestError => console.error(requestError.message));
	};

	const handleCloseAlert = () => {
		setOpenAlert(false);
	};

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={openAlert}
				autoHideDuration={3000}
				onClose={handleCloseAlert}
			>
				<Alert severity='error'>
					Старт работы приходится на возраст менее 16 лет!
				</Alert>
			</Snackbar>
			<Button
				sx={{ width: '1rem' }}
				variant='contained'
				color='inherit'
				onClick={handleDownloadCV}
			>
				{percent < 0 ? percent : `+${percent}`}
			</Button>
		</>
	);
};
