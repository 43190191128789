import React from 'react';

import { DealTableFormatDate } from '../../../../project-page/project-details-page/project-deal-information/deals-utils/deal-format-date';

import { TableHead, TableRow, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomTableCell = styled(TableCell)({
	minWidth: '150px',
	textAlign: 'center'
});

export const SubscribersTableHead = ({ bot }) => {
	return (
		<TableHead>
			<TableRow sx={{ border: '3px solid #f6b26b', borderRadius: '15px' }}>
				<TableCell
					sx={{ textAlign: 'center', backgroundColor: '#f6b26b' }}
					colSpan={3}
				>
					{bot.name}
				</TableCell>
			</TableRow>
			<TableRow
				sx={{
					backgroundColor: '#e69138',
					borderBottom: 0,
					border: '3px solid #e69138'
				}}
			>
				<TableCell sx={{ backgroundColor: '#e69138', minWidth: '150px' }}>
					Вызвано команд за период
				</TableCell>
				<TableCell
					colSpan={2}
					sx={{
						backgroundColor: '#e69138',
						minWidth: '150px',
						textAlign: 'right'
					}}
				>
					{bot.count}
				</TableCell>
			</TableRow>
			{bot.name !== 'Все боты' && (
				<TableRow
					sx={{
						backgroundColor: '#e69138',
						borderBottom: 0,
						border: '3px solid #e69138'
					}}
				>
					<TableCell sx={{ backgroundColor: '#e69138', minWidth: '150px' }}>
						Дата последнего взаимодействия
					</TableCell>
					<TableCell
						colSpan={2}
						sx={{
							backgroundColor: '#e69138',
							minWidth: '150px',
							textAlign: 'right'
						}}
					>
						{DealTableFormatDate(bot.lastAppeal)}
					</TableCell>
				</TableRow>
			)}
			<TableRow>
				<TableCell>Название источника</TableCell>
				<CustomTableCell>Количество</CustomTableCell>
				<CustomTableCell>Дата последнего взаимодействия</CustomTableCell>
			</TableRow>
		</TableHead>
	);
};
