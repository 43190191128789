import { TermGrid } from '../../../lib/ui/sections/term-grid';
import { Box } from '@mui/material';
import { getAllRequirementsRequest, getCategoriesStructureRequest } from '../../../../api/api';
import { useEffect, useState } from 'react';
import { requestErrorWrapper } from '../../../../utils/request-error-wrapper/request-error-wrapper';
import { parseItCategories, reformatToReqsWithCategories } from './utils/utils';
import {
	postAddSeveralRequirements,
	postDeleteRequirementById,
	postUpdateRequirementById
} from '../../../../api/apiCandidate';
import { DEFAULT_REQUIREMENTS_CONFIG } from './config';

/**
 * Компонент для вкладки Стандартные Требования.
 * Управляет отображением и функциональностью требований.
 */
export const DefaultRequirementsTab = () => {
	const [requirements, setRequirements] = useState([]);
	const [categoriesConfig, setCategoriesConfig] = useState({});

	const paginationConfig = {
		ITEMS_PER_PAGE: 36
	};

	/**
	 * Получает и устанавливает данные о требованиях.
	 */
	const getRequirementsData = async () => {
		const requirementsData = await requestErrorWrapper({
			handleRequest: getAllRequirementsRequest
		});

		if (requirementsData) setRequirements(requirementsData);
	};

	/**
	 * Получает и устанавливает данные об IT-категориях.
	 */
	const getItCategoriesData = async () => {
		const itCategoriesData = await requestErrorWrapper({
			handleRequest: getCategoriesStructureRequest
		});

		const parsedItCategories = parseItCategories(itCategoriesData); // [{ id, categoryName, options }] => { Категории: [{ id, categoryName }] }

		setCategoriesConfig({ filterGroupName: 'stack', filterList: parsedItCategories });
	};

	/**
	 * Удаляет требование по ID.
	 * @param {string} reqId - ID требования для удаления.
	 */
	const deleteRequirement = async reqId => {
		await requestErrorWrapper({
			handleRequest: () => postDeleteRequirementById({ reqId })
		}).then(() => setRequirements(prevReqs => prevReqs.filter(({ id }) => id !== reqId)));
	};

	/**
	 * Добавляет несколько требований.
	 * @param {array} reqs - Добавляемые требования.
	 * @param {array} categoryNames - Названия категорий для требований.
	 */
	const addRequirements = async (reqs, categoryNames) => {
		const reqsWithCategoryIds = reformatToReqsWithCategories(categoryNames, categoriesConfig.filterList, reqs);

		const addedReqs = await requestErrorWrapper({
			handleRequest: () => postAddSeveralRequirements({ newReqs: reqsWithCategoryIds })
		});

		if (addedReqs) setRequirements(prevReqs => [...prevReqs, ...addedReqs]);
	};

	/**
	 * Обновляет требование по ID.
	 * @param {string} reqId - ID требования для обновления.
	 * @param {object} newReqData - Новые данные для требования.
	 * @param {array} categoryNames - Названия категорий для требования.
	 */
	const updateRequirement = async (reqId, newReqData, categoryNames) => {
		const reqsWithCategoryIds = reformatToReqsWithCategories(categoryNames, categoriesConfig.filterList, newReqData);

		const updatedReq = await requestErrorWrapper({
			handleRequest: () => postUpdateRequirementById({ reqId, newReqData: reqsWithCategoryIds[0] })
		});

		if (updatedReq) setRequirements(prevReqs => prevReqs.map(req => (req.id === reqId ? updatedReq : req)));
	};

	/**
	 * Хук useEffect для получения начальных данных при монтировании компонента.
	 */
	useEffect(() => {
		getRequirementsData();
		getItCategoriesData();
	}, []);

	return (
		<Box sx={{ mt: 2, ml: 12, width: '94vw', height: '100%' }}>
			<TermGrid
				values={requirements}
				options={{
					paginationConfig,
					categoriesConfig,
					search: true,
					onDelete: deleteRequirement,
					onAdd: addRequirements,
					onEdit: updateRequirement
				}}
				config={DEFAULT_REQUIREMENTS_CONFIG}
			/>
		</Box>
	);
};
