import React, { useEffect, useState } from 'react';
import {
	Box,
	Dialog,
	DialogContent,
	TextField,
	Stack,
	FormControlLabel,
	Checkbox,
	DialogActions,
	Button,
	CircularProgress,
	Typography,
	FormHelperText
} from '@mui/material';

import { addTechnicalTermsRequest } from '../../../../../../api/api';
import { FilterStacksComponent } from '../../filter-stacks-component';

import { checkBoxStyles } from '../../../../../../styles/default-styles/check-box-styles/check-box-styles';
import { buttonStyles } from '../../../../../../styles/default-styles/button-styles/button-styles';
import { textFieldStyles } from '../../../../../../styles/default-styles/text-field-styles/text-field-styles';
import { boxStyles } from '../../../../../../styles/default-styles/box-styles/box-styles';
import { FilterDots } from '../../../../../lib/ui/filter-dots';
import { orange, red } from '@mui/material/colors';
import { UNRECOGNIZED_STACK_ID } from '../../../../../../constants/constants';

export const AddTermDialog = ({
	isOpen,
	handleClose,
	check,
	setCheck,
	filterList,
	filtersByCategory,
	techTerms
}) => {
	const [terms, setTerms] = useState([]);
	const [variations, setVariations] = useState([]);
	const [isAutoAddEnabled, setIsAutoAddEnabled] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [helperText, setHelperText] = useState(false);
	const [activeFilters, setActiveFilters] = useState({});
	const [isValid, setIsValid] = useState(true);

	// проверка вводимых терминов на валидность
	useEffect(() => {
		setIsValid(
			() =>
				!techTerms.some(termData =>
					terms
						.map(term => term.toLowerCase().replace(/\s/g, ''))
						.includes(termData.term.toLowerCase())
				)
		);
	}, [terms]);

	const handleAddButtonClick = () => {
		if (!terms.length || !terms.find(term => term.trim() !== '')) {
			setHelperText(true);
			return;
		}

		setIsLoading(true);

		// получаем список id фильтров по списку имен фильтров
		const stackIds = Object.values(activeFilters)
			.flat()
			.map(subcategoryName => {
				const matchingSubcategory = filtersByCategory
					.flatMap(category => category.subCategories)
					.find(subcategory => subcategory.name === subcategoryName);
				return matchingSubcategory ? matchingSubcategory.id : null;
			});

		const filteredStackIds = stackIds.filter(id => id !== null);

		const filterEmptyStringsAndSpaces = element => {
			return element.trim() !== '';
		};

		const filteredTerms = terms.filter(filterEmptyStringsAndSpaces);

		const filteredVariations = variations
			.filter(variant => variant !== undefined)
			.map(item => {
				return item.trim().split(/\s*,\s*|\s+(?=,)|(?<=,)\s+/);
			})
			.map(item => item.filter(filterEmptyStringsAndSpaces).join(', '));

		Promise.all(
			filteredTerms.map((term, index) => {
				return addTechnicalTermsRequest({
					newTermName: term.trim(),
					options: !filteredVariations[index]
						? []
						: filteredVariations[index].split(','),
					listStackId:
						filteredStackIds.length > 0
							? filteredStackIds
							: [UNRECOGNIZED_STACK_ID]
				});
			})
		).then(
			() => {
				setCheck(!check);
				resetFields();
				handleClose();
				setIsLoading(false);
			},
			error => {
				console.error('Error adding technical terms:', error);
				setIsLoading(false);
			}
		);
	};

	const resetFields = () => {
		setActiveFilters({});
		setTerms([]);
		setVariations([]);
		setIsAutoAddEnabled(true);
	};

	const handleInputChange = event => {
		setHelperText(false);
		const inputTerms = event.target.value.split(',');
		setTerms(inputTerms);
	};

	const handleVariationChange = index => event => {
		var newVariations = [...variations];
		newVariations[index] = event.target.value;
		setVariations(newVariations);
	};

	const handleCheckboxChange = event => {
		setIsAutoAddEnabled(event.target.checked);
	};

	const handleDialogClose = () => {
		resetFields();
		handleClose();
		setHelperText(false);
	};

	const [anchorEl, setAnchorEl] = useState(null);

	const handleStackFilterClose = () => {
		setAnchorEl(null);
	};

	return (
		<Dialog maxWidth='md' fullWidth open={isOpen} onClose={handleDialogClose}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					mt: '20px'
				}}
			>
				<Typography variant='h5'>Добавление термина</Typography>
				<Typography variant='subtitle1'>
					Добавьте слово или несколько слов через запятую
				</Typography>
			</Box>
			<DialogContent>
				<Box
					sx={{
						...boxStyles.flexBoxColumn,
						width: '100%',
						pt: '10px'
					}}
				>
					<TextField
						sx={
							isValid
								? textFieldStyles.orangeTextField
								: textFieldStyles.redTextField
						}
						fullWidth
						label='Добавить термин'
						variant='outlined'
						onChange={handleInputChange}
						multiline
						rows={4}
					/>
					{helperText && (
						<Typography sx={{ color: red[600], fontSize: '.9rem' }}>
							Нельзя добавлять пустые значения
						</Typography>
					)}
					{!isValid && (
						<Typography sx={{ color: red[600], fontSize: '.9rem' }}>
							Такой термин уже существует!
						</Typography>
					)}
				</Box>
				<Box sx={{ ...boxStyles.flexBoxRow, gap: '10px', mt: 1 }}>
					<Typography
						sx={{
							width: '100%',
							color: activeFilters['stack'] ? 'black' : orange[800],
							display: 'flex',
							alignItems: 'center'
						}}
					>
						{activeFilters['stack']?.join(', ') ||
							'Выберите стек, иначе термин попадет в неизвестные'}
					</Typography>
					<FilterDots
						filterGroup='stack'
						filterList={filterList}
						activeFilters={activeFilters['stack']}
						setActiveFilters={setActiveFilters}
						menuType='stacks'
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center'
					}}
				>
					<FormControlLabel
						control={
							<Checkbox
								sx={{ ...checkBoxStyles.orangeCheckBox }}
								checked={isAutoAddEnabled}
								onChange={handleCheckboxChange}
							/>
						}
						label='Указать варианты терминов'
					/>
				</Box>
				{isAutoAddEnabled && (
					<Stack spacing={2} direction='column'>
						{terms.map((term, index) => (
							<TextField
								sx={{ ...textFieldStyles.orangeTextField }}
								key={index}
								label={`Варианты: ${term.trim()}`}
								variant='outlined'
								value={variations[index] || ''}
								onChange={handleVariationChange(index)}
								fullWidth
							/>
						))}
						<FilterStacksComponent
							anchorEl={anchorEl}
							handleClose={handleStackFilterClose}
						/>
					</Stack>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleDialogClose}
					sx={{
						...buttonStyles.orangeButton,
						padding: '8px 16px',
						fontSize: '16px'
					}}
				>
					Отмена
				</Button>
				<Button
					onClick={handleAddButtonClick}
					disabled={!!isLoading || !isValid}
					sx={{
						...buttonStyles.orangeButton,
						padding: '8px 16px',
						fontSize: '16px'
					}}
				>
					{isLoading ? <CircularProgress size={24} /> : 'Добавить'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
