export const processCheckListFilter = (filterGroup, currentFilter, event, setActiveFilters) => {
    setActiveFilters(legacyActiveFilters => {
        const updatedActiveFilters = {...legacyActiveFilters};
        if (
            legacyActiveFilters &&
            Array.isArray(updatedActiveFilters[filterGroup])
        ) {
            if (!updatedActiveFilters[filterGroup].includes(currentFilter)) {
                updatedActiveFilters[filterGroup] = [
                    ...updatedActiveFilters[filterGroup],
                    currentFilter
                ];
            } else {
                const filtersWithoutCurrentFilter = updatedActiveFilters[
                    filterGroup
                    ].filter(filter => filter !== currentFilter);

                if (filtersWithoutCurrentFilter.length > 0) {
                    updatedActiveFilters[filterGroup] = filtersWithoutCurrentFilter;
                } else {
                    delete updatedActiveFilters[filterGroup];
                }
            }
        } else {
            updatedActiveFilters[filterGroup] = [currentFilter];
        }
        return updatedActiveFilters;
    });

    event.stopPropagation();
};