export const MODAL_SPLIT_INPUT_CONFIG = {
	TITLE: 'Заголовок',
	LABEL: 'Значения',
	PLACEHOLDER: 'Введите значения',
	BUTTON_LABEL: 'Обработка',
	SPLIT_REGEX_ERROR: 'Ошибка разбиения входной строки',
	HANDLE_VALUES_ERROR: 'Ошибка обработки значений',
	INVALID_MESSAGE: 'Данные не прошли валидацию',
	REPLACE_STRING: ''
};

export const rowsCount = size => {
	switch (size) {
		case 'fs':
			return 28;
		case 'fsy':
			return 28;
		case 'lg':
			return 17;
		case 'md':
			return 12;
	}
};
