import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { PasswordInput } from "../password-input";

export const RegistrationForm = ({
  Register,
  showRegistration,
  setShowRegistration,
}) => {
  const [checkButton, setCheckButton] = useState(false);

  const [nameData, setNameData] = useState("");
  const [emailData, setEmailData] = useState("");
  const [passwordData, setPasswordData] = useState("");
  const [passwordReplayData, setPasswordReplayData] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [emailHelper, setEmailHelper] = useState("");
  const [passwordHelper, setPasswordHelper] = useState("");

  const [approval, setApproval] = useState(false);

  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    if (
      nameData !== "" &&
      emailData !== "" &&
      passwordData !== "" &&
      passwordReplayData !== "" &&
      approval === true
    ) {
      setCheckButton(true);
    } else {
      setCheckButton(false);
    }
  }, [approval, emailData, nameData, passwordData, passwordReplayData]);

  const handleChangeName = (event) => {
    const temp = event.target.value;
    setNameData(temp.replaceAll(/^[\s.`":'$@~;]*$/g, ""));
  };

  const handleChangeEmail = (event) => {
    const temp = event.target.value;
    setEmailData(temp.replaceAll(/^[\s.`":'$@~;]*$/g, ""));
  };

  const handleApproval = () => {
    setApproval(!approval);
  };

  const passwordVilidate = () => {
    if (passwordData === passwordReplayData) {
      if (passwordData.length > 6) {
        setPasswordError(false);
        setPasswordHelper("");
        return true;
      } else {
        setPasswordError(true);
        setPasswordHelper("Пароль меньше 8 символов");
        return false;
      }
    } else {
      setPasswordError(true);
      setPasswordHelper("Пароли не совпадают");
      return false;
    }
  };

  const emailValidate = () => {
    if (re.test(emailData)) {
      setEmailError(false);
      setEmailHelper("");
      return true;
    } else {
      setEmailError(true);
      setEmailHelper("Неверный формат почты");
      return false;
    }
  };

  const handleRegister = () => {
    let emailCheck = emailValidate();
    let passwordCheck = passwordVilidate();
    if (emailCheck && passwordCheck) {
      Register(emailData, nameData, passwordData);
    }
  };
  return (
    <>
      <Stack spacing={3}>
        <Typography
          id="keep-mounted-modal-title"
          variant="h6"
          component="h2"
          align="center"
          sx={{
            fontSize: "30px",
          }}
        >
          Регистрация
        </Typography>
        <TextField
          name="name"
          type="text"
          label="Имя и Фамилия"
          value={nameData}
          onChange={handleChangeName}
        />
        <TextField
          name="email"
          type="email"
          label="Электронная почта"
          error={emailError}
          helperText={emailHelper}
          value={emailData}
          onChange={handleChangeEmail}
        />

        <PasswordInput
          id="new-password"
          name="password"
          complete="new-password"
          label="Пароль"
          setData={setPasswordData}
          errorText={passwordError}
          helperText={passwordHelper}
        />

        <PasswordInput
          id="new-password-replay"
          name="password"
          complete="new-password"
          label="Повторите пароль"
          setData={setPasswordReplayData}
          errorText={passwordError}
          helperText={passwordHelper}
        />

        <FormControlLabel
          control={
            <Checkbox
              value={approval}
              onChange={handleApproval}
              color="default"
            />
          }
          label="Я соглашаюсь на обработку персональных данных"
        />
        <Button
          disabled={!checkButton}
          variant="contained"
          sx={{
            fontSize: 20,
            color: "#696969",
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "#EEEEED",
            },
          }}
          onClick={handleRegister}
        >
          Зарегистрироваться
        </Button>
      </Stack>
      <Box sx={{ display: "flex", mt: 1 }}>
        Уже есть профиль?
        <Box
          sx={{
            ml: 1,
            color: "#696969",
            cursor: "pointer",
            "&:hover": {
              color: "#b9b9b9",
            },
          }}
          onClick={() => setShowRegistration(!showRegistration)}
        >
          Войти
        </Box>
      </Box>
    </>
  );
};
