import {Box, LinearProgress, TableCell, TableRow, Typography} from "@mui/material";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";

export const CvRatingModalTableRow = ({ objKey, rating, handleOpenElement, handleCloseElement, expandedRows  }) => {
    const [expandedIsOpen, setExpandedIsOpen] = useState(false);

    const CustomTableRow = styled(TableRow)({
        "&:hover": {
            cursor: "pointer",
        },
    });

    const onOpenElement = () => {
        setExpandedIsOpen(true);
        handleOpenElement(objKey);
    }

    const onCLoseElement = () => {
        setExpandedIsOpen(false);
        handleCloseElement(objKey);
    }

    return (
    <CustomTableRow
        onClick={() =>
            expandedRows.length
                ? expandedRows.find((key) => key === objKey)
                    ? onCLoseElement()
                    : onOpenElement()
                : onOpenElement()
        }
        key={objKey}
    >
        <TableCell align="center">
            <Typography
                sx={{
                    fontSize: "18px",
                }}
            >
                {rating.name}
            </Typography>
        </TableCell>
        <TableCell align="center">
            <Typography
                sx={{
                    fontSize: "18px",
                }}
            >
                {rating.comment}
            </Typography>
        </TableCell>
        <TableCell align="center">
            <Typography
                sx={{
                    fontSize: "18px",
                }}
            >
                {expandedIsOpen ? <KeyboardArrowUpRoundedIcon color='warning'/> : <KeyboardArrowDownRoundedIcon />}
            </Typography>
        </TableCell>
        <TableCell align="center">
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box sx={{ width: "100px" }}>
                    {rating.value < 0 && (
                        <LinearProgress
                            variant="determinate"
                            color="error"
                            sx={{
                                transform: "scaleX(-1)",
                                height: "20px",
                                width: "100px",
                                backgroundColor: "transparent",
                            }}
                            value={
                                Math.abs(rating.value) > 100
                                    ? 100
                                    : Math.abs(rating.value)
                            }
                        />
                    )}
                </Box>
                <Box sx={{ width: "50px" }}>
                    <Typography
                        sx={{
                            fontSize: "18px",
                        }}
                    >
                        {rating.value}
                    </Typography>
                </Box>
                <Box sx={{ width: "100px" }}>
                    {rating.value > 0 && (
                        <LinearProgress
                            variant="determinate"
                            color="success"
                            sx={{
                                height: "20px",
                                width: "100px",
                                ml: "5px",
                                backgroundColor: "transparent",
                            }}
                            value={
                                rating.value > 100
                                    ? 100
                                    : rating.value
                            }
                        />
                    )}
                </Box>
            </Box>
        </TableCell>
    </CustomTableRow>
    )
}