import React, { useState } from "react";

import {
  Dialog,
  Button,
  IconButton,
  Paper,
  FormControl,
  Typography,
  OutlinedInput,
  InputAdornment,
  Box,
  Stack,
} from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";

export const ExperienceMenu = ({ setExperienceData }) => {
  const [open, setOpen] = useState(false);

  const [termYear, setTermYear] = useState(0);
  const [termMonth, setTermMonth] = useState(0);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeYear = (event) => {
    const temp = event.target.value;
    setTermYear(temp.replace(/^[\s.`":'$@~;]*$/g, ""));
  };

  const handleChangeMonth = (event) => {
    const temp = event.target.value;
    setTermMonth(temp.replace(/^[\s.`":'$@~;]*$/g, ""));
  };

  const handleChange = () => {
    if (termYear === "" && termMonth === "") {
      setExperienceData(null);
    } else {
      setExperienceData({
        year: termYear,
        months: termMonth,
      });
      setTermYear(0);
      setTermMonth(0);
    }
    setOpen(false);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ p: 0 }}
      >
        <MoreVertIcon sx={{ fontSize: 25 }} />
      </IconButton>
      <Dialog onClose={handleClose} open={open}>
        <Paper
          component="form"
          elevation={6}
          sx={{
            display: "flex",
            alignItems: "center",
            minWidth: "200px",
            p: 2,
          }}
        >
          <Stack spacing={3}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
              align="center"
              sx={{
                fontSize: "25px",
              }}
            >
              Опыт работы
            </Typography>
            <Box>
              <FormControl sx={{ width: "20ch" }} variant="outlined">
                <OutlinedInput
                  required
                  id="input-year"
                  value={termYear}
                  color="warning"
                  onChange={handleChangeYear}
                  endAdornment={
                    <InputAdornment position="end">Лет</InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>
              <FormControl sx={{ ml: 1, width: "20ch" }} variant="outlined">
                <OutlinedInput
                  required
                  value={termMonth}
                  color="warning"
                  onChange={handleChangeMonth}
                  id="input-mouth"
                  endAdornment={
                    <InputAdornment position="end">Месяцев</InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>
            </Box>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                bgcolor: "#ef6c00",
                borderRadius: "3%",
                mt: "10px",
                color: "#FFFFFF",
                fontWeight: "bold",
                mr: 1,
                "&:hover": {
                  bgcolor: "#ff9800",
                  color: "#FFFFFF",
                  transform: "scale(1.03)",
                },
              }}
              onClick={handleChange}
            >
              Подтвердить
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                bgcolor: "#ef6c00",
                borderRadius: "3%",
                mt: "10px",
                color: "#FFFFFF",
                fontWeight: "bold",
                mr: 1,
                "&:hover": {
                  bgcolor: "#ff9800",
                  color: "#FFFFFF",
                  transform: "scale(1.03)",
                },
              }}
              onClick={() => {
                setExperienceData({
                  year: -1,
                  months: -1,
                });
                setOpen(false);
              }}
            >
              Опыт работы не известен
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                bgcolor: "#ef6c00",
                borderRadius: "3%",
                mt: "10px",
                color: "#FFFFFF",
                fontWeight: "bold",
                mr: 1,
                "&:hover": {
                  bgcolor: "#ff9800",
                  color: "#FFFFFF",
                  transform: "scale(1.03)",
                },
              }}
              onClick={() => {
                setExperienceData({
                  year: 0,
                  months: 0,
                });
                setOpen(false);
              }}
            >
              Нет опыта работы
            </Button>
          </Stack>
        </Paper>
      </Dialog>
    </>
  );
};
