import { TERM_GRID_FILTER_CONFIG } from '../config';

// Метод парсинга входящего ОБЪЕКТА списка фильтров для компонента FilterDots => FilterMenu
// { categoryName: [{ id, stackName }] } => { categoryName: ['stackName'] }
export const parseObjectFilterList = filterList => {
	try {
		if (typeof filterList === 'object') {
			return Object.keys(filterList).reduce((acc, categoryName) => {
				acc[categoryName] = filterList[categoryName].map(({ name }) => name);
				return acc;
			}, {});
		}
	} catch (error) {
		console.error(TERM_GRID_FILTER_CONFIG.ERRORS.OBJECT_PARSING, error);
		return {};
	}
};
