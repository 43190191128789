import React, { useEffect, useState } from 'react';

import { Table, TableContainer, Paper } from '@mui/material';

import { RecruiterStatsTableHead } from './recruiter-stats-table-head/recruiter-stats-table-head';
import { RecruiterStatsTableBody } from './recruiter-stats-table-body';

import { GetRecruiterStatisticsData } from '../../../../../api/apiUsers';

export const RecruiterStatsTable = ({ startDate, endDate }) => {
	const [statisticData, setStatisticData] = useState([]);

	useEffect(() => {
		if (startDate && endDate) {
			GetRecruiterStatisticsData(startDate, endDate)
				.then(response => {
					setStatisticData(response);
				})
				.catch(error => {
					console.error('Ошибка при получении данных о недоступных:', error);
				});
		}
	}, [startDate, endDate]);

	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} aria-label='simple table'>
				<RecruiterStatsTableHead statisticData={statisticData} />
				<RecruiterStatsTableBody statisticData={statisticData} />
			</Table>
		</TableContainer>
	);
};
