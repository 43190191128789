import React, { useState } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import 'moment/locale/ru';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pickersLayoutClasses } from '@mui/x-date-pickers/PickersLayout';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ruRU } from '@mui/x-date-pickers/locales';

const REQUEST_DATE_FORMAT = 'YYYY-MM-DD';

export const DateFilterFromBefore = ({ setStartDate, setEndDate, minStartDate, calendarSize="medium" }) => {

	const [term1, setTerm1] = useState(moment().startOf('month'));
	const [term2, setTerm2] = useState(null);

	const [openCalendar1, setOpenCalendar1] = useState(false);
	const [openCalendar2, setOpenCalendar2] = useState(false);


	const handleChange1 = (filter1) => {
		const formattedFromDate = filter1.format(REQUEST_DATE_FORMAT);

		setTerm1(filter1);
		setStartDate(formattedFromDate);
	};

	const handleChange2 = (filter2) => {
		const formattedFromDate = filter2.format(REQUEST_DATE_FORMAT);

		setTerm2(filter2);
		setEndDate(formattedFromDate);
	};

	const handleReset = () => {
		setTerm1(null);
		setTerm2(null);
		setStartDate(minStartDate);
		setEndDate(moment().format(REQUEST_DATE_FORMAT));
	};

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Box sx={{ pr: 2, pl: 2, width: '160px', display: 'flex' }}>
					<LocalizationProvider
						dateAdapter={AdapterMoment}
						adapterLocale="ru"
						localeText={
							ruRU.components.MuiLocalizationProvider.defaultProps.localeText
						}
					>
						<Box components={['DatePicker']}>
							<DatePicker
								label="От"
								closeOnSelect={false}
								views={['year', 'month', 'day']}
								open={openCalendar1}
								onOpen={() => setOpenCalendar1(true)}
								onClose={() => setOpenCalendar1(false)}
								value={term1 || null}
								minDate={moment(minStartDate)}
								maxDate={term2 ? term2 : moment(new Date().setDate(new Date().getDate()))}
								onChange={(newValue) => handleChange1(newValue)}
								slotProps={{
									textField: {
										color: 'warning',
										size: `${calendarSize}`
									},
									toolbar: { hidden: false },
									layout: {
										sx: {
											[`.${pickersLayoutClasses.toolbar}`]: {
												color: 'white',
												backgroundColor: '#fb8c00',
												'& .MuiTypography-root ': {
													color: 'white'
												}
											},
											[`.${pickersLayoutClasses.contentWrapper}`]: {
												'& .Mui-selected': {
													backgroundColor: '#fb8c00',
													borderColor: '#fb8c00',
													color: 'white'
												},
												'& .MuiTabs-indicator': { bgcolor: '#f57c00' },
												'& .MuiClock-pin': { bgcolor: '#f57c00' },
												'& .MuiClockPointer-root': { bgcolor: '#f57c00' },
												'& .MuiClockPointer-thumb': {
													borderColor: '#f57c00'
												},
												'& .Mui-selected:hover': {
													backgroundColor: '#fb8c00',
													borderColor: '#fb8c00',
													color: 'white'
												},
												'& .Mui-selected:focus': {
													backgroundColor: '#fb8c00',
													borderColor: '#fb8c00',
													color: 'white'
												}
											}
										}
									}
								}}
								sx={{
									svg: { color: '#fb8c00' },
									'& .MuiInputLabel-root.Mui-focused': { color: '#fb8c00' }, //styles the label
									'& .MuiInput-underline:after': {
										borderBottomColor: '#fb8c00'
									}
								}}
							/>
						</Box>
					</LocalizationProvider>
				</Box>
				<Typography variant="body1" sx={{ color: '#ddd' }}>—</Typography>
				<Box sx={{ pr: 2, pl: 2, width: '160px', display: 'flex' }}>
					<LocalizationProvider
						dateAdapter={AdapterMoment}
						adapterLocale="ru"
						localeText={
							ruRU.components.MuiLocalizationProvider.defaultProps.localeText
						}
					>
						<Box components={['DatePicker']}>
							<DatePicker
								label="До"
								closeOnSelect={false}
								views={['year', 'month', 'day']}
								open={openCalendar2}
								onOpen={() => setOpenCalendar2(true)}
								onClose={() => setOpenCalendar2(false)}
								value={term2 || null}
								minDate={term1 ? term1 : moment(minStartDate)}
								maxDate={moment(new Date().setDate(new Date().getDate()))}
								onChange={(newValue) => handleChange2(newValue)}
								slotProps={{
									textField: {
										color: 'warning',
										size: `${calendarSize}`
									},
									toolbar: { hidden: false },
									layout: {
										sx: {
											[`.${pickersLayoutClasses.toolbar}`]: {
												color: 'white',
												backgroundColor: '#fb8c00',
												'& .MuiTypography-root ': {
													color: 'white'
												}
											},
											[`.${pickersLayoutClasses.contentWrapper}`]: {
												'& .Mui-selected': {
													backgroundColor: '#fb8c00',
													borderColor: '#fb8c00',
													color: 'white'
												},
												'& .MuiTabs-indicator': { bgcolor: '#f57c00' },
												'& .MuiClock-pin': { bgcolor: '\'#f57c00\' ' },
												'& .MuiClockPointer-root': { bgcolor: '#f57c00' },
												'& .MuiClockPointer-thumb': {
													borderColor: '#f57c00'
												},
												'& .Mui-selected:hover': {
													backgroundColor: '#fb8c00',
													borderColor: '#fb8c00',
													color: 'white'
												},
												'& .Mui-selected:focus': {
													backgroundColor: '#fb8c00',
													borderColor: '#fb8c00',
													color: 'white'
												}
											}
										}
									}
								}}
								sx={{
									svg: { color: '#fb8c00' },
									'& .MuiInputLabel-root.Mui-focused': { color: '#fb8c00' }, //styles the label
									'& .MuiInput-underline:after': {
										borderBottomColor: '#fb8c00'
									}
								}}
							/>
						</Box>
					</LocalizationProvider>
				</Box>
			</Box>
			<Tooltip title="Сбросить диапазон">
				<IconButton sx={{ alignSelf: 'center' }} onClick={handleReset}>
					<ClearIcon
						sx={{
							color: term1 || term2 ? '#ef6c00' : 'inherit'
						}}
					/>
				</IconButton>
			</Tooltip>
		</Box>
	);
};