import React from 'react';

export const EditableHtmlField = ({ onChange, editableFieldRef, handleAddReqModalOpen }) => {
	const handleChange = e => {
		onChange(e.target.innerText);
	};

	const handleClick = e => {
		if (e.target.localName === 'input') {
			handleAddReqModalOpen(e.target.alt);
		}
	};

	return (
		<div
			ref={editableFieldRef}
			style={{
				width: '100%',
				height: '100vh',
				maxHeight: '70vh',
				border: '1px solid gray',
				boxSizing: 'border-box',
				padding: '10px',
				overflowY: 'scroll',
				outline: 'none',
				fontSize: '16px',
				lineHeight: '25px'
			}}
			contentEditable={true}
			suppressContentEditableWarning={true}
			onInput={handleChange}
			onClick={handleClick}
		/>
	);
};
