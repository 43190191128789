import React from 'react';
import { Box, Card } from '@mui/material';
import { TermActionsButtons } from '../terms-action-buttons';

export const TermsCard = ({ row, check, setCheck, onCardClick }) => {
	return (
		<Card
			elevation={3}
			sx={{
				bgcolor: '#FFA500',
				width: '100%',
				height: `70px`,
				transition: 'all 0.3s ease-in-out',
				'&:hover': {
					transform: 'scale(1.02)',
					bgcolor: '#ffae19'
				},
				p: '0 2px',
				cursor: 'pointer'
			}}
			onClick={() => onCardClick(row)}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					height: '100%'
				}}
			>
				<Box
					p={row?.term?.length > 43 ? '5px' : 2}
					sx={{
						color: '#ffffff',
						fontWeight: 'bold',
						alignSelf: row?.term?.length > 43 ? 'flex-start' : 'inherit'
					}}
				>
					{row?.term}
				</Box>
				<TermActionsButtons term={row} check={check} setCheck={setCheck} />
			</Box>
		</Card>
	);
};
