import React from "react";

import { TableHead, TableRow, TableCell } from "@mui/material";

export const UserBotsTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Наименование</TableCell>
        <TableCell>Статус</TableCell>
        <TableCell>В бане до</TableCell>
        <TableCell>В бане с</TableCell>
        <TableCell>Тип</TableCell>
        <TableCell>Добавлен</TableCell>
        <TableCell>Срок жизни</TableCell>
      </TableRow>
    </TableHead>
  );
};
