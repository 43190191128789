import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const sizes = {
	height: {
		md: 500,
		lg: 600,
		fsy: '90%', // vert fullscreen
		fs: '90%' // fullscreen
	},
	width: {
		md: '30%',
		lg: '45%',
		fsy: '45%', // vert fullscreen
		fs: '90%' // fullscreen
	}
};

export const ModalContainer = styled(Box)(({ theme, styles, size }) => ({
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: `translate(-50%, -50%)`,
	backgroundColor: '#fff',
	boxShadow: '0 0 15px rgba(0, 0, 0, 0.3)',
	padding: '1.5rem',
	borderRadius: '1rem',
	overflow: 'hidden',
	width: sizes.width[size],
	height: sizes.height[size],
	minWidth: 400,
	minHeight: 400,
	...styles
}));
