import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCell = styled(TableCell)(({ theme, data }) => ({
	writingMode:
		data.flatMap(stat => stat.statistics).length > 50 && 'vertical-rl',
	transform:
		data.flatMap(stat => stat.statistics).length > 50 && 'rotate(215deg)',
	borderRight: '1px solid #efefef',
	fontSize: '.9rem',
	textAlign: 'center',
	height: 'min-content',
	padding: 3
}));
