import React, { useState, useEffect } from 'react';

import { getCommunicationStatusRequest } from '../../../../../api/api';

import { Box, Divider, Typography, Paper, MenuItem, Menu } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { MessageAlert } from '../../../../lib/ui/alerts/message-alert';

export const CommunicationStatusField = ({
	textName,
	textstaff,
	setData,
	rateData
}) => {
	const [term, setTerm] = useState('');

	const [status, setStatus] = useState([]);

	const [anchorEl, setAnchorEl] = useState(null);
	const [alertIsOpen, setAlertIsOpen] = useState(false);

	useEffect(() => {
		getCommunicationStatusRequest()
			.then(data => {
				setStatus(data);
			})
			.catch(requestError => console.error(requestError.message));
	}, []);

	useEffect(() => {
		if (textstaff !== null && textstaff !== undefined) {
			const filteredResult = [];
			for (let i = 0; i < status.length; i++) {
				if (status[i].id === textstaff) {
					filteredResult.push(status[i]);
				}
			}
			setTerm(filteredResult[0]?.name);
		}
	}, [textstaff, status]);

	const handleChange = status => {
		setAlertIsOpen(status.id === 4 && rateData === 0);
		setTerm(status.name);
		setData(status.id);
		handleClose();
	};

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Paper
			elevation={6}
			sx={{ display: 'flex', alignItems: 'center', minHeight: '32px' }}
		>
			<Typography
				id='keep-mounted-modal-title'
				variant='h6'
				component='h2'
				sx={{
					display: 'flex',
					height: '100%',
					fontSize: '15px',
					bgcolor: '#fb8c00',
					color: '#852508',
					border: '1px solid',
					minWidth: '110px',
					textAlign: 'center',
					borderRadius: '4px',
					borderColor: '#a2a2a2',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				{textName}
			</Typography>
			<Divider sx={{ height: 24, ml: 0.5 }} orientation='vertical' />

			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					flexGrow: 1,
					cursor: 'pointer'
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						flexGrow: 1
					}}
					onClick={handleClick}
				>
					<Box sx={{ flexGrow: 1, userSelect: 'none', width: '100px' }}>
						<Typography
							id='keep-mounted-modal-title'
							sx={{ p: '2px 4px', ml: 1, fontSize: '15px' }}
						>
							{term?.slice(0, 28)}
							{term?.length > 28 ? '...' : ''}
						</Typography>
					</Box>
					<Box>
						<Box size='small' sx={{ color: '#696969', cursor: 'pointer' }}>
							{anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
						</Box>
					</Box>
				</Box>

				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleClose}
					sx={{ maxHeight: '500px' }}
				>
					{status?.map(row => (
						<MenuItem key={row.id} onClick={() => handleChange(row)}>
							{row.name}
						</MenuItem>
					))}
				</Menu>
			</Box>
			<MessageAlert
				open={alertIsOpen}
				type='warning'
				message='Для отображения кандидата в бенч карте - укажите рейт!'
				duration={10000}
				setIsOpen={setAlertIsOpen}
			/>
		</Paper>
	);
};
