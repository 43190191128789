import React, { useState } from 'react';
import {
	TextField,
	InputAdornment,
	IconButton,
	Divider
} from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { textFieldStyles } from '../../../../../styles/default-styles/text-field-styles/text-field-styles';

export const SearchTechnicalTerminologyBar = ({ setSearchTerm }) => {
	const [value, setValue] = useState('');

	const handleSearchChange = event => {
		setSearchTerm(event.target.value);
		setValue(event.target.value);
	};

	const handleClearInput = () => {
		setValue('');
		setSearchTerm('');
	};

	return (
		<TextField
			label='Поиск'
			size="small"
			variant='outlined'
			value={value}
			onChange={handleSearchChange}
			sx={{ ...textFieldStyles.orangeTextField }}
			InputProps={{
				endAdornment: (
					<InputAdornment position='end'>
						<Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
						<IconButton aria-label='delete' onClick={handleClearInput}>
							<BackspaceIcon />
						</IconButton>
					</InputAdornment>
				)
			}}
		/>
	);
};
