import { orange, red } from '@mui/material/colors';

export const textFieldStyles = {
	orangeTextField: {
		'& label.Mui-focused': {
			color: orange[800] // Оранжевый цвет для фокуса
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: orange[800] // Оранжевый цвет после фокуса
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: orange[800] // Оранжевый цвет для обводки
			},
			'&:hover fieldset': {
				borderColor: orange[800] // Оранжевый цвет при наведении
			},
			'&.Mui-focused fieldset': {
				borderColor: orange[800] // Оранжевый цвет после фокуса
			}
		}
	},
	redTextField: {
		'& label.Mui-focused': {
			color: red[600] // Оранжевый цвет для фокуса
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: red[600] // Оранжевый цвет после фокуса
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: red[600] // Оранжевый цвет для обводки
			},
			'&:hover fieldset': {
				borderColor: red[600] // Оранжевый цвет при наведении
			},
			'&.Mui-focused fieldset': {
				borderColor: red[600] // Оранжевый цвет после фокуса
			}
		}
	}
};
