import React from 'react';
import { ProgressElementStyled, ProgressLabel } from './bench-map-progress-bar-styles';
import { boxStyles } from '../../../../../../styles/default-styles/box-styles/box-styles';
import { Box } from '@mui/material';

export const BenchMapProgressBar = ({ config = [], options = {} }) => {
	const { fullWidth } = options;

	return (
		<Box sx={boxStyles.flexBoxRow} width={fullWidth || 'auto'} position='relative'>
			{config.map(({ id, value, styles, label }) => (
				<ProgressElementStyled sx={styles} width={value}>
					<ProgressLabel key={id} widthpercent={value}>
						{label ?? value}
					</ProgressLabel>
				</ProgressElementStyled>
			))}
		</Box>
	);
};
