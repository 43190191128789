import { orange } from "@mui/material/colors";

export const checkBoxStyles = {
  orangeCheckBox: {
    color: orange[800],
    "&.Mui-checked": {
      color: orange[600],
    },
  },
};
