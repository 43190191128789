import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { boxStyles } from '../../../../../styles/default-styles/box-styles/box-styles';

export const HeaderContainer = styled(Box)(() => ({
	...boxStyles.flexBoxRow,
	width: '100%',
	alignItems: 'center',
	padding: '1rem 0',
	justifyContent: 'space-between',
	columnGap: '10rem',
	'@media screen and (max-width: 1450px)': {
		flexDirection: 'column',
		justifyContent: 'center',
		rowGap: '1rem'
	}
}));

export const ButtonPanel = styled(Box)(() => ({
	...boxStyles.flexBoxRow,
	alignItems: 'center',
	gap: '1rem',
	justifyContent: 'space-between',
	'@media screen and (max-width: 800px)': {
		flexDirection: 'column'
	}
}));

export const GridContainer = styled(Box)(() => ({
	display: 'grid',
	gridTemplateColumns: 'repeat(8, 1fr)',
	columnGap: '1.5rem',
	rowGap: '.15rem',
	padding: '0.15rem 0',
	'@media screen and (max-width: 1450px)': {
		gridTemplateColumns: 'repeat(4, 1fr)'
	},
	'@media screen and (max-width: 1000px)': {
		gridTemplateColumns: 'repeat(4, 1fr)'
	},
	'@media screen and (max-width: 800px)': {
		gridTemplateColumns: 'repeat(2, 1fr)'
	}
}));
