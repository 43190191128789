import React from 'react';

export const TableRowDef = ({ Element, colLength, children, ...elProps }) => {
	return (
		<tr>
			<th colSpan={colLength} style={{ paddingTop: '2.5rem' }}>
				<Element {...elProps}>{children}</Element>
			</th>
		</tr>
	);
};
