import React from 'react';
import { Box, Table } from '@mui/material';

import { SubscribersTableHead } from './subscribers-table-head';
import { SubscribersTableBody } from './subscribers-table-body/subscribers-table-body';

export const AdminSubscribersBotTable = ({ bot }) => {
	return (
		<Box
			sx={{
				padding: '10px 20px 20px 20px',
				borderRadius: '15px',
				border: '2px solid #ebebeb',
				display: 'flex',
				justifyContent: 'center',
				width: '50vw'
			}}
		>
			<Table
				sx={{
					width: '100%',
					borderCollapse: 'collapse'
				}}
			>
				<SubscribersTableHead bot={bot} />
				<SubscribersTableBody bot={bot} />
			</Table>
		</Box>
	);
};
