import React, { useState, useEffect } from "react";
import { getCategoriesStructureRequest } from "../../../../../api/api";

import {
  MenuItem,
  Divider,
  Typography,
  Paper,
  Box,
  Menu,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export const CandidateSelectCard = ({ textName, textstaff, setData }) => {
  const [term, setTerm] = useState("");
  const [stacks, setStacks] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (textstaff !== null && textstaff !== undefined) {
      setTerm(textstaff);
    }
  }, [textstaff]);

  useEffect(() => {
    getCategoriesStructureRequest()
      .then((data) => {
        setStacks(data);
      })
      .catch((requestError) => console.error(requestError.message));
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (subStack) => {
    setData(subStack.id);
    setTerm(subStack.name);
  };

  return (
    <Paper
      elevation={6}
      sx={{ display: "flex", alignItems: "center", minHeight: "32px" }}
    >
      <Typography
        id="keep-mounted-modal-title"
        variant="h6"
        component="h2"
        sx={{
          display: "flex",
          height: "100%",
          fontSize: "15px",
          bgcolor: "#fb8c00",
          color: "#852508",
          border: "1px solid",
          minWidth: "110px",
          textAlign: "center",
          borderRadius: "4px",
          borderColor: "#a2a2a2",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {textName}
      </Typography>
      <Divider sx={{ height: 24, ml: 0.5 }} orientation="vertical" />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
          }}
          onClick={handleClick}
        >
          <Box sx={{ flexGrow: 1, userSelect: "none" }}>
            <Typography
              id="keep-mounted-modal-title"
              sx={{ p: "2px 4px", ml: 1, fontSize: "15px" }}
            >
              {term}
            </Typography>
          </Box>
          <Box>
            <Box size="small" sx={{ color: "#696969", cursor: "pointer" }}>
              {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Box>
          </Box>
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{ maxHeight: "840px" }}
        >
          {stacks?.map((stack) => (
            <MenuItem
              key={stack.id}
              onClick={handleClose}
              sx={{ pt: 0, pb: 0 }}
            >
              <Typography variant="h6">{stack.name}:</Typography>
              <List sx={{ display: "flex", flexWrap: "wrap" }}>
                {stack.subCategories.map((subStack) => (
                  <ListItem
                    key={subStack.id}
                    onClick={() => handleChange(subStack)}
                    sx={{ width: "auto", px: 2 }}
                  >
                    <ListItemText primary={subStack.name} />
                  </ListItem>
                ))}
              </List>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Paper>
  );
};
