import React, { useState, useEffect } from 'react';

import { Box, Typography, Menu, MenuItem, Checkbox } from '@mui/material';

import { getCategoriesStructureRequest } from '../../../../../api/api';

export const FilterStacksComponent = ({ anchorEl, handleClose }) => {
	const isMenuOpen = Boolean(anchorEl);
	const [checkTokenError, setCheckTokenError] = useState();
	const [stacks, setStacks] = useState([]);
	const [isCategoriesLoaded, setIsCategoriesLoaded] = useState(false);

	useEffect(() => {
		getCategoriesStructureRequest()
			.then(data => {
				setIsCategoriesLoaded(true);
				setStacks(data);
			})
			.catch(requestError => {
				console.error(requestError.message);
				if (requestError.response.status === 401) {
					localStorage.setItem('tokenError', true);
					setCheckTokenError(!checkTokenError);
				}
			});
	}, [checkTokenError]);

	return (
		<>
			{isCategoriesLoaded && (
				<Menu
					id='filter-menu'
					MenuListProps={{
						'aria-labelledby': 'long-button'
					}}
					anchorEl={anchorEl}
					open={isMenuOpen}
					onClose={handleClose}
					//sx={{ height: 500 }}
				>
					<Box
						sx={{
							maxHeight: '80%',
							//maxWidth: "600px",
							overflow: 'auto'
						}}
					>
						{stacks?.map(stack => (
							<MenuItem key={stack.id}>
								<Typography variant='h6'>{stack.name}:</Typography>

								<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
									{stack.subCategories.map(item => (
										<Box
											key={item.id}
											onClick={() => {
												let filter = item.name;
												//handleFilterClick({ filter });
											}}
											sx={{ width: 'auto', px: 2 }}
										>
											<Checkbox
												//checked={filterOptions[item.name] === 1}
												color='warning'
											/>
											{item.name}
										</Box>
									))}
								</Box>
							</MenuItem>
						))}
					</Box>
				</Menu>
			)}
		</>
	);
};
