import React, { useEffect, useState } from 'react';

import moment from 'moment';
import 'moment/locale/ru';

import { ruRU } from '@mui/x-date-pickers/locales';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { Box, IconButton, Tooltip, Typography } from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { pickersLayoutClasses } from '@mui/x-date-pickers/PickersLayout';
import ClearIcon from '@mui/icons-material/Clear';

export const MonthSelector = ({
	setSelectedDate,
	setPeriodData,
	selectedTab,
	setSelectedTab,
	selectedDate
}) => {
	const { monthFilter } = selectedDate;
	const [term, setTerm] = useState(
		monthFilter ? moment(selectedDate.month) : null
	);
	const [term1, setTerm1] = useState(null);
	const [term2, setTerm2] = useState(null);

	const [openCalendar1, setOpenCalendar1] = useState(false);
	const [openCalendar2, setOpenCalendar2] = useState(false);

	const minDate = moment('2023-06-01');

	const handleChange1 = filter1 => {
		setTerm1(filter1);
		setSelectedDate(prevState => ({
			...prevState, // сохраняем предыдущее состояние
			startDate: filter1._d // обновляем только поле startDate
		}));
	};

	const handleChange2 = filter2 => {
		setTerm2(filter2);
		// если нижняя граница не установлена, фильтруем от начальной даты
		if (term1) {
			setSelectedDate(prevState => ({
				...prevState, // сохраняем предыдущее состояние
				endDate: filter2._d // обновляем только поле startDate
			}));
		} else {
			setSelectedDate(prevState => ({
				...prevState, // сохраняем предыдущее состояние
				startDate: moment(minDate).toDate(),
				endDate: filter2._d
			}));
		}
	};

	useEffect(() => {
		if (term1 || term2) {
			setPeriodData(true);
			setTerm(null);
		}
	}, [term1, term2]);

	useEffect(() => {
		if (term && (term1 || term2)) {
			setPeriodData(false);
			setSelectedDate(prevState => ({
				...prevState,
				startDate: null,
				endDate: null
			}));
			setTerm1(null);
			setTerm2(null);
		} else if (term && !term1 && !term2) {
			setSelectedTab(1);
		}
	}, [term]);

	useEffect(() => {
		if (selectedTab === 1) {
			setPeriodData(false);
			if (monthFilter) {
				setTerm(moment(selectedDate.month));
			} else {
				setTerm(null);
			}
			setTerm1(null);
			setTerm2(null);
			setSelectedDate(prevState => ({
				...prevState,
				startDate: null,
				endDate: null
			}));
		}
	}, [selectedTab]);

	const handleReset = () => {
		setSelectedDate({
			month: new Date(),
			monthFilter: false,
			startDate: null,
			endDate: null
		});
		setTerm(null);
		setTerm1(null);
		setTerm2(null);
		setPeriodData(false);
		setSelectedTab(1);
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Box sx={{ pr: 2, pl: 2, width: '160px', display: 'flex' }}>
					<LocalizationProvider
						dateAdapter={AdapterMoment}
						adapterLocale='ru'
						localeText={
							ruRU.components.MuiLocalizationProvider.defaultProps.localeText
						}
					>
						<Box components={['DatePicker']}>
							<DatePicker
								label='От'
								closeOnSelect={false}
								views={['year', 'month', 'day']}
								open={openCalendar1}
								onOpen={() => setOpenCalendar1(true)}
								onClose={() => setOpenCalendar1(false)}
								value={term1 || null}
								minDate={minDate}
								maxDate={
									term2
										? term2
										: moment(new Date().setDate(new Date().getDate()))
								}
								onChange={newValue => handleChange1(newValue)}
								slotProps={{
									textField: {
										color: 'warning',
										size: 'small'
									},
									toolbar: { hidden: false },
									layout: {
										sx: {
											[`.${pickersLayoutClasses.toolbar}`]: {
												color: 'white',
												backgroundColor: '#fb8c00',
												'& .MuiTypography-root ': {
													color: 'white'
												}
											},
											[`.${pickersLayoutClasses.contentWrapper}`]: {
												'& .Mui-selected': {
													backgroundColor: '#fb8c00',
													borderColor: '#fb8c00',
													color: 'white'
												},
												'& .MuiTabs-indicator': { bgcolor: '#f57c00' },
												'& .MuiClock-pin': { bgcolor: '#f57c00' },
												'& .MuiClockPointer-root': { bgcolor: '#f57c00' },
												'& .MuiClockPointer-thumb': {
													borderColor: '#f57c00'
												},
												'& .Mui-selected:hover': {
													backgroundColor: '#fb8c00',
													borderColor: '#fb8c00',
													color: 'white'
												},
												'& .Mui-selected:focus': {
													backgroundColor: '#fb8c00',
													borderColor: '#fb8c00',
													color: 'white'
												}
											}
										}
									}
								}}
								sx={{
									svg: { color: '#fb8c00' },
									'& .MuiInputLabel-root.Mui-focused': { color: '#fb8c00' }, //styles the label
									'& .MuiInput-underline:after': {
										borderBottomColor: '#fb8c00'
									}
								}}
							/>
						</Box>
					</LocalizationProvider>
				</Box>
				<Typography variant='body1' sx={{ color: '#ddd' }}>
					—
				</Typography>
				<Box sx={{ pr: 2, pl: 2, width: '160px', display: 'flex' }}>
					<LocalizationProvider
						dateAdapter={AdapterMoment}
						adapterLocale='ru'
						localeText={
							ruRU.components.MuiLocalizationProvider.defaultProps.localeText
						}
					>
						<Box components={['DatePicker']}>
							<DatePicker
								label='До'
								closeOnSelect={false}
								views={['year', 'month', 'day']}
								open={openCalendar2}
								onOpen={() => setOpenCalendar2(true)}
								onClose={() => setOpenCalendar2(false)}
								value={term2 || null}
								minDate={term1 ? term1 : minDate}
								maxDate={moment(new Date().setDate(new Date().getDate()))}
								onChange={newValue => handleChange2(newValue)}
								slotProps={{
									textField: {
										color: 'warning',
										size: 'small'
									},
									toolbar: { hidden: false },
									layout: {
										sx: {
											[`.${pickersLayoutClasses.toolbar}`]: {
												color: 'white',
												backgroundColor: '#fb8c00',
												'& .MuiTypography-root ': {
													color: 'white'
												}
											},
											[`.${pickersLayoutClasses.contentWrapper}`]: {
												'& .Mui-selected': {
													backgroundColor: '#fb8c00',
													borderColor: '#fb8c00',
													color: 'white'
												},
												'& .MuiTabs-indicator': { bgcolor: '#f57c00' },
												'& .MuiClock-pin': { bgcolor: "'#f57c00' " },
												'& .MuiClockPointer-root': { bgcolor: '#f57c00' },
												'& .MuiClockPointer-thumb': {
													borderColor: '#f57c00'
												},
												'& .Mui-selected:hover': {
													backgroundColor: '#fb8c00',
													borderColor: '#fb8c00',
													color: 'white'
												},
												'& .Mui-selected:focus': {
													backgroundColor: '#fb8c00',
													borderColor: '#fb8c00',
													color: 'white'
												}
											}
										}
									}
								}}
								sx={{
									svg: { color: '#fb8c00' },
									'& .MuiInputLabel-root.Mui-focused': { color: '#fb8c00' }, //styles the label
									'& .MuiInput-underline:after': {
										borderBottomColor: '#fb8c00'
									}
								}}
							/>
						</Box>
					</LocalizationProvider>
				</Box>
				<Tooltip title='Сбросить фильтры'>
					<IconButton sx={{ alignSelf: 'center' }} onClick={handleReset}>
						<ClearIcon
							sx={{
								color:
									term1 || term2 || selectedTab === 0 || term
										? '#ef6c00'
										: 'inherit'
							}}
						/>
					</IconButton>
				</Tooltip>
			</Box>
		</Box>
	);
};
