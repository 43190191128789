export const formatNamesToIds = (activeStackNames, filterList) => {
	return activeStackNames.reduce((acc, activeStackName) => {
		const stackId = Object.keys(filterList).reduce((innerAcc, categoryName) => {
			const stack = filterList[categoryName].find(stack => stack.name === activeStackName);
			return stack ? stack.id : innerAcc;
		}, null);

		if (stackId !== null) {
			acc.push(stackId);
		}

		return acc;
	}, []);
};

export const formatIdsToNames = (activeStackIds, filterList) =>
	activeStackIds.map(activeStackId => {
		return Object.keys(filterList).reduce((acc, categoryName) => {
			const stack = filterList[categoryName].find(stack => stack.id === activeStackId);
			return stack ? stack.name : acc;
		}, null);
	});

export const foundValueById = (id, values) => values.find(({ id: valueId }) => valueId === id);

export const filterByStack = (activeStackNames = [], categoriesConfig, values) => {
	const { filterList = {} } = categoriesConfig;

	let activeStackIds = [];

	if (activeStackNames.length) {
		if (filterList) {
			activeStackIds = formatNamesToIds(activeStackNames, filterList);
		}
	}

	return values.filter(({ categoriesId }) => activeStackIds.every(categoryId => categoriesId.includes(categoryId)));
};

export const filterBySearch = (filteredValues, searchValue) => {
	return filteredValues.filter(({ value }) => value.toLowerCase().includes(searchValue.toLowerCase()));
};
