import React from "react";
import { Box, Typography } from "@mui/material";
import { UniversalTable } from '../universal-table';
import { getHeadHunterTablesConfig } from '../universal-table/config';

export const HeadHunterTables = ({
  selectedTab,
  filterDataBySevenDays,
  filterDataByMonth,
  filterDataByPeriod,
  sortDataByDate,
  cvCountData,
  contactCvCountData,
}) => {
  const tables = getHeadHunterTablesConfig(cvCountData, contactCvCountData);
  return (
    <Box
      sx={{
        ml: 13,
        mt: 5,
        mr: 3,
        boxSizing: 'border-box',
        padding: '10px 20px 20px 20px',
        borderRadius: '15px',
        border: '2px solid #ebebeb',
        marginTop: '10px'
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'orange',
              padding: '5px',
              width: '200px',
              borderRadius: '5px',
              mb: 1
            }}
          >
            <Typography fontWeight="bold" variant="h6" sx={{ color: 'white' }}>
              Head Hunter
            </Typography>
          </Box>
        </Box>
        {tables.map(({ title, data, totalTitle }, index) => (
          <UniversalTable
            key={index}
            selectedTab={selectedTab}
            data={data}
            filterDataBySevenDays={filterDataBySevenDays}
            filterDataByMonth={filterDataByMonth}
            filterDataByPeriod={filterDataByPeriod}
            sortDataByDate={sortDataByDate}
            title={title}
            totalLabel={true}
            totalTitle={totalTitle}
          />
        ))}
      </Box>
    </Box>
  );
};
