import React from 'react';
import { StyledCard } from './term-grid-card-styles';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const TermGridCard = ({ label = '', onDeleteButtonClick, onCardClick }) => {
	const handleCardClick = e => {
		onCardClick();
	};

	const handleDeleteButtonClick = e => {
		e.stopPropagation();
		onDeleteButtonClick();
	};

	return (
		<StyledCard onClick={handleCardClick}>
			<Typography sx={{ color: 'white', fontWeight: 'bold' }} fontSize={label.length > 20 ? '16px' : '20px'}>
				{label}
			</Typography>
			{onDeleteButtonClick && (
				<IconButton onClick={handleDeleteButtonClick}>
					<CloseIcon size='small' sx={{ color: '#fff' }} />
				</IconButton>
			)}
		</StyledCard>
	);
};
