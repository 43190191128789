import React from "react";

import { Typography } from "@mui/material";

export const CandidateGradeFieldTitle = ({ textName }) => {
  return (
    <Typography
      id="keep-mounted-modal-title"
      variant="h6"
      component="h2"
      sx={{
        display: "flex",
        height: "100%",
        fontSize: "15px",
        bgcolor: "#fb8c00",
        color: "#852508",
        border: "1px solid",
        minWidth: "110px",
        textAlign: "center",
        borderRadius: "4px",
        borderColor: "#a2a2a2",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {textName}
    </Typography>
  );
};
