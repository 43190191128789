import React, { useState, useEffect, useRef } from "react";

import { Box, Tabs, Tab } from "@mui/material";

import { HeadHunterTables } from "./head-hunter-tables";
import { TelegramBotTables } from "./telegram-bot-tables";
import { MonthSelector } from "./month-selector";

import {
  getStatisticsCvNumberByDay,
  getStatisticsCvContactNumberByDay,
  getGarbagePercentageData,
  getNotAccessedData,
  getUserBotStatisticData,
} from "../../../../api/api";

import { StyledTabs } from "../../../../styles/default-styles/styled-tabs/StyledTabs";

export const AdministratorTab = () => {
  const [cvCountData, setCvCountData] = useState([]);
  const [contactCvCountData, setContactCvCountData] = useState([]);
  const [garbagePercentageData, setGarbagePercentageData] = useState([]);
  const [notAccessedData, setNotAccessedData] = useState([]);
  const [userBotStatisticData, setUserBotStatisticData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedDate, setSelectedDate] = useState({ month: new Date(), monthFilter: false, startDate: null, endDate: null });
  const [disableSevenDaysTab, setDisableSevenDaysTab] = useState(false);
  const isFirstRenderRef = useRef(true);
  const [periodData, setPeriodData] = useState(false)

  useEffect(() => {
    const currentDate = new Date();
    const thirtyDaysAgo = new Date(currentDate);
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);

    const defaultDate = selectedDate.monthFilter ? selectedDate.month.getMonth() + 1 : thirtyDaysAgo.getMonth() + 1;

    const startMonth = periodData ? selectedDate.startDate?.getMonth() + 1 : defaultDate;
    const startYear = periodData ? selectedDate.startDate?.getFullYear() : selectedDate.month.getFullYear();
    const endMonth = periodData ? selectedDate.endDate?.getMonth() + 1 :  selectedDate.month.getMonth() + 1;
    const endYear = periodData ? selectedDate.endDate?.getFullYear() : startYear;

    if (!isFirstRenderRef.current) {
      if (periodData) {
        selectedDate.startDate?.setDate(selectedDate.startDate.getDate());
        selectedDate.endDate?.setDate(selectedDate.endDate.getDate());
      }
    }
    isFirstRenderRef.current = false;

    if (periodData) {
      setDisableSevenDaysTab(true);
      setSelectedTab(false);
    }
    else if (!periodData && selectedDate.month.getMonth() !== currentDate.getMonth()) {
      setDisableSevenDaysTab(true);
      setSelectedTab(1);
    }
    else {
      setDisableSevenDaysTab(false);
    }

    fetchGarbageData(startMonth, startYear, endMonth, endYear);
    fetchNotAccessedData(startMonth, startYear, endMonth, endYear);
    fetchUserBotsData(startMonth, startYear, endMonth, endYear);
    fetchContactStatistics(startMonth, startYear, endMonth, endYear);
    fetchCvStatistics(startMonth, startYear, endMonth, endYear);
  }, [selectedDate, periodData]);

  const fetchCvStatistics = (startMonth, startYear, endMonth, endYear) => {
    getStatisticsCvNumberByDay(
      startMonth,
      startYear,
      endMonth,
      endYear
    )
      .then((response) => {
        setCvCountData(response);
      })
      .catch((error) => {
        console.error("Error fetching contact data:", error);
      });
  };

  const fetchContactStatistics = (startMonth, startYear, endMonth, endYear) => {
    getStatisticsCvContactNumberByDay(
      startMonth,
      startYear,
      endMonth,
      endYear
    )
      .then((response) => {
        setContactCvCountData(response);
      })
      .catch((error) => {
        console.error("Error fetching contact data:", error);
      });
  };

  const fetchGarbageData = (startMonth, startYear, endMonth, endYear) => {
    getGarbagePercentageData(
      startMonth,
      startYear,
      endMonth,
      endYear
    )
      .then((response) => {
        setGarbagePercentageData(response);
      })
      .catch((error) => {
        console.error("Ошибка при получении данных о мусоре:", error);
      });
  };

  const fetchNotAccessedData = (startMonth, startYear, endMonth, endYear) => {
    getNotAccessedData(startMonth, startYear, endMonth, endYear)
      .then((response) => {
        setNotAccessedData(response);
      })
      .catch((error) => {
        console.error("Ошибка при получении данных о недоступных:", error);
      });
  };

  const fetchUserBotsData = (startMonth, startYear, endMonth, endYear) => {
    getUserBotStatisticData(
      startMonth,
      startYear,
      endMonth,
      endYear
    )
      .then((response) => {
        setUserBotStatisticData(response);
      })
      .catch((error) => {
        console.error("Ошибка при получении данных о недоступных:", error);
      });
  };

  const filterDataByMonth = (data) => {
    const selectedMonth = (selectedDate.month.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const currentDate = new Date();
    const thirtyDaysAgoDate = new Date().setDate(currentDate.getDate() - 30);

    const filteredData = data.filter((item) => {
      if (!selectedDate.monthFilter) {
        const itemDate = new Date(item.dateTime);
        return itemDate >= thirtyDaysAgoDate && itemDate <= currentDate;
      } else {
        const itemMonth = new Date(item.dateTime).getMonth() + 1;
        return itemMonth === parseInt(selectedMonth, 10);
      }
    });
    return filteredData;
  };

  const filterDataBySevenDays = (data) => {
    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(currentDate.getDate() - 7);

    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.dateTime);
      return (
        itemDate >= sevenDaysAgo && itemDate <= currentDate
      );
    });
    return filteredData;
  };

  const filterDataByPeriod = (data) => {
    let startDate = null;
    let endDate = null;

    if (selectedDate.startDate) {
      startDate = new Date(selectedDate.startDate);
      startDate.setHours(0, 0, 0, 0);
    }

    if (selectedDate.endDate) {
      endDate = new Date(selectedDate.endDate);
      endDate.setHours(23, 59, 59, 999);
    }

    return data.filter((item) => {
      const itemDate = new Date(item.dateTime);

      if (startDate && endDate) {
        return itemDate >= startDate && itemDate <= endDate;
      } else if (startDate) {
        return itemDate >= startDate;
      } else if (endDate) {
        return itemDate <= endDate;
      } else {
        return true;
      }
    });
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const sortDataByDate = (data) => {
    return data.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
          mt: 2,
        }}
      >
        <Box sx={{ ml: 13 }}>
          {" "}
          <MonthSelector
            setSelectedDate={setSelectedDate}
            setPeriodData={setPeriodData}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            selectedDate={selectedDate}
          />
        </Box>
        <Tabs
          sx={{ ...StyledTabs.styledTabs, mr: 3.4 }}
          value={selectedTab}
          onChange={handleTabChange}
        >
          <Tab
            disabled={disableSevenDaysTab}
            sx={StyledTabs.styledTab}
            label="7 дней"
          />
          <Tab sx={StyledTabs.styledTab} label="Месяц" />
        </Tabs>
      </Box>
      <HeadHunterTables
        cvCountData={cvCountData}
        contactCvCountData={contactCvCountData}
        selectedTab={selectedTab}
        filterDataBySevenDays={filterDataBySevenDays}
        filterDataByMonth={filterDataByMonth}
        sortDataByDate={sortDataByDate}
        filterDataByPeriod={filterDataByPeriod}
      />
      <TelegramBotTables
        garbagePercentageData={garbagePercentageData}
        notAccessedData={notAccessedData}
        userBotStatisticData={userBotStatisticData}
        selectedTab={selectedTab}
        filterDataBySevenDays={filterDataBySevenDays}
        filterDataByMonth={filterDataByMonth}
        sortDataByDate={sortDataByDate}
        filterDataByPeriod={filterDataByPeriod}
      />
    </Box>
  );
};
