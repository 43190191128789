import React from 'react';

import { useNavigate } from 'react-router';

import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { buttonStyles } from '../../../../../styles/default-styles/button-styles/button-styles';
import { styled } from '@mui/material/styles';

export const ReturnButton = ({ way, isStreamSample }) => {
	const navigate = useNavigate();

	return (
		<Button
			variant='contained'
			color='primary'
			size='large'
			startIcon={<ArrowBackIcon />}
			sx={{
				...buttonStyles.orangeButton,
				mr: 1
			}}
			onClick={() => navigate(way, { state: { isStreamSample } })}
		>
			Вернуться
		</Button>
	);
};
