import React, { useState } from "react";

import { TextField, Button, CircularProgress } from "@mui/material";

import { ResetPasswordByEmail } from "../../../../../../api/api";
import { LoginMessage } from "../../login-message/login-message";

export const SendCodeForm = ({
  setCodeBeingSent,
  checkAbout,
  setCheckAbout,
  emailData,
  setEmailData,
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [text, setText] = useState("");
  const [severity, setSeverity] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleChangeEmail = (event) => {
    const temp = event.target.value;
    setEmailData(temp.replaceAll(/^[\s.`":'$@~;]*$/g, ""));
  };

  const handleCodeSend = (email) => {
    if (!validateEmail(email)) {
      setText("Правильно введите почту!");
      setSeverity("error");
      setShowAlert(true);
      return;
    }
    setIsLoading(true);
    ResetPasswordByEmail(email)
      .then(() => {
        setCheckAbout(checkAbout + 1);
        setTimeout(() => {
          setCodeBeingSent(true);
        }, 3000);

        setIsLoading(false);
        setText("Проверьте почту!");
        setSeverity("success");
        setShowAlert(true);
      })
      .catch((requestError) => {
        console.error(requestError.message);
        setIsLoading(false);
        setText("Что-то пошло не так!");
        setSeverity("error");
        setShowAlert(true);
      });
  };

  return (
    <>
      <LoginMessage
        text={text}
        severity={severity}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
      />

      <TextField
        id="outlined-basic"
        name="email"
        type="email"
        label="Введите электронную почту"
        value={emailData}
        onChange={handleChangeEmail}
      />
      <Button
        variant="contained"
        disabled={isLoading}
        sx={{
          fontSize: 20,
          color: "#696969",
          backgroundColor: "white",
          "&:hover": {
            backgroundColor: "#EEEEED",
          },
        }}
        onClick={() => handleCodeSend(emailData)}
      >
        {isLoading ? <CircularProgress size={24} /> : "Отправить код на почту"}
      </Button>
    </>
  );
};
