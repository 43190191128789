export const cellStyles = {
	body: {
		fontSize: '.9rem',
		borderRight: '1px solid #dddddd',
		textAlign: 'center',
		borderBottom: '1px solid #dddddd',
		p: 0
	},
	head: {
		textAlign: 'left',
		fontSize: '1rem',
		p: 0,
		pl: 1
	},
	subheader: {
		fontSize: '.9rem',
		borderRight: '1px solid #ddd',
		p: 0.5
	}
};

export const defineColors = index => {
	switch (index) {
		case 0:
			return 'rgba(255, 243, 224)';
		case 1:
			return 'rgba(245, 245, 245)';
		default:
			return '';
	}
};
