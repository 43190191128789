import React, { useState, useEffect, useCallback } from 'react';
import { InputBase } from '@mui/material';

export const DateExperienceFieldCV = ({
	textData,
	setData,
	weight,
	setIsCurrently,
	setAlertIsOpen
}) => {
	const [term, setTerm] = useState('');
	const [termTime, setTermTime] = useState('');
	const [isChanged, setIsChanged] = useState(false);

	const monthMap = {
		1: 'январь',
		2: 'февраль',
		3: 'март',
		4: 'апрель',
		5: 'май',
		6: 'июнь',
		7: 'июль',
		8: 'август',
		9: 'сентябрь',
		10: 'октябрь',
		11: 'ноябрь',
		12: 'декабрь'
	};

	const makeDatePrettier = date => {
		if (Date.parse(date)) {
			const parsedDate = new Date(date);
			const month = parsedDate.getMonth() + 1; // Months are zero-based
			const year = parsedDate.getFullYear();
			// возвращаем строку формата "Январь 2023"
			return `${
				monthMap[month].charAt(0).toUpperCase() + monthMap[month].slice(1)
			} ${year}`;
		} else {
			return 'По настоящее время';
		}
	};

	const makeTextPrettier = text => {
		if (text !== 'По настоящее время') {
			const [month, year] = text.split(' ');
			const monthIndex =
				Object.values(monthMap).indexOf(month.toLowerCase()) + 1;
			if (monthIndex > 0) {
				const formattedMonth = monthIndex.toString().padStart(2, '0');
				return `${year}-${formattedMonth}-01`;
			}
		} else {
			return 'По настоящее время';
		}
	};
	useEffect(() => {
		if (textData !== undefined) {
			setTerm(makeDatePrettier(textData));
		}
	}, [textData]);

	const handleChange = useCallback(element => {
		try {
			const term = element.target.value;

			const termReplaced = term.replace(/^[\s.`":'$@~;]*$/g, '');
			setTerm(termReplaced);

			const termPrettier = makeTextPrettier(termReplaced);
			setTermTime(termPrettier);

			setIsChanged(true);
		} catch (error) {
			console.log('Ошибка обработчика даты');
		}
	}, []);

	const handleSave = () => {
		try {
			const isEndDate = typeof setIsCurrently === 'function';
			const termTimeWithoutSpaces = termTime.replace(/\s/g, '');

			if (termTimeWithoutSpaces.toLowerCase() !== 'понастоящеевремя') {
				setData(termTime);
				isEndDate && setIsCurrently(false);
			} else {
				isEndDate && setIsCurrently(true);
			}
		} catch (error) {
			if (error.name === 'TypeError') {
				setAlertIsOpen(true);
			}
		}
	};

	useEffect(() => {
		const handleBeforeUnload = event => {
			// Perform actions before the component unloads
			if (
				isChanged &&
				makeTextPrettier(
					makeDatePrettier(textData?.replace(/^[\s.`":'$@~;]*$/g, ''))
				) !== termTime
			) {
				event.preventDefault();
				event.returnValue = '';
			}
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [isChanged, termTime]);

	return (
		<InputBase
			value={term}
			onChange={handleChange}
			onBlur={() => {
				setIsChanged(false);
				handleSave();
			}}
			multiline
			fullWidth
			sx={{ p: 0, fontSize: '15px', fontWeight: weight ? 'bold' : '' }}
		/>
	);
};
