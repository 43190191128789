import React, { memo, useEffect, useState } from 'react';
import { paginationStyles } from '../../../../styles/default-styles/pagination-styles/pagination-styles';
import { Pagination } from '@mui/material';
import { PAGE_PAGINATION_CONFIG } from './config';

export const PagePagination = memo(({ values = [], setVisibleValues, config, resetDependence }) => {
	const { ITEMS_PER_PAGE } = { ...PAGE_PAGINATION_CONFIG, ...config };
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		setCurrentPage(1);
	}, [resetDependence]);

	useEffect(() => {
		const indexOfLastTerm = currentPage * ITEMS_PER_PAGE;
		const indexOfFirstTerm = indexOfLastTerm - ITEMS_PER_PAGE;

		setVisibleValues(values.slice(indexOfFirstTerm, indexOfLastTerm));
	}, [values, currentPage]);

	const handlePageChange = newPage => setCurrentPage(newPage);

	return (
		<Pagination
			count={Math.ceil(values.length / ITEMS_PER_PAGE)}
			page={currentPage}
			onChange={(e, newPage) => handlePageChange(newPage)}
			color='primary'
			size='large'
			showFirstButton
			showLastButton
			sx={paginationStyles.orangePagination}
		/>
	);
});
