import { CANDIDATE_CARD_PAGE_CONFIG } from './config';

export const addMarkerToRequirements = (string = '', checkedRequirements = []) => {
	const foundRequirements = checkedRequirements.filter(({ isFind }) => !!isFind);

	// Проходимся по каждому элементу
	foundRequirements.forEach(({ requirement, isFind }) => {
		const requirementIncludes = string.includes(requirement);

		// Если элемент отмечен как найденный
		if (isFind && requirementIncludes) {
			// Добавляем символ перед требованием
			string = string.replace(
				requirement,
				`${CANDIDATE_CARD_PAGE_CONFIG.CHECK_REQ_MODAL.FIND_INDICATOR} ${requirement}`
			);
		}
	});

	return string;
};

export const parseRequirements = checkedRequirementsData => {
	return checkedRequirementsData.map(({ id, requirement, isFind }) => ({
		id,
		value: isFind ? `${CANDIDATE_CARD_PAGE_CONFIG.CHECK_REQ_MODAL.FIND_INDICATOR} ${requirement.trim()}` : requirement
	}));
};
