import { ApiClient } from '../clients/client';

export const GetAllDeals = async () => {
	const { data } = await ApiClient.get(`tg/api/Project/AllDeals`);
	return data;
};

export const getProjectRequest = async id => {
	const { data } = await ApiClient.get(`tg/api/Project/GetProject?id=${id}`);
	return data;
};

export const getAllProjectsRequest = async () => {
	const { data } = await ApiClient.get('tg/api/Project/AllProjects');
	return data;
};

export const getProjectsByStaffId = async staffId => {
	const { data } = await ApiClient.get(
		`tg/api/Project/GetProjectsByStaffId/${staffId}`
	);
	return data;
};

export const getProjectStatuses = async () => {
	const { data } = await ApiClient.get(`tg/api/Project/Statuses`);
	return data;
};

export const deleteProjectsRequest = async id => {
	const { data } = await ApiClient.post(
		`tg/api/Project/DeleteProject?projectID=${id}`
	);
	return data;
};

export const updateProjectRequest = async (id, newName) => {
	const { data } = await ApiClient.post(
		`tg/api/Project/UpdateprojectName?projectId=${id}`,
		{
			newName: newName
		}
	);
	return data;
};

export const updateProjectParamsRequest = async (
	id,
	newStatus,
	newComment,
	newStack,
	newResponsible
) => {
	const { data } = await ApiClient.post(`tg/api/Project/Update/${id}`, {
		status: newStatus,
		comment: newComment,
		stackID: newStack,
		responsibleID: newResponsible
	});
	return data;
};

export const updateProjectIsMain = async (id, isMain) => {
	const { data } = await ApiClient.post(`tg/api/Project/Update/${id}`, {
		isMain: isMain
	});
	return data;
};

export const sendToCreateProjectRequest = async name => {
	const { data } = await ApiClient.post(
		`tg/api/Project/CreateProject?name=${name}`
	);
	return data;
};

export const sendToDeleteParsedMessageInProject = async (
	projectID,
	parsedMessageID
) => {
	const { data } = await ApiClient.post(
		`tg/api/Project/DeleteParsedMessageInProject?projectID=${projectID}&parsedMessageID=${parsedMessageID}`
	);
	return data;
};

export const sendToAddParsedMessageInProject = async (
	projectID,
	parsedMessageID
) => {
	const { data } = await ApiClient.post(
		`tg/api/Project/AddParsedMessageInProject?projectID=${projectID}&parsedMessageID=${parsedMessageID}`
	);
	return data;
};

export const sendToAddParsedMessageInProjectByPriorityAndSubCategory = async (
	stackID,
	parsedMessageId
) => {
	const { data } = await ApiClient.post(
		`tg/api/Project/AddParsedMessageInProjectByPriorityAndSubCategory?stackID=${stackID}&parsedMessageId=${parsedMessageId}`
	);
	return data;
};

export const getProjectHistory = async projectId => {
	const { data } = await ApiClient.get(
		`tg/api/Project/ProjectHistory?projectId=${projectId}`
	);
	return data;
};

export const getAllDealStatusesRequest = async () => {
	const { data } = await ApiClient.get(`tg/api/Project/DealStatuses`);
	return data;
};

export const getAllDealResultRequest = async () => {
	const { data } = await ApiClient.get(`tg/api/Project/Deal/Results`);
	return data;
};

export const getProjectDealHistory = async projectId => {
	const { data } = await ApiClient.get(
		`tg/api/Project/DealHistory?projectId=${projectId}`
	);
	return data;
};
