import { Box, Typography } from '@mui/material';
import React from 'react';

export const StatTypography = ({ value = '', align = 'center' }) => (
	<Box
		sx={{
			width: '100%',
			display: 'flex',
			justifyContent: align
		}}
	>
		<Typography
			onClick={e => e.stopPropagation()}
			sx={{ width: 'fit-content', fontSize: '.9rem' }}
			children={value}
		/>
	</Box>
);
