import React from 'react';

import { IconButton } from '@mui/material';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';

export const OpenWhatsAppTgWithNumber = ({ phoneNumber, wa }) => {
	const handleWhatsAppIconClick = () => {
		const url = `https://${wa ? 'wa.me' : 't.me'}/${phoneNumber.replace(
			/[()-\s]/g,
			''
		)}`;
		window.open(url, '_blank');
	};
	return (
		<IconButton aria-label='Open WhatsApp' onClick={handleWhatsAppIconClick}>
			{wa ? (
				<WhatsAppIcon fontSize='small' color='success' />
			) : (
				<TelegramIcon color='info' fontSize='small' />
			)}
		</IconButton>
	);
};
