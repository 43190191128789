import React, { useState, useEffect, useCallback } from 'react';
import { InputBase } from '@mui/material';

export const DateFieldCV = ({ textData, setData }) => {
	const [term, setTerm] = useState('');
	const [termTime, setTermTime] = useState();

	useEffect(() => {
		if (textData !== null && textData !== undefined) {
			setTerm(textData);
		}
	}, [textData]);

	const handleChange = useCallback(element => {
		const term = element.target.value;
		setTerm(term.replace(/^[\s.`":'$@~;]*$/g, ''));
		setTermTime(term.replace(/^[\s.`":'$@~;]*$/g, ''));
	}, []);

	useEffect(() => {
		const timeOutId = setTimeout(() => setData(termTime), 500);
		return () => clearTimeout(timeOutId);
	}, [setData, termTime]);

	return (
		<InputBase
			label='Choose your birthdate'
			value={term}
			onChange={handleChange}
			type='date'
			inputlabelprops={{
				shrink: true
			}}
			sx={{ fontSize: '15px', width: 'fit-content' }}
		/>
	);
};
