import React from "react";
import { Box, Stack, Typography } from "@mui/material";

export const VerificationTab = ({}) => {

	return (
		<>
			<Box sx={{ flexGrow: 1 }}>
				<img style={{ objectFit: 'cover', width: 'auto', height: '76px', marginBottom: '15px' }} src={`/logo.png`}
						 alt="" />
			</Box>

			<Stack spacing={6}>
				<Typography
					variant="h3"
					component="h4"
					align="center"
					sx={{
						fontSize: "25px",
						fontWeight: "bold",
					}}
				>
					Регистрация успешно завершена!
				</Typography>
				<Stack spacing={2}>
					<Typography
						variant="h6"
						component="h6"
						align="center"
						sx={{
							fontSize: "20px",
							lineHeight: "normal",
						}}
					>
						Пользование сайтом будет доступно после проверки Вашего аккаунта администратором. Это занимает не более
						получаса.
						После проверки мы пришлем Вам уведомление в Telegram.
					</Typography>
					<Typography
						variant="h6"
						component="h6"
						align="center"
						sx={{
							fontSize: "20px",
						}}
					>
						Спасибо за ожидание!
					</Typography>
				</Stack>

				<Box sx={{ display: 'flex', mt: 1, fontSize: '18px', fontWeight: 'lighter' }}>
					Ответим на Ваши вопросы: helptechcrew@gmail.com
				</Box>
			</Stack>
		</>
	);
};
