export const sortedUsers = (users, thisUserId, activeUserIds) => {
	// Сортируем пользователей сразу
	let sortedUsers = users.sort((a, b) => (a.name > b.name ? 1 : -1));

	const result = [];

	// Извлекаем текущего пользователя, если он указан
	if (thisUserId) {
		const thisUser = sortedUsers.find(user => user.id === thisUserId);

		if (!!thisUser) {
			result.push(thisUser);
			sortedUsers = sortedUsers.filter(user => user.id !== thisUserId);
		}
	}

	// Разделяем пользователей на активных и неактивных в один проход
	const activeUsers = [];
	const inactiveUsers = [];

	sortedUsers.forEach(user => {
		if (activeUserIds.includes(user.id)) {
			activeUsers.push(user);
		} else {
			inactiveUsers.push(user);
		}
	});

	// Собираем конечный результат
	result.push(...activeUsers, ...inactiveUsers);

	return result;
};
