import React, { useEffect, useState } from 'react';
import { Fade, Modal } from '@mui/material';
import { ModalContainer, sizes } from './modal-styles';
export const RootModal = ({
	isOpen = false,
	onClose = () => {},
	children = <></>,
	styles = {},
	size = 'md'
}) => {
	return (
		<Modal
			keepMounted
			id='message-modal'
			open={isOpen}
			onClose={onClose}
			onDoubleClick={event => event.stopPropagation()}
			aria-labelledby='keep-mounted-modal-title'
			aria-describedby='keep-mounted-modal-description'
		>
			<Fade in={isOpen}>
				<ModalContainer sx={styles} size={size}>
					{children}
				</ModalContainer>
			</Fade>
		</Modal>
	);
};
