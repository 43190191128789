import React from "react";

import { Divider, Paper } from "@mui/material";

import { CandidateGradeFieldTitle } from "./candidate-grade-field-title/candidate-grade-field-title";
import { CandidateGrade } from "./candidate-grade";

export const CandidateGradeField = ({ textName, textstaff, setData }) => {
  return (
    <Paper
      elevation={6}
      sx={{ display: "flex", alignItems: "center", minHeight: "32px" }}
    >
      <CandidateGradeFieldTitle textName={textName} />
      <Divider sx={{ height: 24, ml: 0.5 }} orientation="vertical" />
      <CandidateGrade textstaff={textstaff} setData={setData} />
    </Paper>
  );
};
