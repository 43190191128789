import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const ModalContainer = styled(Box)(() => ({
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '40%',
	backgroundColor: 'white',
	padding: '1rem',
	boxSizing: 'border-box',
	boxShadow: 24,
	'@media screen and (max-width: 1450px)': {
		width: '90%'
	}
}));
