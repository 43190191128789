import React, { useState, useEffect } from "react";

import { SendCodeForm } from "./send-code-form/send-code-form";
import { PasswordChangeForm } from "./password-change-form";

export const ResetPasswordForm = ({ checkAbout, setCheckAbout, Auth }) => {
  const [codeBeingSent, setCodeBeingSent] = useState(false);
  const [checkResettingPassword, setCheckResettingPassword] = useState(false);
  const [emailData, setEmailData] = useState("");
  const [passwordData, setPasswordData] = useState("");

  useEffect(() => {
    if (checkResettingPassword) {
      Auth(emailData, passwordData);
    }
  }, [checkResettingPassword]);

  return (
    <>
      {!codeBeingSent ? (
        <SendCodeForm
          setCodeBeingSent={setCodeBeingSent}
          checkAbout={checkAbout}
          setCheckAbout={setCheckAbout}
          emailData={emailData}
          setEmailData={setEmailData}
        />
      ) : (
        <PasswordChangeForm
          checkAbout={checkAbout}
          setCheckAbout={setCheckAbout}
          passwordData={passwordData}
          setPasswordData={setPasswordData}
          setCheckResettingPassword={setCheckResettingPassword}
        />
      )}
    </>
  );
};
