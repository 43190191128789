import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { orange } from '@mui/material/colors';
import { REQUIREMENT_GRID_ITEM_CONFIG } from './config';

const { TEXTAREA_FONTSIZE } = REQUIREMENT_GRID_ITEM_CONFIG;

export const ItemContainer = styled(Box)(({}) => ({
	display: 'flex',
	gap: '10px',
	alignItems: 'center',
	borderRadius: '25px',
	width: 'fit-content',
	overflow: 'hidden',
	outline: 'none',
	padding: '2px 5px',
	transition: 'background-color 0.35s ease',
	'&:hover': {
		cursor: 'pointer',
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		'.requirement-grid-item_button_delete': {
			opacity: 1,
			visibility: 'visible'
		},
		'.requirement-grid-item_list-dot': {
			color: orange[800]
		}
	}
}));

export const TransparentTextField = styled('textarea')(({ theme }) => ({
	margin: 0,
	padding: 0,
	border: 0,
	paddingBottom: '2px',
	backgroundColor: 'transparent',
	outline: 'none',
	fontSize: TEXTAREA_FONTSIZE + 'px',
	overflow: 'hidden',
	resize: 'none',
	fontFamily: 'inherit',
	width: '400px'
}));
