import React from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableRow,
  TableCell,
  Box,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { escapedNewLineToLineBreakTag } from "../../../../../../utils/to-line-break-tag";

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  "&& .MuiAccordionSummary-root:hover, .MuiButtonBase-root:hover": {
    cursor: "default",
  },
  backgroundColor: "#fff3e0",
}));

export const RenderCustomAccordions = ({
  ratings,
  objKey,
  showRecommendation,
}) => {
  return (
    <TableRow>
      <TableCell colSpan={4}>
        <Box sx={{ margin: "10px 0" }}>
          <CustomAccordion expanded>
            <AccordionSummary>Формула</AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ textAlign: "left" }}>
                {escapedNewLineToLineBreakTag(ratings[objKey].formula)}
              </Typography>
            </AccordionDetails>
          </CustomAccordion>
          <CustomAccordion expanded>
            <AccordionSummary>Пояснение</AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ textAlign: "left" }}>
                {escapedNewLineToLineBreakTag(ratings[objKey].explanation)}
              </Typography>
            </AccordionDetails>
          </CustomAccordion>
          {showRecommendation && (
            <CustomAccordion expanded>
              <AccordionSummary>Рекомендация</AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ textAlign: "left" }}>
                  {escapedNewLineToLineBreakTag(ratings[objKey].recommendation)}
                </Typography>
              </AccordionDetails>
            </CustomAccordion>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};
