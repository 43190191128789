import React from 'react';

import { Box, Grid, Divider, Typography } from '@mui/material';

import { UpdatingCvEducationInfo } from './updating-cv-education-info';

import { TextFieldStackCV } from '../ui/text-field-stack-CV';

export const UpdatingCvDetailsEducationInfo = ({
	cvData,
	check,
	setCheck,
	setEducationData
}) => {
	return (
		<Box>
			<Divider sx={{ height: 20, m: '0px 0px 20px 0px' }} />

			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					marginBottom: '20px'
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-start'
					}}
				>
					<Typography
						variant='h6'
						component='h2'
						sx={{
							fontSize: '20px'
						}}
					>
						Образование
					</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-start',
						pb: 2
					}}
				>
					<Typography
						variant='h6'
						component='h2'
						sx={{
							fontSize: '20px',
							fontWeight: 'bold',
							p: '5px'
						}}
					>
						Уровень:
					</Typography>

					<TextFieldStackCV
						textData={cvData.education}
						setData={setEducationData}
					/>
				</Box>
				<Grid container spacing={0}>
					{cvData.educations?.map(item => (
						<UpdatingCvEducationInfo
							key={item.id}
							id={item.id}
							educationName={item.name}
							educationOrganization={item.organization}
							educationResult={item.result}
							educationYear={item.year}
							check={check}
							setCheck={setCheck}
						/>
					))}
				</Grid>
			</Box>
		</Box>
	);
};
