export const DEFAULT_REQUIREMENTS_CONFIG = {
	KEY_VALUE_NAME: 'требование',
	ADD_BUTTON_LABEL: 'Добавить требования',
	ADD_MODAL: {
		SPLIT_REGEX: /\n|, ?/g,
		TITLE: 'Добавить требования',
		HELPER_TEXT: 'Введите требования разделяя их запятыми или переносом строки',
		PLACEHOLDER: '',
		LABEL: 'Новые требования',
		BUTTON_LABEL: 'Добавить',
		ROWS_COUNT: 10
	},
	EDIT_MODAL: {
		REPLACE_REGEX: /\n/g,
		REPLACE_STRING: ' ',
		TITLE: 'Редактировать требование',
		HELPER_TEXT: 'Введите новое или измените требование',
		PLACEHOLDER: '',
		LABEL: 'Требование',
		BUTTON_LABEL: 'Изменить'
	}
};
