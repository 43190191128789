export const buttonStyles = {
	defaultButton: {
		backgroundColor: '#BEBEBE',
		borderRadius: '3%',
		marginBottom: '1%',
		color: 'black',
		fontWeight: 'bold',
		'&:hover': {
			backgroundColor: '#333333',
			color: '#FFFFFF',
			transform: 'scale(1.03)'
		}
	},
	orangeButton: {
		backgroundColor: '#ef6c00',
		borderRadius: '3%',
		color: '#FFFFFF',
		fontWeight: 'bold',
		boxShadow: '',
		'&:hover': {
			backgroundColor: '#ff9800',
			color: '#FFFFFF',
			transform: 'scale(1.03)'
		}
	}
};
