import { useState, useEffect } from 'react';

export const useCommentUpdate = (initialValue, delay, onUpdate) => {
	const [value, setValue] = useState(initialValue);

	// метод установки нового значения
	const handleChange = e => {
		setValue(e.target.value);
	};

	useEffect(() => {
		if (value !== initialValue) {
			const handler = setTimeout(() => {
				onUpdate(value);
			}, delay);

			return () => clearTimeout(handler);
		}
	}, [value, delay, onUpdate]);

	return [value, handleChange];
};
