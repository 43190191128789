import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";

import { buttonStyles } from "../../../../../styles/default-styles/button-styles/button-styles";

import { PhoneNumberFields } from "./phone-number-fields";
import { ConfirmPhoneFields } from "./confirm-phone-fields";

import { CreateUserBot, SendCreateBotCode } from "../../../../../api/api";
import { isPossiblePhoneNumber } from "react-phone-number-input";

export const AddUserBotButton = () => {
  const [open, setOpen] = useState(false);
  const [confirmPhoneFields, setConfirmPhoneFields] = useState([]);
  const [phoneNumberSent, setPhoneNumberSent] = useState(false);
  const [check, setCheck] = useState();
  const [phoneNumbers, setPhoneNumbers] = useState([""]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPhoneNumbers([""]);
    setPhoneNumberSent(false);
    setConfirmPhoneFields([]);
  };

  const handleCreate = () => {
    if (
      phoneNumbers.find((phoneNumber) => !isPossiblePhoneNumber(phoneNumber))
    ) {
      return;
    }
    for (const [index, number] of phoneNumbers.entries()) {
      if (number !== "") {
        CreateUserBot(number)
          .then((data) => {
            if (!data.data.isError) {
              setConfirmPhoneFields((prevFields) => {
                return [
                  ...prevFields,
                  { id: index, value: "", phoneNumber: number },
                ];
              });
            }
            setCheck(!check);
          })
          .catch((requestError) => console.error(requestError.message));
      }
    }
  };

  useEffect(() => {
    if (confirmPhoneFields.length) {
      setPhoneNumberSent(true);
      setPhoneNumbers([""]);
    }
  }, [confirmPhoneFields]);

  const handleSetCode = () => {
    if (!confirmPhoneFields.find((field) => field.value !== "")) {
      return;
    }
    for (const field of confirmPhoneFields) {
      if (field.value !== "") {
        SendCreateBotCode(field.phoneNumber, parseInt(field.value))
          .then(() => {
            setCheck(!check);
          })
          .catch((requestError) => console.error(requestError.message));
      }
    }
    handleClose();
    setPhoneNumberSent(false);
    setConfirmPhoneFields([]);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="large"
        sx={{
          bgcolor: "#ef6c00",
          borderRadius: "3%",
          //marginBottom: "2%",
          color: "#FFFFFF",
          fontWeight: "bold",
          "&:hover": {
            bgcolor: "#ff9800",
            color: "#FFFFFF",
            transform: "scale(1.03)",
          },
        }}
        onClick={handleClickOpen}
      >
        Добавить user-бота
      </Button>
      <Dialog maxWidth="md" open={open} onClose={handleClose}>
        <Typography
          variant="h6"
          sx={{
            bgcolor: "#ef6c00",
            textAlign: "center",
            color: "#ffffff",
            borderRadius: "5px",
            padding: "10px 10px",
            margin: "10px 10px 0",
            width: "400px",
          }}
        >
          {!phoneNumberSent
            ? "Добавление user-бота(ов)"
            : "Подтверждение user-бота(ов)"}
        </Typography>

        <DialogContent>
          {!phoneNumberSent && (
            <PhoneNumberFields
              phoneNumbers={phoneNumbers}
              setPhoneNumbers={setPhoneNumbers}
            />
          )}
          {phoneNumberSent && (
            <ConfirmPhoneFields
              confirmPhoneFields={confirmPhoneFields}
              setConfirmPhoneFields={setConfirmPhoneFields}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ ...buttonStyles.orangeButton, fontSize: "18px" }}
            onClick={handleClose}
          >
            Отмена
          </Button>
          <Button
            sx={{ ...buttonStyles.orangeButton, fontSize: "18px" }}
            onClick={!phoneNumberSent ? handleCreate : handleSetCode}
          >
            Ок
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
