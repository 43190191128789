import React, { useState } from "react";

import { TextField, Button, CircularProgress, Typography } from "@mui/material";

import bcrypt from "bcryptjs-react";
import { LoginMessage } from "../../login-message/login-message";
import { PasswordInput } from "../../password-input";
import { ChangePasswordByCode } from "../../../../../../api/api";

export const PasswordChangeForm = ({
  checkAbout,
  setCheckAbout,
  setCheckResettingPassword,
  passwordData,
  setPasswordData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [text, setText] = useState("");
  const [severity, setSeverity] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [confirmPasswordData, setConfirmPasswordData] = useState("");

  const handleChange = (event) => {
    event.preventDefault();
    setConfirmationCode(event.target.value);
  };

  const handlePasswordChange = (code, newPassword) => {
    if (code === "") {
      setText("Введите код!");
      setSeverity("error");
      setShowAlert(true);
      return;
    }
    if (!newPassword || newPassword.length < 8) {
      setText("Пароль должен быть не менее 8 символов");
      setSeverity("error");
      setShowAlert(true);
      return;
    }
    setIsLoading(true);
    const hashedPassword = bcrypt.hashSync(
      newPassword,
      "$2a$10$CwTycUXWue0Thq9StjUM0u"
    );
    ChangePasswordByCode(code, hashedPassword)
      .then(() => {
        setCheckAbout(checkAbout + 1);
        setCheckResettingPassword(true);
        setIsLoading(false);
      })
      .catch((requestError) => {
        console.error(requestError.message);
        setIsLoading(false);
        setText("Что-то пошло не так!");
        setSeverity("error");
        setShowAlert(true);
      });
  };
  return (
    <>
      <LoginMessage
        text={text}
        severity={severity}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
      />

      <PasswordInput
        id="password"
        name="password"
        complete="new-password"
        label="Введите новый пароль"
        setData={setPasswordData}
      />

      <PasswordInput
        id="confirmPassword"
        name="confirmPassword"
        complete="new-password"
        label="Подтвердите новый пароль"
        setData={setConfirmPasswordData}
      />
      {confirmPasswordData !== "" && passwordData !== confirmPasswordData && (
        <Typography color="red" style={{ margin: 0 }}>
          Пароли не совпадают!
        </Typography>
      )}

      <TextField
        id="outlined-basic-code"
        name="text"
        type="text"
        label="Код подтверждения"
        value={confirmationCode}
        onChange={handleChange}
      />
      <Button
        variant="contained"
        disabled={isLoading}
        sx={{
          fontSize: 20,
          color: "#696969",
          backgroundColor: "white",
          "&:hover": {
            backgroundColor: "#EEEEED",
          },
        }}
        onClick={() => handlePasswordChange(confirmationCode, passwordData)}
      >
        {isLoading ? <CircularProgress size={24} /> : "Обновить пароль и войти"}
      </Button>
    </>
  );
};
