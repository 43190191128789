import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';

import {
	Box,
	Modal,
	Typography,
	ListItemButton,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	Skeleton,
	Button
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import AttachmentIcon from '@mui/icons-material/Attachment';

import { formatDate } from '../../../../../utils/format-date';
import { DeleteStuffButton } from './delete-stuff-button/delete-stuff-button';
import { AddCandidatesClipsModal } from './add-candidates-clips-modal';
import { buttonStyles } from '../../../../../styles/default-styles/button-styles/button-styles';
import {
	getProjectsByStaffId,
	getProjectStatuses
} from '../../../../../api/apiProject';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: '#fff3e0'
	},
	'&:last-child td, &:last-child th': {
		border: 0
	}
}));

export const CandidatesClipsModal = ({ id, stackID }) => {
	const [open, setOpen] = useState(false);
	const [check, setCheck] = useState(false);
	const [projects, setProjects] = useState(null);

	useEffect(() => {
		getProjectsByStaffId(id)
			.then(projects => {
				getProjectStatuses()
					.then(data => {
						const statusMap = {};
						let result = projects;
						data.forEach(status => {
							statusMap[status.id] = status.name;
						});

						for (let i = 0; i < result.length; i++) {
							result[i].status = statusMap[result[i].status];
						}
						setProjects(result);
					})
					.catch(requestError =>
						console.error(
							`Failed to fetch project statuses: ${requestError.message}`
						)
					);
			})
			.catch(requestError => console.error(requestError.message));
	}, [id, check]);

	const handleOpen = () => setOpen(!open);

	return (
		<>
			<Button
				variant='contained'
				color='primary'
				size='large'
				startIcon={<AttachmentIcon sx={{ fontSize: 35 }} />}
				sx={{
					...buttonStyles.orangeButton
				}}
				onClick={handleOpen}
			>
				Прикрепить кандидата к проекту
			</Button>
			<Modal
				keepMounted
				id='message-modal'
				open={open}
				onClose={handleOpen}
				onDoubleClick={event => event.stopPropagation()}
				aria-labelledby='keep-mounted-modal-title'
				aria-describedby='keep-mounted-modal-description'
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '95%',
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 3
					}}
				>
					<Box sx={{ display: 'flex' }}>
						<Box sx={{ flexGrow: 1 }}>
							<Typography
								id='keep-mounted-modal-title'
								variant='h6'
								component='h2'
								sx={{
									fontSize: '30px'
								}}
							>
								Список прикреплённых проектов
							</Typography>
						</Box>
						<Box>
							<ListItemButton onClick={handleOpen}>
								<CloseIcon color={'warning'} sx={{ fontSize: 35 }} />
							</ListItemButton>
						</Box>
					</Box>
					<AddCandidatesClipsModal
						id={id}
						check={check}
						clipsProject={projects}
						setCheck={setCheck}
						stackID={stackID}
					/>
					{projects ? (
						projects.length !== 0 ? (
							<TableContainer
								component={Paper}
								elevation={6}
								sx={{ mt: '20px' }}
							>
								<Table
									sx={{ minWidth: 900 }}
									size='small'
									aria-label='customized table'
								>
									<TableHead>
										<TableRow>
											<TableCell>Название проекта</TableCell>
											<TableCell align='center'>Стек</TableCell>
											<TableCell align='center'>Дата создания</TableCell>
											<TableCell align='center'>Ответственный</TableCell>
											<TableCell align='center'>Статус</TableCell>
											<TableCell align='center'></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{projects?.map(row => (
											<StyledTableRow key={row.id}>
												<TableCell component='th' scope='row'>
													{row?.name}
												</TableCell>
												<TableCell align='center'>{row.stackName}</TableCell>
												<TableCell align='center'>
													{formatDate(row?.dateOfCreation)}
												</TableCell>
												<TableCell align='center'>
													{row?.responsible.name}
												</TableCell>
												<TableCell align='center'>{row?.status}</TableCell>
												<TableCell align='center'>
													<DeleteStuffButton
														projectId={row?.id}
														staffID={id}
														check={check}
														setCheck={setCheck}
													/>
												</TableCell>
											</StyledTableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						) : (
							<Typography
								id='keep-mounted-modal-title'
								variant='h6'
								component='h2'
								sx={{
									fontSize: '30px',
									textAlign: 'center',
									mt: '20px'
								}}
							>
								Нет прикреплённых проектов
							</Typography>
						)
					) : (
						<>
							<Skeleton variant='rectangular' height={300} />
						</>
					)}
				</Box>
			</Modal>
		</>
	);
};
