import React from "react";

import { Box, IconButton, Typography } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";

export const PhoneNumberFields = ({ phoneNumbers, setPhoneNumbers }) => {
  const handlePhoneInputChange = (phoneNumber, index) => {
    const updatedPhoneNumbers = [...phoneNumbers];
    updatedPhoneNumbers[index] = phoneNumber;
    setPhoneNumbers(updatedPhoneNumbers);
  };

  const addPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, ""]);
  };

  const removePhoneNumber = (index) => {
    const updatedPhoneNumbers = [...phoneNumbers];
    updatedPhoneNumbers.splice(index, 1);
    setPhoneNumbers(updatedPhoneNumbers);
  };

  // Define an array of ISO country codes for the allowed countries
  const allowedCountries = ["RU", "ID"];

  return (
    <>
      {phoneNumbers.map((phoneNumber, index) => (
        <Box
          key={index}
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            //marginBottom: "1rem",
            pt: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <PhoneInput
              placeholder="Введите номер телефона"
              international
              countryCallingCodeEditable={false}
              labels={{ RU: "Россия", ID: "Индонезия" }}
              countries={allowedCountries}
              defaultCountry="RU"
              value={phoneNumber}
              onChange={(phoneNumber) =>
                handlePhoneInputChange(phoneNumber, index)
              }
              style={{ fontSize: "20px" }}
            />

            <IconButton onClick={() => removePhoneNumber(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography
              sx={{
                color: "red",
                display:
                  phoneNumber && !isPossiblePhoneNumber(phoneNumber)
                    ? "inline"
                    : "none",
              }}
            >
              Введите правильный номер
            </Typography>
          </Box>
        </Box>
      ))}
      <IconButton onClick={addPhoneNumber}>
        <AddIcon />
      </IconButton>
    </>
  );
};
