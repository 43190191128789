export const sortAndGroupDataByStack = data => {
	const sortedData = data.sort((a, b) => a.bench.stackId - b.bench.stackId);

	// Группировка данных и добавление пустых строк между различными стеками
	const groupedData = [];
	let lastStack = null;

	sortedData.forEach(item => {
		if (lastStack !== item.bench.stack) {
			if (lastStack !== null) {
				// Добавляем разделитель только если это не первый элемент группы
				groupedData.push({ id: `divider-${item.bench.stack}`, divider: true });
			}
			lastStack = item.bench.stack;
		}
		groupedData.push(item);
	});

	return groupedData;
};

export const formatBenchStat = candidatesData => {
	const { benchStatistics, daysOnBenchStacks } = candidatesData;

	return benchStatistics.map(statistic => {
		const element = daysOnBenchStacks.find(elem => elem.stackId === statistic.bench?.stackId);
		const count = element ? element.count : null;

		// Возвращаем новый объект с добавленными полями
		return {
			...statistic,
			id: statistic.bench.cvId,
			daysOnBenchStack: count
		};
	});
};
