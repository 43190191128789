import React, { useState } from "react";

import { CreateSurveyList } from "../../../../../api/api";
import { Button, Alert, Snackbar } from "@mui/material";

import copy from "copy-to-clipboard";
import { useEffect } from "react";
import {buttonStyles} from "../../../../../styles/default-styles/button-styles/button-styles";

export const CreateSurveyButton = ({ stackID, staffID }) => {
  const [checkTokenError, setCheckTokenError] = useState();
  const [copied, setCopied] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const createNewSurvey = () => {
    setIsLoading(true);

    CreateSurveyList(staffID, stackID)
      .then((data) => {
        copy(data.link);
        setCopied(true);
        setIsLoading(false);
      })
      .catch((requestError) => {
        console.error(requestError.message);
        setIsLoading(false);
        if (requestError.response.status === 401) {
          localStorage.setItem("tokenError", true);
          setCheckTokenError(!checkTokenError);
        }
      });
  };

  return (
    <>
      <Button
        onClick={createNewSurvey}
        disabled={isLoading}
        variant="contained"
        color="primary"
        size="large"
        sx={{
            ...buttonStyles.orangeButton,
        }}
      >
        Ссылка на опрос
      </Button>

      <Snackbar
        open={copied}
        autoHideDuration={3000}
        onClose={() => setCopied(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="info" onClose={() => setCopied(false)}>
          Ссылка на опросник скопирована в буфер обмена
        </Alert>
      </Snackbar>
    </>
  );
};
