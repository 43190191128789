import React from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress } from "@mui/material";

export const ConvertResumeButton = ({ loading, uploadFile, selectedFile }) => {
  const isDisabled = !selectedFile;

  return (
    <Button variant="contained" sx={{ mt: 2 }} onClick={uploadFile} disabled={isDisabled || loading}>
      {isDisabled ? "Выберите файл" : loading ? <CircularProgress size={24} /> : "Конвертировать"}
    </Button>
  );
};

ConvertResumeButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  uploadFile: PropTypes.func.isRequired,
  selectedFile: PropTypes.object,
};
