import React, { useState } from "react";
import { CVWorkExperienceDeleteRequest } from "../../../../../api/api";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { buttonStyles } from "../../../../../styles/default-styles/button-styles/button-styles";

export const DeleteCvWorkExperience = ({ id, check, setCheck }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleConfirm = () => {
    CVWorkExperienceDeleteRequest(id)
      .then(() => {
        setCheck(!check);
      })
      .catch((requestError) => console.error(requestError.message));
    setIsDialogOpen(false);
  };

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <DeleteIcon fontSize="small" />
      </IconButton>
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Подтверждение</DialogTitle>
        <DialogContent>
          Вы уверены что хотите удалить опыт работы?
        </DialogContent>
        <DialogActions>
          <Button
            sx={buttonStyles.orangeButton}
            onClick={handleDialogClose}
            color="primary"
          >
            Отмена
          </Button>
          <Button
            sx={buttonStyles.orangeButton}
            onClick={handleConfirm}
            color="primary"
          >
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
