import React, { useContext, useMemo, useState } from 'react';
import copy from 'copy-to-clipboard';
import { GenerateBenchMapWithStatistics, GenerateRemovedBenchMapWithStatistics } from '../../../../api/api';

import { Box, Button, Tab, Tabs, Tooltip, Typography } from '@mui/material';

import { ModalWithTables } from '../../../lib/ui/modals/modal-with-tables';
import { BenchTableRow } from './bench-table-row';
import { BENCH_HEADER_COLUMNS, BENCH_MAP_CONFIG, TABS_CONFIG } from './config';
import { requestErrorWrapper } from '../../../../utils/request-error-wrapper/request-error-wrapper';
import { buttonStyles } from '../../../../styles/default-styles/button-styles/button-styles';
import { StyledTabs } from '../../../../styles/default-styles/styled-tabs/StyledTabs';
import { AlertContext } from '../../../contexts/AlertContext/AlertContext';
import { formatBenchStat, sortAndGroupDataByStack } from './utils';

export const BenchMap = () => {
	const [open, setOpen] = useState(false);
	const [currentBenchArr, setCurrentBenchArr] = useState([]);
	const [previousBenchArr, setPreviousBenchArr] = useState([]);
	const [candidatesIsLoaded, setCandidatesIsLoaded] = useState(false);
	const [tabValue, setTabValue] = useState(0);
	const { showAlert } = useContext(AlertContext);
	const { COPY_MESSAGE } = BENCH_MAP_CONFIG;

	const handleOpen = () => {
		generateBench();
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const getCandidatesStat = () =>
		requestErrorWrapper({
			handleRequest: GenerateBenchMapWithStatistics
		});

	const getRemovedCandidatesStat = () =>
		requestErrorWrapper({
			handleRequest: GenerateRemovedBenchMapWithStatistics
		});

	const getBenchStatistics = async () => {
		const [candidatesStat, removedCandidatesStat] = await Promise.all([
			getCandidatesStat(),
			getRemovedCandidatesStat()
		]);

		return [candidatesStat, removedCandidatesStat];
	};

	// форматирование и фильтр необходимых для бенч-карты данных
	const sortAndFormatCandidatesData = async () => {
		const [candidatesStat, removedCandidatesStat] = await getBenchStatistics();
		// Создание нового массива с измененными данными
		let formattedCurrentStat = formatBenchStat(candidatesStat);
		let formattedPreviousStat = formatBenchStat(removedCandidatesStat);

		formattedCurrentStat = sortAndGroupDataByStack(formattedCurrentStat);
		formattedPreviousStat = sortAndGroupDataByStack(formattedPreviousStat);

		return [formattedCurrentStat, formattedPreviousStat];
	};

	// метод генерации итоговых данных (триггер)
	const generateBench = async () => {
		try {
			setCandidatesIsLoaded(false);

			const [formattedCurrentStat, formattedPreviousStat] = await sortAndFormatCandidatesData();

			if (formattedCurrentStat.length > 0) {
				setCurrentBenchArr(formattedCurrentStat);
			}
			if (formattedPreviousStat.length > 0) {
				setPreviousBenchArr(formattedPreviousStat);
			}

			setCandidatesIsLoaded(true);
		} catch (error) {
			console.error('Ошибка генерации бенч карты', error);
		}
	};

	const handleBenchCopy = data => {
		let result = data;
		let copyText = '';
		result.forEach(item => {
			if (!item.divider) {
				copyText += `${item.bench.stack} ${item.bench.shortName} ${item.bench.grade} ${item.bench.totalWorkExpirience} лет опыта, ставка - ${item.bench.rate}₽\n`;
			}
		});

		const isCopied = copy(copyText);

		if (isCopied) {
			showAlert({ type: 'info', message: COPY_MESSAGE });
		}
	};

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	const modalCustomStyles = {
		size: 'fs',
		isOpen: open,
		onClose: handleClose,
		dataIsLoaded: candidatesIsLoaded
	};

	const config = {
		sections: [
			{
				table: {
					columns: BENCH_HEADER_COLUMNS,
					data: tabValue === 0 ? currentBenchArr : previousBenchArr,
					CustomTableRow: BenchTableRow,
					dataIsLoaded: true,
					options: {
						tableWidth: '100%',
						tableHeight: '100%',
						tableStyles: {
							padding: '0px 10px 0px 10px'
						},
						tableContainerStyles: {
							overflow: 'auto',
							height: '100%'
						},
						height: '95%'
					}
				}
			}
		]
	};

	return (
		<>
			<Tooltip title='Сформировать бенч карту по кандидатам, готовым с нами работать'>
				<Button
					variant='contained'
					color='primary'
					size='large'
					sx={{
						bgcolor: '#ef6c00',
						borderRadius: '3%',
						color: '#FFFFFF',
						fontWeight: 'bold',
						maxHeight: '60px',
						'&:hover': {
							bgcolor: '#ff9800',
							color: '#FFFFFF',
							transform: 'scale(1.03)'
						}
					}}
					onClick={handleOpen}>
					Бенч карта
				</Button>
			</Tooltip>

			<ModalWithTables
				modalCustomStyles={modalCustomStyles}
				config={config}
				content={
					<Box
						sx={{
							display: 'flex',
							flexGrow: 1,
							justifyContent: 'space-between',
							alignItems: 'center',
							width: '95%',
							paddingBottom: 1
						}}>
						<Typography
							id='keep-mounted-modal-title'
							variant='h6'
							component='h2'
							sx={{
								fontSize: '40px',
								color: '#ef6c00'
							}}>
							Бенч карта
						</Typography>

						<Tabs sx={StyledTabs.styledTabs} value={tabValue} onChange={handleTabChange}>
							{TABS_CONFIG.map(({ id, label }) => (
								<Tab key={id} label={label} sx={{ ...StyledTabs.styledTab, width: 150 }} />
							))}
						</Tabs>

						<Button
							variant='contained'
							color='primary'
							size='large'
							sx={buttonStyles.orangeButton}
							onClick={() => handleBenchCopy(tabValue === 0 ? currentBenchArr : previousBenchArr)}>
							Копировать
						</Button>
					</Box>
				}
			/>
		</>
	);
};
