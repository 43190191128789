import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';


export const ModalContainer = styled(Box)(({ theme, styles }) => ({
	position: 'absolute',
	top: '50%',
	left: '50%',
	minHeight: '30%',
	transform: 'translate(-50%, -50%)',
	backgroundColor: '#fff',
	boxShadow: '0 0 15px rgba(0, 0, 0, 0.3)',
	padding: '1.5rem',
	borderRadius: '1rem',
	overflow: 'none',
	...styles
}));

export const CircularContainer = styled(Box)(({ theme }) => ({
	margin: 'auto',
	width: 'min-content',
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)'
}));
