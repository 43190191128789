import React, { useEffect, useState } from 'react';

import { TableHead, TableRow } from '@mui/material';

import { DealTableFormatDate } from '../../../../project-page/project-details-page/project-deal-information/deals-utils/deal-format-date';
import { StyledCell } from './recruiter-stats-table-head-styles';

export const RecruiterStatsTableHead = ({ statisticData }) => {
	const [statDates, setStatDates] = useState([]);

	useEffect(() => {
		if (statisticData) {
			let datesArray = [];

			const arrayOfStats = statisticData[0]?.statistics ?? [];

			for (const stat of arrayOfStats) {
				datesArray.push(DealTableFormatDate(stat.date));
			}

			setStatDates(datesArray);
		}
	}, [statisticData]);

	return (
		<TableHead>
			<TableRow>
				<StyledCell sx={{ borderRight: 0 }} data={statisticData} />
				<StyledCell data={statisticData} />
				<StyledCell data={statisticData} />
				<StyledCell data={statisticData}>Итого</StyledCell>
				{statDates.reverse().map(date => (
					<StyledCell data={statisticData} key={date}>
						{date}
					</StyledCell>
				))}
				<StyledCell data={statisticData} />
			</TableRow>
		</TableHead>
	);
};
