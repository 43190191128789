import React, { useEffect, useState } from 'react';

import { Divider, Grid, Typography } from '@mui/material';

import { displayingQuotationMarks } from '../../../../../../utils/displaying-quotation-marks';
import { wrapTechnicalTerms } from '../../../../../../utils/wrap-technical-terms';
import {
	GridContainer,
	GridItem,
	GridItemLong,
	GridItemShort,
	GridItemWrapper
} from './cv-work-info-styles';

export const CvWorkInfo = ({
	id,
	companyCity,
	companyName,
	description,
	duration,
	end,
	isRelevant,
	position,
	start
}) => {
	const monthMap = {
		1: 'Январь',
		2: 'Февраль',
		3: 'Март',
		4: 'Апрель',
		5: 'Май',
		6: 'Июнь',
		7: 'Июль',
		8: 'Август',
		9: 'Сентябрь',
		10: 'Октябрь',
		11: 'Ноябрь',
		12: 'Декабрь'
	};

	const [wrappedText, setWrappedText] = useState('');

	const makeDatePrettier = date => {
		if (date) {
			let parsedDate = new Date(date);
			let month = parsedDate.getMonth(); // Это уже число от 0 до 11
			let year = parsedDate.getFullYear();
			return `${monthMap[month + 1]} ${year}`;
		} else {
			return 'По настоящее время';
		}
	};

	useEffect(() => {
		if (description) {
			wrapTechnicalTerms(description, setWrappedText);
		}
	}, [description]);

	return (
		<GridItemWrapper sm={6} md={6}>
			<GridContainer>
				<GridItemShort>
					<Typography
						variant='h6'
						component='h2'
						sx={{
							fontSize: '15px'
						}}
					>
						{makeDatePrettier(start)} - {makeDatePrettier(end)}
					</Typography>
					<Typography
						variant='h6'
						component='h2'
						sx={{
							fontSize: '15px'
						}}
					>
						{duration ? duration : '-'}
					</Typography>
					<Typography
						variant='h6'
						component='h2'
						sx={{
							fontSize: '15px'
						}}
					>
						{isRelevant ? 'Релевантный опыт' : 'Не релевантный опыт'}
					</Typography>
				</GridItemShort>
				<GridItemLong>
					<Typography
						variant='h6'
						component='h2'
						sx={{
							fontSize: '15px',
							fontWeight: companyName ? 'bold' : ''
						}}
					>
						{companyName ? displayingQuotationMarks(companyName) : '-'}
					</Typography>
					<Typography
						variant='h6'
						component='h2'
						sx={{
							fontSize: '15px'
						}}
					>
						{companyCity ? companyCity : '-'}
					</Typography>
					<Typography
						variant='h6'
						component='h2'
						sx={{
							fontSize: '15px',
							fontWeight: position ? 'bold' : ''
						}}
					>
						{position ? position : '-'}
					</Typography>

					<Typography
						variant='h6'
						component='h2'
						sx={{
							fontSize: '15px'
						}}
						style={{ whiteSpace: 'pre-wrap' }}
						// dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description.replace())}}
						dangerouslySetInnerHTML={{
							__html: wrappedText
						}}
					/>
				</GridItemLong>
			</GridContainer>
		</GridItemWrapper>
	);
};
