import React, { useEffect } from 'react';
import { Box, Stack, TextField } from '@mui/material';
import { textFieldStyles } from '../../../../../styles/default-styles/text-field-styles/text-field-styles';
import { INPUT_VARIATIONS_LIST_CONFIG } from './config';

export const InputVariationsList = ({ values = [], setValues, config, options = {} }) => {
	const { defaultVariations = [] } = options;
	const { LABEL, SPLIT_REGEX } = { ...INPUT_VARIATIONS_LIST_CONFIG, ...config };

	const handleChange = ({ id, value, e }) => {
		const variationsString = e.target.value;

		const variationsArr = variationsString.split(SPLIT_REGEX).filter(item => !!item);

		setValues(prevValues =>
			prevValues.map((item, index) => {
				if (index === id) {
					if (variationsArr.join().length > 0) {
						return { ...item, value: value, variants: variationsArr };
					} else {
						return { ...item, value: value };
					}
				} else {
					return item;
				}
			})
		);
	};

	return (
		<Box>
			<Stack gap={2} sx={{ overflowY: 'auto', maxHeight: '27vh', pt: '6px' }}>
				{values.map((item, index) => (
					<TextField
						sx={textFieldStyles.orangeTextField}
						key={index}
						label={`${LABEL} ${item.value}`}
						onInput={e => handleChange({ id: index, value: item.value, e })}
						defaultValue={defaultVariations && defaultVariations.join(', ')}
					/>
				))}
			</Stack>
		</Box>
	);
};
