import React, { useState, useEffect } from 'react';

import { CVWorkExperienceUpdateRequest } from '../../../../../../api/api';

import { Box, Grid, Typography } from '@mui/material';

import { TextFieldCV } from '../../ui/text-field-CV';
import { TextFieldCVWrapper } from '../../ui/text-field-CV-wrapper';

import { ChangeExperienceCV } from '../../ui/change-experience-CV';
import { DateExperienceFieldCV } from '../../ui/date-experience-field-CV';
import { SwitchChangeExperienceCV } from '../../ui/switch-change-experience-CV';
import { DeleteCvWorkExperience } from '../../delete-cv-work-experience';

import { displayingQuotationMarks } from '../../../../../../utils/displaying-quotation-marks';
import {
	EditExpContainer,
	GridContainer,
	LongGridItem,
	RelExpContainer,
	ShortGridItem
} from './updating-cv-work-info-styles';

export const UpdatingCvWorkInfo = ({
	id,
	companyCity,
	companyName,
	description,
	duration,
	end,
	isRelevant,
	position,
	start,
	check,
	setCheck,
	setAlertIsOpen
}) => {
	const [companyNameData, setCompanyNameData] = useState(null);
	const [companyCityData, setCompanyCityData] = useState(null);

	const [descriptionData, setDescriptionData] = useState(null);
	const [positionData, setPositionData] = useState(null);

	const [startData, setStartData] = useState(null);
	const [endData, setEndData] = useState(null);

	const [isRelevantData, setIsRelevantData] = useState(null);
	const [isCurrently, setIsCurrently] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				await CVWorkExperienceUpdateRequest(
					id,
					companyNameData,
					companyCityData,
					descriptionData,
					positionData,
					startData,
					endData,
					isRelevantData,
					isCurrently
				);
			} catch (error) {
				setAlertIsOpen(true);
				console.log(error.message);
			}
		};

		if (
			companyNameData ||
			companyNameData === '' ||
			companyCityData ||
			companyCityData === '' ||
			descriptionData ||
			descriptionData === '' ||
			positionData ||
			positionData === '' ||
			startData ||
			endData ||
			isRelevantData ||
			isCurrently !== null ||
			isRelevantData === false
		) {
			fetchData().then(() => setCheck(!check));
		}
	}, [
		companyNameData,
		companyCityData,
		descriptionData,
		positionData,
		startData,
		endData,
		isRelevantData,
		isCurrently,
		id
	]);

	return (
		<GridContainer>
			<ShortGridItem>
				<Box
					sx={{
						bgcolor: '#fff5e6'
					}}
				>
					<Box>
						<DateExperienceFieldCV
							textData={start}
							setData={setStartData}
							setAlertIsOpen={setAlertIsOpen}
						/>
					</Box>
					<Box>
						<DateExperienceFieldCV
							setIsCurrently={setIsCurrently}
							textData={end}
							setData={setEndData}
							setAlertIsOpen={setAlertIsOpen}
						/>
					</Box>
				</Box>

				<Typography
					variant='h6'
					component='h2'
					sx={{
						fontSize: '15px'
					}}
				>
					{duration ? duration : '-'}
				</Typography>
				<RelExpContainer>
					<Typography
						variant='h6'
						component='h2'
						sx={{
							fontSize: '15px',
							width: 'fit-content'
						}}
					>
						{isRelevant ? 'Релевантный опыт' : 'Не релевантный опыт'}
					</Typography>
					<SwitchChangeExperienceCV
						textData={isRelevant}
						setData={setIsRelevantData}
					/>
				</RelExpContainer>

				<EditExpContainer>
					<ChangeExperienceCV
						id={id}
						check={check}
						setCheck={setCheck}
						percent={30}
					/>
					<ChangeExperienceCV
						id={id}
						check={check}
						setCheck={setCheck}
						percent={-30}
					/>
				</EditExpContainer>
			</ShortGridItem>
			<LongGridItem>
				<Grid container>
					<Grid item xs={11}>
						<Box
							sx={{
								fontSize: '15px',
								fontWeight: companyName ? 'bold' : '',
								minHeight: 50,
								minWidth: 50,
								bgcolor: '#fff5e6'
							}}
						>
							<TextFieldCV
								textData={
									companyName ? displayingQuotationMarks(companyName) : '-'
								}
								setData={setCompanyNameData}
								weight={true}
							/>
						</Box>
					</Grid>
					<Grid item xs={1}>
						<DeleteCvWorkExperience id={id} check={check} setCheck={setCheck} />
					</Grid>
				</Grid>
				<Box
					sx={{
						mt: 1,
						bgcolor: '#fff5e6'
					}}
				>
					<Box>
						<TextFieldCV
							textData={companyCity ? companyCity : '-'}
							setData={setCompanyCityData}
						/>
					</Box>
					<Box sx={{ height: '16px' }} />
					<Box
						sx={{
							fontSize: '15px',
							fontWeight: position ? 'bold' : ''
						}}
					>
						<TextFieldCV
							textData={position ? position : '-'}
							setData={setPositionData}
							weight={true}
						/>
					</Box>
					<Box sx={{ height: '16px' }} />
					<Box>
						<TextFieldCVWrapper
							textData={description ? description : '-'}
							setData={setDescriptionData}
						/>
					</Box>
				</Box>
			</LongGridItem>
		</GridContainer>
	);
};
