import React, { useState, useEffect } from "react";

import { Switch } from "@mui/material";

export const SwitchChangeExperienceCV = ({ textData, setData }) => {
  const [checked, setChecked] = useState(true);
  useEffect(() => {
    if (textData !== null && textData !== undefined) {
      setChecked(textData);
    }
  }, [textData]);

  const handleChange = (element) => {
    setChecked(element.target.checked);
    setData(element.target.checked);
  };
  const label = { inputProps: { "aria-label": "controlled" } };

  return (
    <Switch
      {...label}
      checked={checked}
      onChange={handleChange}
      color="warning"
    />
  );
};
