import React from "react";

import { styled } from "@mui/material";
import { Box } from "@mui/material";

const StyledBoxImage = styled(Box)`
  minwidth: "100%";
  height: "100%";
  animation: rotation 8s infinite linear;
  background: transparent;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export const LoaderLogo = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          objectFit: "cover",
          width: "75%",
          height: "75%",
        }}
      >
        <StyledBoxImage component="img" alt="TechCrew" src="/loader/logo_transparent_500_500.png" />
      </Box>
    </Box>
  );
};
