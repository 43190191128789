import React, { useState, useEffect, useCallback } from 'react';
import { TextInput } from './text-field-stack-cv-styles';

export const TextFieldStackCV = ({
	textData,
	setData,
	characterWidth = false,
	yearField
}) => {
	const [term, setTerm] = useState('');
	const [termTime, setTermTime] = useState();
	const [width, setWidth] = useState();
	const [isChanged, setIsChanged] = useState(false);

	useEffect(() => {
		if (textData !== null && textData !== undefined) {
			setTerm(textData);
			setWidth(
				!yearField ? textData.length + 10 : textData.toString().length + 10
			);
		}
	}, [textData]);

	const handleChange = useCallback(element => {
		const term = element.target.value;
		setWidth(element.target.value.length + 10);
		setTerm(term.replace(/^[\s.`":'$@~;]*$/g, ''));
		setTermTime(term.replace(/^[\s.`":'$@~;]*$/g, ''));
		setIsChanged(true);
	}, []);

	const compareTwoValues = (previousData, newData) => {
		// check separately number and string values
		const cleanedPreviousData = !yearField
			? previousData.replace(/^[\s.`":'$@~;]*$/g, '')
			: previousData;

		return !yearField
			? cleanedPreviousData !== newData
			: cleanedPreviousData !== Number(newData);
	};

	useEffect(() => {
		const handleBeforeUnload = event => {
			// Perform actions before the component unloads
			if (isChanged && compareTwoValues(textData, termTime)) {
				event.preventDefault();
				event.returnValue = '';
			}
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [isChanged, termTime]);

	return (
		<TextInput
			value={term ? term : '-'}
			onChange={handleChange}
			onBlur={() => {
				setIsChanged(false);
				setData(termTime);
			}}
			characterwidth={characterWidth.toString()}
			width={width}
			multiline
			maxRows={2}
		/>
	);
};
