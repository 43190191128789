import React, { useState, useEffect, useCallback } from 'react';
import { InputBase, Divider, Typography, Paper } from '@mui/material';
import { CopyIconButton } from '../../../../lib/ui/buttons/copy-icon-button';
import { OpenWhatsAppTgWithNumber } from '../../../../lib/ui/buttons/open-whatsapp-tg-with-number';

export const TextFieldCard = ({
	textName,
	textstaff,
	setData,
	ageCard,
	birthDay,
	options = {}
}) => {
	const [term, setTerm] = useState();
	const [termTime, setTermTime] = useState();
	const [isChanged, setIsChanged] = useState(false);

	const { copied = false, isPhone = false } = options;

	useEffect(() => {
		if (textstaff !== null && textstaff !== undefined) {
			setTerm(textstaff);
		}
	}, [textstaff]);

	const handleChange = useCallback(element => {
		const term = element.target.value;
		setTerm(term.replace(/^[\s.`":'$@~;]*$/g, ''));
		setTermTime(term.replace(/^[\s.`":'$@~;]*$/g, ''));
		setIsChanged(true);
	}, []);

	useEffect(() => {
		const handleBeforeUnload = event => {
			// Perform actions before the component unloads
			if (
				isChanged &&
				textstaff.replace(/^[\s.`":'$@~;]*$/g, '') !== termTime
			) {
				event.preventDefault();
				event.returnValue = '';
			}
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [isChanged, termTime]);

	return (
		<Paper
			elevation={6}
			sx={{
				display: 'flex',
				alignItems: 'center',
				minHeight: '32px'
			}}
		>
			<Typography
				id='keep-mounted-modal-title'
				variant='h6'
				component='h2'
				sx={{
					display: 'flex',
					height: '100%',
					fontSize: '15px',
					bgcolor: '#fb8c00',
					color: '#852508',
					border: '1px solid',
					minWidth: '110px',
					textAlign: 'center',
					borderRadius: '4px',
					borderColor: '#a2a2a2',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				{textName}
			</Typography>
			<Divider sx={{ height: 24, ml: 0.5 }} orientation='vertical' />
			<InputBase
				value={term}
				disabled={ageCard && birthDay !== null}
				onBlur={() => {
					setData(termTime);
					setIsChanged(false);
				}}
				onChange={handleChange}
				multiline
				sx={{
					p: '2px 4px',
					ml: 1,
					flex: 1,
					fontSize: isPhone ? '14px' : '15px',
					width: isPhone ? '130px' : 'inherit'
				}}
			/>
			<Divider sx={{ height: 24, ml: 0.5 }} orientation='vertical' />
			{copied && <CopyIconButton copyString={term} itemName={textName} />}
			{isPhone && (
				<>
					<OpenWhatsAppTgWithNumber phoneNumber={textstaff} wa={false} />
					<OpenWhatsAppTgWithNumber phoneNumber={textstaff} wa={true} />
				</>
			)}
		</Paper>
	);
};
