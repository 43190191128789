import React, { useState, useEffect } from 'react';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { IconButton, Snackbar, SnackbarContent, Box } from '@mui/material';
import { getHHTokenStatus } from '../../../../../api/api';

export const NotificationsButton = () => {
	const [tokenStatus, setTokenStatus] = useState('TokenValid');
	const [openSnackBar, setOpenSnackBar] = useState(false);

	useEffect(() => {
		getHHTokenStatus()
			.then(data => {
				setTokenStatus(data.tokenStatus);
			})
			.catch(requestError => {
				console.error(requestError.message);
			});
	}, []);

	const handleClick = () => {
		setOpenSnackBar(true);
	};

	const handleClose = () => {
		setOpenSnackBar(false);
	};

	return (
		<Box style={{ position: 'relative' }}>
			<IconButton
				onClick={handleClick}
				color='inherit'
				aria-label='notification'
				disabled={tokenStatus !== 'TokenValid' ? false : true}
			>
				<NotificationsNoneOutlinedIcon style={{ fontSize: '40px' }} />
			</IconButton>
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				open={openSnackBar}
				autoHideDuration={2000}
				onClose={handleClose}
				style={{ top: '4rem', transform: 'translateX(1rem)' }}
			>
				<SnackbarContent
					style={{
						backgroundColor: 'white',
						color: 'inherit',
						fontSize: '20px'
					}}
					message='Токен не обновлен!'
				/>
			</Snackbar>
			{tokenStatus !== 'TokenValid' ? (
				<div
					style={{
						position: 'absolute',
						top: 18,
						right: 15,
						width: 12,
						height: 12,
						borderRadius: '50%',
						backgroundColor: 'red'
					}}
				></div>
			) : (
				''
			)}
		</Box>
	);
};
