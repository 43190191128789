import React, { useState } from "react";

import { Button, Dialog, DialogTitle, DialogActions } from "@mui/material";

import { sendToDetachStuffFromProject } from "../../../../../../api/api";

export const DeleteStuffButton = ({ projectId, staffID, check, setCheck }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    sendToDetachStuffFromProject(projectId, staffID).then(() => {
      handleClose();
      setCheck(!check);
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        sx={{
          bgcolor: "#ef6c00",
          borderRadius: "3%",
          color: "#FFFFFF",
          fontWeight: "bold",
          mr: 1,
          "&:hover": {
            bgcolor: "#ff9800",
            color: "#FFFFFF",
            transform: "scale(1.03)",
          },
        }}
        onClick={handleOpen}
      >
        Открепить
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Вы точно хотите открепить Кандидата из проекта?
        </DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{
              bgcolor: "#ef6c00",
              borderRadius: "3%",
              color: "#FFFFFF",
              fontWeight: "bold",
              mr: 1,
              "&:hover": {
                bgcolor: "#ff9800",
                color: "#FFFFFF",
                transform: "scale(1.03)",
              },
            }}
            onClick={handleDelete}
          >
            Открепить Кандидата
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              bgcolor: "#ef6c00",
              borderRadius: "3%",
              color: "#FFFFFF",
              fontWeight: "bold",
              mr: 1,
              "&:hover": {
                bgcolor: "#ff9800",
                color: "#FFFFFF",
                transform: "scale(1.03)",
              },
            }}
            onClick={handleClose}
          >
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
