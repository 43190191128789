import React from "react";

import { Box, TextField } from "@mui/material";

export const ConfirmPhoneFields = ({
  confirmPhoneFields,
  setConfirmPhoneFields,
}) => {
  const handleChange = (id, value) => {
    const updatedTextFields = confirmPhoneFields.map((textField) =>
      textField.id === id ? { ...textField, value } : textField
    );
    setConfirmPhoneFields(updatedTextFields);
  };

  return (
    <>
      {confirmPhoneFields.map((field) => (
        <Box
          key={field.id}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "1rem",
            pt: "10px",
          }}
        >
          <TextField
            fullWidth
            variant="standard"
            label={`Укажите код подтверждения для: ${field.phoneNumber}`}
            type="number"
            value={field.value}
            onChange={(e) => handleChange(field.id, e.target.value)}
            InputLabelProps={{
              style: {
                fontSize: "17px",
              },
            }}
            InputProps={{
              style: {
                fontSize: "20px",
              },
            }}
          />
        </Box>
      ))}
    </>
  );
};
