import React, { useState, useEffect, useCallback, useRef } from "react";
import { InputBase, Typography, Box } from "@mui/material";
import { wrapTechnicalTerms } from "../../../../../../utils/wrap-technical-terms";

export const TextFieldCVWrapper = ({ textData, setData, weight }) => {
  const [term, setTerm] = useState("");
  const [termTime, setTermTime] = useState();
  const [focused, setFocused] = useState(false);
  const [wrappedText, setWrappedText] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (textData !== null && textData !== undefined) {
      setTerm(textData);
    }
  }, [textData]);

  useEffect(() => {
    wrapTechnicalTerms(textData, setWrappedText);
  }, []);

  const handleChange = useCallback((element) => {
    const term = element.target.value;
    setTerm(term);
    setTermTime(term);
    setIsChanged(true);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Perform actions before the component unloads
      if (isChanged && textData.replace(/^[\s.`":'$@~;]*$/g, "") !== termTime) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isChanged, termTime]);

  const handleBoxClick = () => {
    setFocused(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  return focused ? (
    <InputBase
      inputRef={inputRef}
      value={term}
      onChange={handleChange}
      onBlur={() => {
        const trimmedTerm = term.trimEnd(); // Удаление пробелов и пустых строк в конце строки
        setTerm(trimmedTerm); // Обновление состояния без лишних пробелов и пустых строк
        wrapTechnicalTerms(trimmedTerm, setWrappedText);
        setData(trimmedTerm);
        setIsChanged(false);
        setTimeout(() => {
          setFocused(false);
        }, 200)
      }}
      multiline
      fullWidth
      sx={{
        p: 1,
        fontSize: "15px",
        fontWeight: weight ? "bold" : "",
        backgroundColor:  "#f6e9d6" , // Изменение цвета фона при фокусе
        transition: "background-color 0.3s",
      }}
    />
  ) : (
    <Box
      onClick={() => handleBoxClick()}
      onBlur={() => {
        setFocused(false);
      }}
      sx={{
        minHeight: 50,
        p: 1,
        transition: "background-color 0.1s",
        "&:hover": {
          backgroundColor: "#fcf0de",
          cursor: "pointer"
        },
      }}
    >
      <Typography
        variant="h6"
        component="h2"
        dangerouslySetInnerHTML={{
          __html: wrappedText,
        }}
        style={{ whiteSpace: "pre-wrap", wordBreak: "normal" }}
        sx={{
          fontSize: "15px",
          fontWeight: weight ? "bold" : "",
        }}
      />
    </Box>
  );
};
