import { keyframes, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { orange } from '@mui/material/colors';
import { calculateWidth } from './utils';

const growWidth = width => keyframes`
  0% {
    width: 0;
  }
  100% {
    width: ${width}px;
  }
`;

const appearLabel = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

export const ProgressElementStyled = styled(Box)(({ theme, styles, width }) => ({
	width: calculateWidth(width) + 'px',
	backgroundColor: width > 49 ? (width > 79 ? orange[900] : orange[700]) : orange[600],
	display: 'flex',
	height: 30,
	alignItems: 'center',
	color: 'white',
	fontWeight: 700,
	animation: `${growWidth(calculateWidth(width))} 0.3s ease-in-out forwards`,
	letterSpacing: '.4px',
	...styles
}));

export const ProgressLabel = styled('span')(({ theme, widthpercent }) => ({
	width: '100%',
	position: 'absolute',
	padding: 0,
	fontSize: widthpercent < 2 ? '10px' : '16px',
	letterSpacing: '.1px',
	paddingLeft: '5px',
	animation: `${appearLabel} 0.6s ease forwards`,
	textAlign: 'start'
}));
