import React from 'react';
import { TableCell, TableRow, LinearProgress, Typography } from '@mui/material';
import { cellStyles, defineColors } from './collapse-row-stack-styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { calculatePercentage } from '../../../utils';
import { StatTypography } from '../stat-typography';

export const CollapseRowStack = React.memo(
	({ recruiter, recruiterIndex, rowOptions, isOpen, onOpen }) => {
		const { mainRow, collapseRows } = rowOptions;

		return (
			<React.Fragment>
				<TableRow onClick={() => onOpen()}>
					<TableCell
						sx={{
							p: 0,
							backgroundColor: defineColors(recruiterIndex % 2),
							borderBottom: 0
						}}
					>
						{mainRow.isFirstRow &&
							(isOpen ? (
								<ArrowDropUpIcon
									color='warning'
									sx={{ m: 'auto', cursor: 'pointer' }}
								/>
							) : (
								<ArrowDropDownIcon sx={{ m: 'auto', cursor: 'pointer' }} />
							))}
					</TableCell>
					<TableCell
						sx={{
							backgroundColor: defineColors(recruiterIndex % 2),
							...cellStyles.head,
							borderBottom: 0,
							borderRight: 0
						}}
					>
						<StatTypography
							value={mainRow.isFirstRow && recruiter.name}
							align='left'
						/>
					</TableCell>
					<TableCell
						sx={{
							backgroundColor: defineColors(recruiterIndex % 2),
							...cellStyles.head
						}}
					>
						<StatTypography value={mainRow.name} align='left' />
					</TableCell>
					<TableCell
						sx={{
							backgroundColor: defineColors(recruiterIndex % 2),
							...cellStyles.body,
							...cellStyles.subheader
						}}
					>
						<StatTypography value={mainRow.total} />
					</TableCell>
					{recruiter?.statistics
						.map((statistic, index) => mainRow.statCell({ statistic, index }))
						.reverse()}
					<TableCell
						sx={{
							backgroundColor: defineColors(recruiterIndex % 2),
							...cellStyles.body,
							...cellStyles.subheader,
							padding: 0
						}}
					>
						<LinearProgress
							sx={{
								minWidth: 80,
								minHeight: 20,
								backgroundColor: 'transparent'
							}}
							variant='determinate'
							value={calculatePercentage(mainRow.total, mainRow.maxStatistic)}
						/>
					</TableCell>
				</TableRow>

				{isOpen && (
					<>
						{collapseRows.map(row => (
							<TableRow key={row.name}>
								<TableCell
									sx={{
										backgroundColor: defineColors(recruiterIndex % 2),
										p: 0,
										borderBottom: 0
									}}
								></TableCell>
								<TableCell
									sx={{
										backgroundColor: defineColors(recruiterIndex % 2),
										...cellStyles.head,
										borderBottom: 0
									}}
								></TableCell>
								<TableCell
									sx={{
										...cellStyles.head,
										...cellStyles.subheader
									}}
								>
									{row.name}
								</TableCell>
								<TableCell sx={cellStyles.body}>
									<StatTypography value={row.total} />
								</TableCell>
								{recruiter.statistics
									.map((statistic, index) => row.statCell({ statistic, index }))
									.reverse()}
								<TableCell
									sx={{
										...cellStyles.body,
										padding: 0
									}}
								>
									<LinearProgress
										sx={{
											minWidth: 80,
											minHeight: 20,
											backgroundColor: 'transparent'
										}}
										variant='determinate'
										value={calculatePercentage(row.total, row.maxStatistic)}
									/>
								</TableCell>
							</TableRow>
						))}
					</>
				)}
			</React.Fragment>
		);
	}
);
