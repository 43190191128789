import React from 'react';
import { FilterSources } from '../../../../pages/sources-page/sources-table/sources-table-header/filter-sources';

export const FilterMenuSearchList = ({
	setActiveFilters,
	filterList,
	filterGroup,
	savedFilterValues,
	setSavedFilterValues,
	}) => {
	return (
			<FilterSources
				filterGroup={filterGroup}
				filterOptions={savedFilterValues}
				setFilter={setSavedFilterValues}
				filterValues={filterList}
				setActiveFilters={setActiveFilters}
			/>
	);
}
