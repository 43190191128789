import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

export const StyledInput = styled(TextField)(({ theme, weight }) => ({
	'& .MuiInput-root': {
		padding: '0',
		backgroundColor: '#fff5e6'
	},
	fontSize: '15px',
	fontWeight: weight ? 'bold' : '',
	transition: 'background-color 0.3s',
	width: 170,
	[theme.breakpoints.down('md')]: {
		width: '100%'
	}
}));
