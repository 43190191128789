import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';
import { orange } from '@mui/material/colors';
import { boxStyles } from '../../../../../../styles/default-styles/box-styles/box-styles';

export const StyledCard = styled(Card)(({ theme }) => ({
	...boxStyles.flexBoxRow,
	justifyContent: 'space-between',
	display: 'flex',
	minHeight: '80px',
	alignItems: 'center',
	padding: '6px 15px',
	backgroundColor: orange[400],
	width: '100%,',
	height: '100%',
	transition: 'all 0.3s ease-in-out',
	'&:hover': {
		transform: 'scale(1.02)',
		backgroundColor: orange[300]
	},
	cursor: 'pointer',
	boxSizing: 'border-box'
}));
