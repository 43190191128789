import React, { useState } from 'react';

import { Button, Box } from '@mui/material';

import { AddTermDialog } from './add-term-dialog';

import { buttonStyles } from '../../../../../styles/default-styles/button-styles/button-styles';

export const AddTermButton = ({
	check,
	setCheck,
	filtersByCategory,
	filterList,
	techTerms
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	return (
		<Box sx={{ ml: 2 }}>
			<Button
				onClick={handleOpen}
				sx={{
					...buttonStyles.orangeButton,
					padding: '8px 15px',
					fontSize: '15px'
				}}
			>
				Добавить термин
			</Button>
			<AddTermDialog
				techTerms={techTerms}
				isOpen={isOpen}
				filterList={filterList}
				handleClose={handleClose}
				filtersByCategory={filtersByCategory}
				check={check}
				setCheck={setCheck}
			/>
		</Box>
	);
};
