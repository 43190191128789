import React from 'react';
import {
	Box,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Typography
} from '@mui/material';
import isEqual from 'lodash/isEqual';
import { checkBoxStyles } from '../../../../../styles/default-styles/check-box-styles/check-box-styles';

export const FilterMenuCategories = ({
	filterGroup,
	filterList,
	activeFilters,
	processCheckListFilter,
	setActiveFilters
}) => {
	// Функция активации/деактивации всех фильтров
	const activateAllFilters = () => {
		setActiveFilters(prevFilters => {
			// Копируем текущие активные фильтры
			const updatedFilters = { ...prevFilters };

			// Получаем массив всех стаков из filterList
			const allFilters = Object.values(filterList)
				.flatMap(filter => filter)
				.sort();

			// Сравниваем массив стаков из filterList с активными фильтрами
			if (!isEqual(allFilters, activeFilters?.sort())) {
				// Если стаки различаются, обновляем активные фильтры
				updatedFilters[filterGroup] = [...allFilters];
			} else {
				// Если стаки совпадают, удаляем группу фильтров из активных фильтров
				delete updatedFilters[filterGroup];
			}

			return updatedFilters;
		});
	};

	// Функция активации/деактивации фильтров по категории
	const activateCategoryFilters = filters => {
		setActiveFilters(prevFilters => {
			// Копируем текущие активные фильтры
			const updatedFilters = { ...prevFilters };
			// Фильтруем активные фильтры, оставляя только те, которых нет в выбранной категории
			const updatedActiveFilters = activeFilters?.filter(
				filter => !filters.includes(filter)
			);
			// Если все стаки уже в активных фильтрах, удаляем их оттуда (выключаем чекбокс)
			if (filters.every(stack => activeFilters?.includes(stack))) {
				if (updatedActiveFilters.length > 0) {
					updatedFilters[filterGroup] = updatedActiveFilters;
				} else {
					// Если стаков внутри вообще не осталось, удаляем группу фильтров из активных фильтров
					delete updatedFilters[filterGroup];
				}
			} else {
				// Иначе добавляем в активные фильтры те стаки, которых еще нет
				const elementsToAdd = filters.filter(
					item => !activeFilters?.includes(item)
				);
				updatedFilters[filterGroup] = activeFilters ? [...activeFilters, ...elementsToAdd] : [...elementsToAdd];
			}

			return updatedFilters;
		});
	};

	return (
		<Box
			sx={{
				maxHeight: '80%',
				overflow: 'auto'
			}}
		>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: '1fr 3fr',
					borderBottom: '1px solid \t#D3D3D3'
				}}
			>
				<Typography
					m={2}
					variant='h6'
					sx={{ display: 'flex', alignItems: 'center' }}
				>
					Все фильтры:
				</Typography>
				<FormControlLabel
					sx={{ width: 'fit-content' }}
					control={
						<Checkbox
							sx={checkBoxStyles.orangeCheckBox}
							color='warning'
							checked={isEqual(
								Object.values(filterList)
									.flatMap(filter => filter)
									.sort(),
								activeFilters?.sort()
							)}
							onChange={activateAllFilters}
						/>
					}
					label=''
				/>
			</Box>
			{Object.entries(filterList).map(([stack, filters], index) => (
				<Box
					key={index}
					sx={{
						display: 'grid',
						gridTemplateColumns: '1fr 3fr',
						borderBottom: '1px solid \t#D3D3D3'
					}}
				>
					<FormControlLabel
						sx={{
							fontWeight: '600',
							fontSize: '1.2rem',
							width: 'fit-content',
							paddingLeft: '15px',
							height: 'fit-content'
						}}
						control={
							<Checkbox
								sx={{ ...checkBoxStyles.orangeCheckBox }}
								color='warning'
								checked={filters?.every(stack => activeFilters?.includes(stack))}
								onChange={() => activateCategoryFilters(filters)}
							/>
						}
						disableTypography
						label={stack + ':'}
					/>
					<FormGroup row={true}>
						{filters.map((filter, index) => (
							<FormControlLabel
								sx={{ width: 'fit-content' }}
								key={index}
								control={
									<Checkbox
										sx={checkBoxStyles.orangeCheckBox}
										color='warning'
										checked={activeFilters?.includes(filter)}
										onChange={event =>
											processCheckListFilter(filterGroup, filter, event, setActiveFilters)
										}
									/>
								}
								label={filter}
							/>
						))}
					</FormGroup>
				</Box>
			))}
		</Box>
	);
};
