import React from 'react';

import { Alert, Snackbar } from '@mui/material';

import Grow from '@mui/material/Grow';

export const MessageAlert = ({
	type = 'info',
	message = 'Нет данных',
	open = false,
	duration = 3000,
	setIsOpen
}) => {
	const handleClose = () => setIsOpen(false);

	return (
		<Snackbar
			open={open}
			onClose={handleClose}
			onClick={handleClose}
			autoHideDuration={duration}
			TransitionComponent={Grow}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			sx={{
				top: '100px',
				position: 'fixed',
				zIndex: 10000,
				cursor: 'pointer'
			}}
		>
			<Alert
				variant='filled'
				severity={type}
				sx={{ boxShadow: '0 0 15px rgba(0, 0, 0, 0.3)' }}
			>
				{message}
			</Alert>
		</Snackbar>
	);
};
