import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const GridContainer = styled(Box)(() => ({
	display: 'grid',
	gridTemplateColumns: 'repeat(12, 1fr)',
	gap: '1.5rem',
	'@media screen and (max-width: 1000px)': {
		gridTemplateColumns: 'repeat(6, 1fr)'
	}
}));

export const GridItem = styled(Box)(() => ({
	gridColumn: 'span 6'
}));
