import React, { useEffect, useState } from 'react';
import { Box} from '@mui/material';
import { getCommandStatistic } from '../../../../api/api';
import { AdminSubscribersBotSelector } from './admin-subscribers-bot-selector';
import { AdminSubscribersBotTable } from './admin-subscribers-bot-table';
import { DateFilterFromBefore } from '../../../lib/ui/date-filters';
import moment from 'moment/moment';

const STARTING_SUBSCRIBERS_BOTS_DATE = '2023-12-01';

export const AdminSubscribersBotTab = () => {
	const [data, setData] = useState(null);
	const [selectedBot, setSelectedBot] = useState(0);
	const [startDate, setStartDate] = useState(moment().format('YYYY-MM-01'));
	const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

	const fetchData = (From, Before) => {
		getCommandStatistic(From, Before)
			.then(response => {
				setData(response);
			})
			.catch(error => {
				console.error('Ошибка при получении данных о подписчиках:', error);
			});
	};

	useEffect(() => {
		fetchData(startDate, endDate);
	}, [startDate, endDate]);

	return (
		<Box sx={{ mt: 2, ml: 17, mr: 7 }}>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					mb: 2,
					mt: 2
				}}
			>
					<AdminSubscribersBotSelector
						selectedBot={selectedBot}
						setSelectedBot={setSelectedBot}
						bots={data ? data.bots : []}
					/>
					<DateFilterFromBefore
						minStartDate={STARTING_SUBSCRIBERS_BOTS_DATE}
						setEndDate={setEndDate}
						setStartDate={setStartDate}
						calendarSize='small'
					/>
			</Box>
			{data &&
				(selectedBot === 'all' ? (
					<AdminSubscribersBotTable
						bot={{
							name: 'Все боты',
							count: data.count,
							sources: data.bots.flatMap(bot => bot.sources)
						}}
					/>
				) : (
					data.bots.map(bot => (
						<React.Fragment key={bot.id}>
							{selectedBot === bot.id ? (
								<AdminSubscribersBotTable bot={bot} />
							) : null}
						</React.Fragment>
					))
				))}
			{!data || (Array.isArray(data) && data.length === 0) ? (
				<p>Нет данных</p>
			) : null}
		</Box>
	);
};
