import React from 'react';

import { Paper, Grid, List, ListItem, Box, Typography } from '@mui/material';
import { SurveyList } from './cv-resume-survey-styles';
import { ANSWER_DESCRIPTIONS } from './constants';

export const CvResumeSurvey = ({ surveyList, answersDescription }) => {
	return (
		<Paper
			style={{ backgroundColor: '#fff3e0' }}
			component='form'
			elevation={6}
			sx={{
				textAlign: 'left',
				p: '15px 25px',
				m: '0px 0px 30px 0px'
			}}
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<List
						sx={{
							display: 'flex',
							flexDirection: 'row',
							whiteSpace: 'nowrap'
						}}
					>
						{answersDescription
							? answersDescription.map((item, index) => (
									<ListItem
										sx={{
											display: 'flex',
											justifyContent: 'space-evenly',
											alignItems: 'center',
											fontWeight: 'bold',
											fontSize: '15px'
										}}
										key={index}
									>
										{`${item.id} - "${ANSWER_DESCRIPTIONS[item.id]}"`}
									</ListItem>
								))
							: ''}
					</List>
				</Grid>
				<Grid item xs={12} style={{ padding: 0 }}>
					<List
						sx={{
							padding: 0
						}}
					>
						{surveyList
							? surveyList.map((item, index) => (
									<SurveyList key={index}>
										<Box
											sx={{
												width: '100%',
												textAlign: 'center',
												backgroundColor: 'white',
												padding: '5px',
												borderRadius: '5px',
												marginBottom: '10px'
											}}
										>
											<Typography variant='h5'>
												{item.questionCollection}
											</Typography>
										</Box>
										<Box>
											{item.answers.map((subItem, index) => (
												<Typography
													sx={{ paddingLeft: '30px' }}
													variant='h6'
													key={index}
												>
													{`${subItem.answer}\u00A0\u00A0${subItem.question}`}
												</Typography>
											))}
										</Box>
									</SurveyList>
								))
							: 'Нет данных'}
					</List>
				</Grid>
			</Grid>
		</Paper>
	);
};
