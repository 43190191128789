export const ADMINISTRATION_PAGE_CONFIG = {
	TAB_SECTIONS: [
		{ id: 0, label: 'Активность' },
		{ id: 1, label: 'Админка' },
		{ id: 2, label: 'Технические термины' },
		{ id: 3, label: 'Дефолтные требования' },
		{ id: 4, label: 'UserBot' },
		{ id: 5, label: 'Администрирование' },
		{ id: 6, label: 'Подписчики ботов' }
	]
};
