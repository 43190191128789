import axios from 'axios';
const source = axios.CancelToken.source();

const addAuthorizationHeaderInterceptor = axiosInstance => {
	let isRefreshing = false;
	let failedRequestsQueue = [];

	axiosInstance.interceptors.request.use(
		config => {
			const accessToken = localStorage.getItem('accessToken');
			if (accessToken) {
				config.headers.Authorization = accessToken;
			}
			return config;
		},
		error => {
			return Promise.reject(error);
		}
	);

	axiosInstance.interceptors.response.use(
		response => {
			return response;
		},
		async error => {
			const originalRequest = error.config;

			if (
				error.response &&
				error.response.status === 401 &&
				!originalRequest._retry &&
				!isRefreshing
			) {
				originalRequest._retry = true;

				const refreshToken = localStorage.getItem('refreshToken');
				const accessToken = localStorage.getItem('accessToken');

				if (!refreshToken || !accessToken) {
					console.error('Refresh token not available. Logging out the user...');

					return Promise.reject(error);
				}

				isRefreshing = true;
				try {
					const response = await ApiClient.post('cv/api/Auth/RefreshToken', {
						refreshToken,
						accessToken
					});

					const newAccessToken = response.data.accessToken;
					const newRefreshToken = response.data.refreshToken;

					localStorage.setItem('accessToken', newAccessToken);
					localStorage.setItem('refreshToken', newRefreshToken);

					originalRequest.headers.Authorization = newAccessToken;

					failedRequestsQueue.forEach(failedRequest => {
						failedRequest.headers.Authorization = newAccessToken;
						axiosInstance(failedRequest)
							.then(res => failedRequest.resolve(res))
							.catch(err => failedRequest.reject(err));
					});
					failedRequestsQueue = [];

					return axiosInstance(originalRequest);
				} catch (refreshError) {
					console.error('Token refresh failed. Logging out the user...');

					return Promise.reject(refreshError);
				} finally {
					isRefreshing = false;
				}
			}

			return Promise.reject(error);
		}
	);
};

export const ApiClient = axios.create({
	baseURL: process.env.REACT_APP_API_HOST,
	cancelToken: source.token
});

addAuthorizationHeaderInterceptor(ApiClient);
