import React, { useEffect, useState } from "react";

import { Box, Grid, Typography, Divider } from "@mui/material";
import { wrapTechnicalTerms } from "../../../../../utils/wrap-technical-terms";

export const CvDetailsAboutInfo = ({ cvData }) => {
  const [wrappedText, setWrappedText] = useState("");

  useEffect(() => {
    if (cvData.hasOwnProperty("about") && cvData.about !== null) {
      wrapTechnicalTerms(cvData.about, setWrappedText);
    }
  }, [cvData]);

  return (
    <Box sx={{ display: cvData.about !== null ? "" : "none" }}>
      <Divider sx={{ height: 20, m: "0px 0px 20px 0px" }} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          marginBottom: "20px",
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          sx={{
            fontSize: "20px",
          }}
        >
          {"Обо мне"}
        </Typography>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            component="h2"
            dangerouslySetInnerHTML={{ __html: wrappedText }}
            style={{ whiteSpace: "pre-wrap" }}
            sx={{
              fontSize: "15px",
            }}
          ></Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
