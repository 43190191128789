import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { orange } from '@mui/material/colors';
import { boxStyles } from '../../../../../../styles/default-styles/box-styles/box-styles';

export const GridContainer = styled(Box)(() => ({
	display: 'grid',
	gridTemplateColumns: 'repeat(12, 1fr)',
	gap: '1rem',
	boxSizing: 'border-box',
	padding: '.5rem',
	'@media screen and (max-width: 1000px)': {
		gridTemplateColumns: 'repeat(6, 1fr)'
	}
}));

export const ShortGridItem = styled(Box)(() => ({
	gridColumn: `span 3`,
	'@media screen and (max-width: 1000px)': {
		gridColumn: `span 12`
	}
}));

export const LongGridItem = styled(Box)(() => ({
	gridColumn: `span 9`,
	'@media screen and (max-width: 1000px)': {
		gridColumn: `span 12`
	}
}));

export const RelExpContainer = styled(Box)(() => ({
	...boxStyles.flexBoxColumn,
	'@media screen and (max-width: 1000px)': {
		alignItems: 'center',
		flexDirection: 'row'
	}
}));

export const EditExpContainer = styled(Box)(() => ({
	...boxStyles.flexBoxRow,
	gap: '.4rem',
	'@media screen and (max-width: 1450px)': {
		flexDirection: 'column'
	},
	'@media screen and (max-width: 1000px)': {
		flexDirection: 'row'
	}
}));
