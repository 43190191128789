import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const GridContainer = styled(Box)(() => ({
	display: 'grid',
	gridTemplateColumns: 'repeat(12, 1fr)'
}));

export const GridItem = styled(Box)(() => ({
	gridColumn: 'span 3',
	width: '300px',
	'@media screen and (max-width: 1450px)': {
		gridColumn: 'span 6',
		width: 'fit-content'
	},
	'@media screen and (max-width: 1000px)': {
		gridColumn: 'span 12'
	}
}));
