import React from "react";
import { Box, Select, MenuItem } from "@mui/material";

export const AdminSubscribersBotSelector = ({ selectedBot, setSelectedBot, bots }) => {
  const handleBotChange = (event) => {
    setSelectedBot(event.target.value);
  };
  return (
    <Box>
      <Select
        value={selectedBot}
        onChange={handleBotChange}
        displayEmpty
        size='small'
        sx={{
          minWidth: 140,
          color: "#FF8C00",
          borderColor: "#FF8C00",
          "& .MuiSelect-icon": {
            color: "#FF8C00",
          },
          "&.MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#FF8C00",
            },
            "&:hover fieldset": {
              borderColor: "#FF8C00",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FF8C00",
            },
          },
          "& .MuiMenuItem-root": {
            "&.Mui-selected": {
              color: "#FF8C00",
            },
          },
        }}
      >
        <MenuItem value="all">Все боты</MenuItem>
        {bots &&
          bots.map((bot) => (
            <MenuItem key={bot.id} value={bot.id}>
              {bot.name}
            </MenuItem>
          ))}
      </Select>
    </Box>
  );
};
