import React, { useEffect, useState, useCallback, useMemo } from 'react';

import { styled } from '@mui/material/styles';

import { sendToAddCandidatInProject } from '../../../../../../api/api';

import {
	Box,
	Modal,
	Typography,
	ListItemButton,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	Button,
	Skeleton
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import AttachmentIcon from '@mui/icons-material/Attachment';

import { formatDate } from '../../../../../../utils/format-date';
import { SearchFieldUi } from '../../../../../lib/ui/search-field-ui';
import {
	getAllProjectsRequest,
	getProjectStatuses
} from '../../../../../../api/apiProject';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: '#fff3e0'
	},
	'&:last-child td, &:last-child th': {
		border: 0
	}
}));

export const AddCandidatesClipsModal = ({
	id,
	clipsProject,
	stackID,
	check,
	setCheck
}) => {
	const [open, setOpen] = useState(false);
	const [term, setTerm] = useState('');
	const [checkRender, setCheckRender] = useState('');
	const [projects, setProjects] = useState(null);

	useEffect(() => {
		getAllProjectsRequest()
			.then(projects => {
				getProjectStatuses()
					.then(data => {
						const statusMap = {};
						let result = projects;
						data.forEach(status => {
							statusMap[status.id] = status.name;
						});

						for (let i = 0; i < result.length; i++) {
							result[i].status = statusMap[result[i].status];
						}
						setProjects(result);
					})
					.catch(requestError =>
						console.error(
							`Failed to fetch project statuses: ${requestError.message}`
						)
					);
			})
			.catch(requestError => console.error(requestError.message));
	}, []);

	const handleOpen = () => setOpen(!open);

	const filteredList = useMemo(() => {
		let result = projects;
		if (clipsProject !== '' && result !== null) {
			result = result.filter(
				item => clipsProject?.findIndex(temp => temp.id === item.id) === -1
			);

			const filteredResult = [];
			for (let i = 0; i < result.length; i++) {
				if (result[i].stackId === stackID && result[i].status === 'В работе') {
					filteredResult.push(result[i]);
				}
			}

			result = filteredResult;
			return result;
		} else {
			return result;
		}
	}, [clipsProject, projects, stackID]);

	const searchItems = useCallback((items, term) => {
		if (term.length === 0) return items;
		const searchFields = ['name', 'dateOfCreation', 'responsible', 'status'];
		return items.filter(item =>
			searchFields.some(field => {
				if (typeof item[field] === 'number') {
					return item[field].toString().includes(term);
				} else {
					return item[field]
						.toString()
						.toLowerCase()
						.includes(term.toLowerCase());
				}
			})
		);
	}, []);

	const onUpdateSearch = term => {
		setTerm(term);
	};

	const clearSearchField = () => {
		setTerm('');
	};

	const data = useMemo(
		() => searchItems(filteredList, term),
		[searchItems, filteredList, term]
	);

	return (
		<>
			<Button
				variant='contained'
				color='primary'
				size='large'
				startIcon={<AttachmentIcon sx={{ fontSize: 35 }} />}
				sx={{
					bgcolor: '#ef6c00',
					borderRadius: '3%',
					mt: '10px',
					color: '#FFFFFF',
					fontWeight: 'bold',
					mr: 1,
					'&:hover': {
						bgcolor: '#ff9800',
						color: '#FFFFFF',
						transform: 'scale(1.03)'
					}
				}}
				onClick={handleOpen}
			>
				Прикрепить кандидата к проекту
			</Button>
			<Modal
				keepMounted
				id='message-modal'
				open={open}
				onClose={handleOpen}
				onDoubleClick={event => event.stopPropagation()}
				aria-labelledby='keep-mounted-modal-title'
				aria-describedby='keep-mounted-modal-description'
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 1300,
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 3
					}}
				>
					<Box sx={{ display: 'flex' }}>
						<Box sx={{ flexGrow: 1 }}>
							<Typography
								id='keep-mounted-modal-title'
								variant='h6'
								component='h2'
								sx={{
									fontSize: '30px'
								}}
							>
								Список проектов
							</Typography>
						</Box>
						<Box>
							<ListItemButton onClick={handleOpen}>
								<CloseIcon color={'warning'} sx={{ fontSize: 35 }} />
							</ListItemButton>
						</Box>
					</Box>
					<SearchFieldUi
						clearSearchField={clearSearchField}
						onUpdateSearchProp={onUpdateSearch}
					/>
					{projects ? (
						data.length !== 0 ? (
							<TableContainer
								component={Paper}
								elevation={6}
								sx={{ mt: '20px' }}
							>
								<Table
									sx={{ minWidth: 900 }}
									size='small'
									aria-label='customized table'
								>
									<TableHead>
										<TableRow>
											<TableCell>Название проекта</TableCell>
											<TableCell align='center'>Стек</TableCell>
											<TableCell align='center'>Дата создания</TableCell>
											<TableCell align='center'>Ответственный</TableCell>
											<TableCell align='center'>Статус</TableCell>
											<TableCell align='center'></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data.map(row => (
											<StyledTableRow key={row.id}>
												<TableCell component='th' scope='row'>
													{row.name}
												</TableCell>
												<TableCell align='center'>{row.stack}</TableCell>
												<TableCell align='center'>
													{formatDate(row.dateOfCreation)}
												</TableCell>
												<TableCell align='center'>{row.responsible}</TableCell>
												<TableCell align='center'>{row.status}</TableCell>
												<TableCell align='center'>
													<Button
														variant='contained'
														color='primary'
														sx={{
															bgcolor: '#ef6c00',
															borderRadius: '3%',
															color: '#FFFFFF',
															fontWeight: 'bold',
															mr: 1,
															'&:hover': {
																bgcolor: '#ff9800',
																color: '#FFFFFF',
																transform: 'scale(1.03)'
															}
														}}
														onClick={() => {
															sendToAddCandidatInProject(id, row.id).then(
																() => {
																	setCheckRender(!checkRender);
																	setCheck(!check);
																}
															);
														}}
													>
														Прикрепить
													</Button>
												</TableCell>
											</StyledTableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						) : (
							<Typography
								id='keep-mounted-modal-title'
								variant='h6'
								component='h2'
								sx={{
									fontSize: '30px',
									textAlign: 'center',
									mt: '20px'
								}}
							>
								Подходящих проектов нет...
							</Typography>
						)
					) : (
						<>
							<Skeleton variant='rectangular' height={300} />
						</>
					)}
				</Box>
			</Modal>
		</>
	);
};
