import React, { useState, useEffect, useRef } from 'react';
import { StyledInput } from './auto-save-input-styles';
import { validate } from '../../../../../../utils/validate';

export const AutoSaveInput = ({
	defaultValue,
	setData,
	weight,
	onChange,
	dataKey,
	type = 'string'
}) => {
	const [value, setValue] = useState('');
	const [isValid, setIsValid] = useState(true);
	const firstRender = useRef(true);

	const handleChange = e => {
		setValue(prevValue => {
			const newValue = e.target.value;

			if (prevValue !== newValue)
				return type === 'number'
					? newValue
							.replaceAll(' ', '')
							.toString()
							.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
					: newValue;
		});
	};

	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
			return;
		}
		const isValid = validate({ type, value });

		if (isValid) {
			setIsValid(true);
			const updateData = () => {
				setData(type === 'number' ? value.replace(/\s+/g, '') : value);
				onChange({ [dataKey]: value });
			};

			const timeout = setTimeout(() => updateData(), 500);
			return () => clearTimeout(timeout);
		} else {
			setIsValid(false);
		}
	}, [value]);

	useEffect(() => {
		setValue(
			type === 'number'
				? defaultValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
				: defaultValue
		);
	}, []);

	return (
		<StyledInput
			value={value}
			onChange={handleChange}
			variant='standard'
			multiline
			color={!isValid ? 'error' : 'warning'}
			weight={weight}
			type={type}
			helperText={
				!isValid && <span style={{ color: 'red' }}>Неверное значение!</span>
			}
		/>
	);
};
