/**
 * Возвращает строку, ограниченную определенным числом символов.
 *
 * @param {string} string - Исходная строка.
 * @param {number} symbolCount - Количество символов для ограничения строки (по умолчанию 200).
 * @param {boolean} dotEnd - Флаг для добавления троеточия в конце строки (по умолчанию true).
 * @returns {string} - Ограниченная строка.
 */
export const limitedString = (string, symbolCount = 200, dotEnd = true) => {
	try {
		let limitedString = string.slice(0, symbolCount);

		if (dotEnd) {
			limitedString = limitedString.concat('...');
		}

		return limitedString;
	} catch (error) {
		console.log('Ошибка лимитирования строки');
		console.error(error.message);
	}
};

/**
 * Возвращает строку с первой буквой в верхнем регистре.
 * @param {string} str - Строка, которая будет обработана.
 * @returns {string} - Обработанная строка.
 */
export const capitalizeFirstLetter = str => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Возвращает сокращенное представление полного имени.
 *
 * @param {string} string - Полное имя для сокращения.
 * @returns {string} - Сокращенное представление полного имени.
 */
export const abbreviationFullName = string => {
	try {
		const words = string.split(' ');
		let result;

		// Делаем первое слово с заглавной буквы полностью
		result = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();

		// Проверяем, что в строке как минимум два слова
		if (words.length > 1) {
			// Берем первую букву второго слова и делаем ее заглавной
			const secondWordInitial = words[1].charAt(0).toUpperCase();

			// Формируем итоговую строку
			result = `${result} ${secondWordInitial}.`;
		}

		return result;
	} catch (error) {
		console.log('Ошибка формирования сокращения полного имени');
	}
};
