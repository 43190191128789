import React from "react";

import { 
  Box,
  Grid,
  Typography,
  Divider
} from "@mui/material";

export const CvDetailsSkillsInfo = ({cvData}) => {

    return (
        <Box
            sx={{
                display:
                    cvData.stack !== null && cvData.stack !== "" && cvData.keySkills?.length >0
                        ? ""
                        : "none",
            }}
        >
            <Divider sx={{height: 20, m: "0px 0px 20px 0px"}}/>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginBottom: "20px",
                }}
            >
                <Typography
                    variant="h6"
                    component="h2"
                    sx={{
                        fontSize: "20px",
                    }}
                >
                    {"Ключевые навыки"}
                </Typography>
            </Box>
            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 1, md: 1}}>
                {
                    cvData.keySkills?.map((item) => (
                        <Grid key={item.id} item>
                            <Box>
                                <Typography
                                    variant="h6"
                                    component="h2"
                                    sx={{
                                        fontSize: "15px",
                                    }}
                                >
                                    {item.value} •
                                </Typography>
                            </Box>
                        </Grid>
                    ))
                }
            </Grid>
        </Box>

    );
};
