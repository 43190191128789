import { styled } from '@mui/material/styles';
import { Tab } from '@mui/material';

export const TabButton = styled(Tab)(() => ({
	backgroundColor: '#ef6c00',
	color: '#FFFFFF',
	fontWeight: 'bold',
	'&:hover': {
		backgroundColor: '#ff9800',
		color: '#FFFFFF',
		transform: 'scale(1.03)'
	}
}));
