import { REGEXP_NUMBER } from '../constants/regexp';

export const validate = ({ type, value }) => {
	switch (type) {
		case 'number':
			return REGEXP_NUMBER.test(value);
		case 'string':
			return typeof value === 'string';
		default:
			return false;
	}
};
