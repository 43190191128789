import { styled } from '@mui/material/styles';
import { ListItem } from '@mui/material';

export const SurveyList = styled(ListItem)(({ key }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	height: 'fit-content',
	width: '50%',
	float: `${(key + 1) % 2 !== 0 ? 'left' : 'right'}`,
	'@media screen and (max-width: 800px)': {
		float: 'none',
		width: '100%'
	}
}));
