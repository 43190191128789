import React, { useState, useEffect } from "react";

import { Table, TableContainer, Paper } from "@mui/material";

import { getUserBotsInfo, getUserBotsStatuses } from "../../../../../api/api";

import { UserBotsTableHeader } from "./user-bots-table-header";
import { UserBotsTableBody } from "./user-bots-table-body";

export const UserBotsTable = () => {
  const [checkTokenError, setCheckTokenError] = useState();
  const [userBotsInfo, setUserBotsInfo] = useState();
  const [statuses, setStatuses] = useState();

  useEffect(() => {
    getUserBotsInfo()
      .then((data) => {
        setUserBotsInfo(data.sort(sortByPrivacy));
      })
      .catch((requestError) => {
        console.error(requestError.message);
        if (requestError.response.status === 401) {
          localStorage.setItem("tokenError", true);
          setCheckTokenError(!checkTokenError);
        }
      });
  }, [checkTokenError]);

  useEffect(() => {
    getUserBotsStatuses()
      .then((data) => {
        setStatuses(data);
      })
      .catch((requestError) => {
        console.error(requestError.message);
        if (requestError.response.status === 401) {
          localStorage.setItem("tokenError", true);
          setCheckTokenError(!checkTokenError);
        }
      });
  }, [checkTokenError]);

  const sortByPrivacy = (obj1, obj2) => {
    if (obj1.isPrivate === true && obj2.isPrivate === false) {
      return -1; // move obj1 to a lower index
    } else if (obj1.isPrivate === false && obj2.isPrivate === true) {
      return 1; // move obj2 to a lower index
    } else {
      return 0; // preserve the order of obj1 and obj2
    }
  };

  return (
    <Paper
      sx={{ width: "100%", height: "100%", overflow: "hidden" }}
      elevation={3}
    >
      {userBotsInfo && statuses && (
        <TableContainer>
          <Table>
            <UserBotsTableHeader />
            <UserBotsTableBody
              userBotsInfo={userBotsInfo}
              statuses={statuses}
            />
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};
