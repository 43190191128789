import React, { useEffect, useState } from 'react';

import moment from 'moment';
import 'moment/locale/ru';

import { ruRU } from '@mui/x-date-pickers/locales';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { Box, IconButton, Tooltip, Typography } from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { pickersLayoutClasses } from '@mui/x-date-pickers/PickersLayout';

import {
	STARTING_COUNTDOWN_RECRUITERS_STAT_DATE,
	requestDateFormat
} from './config';
import ClearIcon from '@mui/icons-material/Clear';

export const DateComponent = ({
	setStartDate,
	setEndDate,
	datePickersEmpty,
	setDatePickersEmpty
}) => {
	const today = moment(new Date());

	const [term1, setTerm1] = useState(null);
	const [term2, setTerm2] = useState(null);

	const [openCalendar1, setOpenCalendar1] = useState(false);
	const [openCalendar2, setOpenCalendar2] = useState(false);

	useEffect(() => {
		if (datePickersEmpty) {
			setTerm1(null);
			setTerm2(null);
		}
	}, [datePickersEmpty]);

	const handleChange1 = filter1 => {
		const formattedFromDate = filter1.format(requestDateFormat);

		setTerm1(filter1);
		setStartDate(formattedFromDate);

		if (!term2) {
			const temp = filter1.clone().add(29, 'days');
			const filter2 = temp > today ? today : temp;
			const formattedToDate = filter2.format(requestDateFormat);

			setEndDate(formattedToDate);
			setTerm2(filter2);
		}

		setDatePickersEmpty(false);
	};

	const handleChange2 = filter2 => {
		const formattedToDate = filter2.format(requestDateFormat);

		setTerm2(filter2);
		setDatePickersEmpty(false);

		// если нижняя граница не установлена, фильтруем от начальной даты
		if (!term1) {
			const temp = filter2.clone().subtract(29, 'days');
			const filter1 =
				temp < moment(STARTING_COUNTDOWN_RECRUITERS_STAT_DATE)
					? moment(STARTING_COUNTDOWN_RECRUITERS_STAT_DATE)
					: temp;

			const formattedFromDate = filter1.format(requestDateFormat);

			setStartDate(formattedFromDate);
		}
		setEndDate(formattedToDate);
	};

	const resetDates = () => {
		setTerm1(null);
		setTerm2(null);
		setDatePickersEmpty(true);
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Box sx={{ pr: 2, width: '160px', display: 'flex' }}>
					<LocalizationProvider
						dateAdapter={AdapterMoment}
						adapterLocale='ru'
						localeText={
							ruRU.components.MuiLocalizationProvider.defaultProps.localeText
						}
					>
						<Box components={['DatePicker']}>
							<DatePicker
								label='От'
								closeOnSelect={false}
								views={['year', 'month', 'day']}
								open={openCalendar1}
								onOpen={() => setOpenCalendar1(true)}
								onClose={() => setOpenCalendar1(false)}
								value={term1 || null}
								minDate={moment(STARTING_COUNTDOWN_RECRUITERS_STAT_DATE)}
								maxDate={term2 || today}
								onChange={newValue => handleChange1(newValue)}
								slotProps={{
									textField: {
										color: 'warning',
										size: 'small'
									},
									toolbar: { hidden: false },
									layout: {
										sx: {
											[`.${pickersLayoutClasses.toolbar}`]: {
												color: 'white',
												backgroundColor: '#fb8c00',
												'& .MuiTypography-root ': {
													color: 'white'
												}
											},
											[`.${pickersLayoutClasses.contentWrapper}`]: {
												'& .Mui-selected': {
													backgroundColor: '#fb8c00',
													borderColor: '#fb8c00',
													color: 'white'
												},
												'& .MuiTabs-indicator': { bgcolor: '#f57c00' },
												'& .MuiClock-pin': { bgcolor: '#f57c00' },
												'& .MuiClockPointer-root': { bgcolor: '#f57c00' },
												'& .MuiClockPointer-thumb': {
													borderColor: '#f57c00'
												},
												'& .Mui-selected:hover': {
													backgroundColor: '#fb8c00',
													borderColor: '#fb8c00',
													color: 'white'
												},
												'& .Mui-selected:focus': {
													backgroundColor: '#fb8c00',
													borderColor: '#fb8c00',
													color: 'white'
												}
											}
										}
									}
								}}
								sx={{
									svg: { color: '#fb8c00' },
									'& .MuiInputLabel-root.Mui-focused': { color: '#fb8c00' }, //styles the label
									'& .MuiInput-underline:after': {
										borderBottomColor: '#fb8c00'
									}
								}}
							/>
						</Box>
					</LocalizationProvider>
				</Box>
				<Typography variant='body1' sx={{ color: '#ddd' }}>
					—
				</Typography>
				<Box sx={{ pr: 2, pl: 2, width: '160px', display: 'flex' }}>
					<LocalizationProvider
						dateAdapter={AdapterMoment}
						adapterLocale='ru'
						localeText={
							ruRU.components.MuiLocalizationProvider.defaultProps.localeText
						}
					>
						<Box components={['DatePicker']}>
							<DatePicker
								label='До'
								closeOnSelect={false}
								views={['year', 'month', 'day']}
								open={openCalendar2}
								onOpen={() => setOpenCalendar2(true)}
								onClose={() => setOpenCalendar2(false)}
								value={term2 || null}
								minDate={
									term1
										? term1
										: moment(STARTING_COUNTDOWN_RECRUITERS_STAT_DATE)
								}
								maxDate={
									term1
										? term1.clone().add(29, 'days') > today
											? today
											: term1.clone().add(29, 'days') > today
										: today
								}
								onChange={newValue => handleChange2(newValue)}
								slotProps={{
									textField: {
										color: 'warning',
										size: 'small'
									},
									toolbar: { hidden: false },
									layout: {
										sx: {
											[`.${pickersLayoutClasses.toolbar}`]: {
												color: 'white',
												backgroundColor: '#fb8c00',
												'& .MuiTypography-root ': {
													color: 'white'
												}
											},
											[`.${pickersLayoutClasses.contentWrapper}`]: {
												'& .Mui-selected': {
													backgroundColor: '#fb8c00',
													borderColor: '#fb8c00',
													color: 'white'
												},
												'& .MuiTabs-indicator': { bgcolor: '#f57c00' },
												'& .MuiClock-pin': { bgcolor: "'#f57c00' " },
												'& .MuiClockPointer-root': { bgcolor: '#f57c00' },
												'& .MuiClockPointer-thumb': {
													borderColor: '#f57c00'
												},
												'& .Mui-selected:hover': {
													backgroundColor: '#fb8c00',
													borderColor: '#fb8c00',
													color: 'white'
												},
												'& .Mui-selected:focus': {
													backgroundColor: '#fb8c00',
													borderColor: '#fb8c00',
													color: 'white'
												}
											}
										}
									}
								}}
								sx={{
									svg: { color: '#fb8c00' },
									'& .MuiInputLabel-root.Mui-focused': { color: '#fb8c00' }, //styles the label
									'& .MuiInput-underline:after': {
										borderBottomColor: '#fb8c00'
									}
								}}
							/>
						</Box>
					</LocalizationProvider>
				</Box>
				<Tooltip title='Сбросить диапазон'>
					<IconButton sx={{ alignSelf: 'center' }} onClick={resetDates}>
						<ClearIcon
							sx={{
								color: !datePickersEmpty ? '#ef6c00' : 'inherit'
							}}
						/>
					</IconButton>
				</Tooltip>
			</Box>
		</Box>
	);
};
