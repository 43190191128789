import React, { useEffect, useState } from 'react';
import { SplitInputModal } from '../split-input-modal';
import { InputVariationsList } from '../../lists/input-variations-list';
import { Box, Typography } from '@mui/material';
import { boxStyles } from '../../../../../styles/default-styles/box-styles/box-styles';
import { FilterMenuCategories } from '../../filter-menu/filter-menu-categories';
import { processCheckListFilter } from '../../../../../utils/process-check-list-filter/process-check-list-filter';

export const TermWithVariantsModal = ({ onClose, isOpen, config, options = {}, onProcess, size }) => {
	const { defaultValues = [], defaultCategories = [], defaultVariations = [], categories = [], multiple } = options;
	const [activeFilters, setActiveFilters] = useState({ stack: [] });

	const handleProcess = values => {
		if (Object.keys(activeFilters).includes('stack')) {
			return onProcess(values, activeFilters.stack);
		} else {
			return onProcess(values, []);
		}
	};

	useEffect(() => {
		setActiveFilters(prevState => ({ stack: [...prevState.stack, ...defaultCategories] }));
	}, []);

	return (
		<SplitInputModal
			onClose={onClose}
			isOpen={isOpen}
			config={config}
			onProcess={handleProcess}
			size={size}
			options={{
				multiple: multiple,
				ExtendedElement: ({ values, setValues }) => (
					<Box sx={{ ...boxStyles.flexBoxColumn }} gap={1}>
						{categories && (
							<Box
								sx={{
									...boxStyles.flexBoxColumn,
									width: '100%',
									alignItems: 'flex-start',
									justifyContent: 'space-between',
									pl: '3px',
									mb: 2
								}}>
								<Typography variant="h5" sx={{ mb: 1 }}>Выберите категорию</Typography>
								<FilterMenuCategories
									filterGroup='stack'
									filterList={categories}
									activeFilters={activeFilters['stack']}
									processCheckListFilter={processCheckListFilter}
									setActiveFilters={setActiveFilters}
								/>
							</Box>
						)}
						{values.length > 0 && (
							<InputVariationsList
								values={values}
								setValues={setValues}
								options={{ defaultVariations: defaultVariations }}
							/>
						)}
					</Box>
				),
				defaultValues: defaultValues
			}}
		/>
	);
};
