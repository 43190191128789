import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { TermsCardPagination } from './terms-card-pagination';
import CircularProgress from '@mui/material/CircularProgress';
import { TermsCard } from './terms-card';
import { TermsModal } from './terms-modal';

export const TermsCardContainer = ({
	termsIsLoaded,
	filteredTerms,
	check,
	setCheck,
	filterList,
	filtersByCategory
}) => {
	const [itemsPerPage, setItemsPerPage] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [selectedTerm, setSelectedTerm] = useState({
		term: '',
		options: [],
		listStackID: []
	});
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const cardWidth = 280;
	const containerWidthPercentage = 90;
	const cardHeight = 70;

	// обработчик клика по термину
	const handleTermClick = termData => {
		setModalIsOpen(true);
		setSelectedTerm(termData);
	};

	// очищаем данные термина при закрытии модального окна
	const handleCloseModal = () => {
		setModalIsOpen(false);
		setSelectedTerm({ term: '', options: [], listStackID: [] });
	};

	const calculateItemsPerPage = () => {
		const containerWidth = (window.innerWidth * containerWidthPercentage) / 100;
		const cardsPerRow = Math.floor(containerWidth / cardWidth);
		const containerHeight = (window.innerHeight * 60) / 100;
		const rowsPerPage = Math.floor(containerHeight / cardHeight);
		const calculatedItemsPerPage = cardsPerRow * rowsPerPage;

		setItemsPerPage(calculatedItemsPerPage > 0 ? calculatedItemsPerPage : 1);
	};

	useEffect(() => {
		calculateItemsPerPage();
		window.addEventListener('resize', calculateItemsPerPage);

		return () => {
			window.removeEventListener('resize', calculateItemsPerPage);
		};
	}, []);

	const currentTerms = useMemo(() => {
		const indexOfLastTerm = currentPage * itemsPerPage;
		const indexOfFirstTerm = indexOfLastTerm - itemsPerPage;

		return filteredTerms.slice(indexOfFirstTerm, indexOfLastTerm);
	}, [filteredTerms, currentPage]);

	const handlePageChange = (event, value) => {
		setCurrentPage(value);
	};

	return (
		<>
			<Box
				id='terms-card-container'
				sx={{
					borderRadius: '15px',
					border: '2px solid #ebebeb',
					padding: '20px',
					width: `${containerWidthPercentage}vw`,
					mr: 1,
					alignItems: 'center'
				}}>
				<Box
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						flexDirection: 'column',
						gap: 1,
						justifyContent: 'center',
						alignItems: 'center',
						height: '65vh'
					}}>
					{termsIsLoaded ? (
						currentTerms.length ? (
							currentTerms.map(row => (
								<Box
									key={row.id}
									sx={{
										flexGrow: 1,
										width: `${cardWidth}px`,
										maxWidth: '100%'
									}}>
									<TermsCard
										row={row}
										check={check}
										setCheck={setCheck}
										filtersByCategory={filtersByCategory}
										onCardClick={handleTermClick}
									/>
								</Box>
							))
						) : (
							<Typography sx={{ fontWeight: 'bold' }} variant='h6'>
								Технические термины не найдены!
							</Typography>
						)
					) : (
						<Box sx={{ display: 'flex' }}>
							<CircularProgress color='warning' />
						</Box>
					)}
				</Box>
				<TermsCardPagination
					filteredTerms={filteredTerms}
					itemsPerPage={itemsPerPage}
					currentPage={currentPage}
					handlePageChange={handlePageChange}
				/>
			</Box>
			<TermsModal
				isOpen={modalIsOpen}
				term={selectedTerm}
				filtersByCategory={filtersByCategory}
				onClose={handleCloseModal}
				filterList={filterList}
				setCheck={setCheck}
				check={check}
			/>
		</>
	);
};
