import React, { useEffect, useState } from 'react';

import { Box, TableBody, TableCell } from '@mui/material';
import { CollapseRowStack } from './collapse-rows-stack';
import {
	cellStyles,
	defineColors
} from './collapse-rows-stack/collapse-row-stack-styles';

import { getMaxStatistics } from '../../utils';
import { StatTypography } from './stat-typography';

/**
 * Компонент таблицы статистики рекрутера
 * @param {Object} statisticData - Данные статистики рекрутера
 * @returns {JSX.Element} - JSX-элемент таблицы статистики
 */
export const RecruiterStatsTableBody = React.memo(({ statisticData }) => {
	const [isOpen, setIsOpen] = useState(true);
	const [maxStatistics, setMaxStatistics] = useState({});

	useEffect(() => {
		setMaxStatistics(getMaxStatistics(statisticData));
	}, [statisticData]);

	const toggleIsOpen = () => {
		setIsOpen(!isOpen);
	};

	return (
		<TableBody>
			{statisticData.map((recruiter, recruiterIndex) => (
				<React.Fragment key={recruiter.name}>
					{/*Разобрано запросы*/}
					<CollapseRowStack
						recruiter={recruiter}
						recruiterIndex={recruiterIndex}
						isOpen={isOpen}
						onOpen={toggleIsOpen}
						rowOptions={{
							mainRow: {
								name: 'Разобрано запросы',
								isFirstRow: true,
								total: recruiter?.statistics.reduce(
									(acc, obj) => acc + obj.messageStatistics.allCount,
									0
								),
								statCell: function ({ statistic, index }) {
									return (
										<TableCell
											key={index}
											sx={{
												backgroundColor: defineColors(recruiterIndex % 2),
												...cellStyles.body,
												...cellStyles.subheader
											}}
										>
											<StatTypography
												value={statistic?.messageStatistics?.allCount}
											/>
										</TableCell>
									);
								},
								maxStatistic: maxStatistics?.messageStatistics?.allCount
							},
							collapseRows: [
								{
									name: 'Корзина',
									total: recruiter.statistics.reduce(
										(acc, obj) => acc + obj.messageStatistics.trashCount,
										0
									),
									statCell: function ({ statistic, index }) {
										return (
											<TableCell sx={cellStyles.body} key={index}>
												<StatTypography
													value={statistic.messageStatistics.trashCount}
												/>
											</TableCell>
										);
									},
									maxStatistic: maxStatistics?.messageStatistics?.trashCount
								},
								{
									name: 'Избранное',
									total: recruiter.statistics.reduce(
										(acc, obj) => acc + obj.messageStatistics.favoritesCount,
										0
									),
									statCell: function ({ statistic, index }) {
										return (
											<TableCell sx={cellStyles.body} key={index}>
												<StatTypography
													value={statistic.messageStatistics.favoritesCount}
												/>
											</TableCell>
										);
									},
									maxStatistic: maxStatistics?.messageStatistics?.favoritesCount
								}
							]
						}}
					/>
					{/*Разобрано CV*/}
					<CollapseRowStack
						recruiter={recruiter}
						recruiterIndex={recruiterIndex}
						isOpen={isOpen}
						onOpen={toggleIsOpen}
						rowOptions={{
							mainRow: {
								name: 'Разобрано CV',
								total: recruiter.statistics.reduce(
									(acc, obj) => acc + obj.cvStatistics.allCount,
									0
								),
								statCell: function ({ statistic, index }) {
									return (
										<TableCell
											key={index}
											sx={{
												backgroundColor: defineColors(recruiterIndex % 2),
												...cellStyles.body,
												...cellStyles.subheader
											}}
										>
											<StatTypography value={statistic.cvStatistics.allCount} />
										</TableCell>
									);
								},
								maxStatistic: maxStatistics?.cvStatistics?.allCount
							},
							collapseRows: [
								{
									name: 'Отказано',
									total: recruiter.statistics.reduce(
										(acc, obj) => acc + obj.cvStatistics.dislikesCount,
										0
									),
									statCell: function ({ statistic, index }) {
										return (
											<TableCell sx={cellStyles.body} key={index}>
												<StatTypography
													value={statistic.cvStatistics.dislikesCount}
												/>
											</TableCell>
										);
									},
									maxStatistic: maxStatistics?.cvStatistics?.dislikesCount
								},
								{
									name: 'Избранное',
									total: recruiter.statistics.reduce(
										(acc, obj) => acc + obj.cvStatistics.likesCount,
										0
									),
									statCell: function ({ statistic, index }) {
										return (
											<TableCell sx={cellStyles.body} key={index}>
												<StatTypography
													value={statistic.cvStatistics.likesCount}
												/>
											</TableCell>
										);
									},
									maxStatistic: maxStatistics?.cvStatistics?.likesCount
								}
							]
						}}
					/>
					{/*Добавлено кандидатов*/}
					<CollapseRowStack
						recruiter={recruiter}
						recruiterIndex={recruiterIndex}
						isOpen={isOpen}
						onOpen={toggleIsOpen}
						rowOptions={{
							mainRow: {
								name: 'Добавлено кандидатов',
								total: recruiter.statistics.reduce(
									(acc, obj) => acc + obj.addingCandidatesStatistics.allCount,
									0
								),
								statCell: function ({ statistic, index }) {
									return (
										<TableCell
											key={index}
											sx={{
												backgroundColor: defineColors(recruiterIndex % 2),
												...cellStyles.body,
												...cellStyles.subheader
											}}
										>
											<StatTypography
												value={statistic.addingCandidatesStatistics.allCount}
											/>
										</TableCell>
									);
								},
								maxStatistic:
									maxStatistics?.addingCandidatesStatistics?.allCount
							},
							collapseRows: [
								{
									name: 'Ссылки',
									total: recruiter.statistics.reduce(
										(acc, obj) =>
											acc + obj.addingCandidatesStatistics.addedByLinkCount,
										0
									),
									statCell: function ({ statistic, index }) {
										return (
											<TableCell sx={cellStyles.body} key={index}>
												<StatTypography
													value={
														statistic.addingCandidatesStatistics
															.addedByLinkCount
													}
												/>
											</TableCell>
										);
									},
									maxStatistic:
										maxStatistics?.addingCandidatesStatistics?.addedByLinkCount
								},
								{
									name: 'Выборка/Поток',
									total: recruiter.statistics.reduce(
										(acc, obj) =>
											acc + obj.addingCandidatesStatistics.addedViaQueryCount,
										0
									),
									statCell: function ({ statistic, index }) {
										return (
											<TableCell sx={cellStyles.body} key={index}>
												<StatTypography
													value={
														statistic.addingCandidatesStatistics
															.addedViaQueryCount
													}
												/>
											</TableCell>
										);
									},
									maxStatistic:
										maxStatistics?.addingCandidatesStatistics
											?.addedViaQueryCount
								}
							]
						}}
					/>
					{/*Обзвонено кандидатов*/}
					<CollapseRowStack
						recruiter={recruiter}
						recruiterIndex={recruiterIndex}
						isOpen={isOpen}
						onOpen={toggleIsOpen}
						rowOptions={{
							mainRow: {
								name: 'Обзвонено кандидатов',
								total: recruiter.statistics.reduce(
									(acc, obj) => acc + obj.callsToCandidatesStatistics.allCount,
									0
								),
								statCell: function ({ statistic, index }) {
									return (
										<TableCell
											key={index}
											sx={{
												backgroundColor: defineColors(recruiterIndex % 2),
												...cellStyles.body,
												...cellStyles.subheader
											}}
										>
											<StatTypography
												value={statistic.callsToCandidatesStatistics.allCount}
											/>
										</TableCell>
									);
								},
								maxStatistic:
									maxStatistics?.callsToCandidatesStatistics?.allCount
							},
							collapseRows: [
								{
									name: 'Неудачно',
									total: recruiter.statistics.reduce(
										(acc, obj) =>
											acc +
											obj.callsToCandidatesStatistics.unsuccessfulCallsCount,
										0
									),
									statCell: function ({ statistic, index }) {
										return (
											<TableCell sx={cellStyles.body} key={index}>
												<StatTypography
													value={
														statistic.callsToCandidatesStatistics
															.unsuccessfulCallsCount
													}
												/>
											</TableCell>
										);
									},
									maxStatistic:
										maxStatistics?.callsToCandidatesStatistics
											?.unsuccessfulCallsCount
								},
								{
									name: 'Удачно',
									total: recruiter.statistics.reduce(
										(acc, obj) =>
											acc +
											obj.callsToCandidatesStatistics.successfulCallsCount,
										0
									),
									statCell: function ({ statistic, index }) {
										return (
											<TableCell sx={cellStyles.body} key={index}>
												<StatTypography
													value={
														statistic.callsToCandidatesStatistics
															.successfulCallsCount
													}
												/>
											</TableCell>
										);
									},
									maxStatistic:
										maxStatistics?.callsToCandidatesStatistics
											?.successfulCallsCount
								},
								{
									name: 'Ждем',
									total: recruiter.statistics.reduce(
										(acc, obj) =>
											acc + obj.callsToCandidatesStatistics.pendingCallsCount,
										0
									),
									statCell: function ({ statistic, index }) {
										return (
											<TableCell sx={cellStyles.body} key={index}>
												<StatTypography
													value={
														statistic.callsToCandidatesStatistics
															.pendingCallsCount
													}
												/>
											</TableCell>
										);
									},
									maxStatistic:
										maxStatistics?.callsToCandidatesStatistics
											?.pendingCallsCount
								}
							]
						}}
					/>
					{/*Сделки*/}
					<CollapseRowStack
						recruiter={recruiter}
						recruiterIndex={recruiterIndex}
						isOpen={isOpen}
						onOpen={toggleIsOpen}
						rowOptions={{
							mainRow: {
								name: 'Сделки',
								total: recruiter.statistics.reduce(
									(acc, obj) => acc + obj.dealStatusStatistics.allCount,
									0
								),
								statCell: function ({ statistic, index }) {
									return (
										<TableCell
											key={index}
											sx={{
												backgroundColor: defineColors(recruiterIndex % 2),
												...cellStyles.body,
												...cellStyles.subheader
											}}
										>
											<StatTypography
												value={statistic.dealStatusStatistics.allCount}
											/>
										</TableCell>
									);
								},
								maxStatistic: maxStatistics?.dealStatusStatistics?.allCount
							},
							collapseRows: [
								{
									name: 'В работе',
									total: recruiter.statistics.reduce(
										(acc, obj) => acc + obj.dealStatusStatistics.atWork,
										0
									),
									statCell: function ({ statistic, index }) {
										return (
											<TableCell sx={cellStyles.body} key={index}>
												<StatTypography
													value={statistic.dealStatusStatistics.atWork}
												/>
											</TableCell>
										);
									},
									maxStatistic: maxStatistics?.dealStatusStatistics?.atWork
								},
								{
									name: 'Ждем',
									total: recruiter.statistics.reduce(
										(acc, obj) => acc + obj.dealStatusStatistics.waiting,
										0
									),
									statCell: function ({ statistic, index }) {
										return (
											<TableCell sx={cellStyles.body} key={index}>
												<StatTypography
													value={statistic.dealStatusStatistics.waiting}
												/>
											</TableCell>
										);
									},
									maxStatistic: maxStatistics?.dealStatusStatistics?.waiting
								},
								{
									name: 'Готово',
									total: recruiter.statistics.reduce(
										(acc, obj) => acc + obj.dealStatusStatistics.done,
										0
									),
									statCell: function ({ statistic, index }) {
										return (
											<TableCell sx={cellStyles.body} key={index}>
												<StatTypography
													value={statistic.dealStatusStatistics.done}
												/>
											</TableCell>
										);
									},
									maxStatistic: maxStatistics?.dealStatusStatistics?.done
								}
							]
						}}
					/>
				</React.Fragment>
			))}
		</TableBody>
	);
});
