import { Button, CircularProgress, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { RootModal } from '../modal';
import { CircularContainer } from './custom-modal-styles';

/**
 * Компонент модального окна.
 * @param {Object} - Пропсы компонента.
 * @param {boolean} [isOpen] - Флаг открытия модального окна.
 * @param {boolean} [dataIsLoaded] - Флаг загрузки данных.
 * @param {ReactNode} [children] - Дочерние элементы модального окна.
 * @param {Function} [onClose] - Функция обратного вызова при закрытии модального окна.
 * @param {Object} [styles] - Стили модального окна.
 * @returns {ReactElement} Компонент модального окна.
 */
export const CustomModal = ({
	isOpen = false,
	dataIsLoaded = true,
	children = <></>,
	onClose = () => {},
	styles = {},
	size = 'md'
}) => {
	return (
		<RootModal isOpen={isOpen} onClose={onClose} styles={styles} size={size}>
			{/* Если данные загружены, отображаем дочерние элементы, иначе отображаем индикатор загрузки */}
			{dataIsLoaded ? (
				children
			) : (
				<CircularContainer>
					<CircularProgress color='warning' />
				</CircularContainer>
			)}
			<Tooltip
				title='Закрыть'
				placement='top'
				sx={{
					width: 'fit-content',
					position: 'absolute',
					right: 24,
					top: 25
				}}>
				<Button onClick={onClose}>
					<CloseIcon color='warning' sx={{ fontSize: 35, ...styles.iconStyles }} />
				</Button>
			</Tooltip>
		</RootModal>
	);
};
