import React, { useState, useEffect } from 'react';
import { InputBase, Divider, Typography, Paper } from '@mui/material';

export const NumberFieldCard = ({ textName, textstaff, setData }) => {
	const [term, setTerm] = useState('');
	const [termTime, setTermTime] = useState();
	const [isChanged, setIsChanged] = useState(false);

	useEffect(() => {
		if (textstaff !== null && textstaff !== undefined) {
			setTerm(textstaff.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '));
		}
	}, [textstaff]);

	const handleChange = event => {
		const temp = event.target.value;
		setTermTime(temp.replaceAll(' ', ''));
		setTerm(
			temp
				.replaceAll(' ', '')
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
		);
		setIsChanged(true);
	};

	useEffect(() => {
		const handleBeforeUnload = event => {
			// Perform actions before the component unloads
			if (isChanged && textstaff.toString().replaceAll(' ', '') !== termTime) {
				event.preventDefault();
				event.returnValue = '';
			}
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [isChanged, termTime]);

	return (
		<Paper
			elevation={6}
			sx={{
				display: 'flex',
				alignItems: 'center',
				minHeight: '32px'
			}}
		>
			<Typography
				id='keep-mounted-modal-title'
				variant='h6'
				component='h2'
				sx={{
					display: 'flex',
					height: '100%',
					fontSize: '15px',
					bgcolor: '#fb8c00',
					color: '#852508',
					border: '1px solid',
					minWidth: '110px',
					textAlign: 'center',
					borderRadius: '4px',
					borderColor: '#a2a2a2',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				{textName}
			</Typography>
			<Divider sx={{ height: 24, ml: 0.5 }} orientation='vertical' />
			<InputBase
				value={term}
				onBlur={() => {
					setData(termTime === '' ? 0 : termTime);
					setIsChanged(false);
				}}
				onChange={handleChange}
				multiline
				sx={{ p: '2px 4px', ml: 1, flex: 1, fontSize: '15px' }}
			/>
		</Paper>
	);
};
