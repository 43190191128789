import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Tab, Tabs, Box } from '@mui/material';
import { AdministratorTab } from './administrator-tab';
import { UnifiedTechnicalTerminologyTab } from './unified-technical-terminology-tab';
import { UserBotsTab } from './user-bots-tab';
import { AdminSubscribersBotTab } from './admin-subscribers-bot-tab';
import { RecruiterStatsTab } from './recruiter-stats-tab';
import { StyledTabs } from '../../../styles/default-styles/styled-tabs/StyledTabs';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { UsersStatsTab } from './users-stats-tab';
import { ADMINISTRATION_PAGE_CONFIG } from './config';
import { DefaultRequirementsTab } from './default-requirements-tab';

export const AdministrationPage = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { TAB_SECTIONS } = ADMINISTRATION_PAGE_CONFIG;

	// функция для получения начального значения вкладки
	const getInitialTabValue = () => {
		const tabFromUrl = new URLSearchParams(location.search).get('tab');
		const tabFromSession = sessionStorage.getItem('administrationLastUserTab');
		if (tabFromUrl !== null) {
			return parseInt(tabFromUrl, 10);
		} else if (tabFromSession !== null) {
			return parseInt(tabFromSession, 10);
		} else {
			// если нет значения ни в URL, ни в sessionStorage, используем значение по умолчанию для открытия первой вкладки
			return 0;
		}
	};

	const [value, setValue] = useState(getInitialTabValue);

	const handleTabChange = (event, newValue) => {
		setValue(newValue);
	};

	useEffect(() => {
		// сохраняем значение в sessionStorage и обновляем URL при необходимости
		sessionStorage.setItem('administrationLastUserTab', value.toString());

		const searchParams = new URLSearchParams(location.search);

		if (searchParams.get('tab') === null || parseInt(searchParams.get('tab'), 10) !== value) {
			navigate(`?tab=${value}`, { replace: true });
		}
	}, [value, navigate, location.search]);

	useLayoutEffect(() => {
		document.title = 'Администрирование';
	}, []);

	return (
		<Box>
			<Box sx={{ mt: 3, ml: 12 }}>
				<Tabs sx={StyledTabs.styledTabs} value={value} onChange={handleTabChange}>
					{TAB_SECTIONS.map(({ id, label }) => (
						<Tab key={id} sx={StyledTabs.styledTab} label={label} />
					))}
				</Tabs>
			</Box>

			{value === 0 && <RecruiterStatsTab />}
			{value === 1 && <UsersStatsTab />}
			{value === 2 && <UnifiedTechnicalTerminologyTab />}
			{value === 3 && <DefaultRequirementsTab />}
			{value === 4 && <UserBotsTab />}
			{value === 5 && <AdministratorTab />}
			{value === 6 && <AdminSubscribersBotTab />}
		</Box>
	);
};
