import React, { useState, useEffect, useRef } from 'react';

import { UpdateKeySkillRequest } from '../../../../../../api/api';

import { InputBase } from '@mui/material';

export const KeySkillFieldCV = ({ textData, id, order }) => {
	const [term, setTerm] = useState('');
	const [termTime, setTermTime] = useState();
	const [width, setWidth] = useState();
	const isFirstRenderRef = useRef(true);

	useEffect(() => {
		if (textData !== null && textData !== undefined) {
			setTerm(textData);
			setWidth(textData.length + 8);
		}
	}, [textData]);

	const handleChange = element => {
		const temp = element.target.value;
		setWidth(element.target.value.length + 8);
		setTerm(temp.replace(/^[\s.`":'$@~;]*$/g, ''));
		setTermTime(temp.replace(/^[\s.`":'$@~;]*$/g, ''));
	};

	useEffect(() => {
		if (isFirstRenderRef.current) {
			isFirstRenderRef.current = false;
			return;
		}
		const timeOutId = setTimeout(() => {
			UpdateKeySkillRequest(id, termTime, order).catch(requestError => {
				console.error(requestError.message);
				if (requestError.response.status === 401)
					localStorage.setItem('tokenError', true);
			});
		}, 500);
		return () => clearTimeout(timeOutId);
	}, [id, termTime, order]);

	return (
		<InputBase
			value={term}
			onChange={handleChange}
			fullWidth
			sx={{ p: 0, ml: 1, width: width + 'ch', fontSize: '15px' }}
		/>
	);
};
