import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Tabs, Box } from '@mui/material';

import { getResumeStaffsRequest, GetSurveyList, GetSurveyAnswersDescription } from '../../../../../api/api';
import { CvResumeCard } from '../../../../lib/ui/cv-resume-card';
import { CvResumeSurvey } from '../../../../lib/ui/cv-resume-survey';
import { LinearProgressBar } from '../../../../lib/ui/cv-resume-survey/linear-progress-bar';
import { TabButton } from './tabs-resume-styles';
import { CheckListsContainer } from './check-lists-container';

const TabPanel = props => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && (
				<Box sx={{ pt: 1 }}>
					<Box>{children}</Box>
				</Box>
			)}
		</Box>
	);
};

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
};

const a11yProps = index => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
};

export const TabResume = ({
	staffid,
	cvId,
	publicCvId,
	checkPublicCV,
	setCheckPublicCV,
	cvEmailData,
	setAlertIsOpen,
	alertIsOpen,
	publicCVData,
	setPublicCVData
}) => {
	const [value, setValue] = useState(0);
	const [cvData, setCvData] = useState({});
	const [check, setCheck] = useState();
	const [surveyList, setSurveyList] = useState([]);
	const [isDisabledSurvey, setIsDisabledSurvey] = useState(true);
	const [answersDescription, setAnswersDescription] = useState('');
	const [progressSurvey, setProgressSurvey] = useState(null);
	const [checkTokenError, setCheckTokenError] = useState();

	useEffect(() => {
		getResumeStaffsRequest(cvId)
			.then(data => {
				setCvData(data);
			})
			.catch(requestError => {
				console.error(requestError.message);
				if (requestError.response.status === 401) localStorage.setItem('tokenError', true);
			});
	}, [cvId]);

	useEffect(() => {
		getResumeStaffsRequest(publicCvId)
			.then(data => {
				setPublicCVData(data);
			})
			.catch(requestError => {
				console.error(requestError.message);
				if (requestError.response.status === 401) localStorage.setItem('tokenError', true);
			});
	}, [publicCvId, check, checkPublicCV]);

	useEffect(() => {
		GetSurveyList(staffid)
			.then(data => {
				if (!data.isError) {
					setProgressSurvey(Math.floor(data.payload.progress * 100));
					const filteredSurvey = data.payload.collectionAnswers
						.map(item => ({
							...item,
							answers: item.answers.filter(answer => answer.answer !== null)
						}))
						.filter(collection => collection.answers.length);
					setSurveyList(filteredSurvey);
					if (filteredSurvey.length) {
						setIsDisabledSurvey(false);
					}
				}
			})
			.catch(requestError => {
				console.error(requestError.message);
				if (requestError.response.status === 401) {
					localStorage.setItem('tokenError', true);
					setCheckTokenError(!checkTokenError);
				}
			});
	}, [checkTokenError]);

	useEffect(() => {
		GetSurveyAnswersDescription()
			.then(data => {
				setAnswersDescription(data.slice(0, 4));
			})
			.catch(requestError => {
				console.error(requestError.message);
				if (requestError.response.status === 401) {
					localStorage.setItem('tokenError', true);
					setCheckTokenError(!checkTokenError);
				}
			});
	}, [checkTokenError]);

	const handleChange = (event, newValue) => {
		if (!alertIsOpen) {
			setValue(newValue);
		}
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					value={value}
					onChange={handleChange}
					textColor='inherit'
					sx={{
						'& .Mui-selected': { color: '#FFFFFF' },
						'& .MuiTabs-indicator': { bgcolor: '#852508' }
					}}>
					<TabButton label='Исходное CV' {...a11yProps(0)} />
					<TabButton label='Новое CV' {...a11yProps(1)} />
					<TabButton disabled={isDisabledSurvey} label='Опрос' {...a11yProps(2)} />
					<TabButton label='Чек листы' {...a11yProps(3)} />
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
				<CvResumeCard cvData={cvData} setAlertIsOpen={setAlertIsOpen} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<CvResumeCard
					staffid={staffid}
					cvData={publicCVData}
					cvId={publicCVData.id}
					check={check}
					setCheck={setCheck}
					checkPublicCV={checkPublicCV}
					setCheckPublicCV={setCheckPublicCV}
					modifi={true}
					cvEmailData={cvEmailData}
					setAlertIsOpen={setAlertIsOpen}
				/>
			</TabPanel>
			<TabPanel value={value} index={2}>
				<LinearProgressBar progressSurvey={progressSurvey} />
				<CvResumeSurvey surveyList={surveyList} answersDescription={answersDescription} />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<CheckListsContainer staffId={staffid} />
			</TabPanel>
		</Box>
	);
};
