import React, { useState, useContext } from 'react';

import { AddKeySkillRequest, DeleteKeySkillRequest, getResumeStaffsRequest } from '../../../../../api/api';

import { Box, Grid, Typography, Divider, IconButton, Button } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { KeySkillFieldCV } from '../ui/key-skill-field-CV';
import { buttonStyles } from '../../../../../styles/default-styles/button-styles/button-styles';
import { boxStyles } from '../../../../../styles/default-styles/box-styles/box-styles';
import { SplitInputModal } from '../../modals/split-input-modal';
import UPDATING_CV_SKILLS_CONFIG from './config';
import { postAddSeveralSkills } from '../../../../../api/apiCandidate';
import { AlertContext } from '../../../../contexts/AlertContext/AlertContext';

export const UpdatingCvDetailsSkillsInfo = ({ cvData, check, setCheck }) => {
	//Implement drag and drop of key skills

	const [list, setList] = useState(cvData?.keySkills);
	const [currentCard, setCurrentCard] = useState(null);
	const [addSkillsModalIsOpen, setAddSkillsModalIsOpen] = useState(false);

	const { showAlert } = useContext(AlertContext);

	const handleAddSkill = () => {
		AddKeySkillRequest(cvData.id)
			.then(() => {
				getResumeStaffsRequest(cvData.id).then(res => {
					setList(res.keySkills);
					setCheck(!check);
				});
			})
			.catch(requestError => console.error(requestError.message));
	};

	const handleDeleteSkill = keySkillId => {
		DeleteKeySkillRequest(keySkillId)
			.then(() => {
				getResumeStaffsRequest(cvData.id).then(res => {
					setList(res.keySkills);
					setCheck(!check);
				});
			})
			.catch(requestError => console.error(requestError.message));
	};

	const dragStartHandler = (e, card) => {
		setCurrentCard(card);
	};

	const dragOverHandler = e => {
		e.preventDefault();
	};

	const dropHandler = (e, card) => {
		e.preventDefault();

		let array = [...list];

		function moveElement(array, elementA, targetElement) {
			const elementAOrder = elementA.order;
			const targetElementOrder = targetElement.order;

			if (elementAOrder - targetElementOrder > 1) {
				array = array.map(obj => {
					if (obj.order > targetElementOrder && obj.order < elementAOrder) {
						obj.order++;
					}
					return obj;
				});
				elementA.order = targetElementOrder;
				targetElement.order++;
			} else if (targetElementOrder - elementAOrder > 1) {
				array = array.map(obj => {
					if (obj.order < targetElementOrder && obj.order > elementAOrder) {
						obj.order--;
					}
					return obj;
				});
				elementA.order = targetElementOrder;
				targetElement.order--;
			} else {
				elementA.order = targetElementOrder;
				targetElement.order = elementAOrder;
			}
			return array;
		}

		setList(moveElement(array, currentCard, card));
	};

	const sortCards = (a, b) => {
		if (a.order > b.order) {
			return 1;
		} else {
			return -1;
		}
	};

	/* Методы обработки добавления новых навыков */

	const handleAddSkillsModalOpen = () => {
		setAddSkillsModalIsOpen(true);
	};

	const handleAddSkillsModalClose = () => {
		setAddSkillsModalIsOpen(false);
	};

	// обработчик ошибок запроса добавления нескольких навыков
	const addSeveralSkillsRequest = async newSkills => {
		try {
			return await postAddSeveralSkills({
				resumeId: cvData.id,
				newSkills: newSkills
			});
		} catch (requestError) {
			console.error(requestError.message);
			if (requestError.response.status === 401) localStorage.setItem('tokenError', true);
		}
	};

	// метод очистки от дубликатов массивов навыков
	const clearDuplicates = newSkills => {
		// форматируем новые и старые данные в массив навыков в нижнем регистре
		const lowerSkills = list.map(({ value }) => value.toLowerCase());
		const lowerNewSkills = newSkills.map(({ value }) => value.toLowerCase());

		// удаляем все дупликаты
		const skillListSet = new Set([...lowerSkills, ...lowerNewSkills]);

		// возвращаем только новые навыки для отправки на сервер
		return [...skillListSet].filter(skill => !lowerSkills.includes(skill));
	};

	/**
	 * Обработчик добавления нескольких навыков
	 * @param {Array} newSkills - Массив новых навыков для добавления
	 * @returns {Promise<void>} - Промис, который разрешается при успешном добавлении навыков или отклоняется при ошибке
	 */
	const addSeveralSkillsHandler = async newSkills => {
		// получаем новые уникальные значения
		const uniqueNewSkills = clearDuplicates(newSkills);

		// Отправляем запрос на добавление новых навыков
		const response = await addSeveralSkillsRequest(uniqueNewSkills);

		// Если ответ успешно получен
		if (response) {
			// Обновляем список навыков, добавляя новые навыки
			setList(prevSkills => {
				return [...prevSkills, ...response];
			});

			// Показываем уведомление об успешном добавлении навыков
			showAlert({
				message: UPDATING_CV_SKILLS_CONFIG.ALERT_MESSAGE.SUCCESS_ADD_SKILLS,
				type: 'success'
			});

			return;
		}

		// Если ответ не был успешным, показываем уведомление об ошибке
		showAlert({
			message: UPDATING_CV_SKILLS_CONFIG.ALERT_MESSAGE.ERROR_ADD_SKILLS,
			type: 'error'
		});
	};

	return (
		<>
			<Box sx={{ ...boxStyles.flexBoxColumn, gap: 2 }}>
				<Divider sx={{ height: 20, m: '0px 0px 20px 0px' }} />
				<Box
					sx={{
						...boxStyles.flexBoxRow,
						width: '100%',
						alignItems: 'center',
						justifyContent: 'flex-start',
						gap: 5
					}}>
					<Typography
						variant='h6'
						component='h2'
						sx={{
							fontSize: '20px'
						}}>
						Ключевые навыки
					</Typography>
					<Button
						sx={{
							...buttonStyles.orangeButton,
							width: 'fit-content',
							alignSelf: 'center'
						}}
						onClick={handleAddSkillsModalOpen}>
						{UPDATING_CV_SKILLS_CONFIG.ADD_SKILLS_BUTTON_LABEL}
					</Button>
				</Box>

				{list && (
					<Box sx={{ display: 'flex' }}>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
							{list.sort(sortCards).map(item => (
								<Grid key={item.id} item>
									<Box
										onDragStart={e => dragStartHandler(e, item)}
										onDragOver={e => dragOverHandler(e)}
										onDrop={e => dropHandler(e, item)}
										draggable
										sx={{ display: 'flex' }}>
										<Box
											sx={{
												display: 'flex',
												borderRadius: '3px',
												border: '1px solid #ffb74d'
											}}>
											<KeySkillFieldCV textData={item.value} id={item.id} order={item.order} />
										</Box>
										<IconButton
											variant='contained'
											color='inherit'
											sx={{ p: 0 }}
											onClick={() => handleDeleteSkill(item.id)}>
											<DeleteIcon />
										</IconButton>
									</Box>
								</Grid>
							))}
							<Grid item>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										mt: 0.5
									}}>
									<IconButton variant='contained' color='inherit' sx={{ p: 0 }} onClick={handleAddSkill}>
										<AddIcon />
									</IconButton>
								</Box>
							</Grid>
						</Grid>
					</Box>
				)}
			</Box>
			{addSkillsModalIsOpen && (
				<SplitInputModal
					isOpen={addSkillsModalIsOpen}
					onClose={handleAddSkillsModalClose}
					config={UPDATING_CV_SKILLS_CONFIG.ADD_SKILLS_MODAL}
					onProcess={addSeveralSkillsHandler}
					size='fsy'
				/>
			)}
		</>
	);
};
