import React, { useEffect, useState } from 'react';
import { Box, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { StyledLink } from '../../../../../styles/default-styles/styled-link/styled-link';
import { BenchStyleTableRow, BenchTableCell } from './bench-table-row-styles';
import { blue, orange } from '@mui/material/colors';
import { countPercentage } from './bench-map-progress-bar/utils';
import { BenchMapProgressBar } from './bench-map-progress-bar/bench-map-progress-bar';

export const BenchTableRow = ({ data = {}, onClick = () => {} }) => {
	const [countForGraph, setCountForGraph] = useState(null);
	const {
		id,
		bench,
		daysOnBench,
		daysOnBenchStack,
		interviewsCount,
		requestsCount,
		interviewsPercentCount,
		requestsInDay
	} = data;

	const progressBarConfig = [
		{
			id: 0,
			label: `${Math.floor(countForGraph)}% (${interviewsCount}/${requestsCount})`,
			value: countForGraph,
			styles: {}
		}
	];

	useEffect(() => {
		setCountForGraph(countPercentage(interviewsCount, requestsCount));
	}, [data]);

	if (data.divider) {
		return (
			<BenchStyleTableRow>
				<BenchTableCell align='left' sx={{ width: '30%', borderRight: '1px solid black', userSelect: 'text' }} />
				<BenchTableCell colSpan={7} style={{ height: '10px', userSelect: 'text' }} />
			</BenchStyleTableRow>
		);
	}

	return (
		<>
			<BenchStyleTableRow value={id}>
				<BenchTableCell align='left' sx={{ width: '350px', borderRight: '1px solid black', userSelect: 'text' }}>
					<ListItem sx={{ p: 0 }} key={bench.cvId}>
						<ListItemText sx={{ p: 0 }}>
							{bench.stack + ' '}
							<Link
								to={`/candidates/${bench.staffId}`}
								style={{
									...StyledLink.styledOrangeLink,
									fontWeight: '600'
								}}
								target='_blank'
								rel='noopener noreferrer'>
								{bench.shortName + ' '}
							</Link>
							{bench.grade} {bench.totalWorkExpirience} лет опыта, ставка - {bench.rate}₽
						</ListItemText>
					</ListItem>
				</BenchTableCell>
				<BenchTableCell align='center'>{daysOnBench || ''}</BenchTableCell>
				<BenchTableCell align='center'>{requestsCount || ''}</BenchTableCell>
				<BenchTableCell align='center'>{interviewsCount || ''}</BenchTableCell>
				<BenchTableCell align='center'>
					{Math.round(parseFloat(interviewsPercentCount.replace(/%/g, ''))) || ''}
				</BenchTableCell>
				<BenchTableCell align='center'>{requestsInDay || ''}</BenchTableCell>
				<BenchTableCell align='center' sx={{ width: '10%' }}>
					<Box p={0} m={0} sx={{ display: 'flex', justifyContent: 'center', width: '400px', margin: 'auto' }}>
						{!!requestsCount && <BenchMapProgressBar config={progressBarConfig} options={{ fullWidth: '400px' }} />}
					</Box>
				</BenchTableCell>
			</BenchStyleTableRow>
		</>
	);
};
