import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import { boxStyles } from '../../../../styles/default-styles/box-styles/box-styles';

export const CandidateInfo = styled(Box)(() => ({
	...boxStyles.flexBoxRow,
	flexWrap: 'nowrap',
	'@media screen and (max-width: 1000px)': {
		...boxStyles.flexBoxColumn
	}
}));

export const CandidateInfoStacks = styled(Box)(() => ({
	display: 'grid',
	gridTemplateColumns: 'repeat(3, 1fr)',
	gap: '10px',
	'@media screen and (max-width: 1450px)': {
		gridTemplateColumns: 'repeat(1, 1fr)'
	}
}));

export const StyledStack = styled(Stack)(() => ({
	marginTop: '1rem',
	marginLeft: '1rem',
	'@media screen and (max-width: 1000px)': {
		marginLeft: 0
	}
}));
